import { NeedsVerificationMutation } from "$relay/NeedsVerificationMutation.graphql"
import React, { useState } from "react"
import { View } from "react-native"
import { useMutation } from "react-relay"
import { graphql } from "relay-runtime"
import { useTheme } from "styled-rn"

import { useGetUser } from "../../CoreContext"
import { BodyText, BodyTextAnchor, ExternalLink } from "../DesignSystem"

export const hrefToClickForEmail = (email: string) => {
  if (email.includes("gmail")) return `https://mail.google.com/mail/u/#search/puzmo`
  if (email.includes("yahoo")) return `https://mail.yahoo.com/`
  if (email.includes("outlook")) return `https://outlook.live.com/mail/`
  if (email.includes("hotmail")) return `https://outlook.live.com/mail/`
}

const ResendReq = graphql`
  mutation NeedsVerificationMutation {
    requestVerifyPasswordEmailResendForCurrentUser
  }
`

export const NeedsVerification = () => {
  const user = useGetUser()
  const theme = useTheme()

  const [hasSent, setHasSent] = useState(false)

  const [resendMutation] = useMutation<NeedsVerificationMutation>(ResendReq)

  if (user.type !== "user" || user.currentUser.type !== "Unverified") return null

  const email = user.currentUser.accounts[0].email
  const searchURL = hrefToClickForEmail(email)

  const Suffix = () =>
    searchURL ? (
      <ExternalLink to={searchURL}>
        <BodyTextAnchor style={{ color: theme.alwaysDark }}>Please check your inbox</BodyTextAnchor>
      </ExternalLink>
    ) : (
      <BodyText style={{ color: theme.alwaysDark }}>Please check your inbox</BodyText>
    )

  const Resend = () => {
    if (!hasSent) {
      return (
        <BodyTextAnchor
          style={{ color: theme.alwaysDark }}
          onPress={() => {
            resendMutation({
              variables: {},
            })
            setHasSent(true)
          }}
        >
          Resend email
        </BodyTextAnchor>
      )
    } else {
      return <BodyText>Email sent</BodyText>
    }
  }

  return (
    <View style={{ backgroundColor: "#FFAAAC" }}>
      <BodyText style={{ textAlign: "center", padding: 8, color: theme.alwaysDark }}>
        Your email is not verified. <Suffix />
        {". "}
        <Resend />
        {"."}
      </BodyText>
    </View>
  )
}
