import React from "react"
import { View } from "react-native"
import { useTheme } from "styled-rn"
import { useContextSelector } from "use-context-selector"

import { DayInfoBarCalendarButton } from "./DayInfoBarCalendarButton"
import { DayInfoContext } from "./DayInfoContext"
import { DayInfoCountdownBar } from "./DayInfoCountdown"
import { useIsMobile } from "../../hooks/useIsMobile"
import { pluralize } from "../../lib/pluralize"
import { REACT_NAV_HEADER_MARGIN } from "../../navigation"
import { numberFormatter } from "../../util/numberFormatter"
import { BASE_PADDING, BASE_MARGIN, BodyText, LeftArrowIcon, Row, ThinButton, InternalLink } from "../DesignSystem"
import { TimerInfo } from "../TimerInfo"
import { TodayLink } from "../TodayLink"
import { PopoverTooltip } from "../popovers/PopoverTooltip"

export const DAY_INFO_BAR_HEIGHT = 36

function getDaysAgo(start: string | undefined, end: string | undefined) {
  if (!start || !end) return undefined
  const startDay = new Date(start).getTime()
  const endDay = new Date(end).getTime()
  const diff = endDay - startDay
  const days = diff / (1000 * 60 * 60 * 24)
  return Math.round(days)
}

export const DayInfoBar = () => {
  const game = useContextSelector(DayInfoContext, (c) => c.game)
  const dayState = useContextSelector(DayInfoContext, (c) => c.dayState)
  const userState = useContextSelector(DayInfoContext, (c) => c.userState)

  const theme = useTheme()
  const isMobile = useIsMobile()

  if (!userState && !game && !dayState) return null

  const today = dayState && dayState.isToday
  const rightMessages = [] as JSX.Element[]

  const shouldShowScore = !isMobile || (!game && isMobile)
  if (today && shouldShowScore) {
    if (userState && userState.points > 0) {
      rightMessages.push(<BodyText>Daily Score: {userState.points.toLocaleString()}</BodyText>)
    } else if (userState) {
      rightMessages.push(<BodyText>No daily score yet</BodyText>)
    }
  } else if (dayState && !game) {
    rightMessages.push(
      <InternalLink style={{ marginLeft: BASE_MARGIN * 0.5 }} screen="Today" routeOpts={{}}>
        <ThinButton
          bgColor={`${theme.alwaysDark}33`}
          titleStyle={{ color: theme.alwaysDark }}
          title="Go to Today"
          onPress={() => window.location.reload()}
        />
      </InternalLink>
    )
  }

  if (game) {
    rightMessages.push(<TimerInfo size="medium" bold style={{ color: today ? theme.fg : theme.alwaysDark }} />)
  }

  let showTimeLeft = false
  let endOfDay = 0
  if (today) {
    const threeHoursInMS = 3 * 60 * 60 * 1000
    const twentyFourHoursInMS = 24 * 60 * 60 * 1000
    endOfDay = new Date(dayState.day).getTime() + twentyFourHoursInMS
    const now = new Date().getTime()
    const timeLeft = endOfDay - now
    showTimeLeft = timeLeft < threeHoursInMS
  }

  const daysAgo = getDaysAgo(dayState?.day, dayState?.todayDay)

  return (
    <Row
      id="day-info-bar"
      style={{
        maxWidth: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: today ? theme.a_infoBG : theme.a_puzmo,
        paddingHorizontal: isMobile ? REACT_NAV_HEADER_MARGIN : BASE_MARGIN * 2,
        height: DAY_INFO_BAR_HEIGHT,
        position: "relative",
      }}
    >
      <Row centerH>
        {!isMobile && game && (
          <View
            style={{
              position: "relative",
              marginRight: BASE_MARGIN,
            }}
          >
            <TodayLink
              style={{
                display: "flex",
                alignItems: "center",
                height: DAY_INFO_BAR_HEIGHT,
                paddingRight: BASE_PADDING,
              }}
              renderDisplay={(dateString: string) => (
                <PopoverTooltip text={`Go to the home page for ${dateString}`}>
                  <LeftArrowIcon colorKey={today ? "fg" : "alwaysDark"} size={16} />
                </PopoverTooltip>
              )}
            />
            <View
              style={{
                position: "absolute",
                right: 0,
                top: 6,
                bottom: 6,
                borderRightWidth: 1,
                borderRightColor: `${today ? theme.fg : theme.alwaysDark}33`,
              }}
            />
          </View>
        )}
        <DayInfoBarCalendarButton isToday={Boolean(today)} day={dayState?.day || ""} />
        {daysAgo ? (
          <BodyText style={{ marginLeft: BASE_MARGIN * 0.5, fontFamily: "Poppins-Regular" }} customColorKey="alwaysDark">
            ({numberFormatter(daysAgo)}
            {isMobile ? "d" : " " + pluralize("day", daysAgo)} ago)
          </BodyText>
        ) : undefined}

        {showTimeLeft ? (
          <DayInfoCountdownBar style={{ color: today ? theme.fg : theme.alwaysDark, marginLeft: BASE_MARGIN / 2 }} endOfDay={endOfDay} />
        ) : undefined}
      </Row>
      <Row centerH>
        {rightMessages.map((m: JSX.Element, i: number) => {
          return (
            <React.Fragment key={i}>
              {i !== 0 && (
                <View
                  style={{
                    marginLeft: BASE_MARGIN,
                    marginRight: BASE_MARGIN,
                    height: DAY_INFO_BAR_HEIGHT - 12,
                    borderRightWidth: 1,
                    borderRightColor: `${today ? theme.fg : theme.alwaysDark}33`,
                  }}
                />
              )}
              {m}
            </React.Fragment>
          )
        })}
      </Row>
    </Row>
  )
}
