export const actionableNotificationsCounts = (notif: import("@heroiclabs/nakama-js").Notification[]) => {
  let friends = 0
  let groups = 0

  notif.forEach((f) => {
    switch (f.code) {
      case -2: // Friend request
      case -3: // request accepted
        friends++
        break
      case -4: // Accepted into group
      case -5: // wants to join group
        groups++
        break
    }
  })
  return { friends, groups }
}

export const notificationsToObjs = (notif: import("@heroiclabs/nakama-js").Notification) => {}
