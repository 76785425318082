import type { Theme } from "./hostAPI"

/* This file was directly imported from the site repo, which you can run

      yarn rw exec SyncDTS

   To migrate (and others) it across all the puzmo repos.
*/

export const lightTheme: Theme = {
  name: "Puzzmo (light)",
  type: "light" as "light" | "dark",

  /** The bright Pink */
  key: "#FFAAAC",
  /** The color to draw on a key */
  keyFG: "#000000",

  /** A bolder version of the bright pink */
  keyStrong: "#F7868B",
  /** A lighter version of the bright pink */
  keyLight: "#FFD2D3",
  /** The blue you traditionally see around a user avatar and selection */
  player: "#5DBAFC",
  /** The color render text above a players */
  playerFG: "#000000",
  /** A lighter variant on the user's blue */
  playerLight: "#9EDDFF",
  /** Alt color, this one is an earthy green in the main theme */
  alt1: "#98B389",
  /** Alt color 2, this one is a faded yellow which looks quite like the puzmo yellow */
  alt2: "#FAC16C",
  /** Alt color 3, this one is a cute purple */
  alt3: "#D298FF",

  /** The foreground body text color  */
  fg: "#000000",
  /** The red for showing errors */
  error: "#FF3C3C",

  /** Stays dark regardless of being in light/dark mode */
  alwaysDark: "#1B1D29",
  /** Stays light regardless of being in light/dark mode */
  alwaysLight: "#FFFFFF",

  /** The 'tile' usually */
  g_bg: "#FFFFFF",
  /** The 'tile' alternative in checkered patterns */
  g_bgAlt: "#EBEBEB",
  /** Sorta useful for content layers on the existing bg, a darker version */
  g_bgDark: "#D6D6D6",
  /** When _the text_ is on a light background, this is basically black */
  g_textDark: "#1B1B28",
  /** When _the text_ is dark background, this is white */
  g_textLight: "#FFFFFF",
  /** For voids in a game, this is black */
  g_blank: "#000000",
  /** For when a tile is not yet solved, this is a lighter grey */
  g_unsolved: "#C2C2C2",
  /** Border for a game frame, for example the crossword edge */
  g_outline: "#1B1D29",

  /** The light grey you see as the background for most pages */
  a_bg: "#F2F2F2",
  /** The darker grey which usually is seen as borders in the background */
  a_bgAlt: "#ECECEC",
  /** The yellow shade of the puzmonaut */
  a_puzmo: "#FFC000",
  /** Header colors */
  a_headerText: "#000000",
  /** When tabulating results, the background color for an even table row */
  a_table: "#EDEDED",
  /** When tabulating results, the background color for an odd table row */
  a_tableAlt: "#DBDBDB",
  /** Inline tag bg (??) */
  a_inlineTag: "#D9D9D9",
  /** Used to indicate a link */
  a_anchor: "#1F97EE",
  /** The background color of the info bar, couldn't think of a semantic name :D */
  a_infoBG: "#FFFFFF",
}

export const themes: Theme[] = [
  lightTheme,
  {
    name: "Puzzmo (dark)",
    type: "dark" as "light" | "dark",
    key: "#FFAAAC",
    keyFG: "#000000",
    keyStrong: "#F7868B",
    keyLight: "#FFD2D3",
    player: "#5DBAFC",
    playerFG: "#000000",
    playerLight: "#9EDDFF",
    alt1: "#98B389",
    alt2: "#FAC16C",
    alt3: "#D298FF",
    error: "#FF3C3C",

    fg: "#ECECEC",
    alwaysDark: "#1B1D29",
    alwaysLight: "#FFFFFF",

    g_bg: "#374351",
    g_bgAlt: "#333D49",
    g_bgDark: "#2C2F33",
    g_textDark: "#F2F2F2",
    g_textLight: "#20180E",
    g_blank: "#000000",
    g_unsolved: "#747474",
    g_outline: "#646464",

    a_bg: "#141620",
    a_bgAlt: "#202433",
    a_puzmo: "#FFC000",
    a_headerText: "#A1BAD4",
    a_table: "#303246",
    a_tableAlt: "#393b52",
    a_inlineTag: "#ECECEC",
    a_anchor: "#1F97EE",
    a_infoBG: "#282C3A",
  },

  {
    name: "Foshay",
    type: "light" as "light" | "dark",
    key: "#A4D379",
    keyFG: "#313540",
    keyStrong: "#94BF6D",
    keyLight: "#B2E583",
    player: "#56C9E9",
    playerFG: "#292B35",
    playerLight: "#A8B5D6",
    alt1: "#98B389",
    alt2: "#FAC16C",
    alt3: "#D298FF",
    error: "#FF3C3C",

    fg: "#292B35",
    alwaysDark: "#292B35",
    alwaysLight: "#D1DBF2",

    g_bg: "#949EBA",
    g_bgAlt: "#8891AB",
    g_bgDark: "#7B839B",
    g_textDark: "#292B35",
    g_textLight: "#FFFFFF",
    g_blank: "#292B35",
    g_unsolved: "#B6B7BA",
    g_outline: "#676F83",

    a_bg: "#828CA3",
    a_bgAlt: "#7F889F",
    a_puzmo: "#FFC000",
    a_headerText: "#292B35",
    a_table: "#7A8399",
    a_tableAlt: "#767E94",
    a_inlineTag: "#ECECEC",
    a_anchor: "#56C9E9",
    a_infoBG: "#767F95",
  },

  {
    name: "Submersible",
    type: "dark" as "light" | "dark",
    key: "#CD6DC6",
    keyFG: "#031698",
    keyStrong: "#FF7ABC",
    keyLight: "#B467CB",
    player: "#4AB1D1",
    playerFG: "#071D47",
    playerLight: "#3C99D7",
    alt1: "#8BA964",
    alt2: "#C69A58",
    alt3: "#7644B5",
    fg: "#FFEBFF",
    error: "#FF3C3C",
    alwaysDark: "#031698",
    alwaysLight: "#FFEBFF",
    g_bg: "#043BED",
    g_bgAlt: "#0A31CC",
    g_bgDark: "#0E2DA8",
    g_textDark: "#031698",
    g_textLight: "#FFEBFF",
    g_blank: "#031698",
    g_unsolved: "#3662F1",
    g_outline: "#031698",
    a_bg: "#043BED",
    a_bgAlt: "#0A31CC",
    a_puzmo: "#FFC000",
    a_headerText: "#FFEBFF",
    a_table: "#043BED",
    a_tableAlt: "#0A31CC",
    a_inlineTag: "#FF7ABC",
    a_anchor: "#FF7ABC",
    a_infoBG: "#0A31CC",
  },
  {
    name: "Hot Dog (beta)",
    type: "light" as "light" | "dark",

    key: "#FFFF00",
    keyFG: "#000000",

    keyStrong: "#FFE600",
    keyLight: "#FFEC44",
    player: "#FF7A00",
    playerFG: "#000000",
    playerLight: "#FFA450",
    alt1: "#3ABC5E",
    alt2: "#5C3ABC",
    alt3: "#BC3AAF",
    fg: "#000000",
    error: "#FF3C3C",
    alwaysDark: "#1B1D29",
    alwaysLight: "#FFFFFF",
    g_bg: "#EBFF00",
    g_bgAlt: "#EBEBEB",
    g_bgDark: "#D6D6D6",
    g_textDark: "#1B1B28",
    g_textLight: "#FFFFFF",
    g_blank: "#000000",
    g_unsolved: "#C2C2C2",
    g_outline: "#1B1D29",
    a_bg: "#FF0000",
    a_bgAlt: "#E50000",
    a_puzmo: "#FFC000",
    a_headerText: "#FFFFFF",
    a_table: "#E50000",
    a_tableAlt: "#FF2525",
    a_inlineTag: "#000000",
    a_anchor: "#000000",
    a_infoBG: "#C6C6C6",
  },
  {
    name: "Outlook Hayesy (beta)",
    type: "light" as "light" | "dark",
    key: "#DAB98C",
    keyFG: "#000000",
    keyStrong: "#B99368",
    keyLight: "#DAB98C",
    player: "#5DBAFC",
    playerFG: "#000000",
    playerLight: "#9EDDFF",
    alt1: "#5EC386",
    alt2: "#5E93C3",
    alt3: "#C35E5E",
    fg: "#000000",
    error: "#FF3C3C",
    alwaysDark: "#5E390F",
    alwaysLight: "#FFF3E4",
    g_bg: "#FFFFFF",
    g_bgAlt: "#EBEBEB",
    g_bgDark: "#D6D6D6",
    g_textDark: "#1B1B28",
    g_textLight: "#FFFFFF",
    g_blank: "#000000",
    g_unsolved: "#C2C2C2",
    g_outline: "#1B1D29",

    a_bg: "#FEF5E8",
    a_bgAlt: "#ffffff",
    a_puzmo: "#FAC7CE",
    a_headerText: "#000000",
    a_table: "#FAE8D3",
    a_tableAlt: "#EED7BC",
    a_inlineTag: "#D9D9D9",
    a_anchor: "#B99368",
    a_infoBG: "#FFFFFF",
  },
]

export type PuzmoTheme = typeof lightTheme

// Useless JS until we get 'satisfies' in TS 4.9
const validThemes = (theme: readonly Theme[]): theme is PuzmoTheme[] => true
validThemes(themes)
