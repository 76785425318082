import { ThinButton, InternalLink } from "$components/DesignSystem"
import React from "react"
import { View } from "react-native"
import { useTheme } from "styled-rn"

import type { AllPitchKeys } from "../screens/SubscribeScreen"

export const SubscribeToPayOverlay = (props: {
  title?: string
  focus?: AllPitchKeys
  onPress?: () => void
  children?: React.ReactNode
}) => {
  const theme = useTheme()
  return (
    <View style={{ position: "relative" }}>
      {props.children}

      <View
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: theme.a_bg + "bb",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <InternalLink onPress={props.onPress} screen="Subscribe" routeOpts={{ focus: props.focus || "all-games" }}>
          <ThinButton colorKey="alwaysDark" bgKey="a_puzmo" title={props.title || "Subscribe to play"} />
        </InternalLink>
      </View>
    </View>
  )
}
