import { OverlayContext } from "$components/socialOverlay/OverlayContext"
import { DayInfoContext } from "$components/today/DayInfoContext"
import React from "react"
import { Text } from "react-native"
import { useTheme } from "styled-rn"
import { useContextSelector } from "use-context-selector"

import { useIsMobile } from "../../hooks/useIsMobile"
import { getPlayerReadableDateString } from "../../lib/getPlayerReadableDateString"
import { AnimatedPressableBase } from "../DesignSystem"
import { CalendarIcon } from "../icons/CalendarIcon"
import { PopoverTooltip } from "../popovers/Popovers"

function Label({ day, color }: { day: string; color: string }) {
  const isMobile = useIsMobile()
  const screen = useContextSelector(DayInfoContext, (c) => c.screen)

  let labelText = getPlayerReadableDateString(day, { short: isMobile })

  if (screen === "TodayRecap") {
    labelText = `Recap: ${labelText}`
  }

  return (
    <Text
      selectable={false}
      style={{
        fontFamily: "Poppins-SemiBold",
        fontSize: 18,
        color,
        marginLeft: 6,
        display: "flex",
        alignItems: "center",
      }}
    >
      {labelText}
    </Text>
  )
}

export function DayInfoBarCalendarButton(props: { isToday: boolean; day: string }) {
  const { day, isToday } = props
  const setCalendarOverlay = useContextSelector(OverlayContext, (c) => c.setCalendarOverlay)
  const theme = useTheme()

  return (
    <PopoverTooltip text="Browse past days">
      <AnimatedPressableBase
        onPress={() => setCalendarOverlay(true)}
        style={{ flexDirection: "row", alignItems: "center" }}
        hitSlop={{ top: 4, bottom: 4 }}
      >
        <CalendarIcon width={22} fill={isToday ? "a_puzmo" : "alwaysDark"} />
        <Label day={day} color={isToday ? theme.fg : theme.alwaysDark} />
      </AnimatedPressableBase>
    </PopoverTooltip>
  )
}
