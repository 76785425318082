/**
 * @generated SignedSource<<bad3532befa7dda5563d15f87a56b4bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompleteGamePlayedInput = {
  additionalTimeAddedSecs?: number | null;
  boardState: string;
  cheatsUsed: number;
  collabUserReferences: ReadonlyArray<string | null>;
  elapsedTimeSecs: number;
  hintsUsed: number;
  metric1: number;
  metric2: number;
  metric3: number;
  metric4: number;
  metricStrings: ReadonlyArray<string>;
  pauses: number;
  pipelineStats: any;
  pointsAwarded: number;
};
export type GamePlayUpdateMutationsGameCompleteMutation$variables = {
  id: string;
  input: CompleteGamePlayedInput;
};
export type GamePlayUpdateMutationsGameCompleteMutation$data = {
  readonly completeGameState: {
    readonly id: string;
    readonly puzzle: {
      readonly " $fragmentSpreads": FragmentRefs<"CurrentUserPuzzleThumbnail">;
    };
  } | null;
};
export type GamePlayUpdateMutationsGameCompleteMutation = {
  response: GamePlayUpdateMutationsGameCompleteMutation$data;
  variables: GamePlayUpdateMutationsGameCompleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GamePlayUpdateMutationsGameCompleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GamePlayed",
        "kind": "LinkedField",
        "name": "completeGameState",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Puzzle",
            "kind": "LinkedField",
            "name": "puzzle",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CurrentUserPuzzleThumbnail"
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GamePlayUpdateMutationsGameCompleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GamePlayed",
        "kind": "LinkedField",
        "name": "completeGameState",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Puzzle",
            "kind": "LinkedField",
            "name": "puzzle",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "gameID",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "puzzle",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "GamePlayed",
                "kind": "LinkedField",
                "name": "currentAccountGamePlayed",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "boardState",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ownerID",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "completed",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "metric1",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "metric2",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "metric3",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "metric4",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "metricStrings",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "viewerPlayed",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Game",
                "kind": "LinkedField",
                "name": "game",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "jsPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "assetsSha",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "thumbnailJSPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "thumbnailGlobalFunction",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b2a7f3084c27021b94f5fcd54ac8cf82",
    "id": null,
    "metadata": {},
    "name": "GamePlayUpdateMutationsGameCompleteMutation",
    "operationKind": "mutation",
    "text": "mutation GamePlayUpdateMutationsGameCompleteMutation(\n  $id: ID!\n  $input: CompleteGamePlayedInput!\n) {\n  completeGameState(id: $id, input: $input) {\n    puzzle {\n      ...CurrentUserPuzzleThumbnail\n      id\n    }\n    id\n  }\n}\n\nfragment CurrentUserPuzzleThumbnail on Puzzle {\n  id\n  name\n  gameID\n  puzzle\n  slug\n  currentAccountGamePlayed {\n    id\n    boardState\n    slug\n    ownerID\n    completed\n    metric1\n    metric2\n    metric3\n    metric4\n    metricStrings\n    viewerPlayed\n  }\n  game {\n    id\n    slug\n    jsPath\n    assetsSha\n    thumbnailJSPath\n    thumbnailGlobalFunction\n  }\n}\n"
  }
};
})();

(node as any).hash = "98e86c1f3ba58902365928cb353cf936";

export default node;
