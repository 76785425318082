import { useCallback, useState } from "react"
import { createContext } from "use-context-selector"

import { RootStackParamList } from "../../../types"

type DayInfoGame = {
  slug: string
  displayName: string
}

type DayInfoUserState = {
  streak: any
  points: number
}

type DayInfoDayState = {
  day: string
  isToday: boolean
  todayDay: string
}

type Screen = "Today" | "TodayRecap" | "PlayGame"

export type setDayInfoDataParams = { screen?: Screen; game?: DayInfoGame; dayState?: DayInfoDayState; userState?: any }
export interface DayInfoContextType {
  game: DayInfoGame | undefined
  dayState: DayInfoDayState | undefined
  userState: DayInfoUserState | undefined
  screen: keyof RootStackParamList
  setDayInfoData: (data: setDayInfoDataParams) => void
}

export const DayInfoContext = createContext<DayInfoContextType>({} as any)

export const DayInfoContextProvider = ({ children }: { children: React.ReactNode }) => {
  // game only exists on play game pages
  // make sure to clear it when closing play game page.
  const [game, setGame] = useState<any>()

  // day and userState should always exist
  const [dayState, setDayState] = useState<DayInfoDayState>()
  const [userState, setUserState] = useState<any>()
  const [screen, setScreen] = useState<Screen>("Today")
  const setDayInfoData = useCallback((data: setDayInfoDataParams) => {
    setGame(data.game)
    setDayState(data.dayState)
    setUserState(data.userState)
    setScreen(data.screen || "Today")
  }, [])

  return (
    <DayInfoContext.Provider
      value={{
        setDayInfoData,
        game,
        dayState,
        userState,
        screen,
      }}
    >
      {children}
    </DayInfoContext.Provider>
  )
}
