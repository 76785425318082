/**
 * @generated SignedSource<<2c710e917e7fe941087400b016e89e36>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserType = "Paid" | "Unverified" | "User" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UserSettingsAvatarSelector$data = {
  readonly avatarURL: string;
  readonly id: string;
  readonly type: UserType;
  readonly " $fragmentType": "UserSettingsAvatarSelector";
};
export type UserSettingsAvatarSelector$key = {
  readonly " $data"?: UserSettingsAvatarSelector$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserSettingsAvatarSelector">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserSettingsAvatarSelector",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatarURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "fd8fc29f88ca46c41c016012af5647d7";

export default node;
