import { singleNakamaSessionContext } from "$components/app/NakamaSingleSessionBlocker"
import { ChatRow$key } from "$relay/ChatRow.graphql"
import { ChatRowGroup$key } from "$relay/ChatRowGroup.graphql"
import { FriendRequestView$key } from "$relay/FriendRequestView.graphql"
import { useBootstrapNakamaDataQuery$data } from "$relay/useBootstrapNakamaDataQuery.graphql"
import {
  Channel,
  ChannelMessage,
  Client,
  MatchData,
  MatchPresenceEvent,
  Notification,
  Presence,
  Session,
  Socket,
  WebSocketAdapterText,
} from "@heroiclabs/nakama-js"
import { useContext as useReactContext, useCallback, useEffect, useReducer, useRef, useMemo } from "react"
import type { IMessage } from "react-native-gifted-chat"
import { useRelayEnvironment } from "react-relay"
import { createContext, useContext, useContextSelector } from "use-context-selector"

import { updateFriendRequests } from "./useBootstrapNakamaData"
import { AppContext } from "../../AppContext"
import { useGetUser } from "../CoreContext"
import { createEventEmitter, makeUseEmitterSubscription } from "../lib/createEmitter"
import { Environment, isTests } from "../lib/environment"
import { NakamaChatMesssageUser, createChatUserTracker } from "../screens/Social/chatUserTracker"

import { inviteTimeoutMS, notificationTypes } from "$consts/constants"

/** Things to know:
 *
 * - The session is an auth'd REST api connection to the server (think, "add friend")
 * - The socket is a persistent connection to the server (think, "chat" or "multiplayer game")
 *
 * We model both of these inside the state!
 */

export type SocketStateContextType = {
  socket: Socket
  socketState: NakamaSocketState | undefined
  client: Client
  socketStateDispatch: React.Dispatch<SocketAction>
  getSession: () => Promise<Session>
  // socketEvents: ReturnType<typeof createEventEmitter<NakamaLiveEvents>>
  useSocketEventsSubscription: <Key extends keyof NakamaLiveEvents>(
    cacheKey: string,
    type: Key,
    callback: (data: NakamaLiveEvents[Key]) => void
  ) => void

  joinChat: (target: string, type: number, persistence: boolean, hidden: boolean) => Promise<Channel | undefined>
  writeMessages: (channelID: string, messages: IMessage[], friendNakamaID?: string) => Promise<void>
  getHistoryForChannel: (channel: Channel, previousCursor?: string) => Promise<void>
}

export const initialState: NakamaSocketState = {
  authed: "disconnected",
  socket: "disconnected",

  nakamaID: undefined,

  socialUpdateTime: new Date(),
  totalBadgeCount: 0,
  friendBadgeCount: 0,
  groupBadgeCount: 0,

  onlineViaUsername: new Set(),
  onlineViaNakamaID: new Set(),

  friendChats: null,
  groupChats: null,
  notifications: null,
  friendRequests: null,

  chatHistory: new Map(),
  currentChatRoom: undefined,
  roomToChannelID: new Map(),

  invitesViaNakamaID: new Map(),
}

type Chat<ChatType> = {
  // username#id / group#id
  id: string
  // The model for the row
  sender: ChatType
  // E.g. should there be a dot or something
  seen: boolean

  mostRecentMessage: string | null
  mostRecentMessageTime: string | null
}

type UserPrimitives = { username: string; usernameID: string; avatarURL: string; nakamaID: string }

export type NakamaSocketState = {
  authed: "connecting" | "refreshing" | "connected" | "disconnected"
  socket: "connecting" | "connected" | "disconnected"

  nakamaID: string | undefined

  socialUpdateTime: Date
  totalBadgeCount: number
  friendBadgeCount: number
  groupBadgeCount: number

  // So you can do a quick lookup of who's online via something like orta#puz
  onlineViaUsername: Set<string>
  // So you can do a quick lookup of who's online via a users nakamaID
  onlineViaNakamaID: Set<string>

  friendRequests: null | Set<FriendRequestView$key & { username: string; userId: string }>

  // Null being "not loaded yet"
  friendChats: null | Set<Chat<ChatRow$key & UserPrimitives>>
  groupChats: null | Set<Chat<ChatRowGroup$key>>

  // Most of the user-facing code only has something like the group slug or userID and can't magic a channelID
  // as that's an arbitrary string. So we store the mapping here.
  roomToChannelID: Map<string, Channel>

  currentChatRoom: string | undefined
  // ChannelID -> Everything useful for a chat screen
  chatHistory: Map<string, { channel: Channel; history: IMessage[]; nextCursor?: string; previousCursor?: string }>

  notifications: null | Notification[]

  invitesViaNakamaID: Map<string, { toGameSlug: string; toGameplaySlug: string; nakamaID: string; dateSent: Date }>
}

export type SocketAction =
  | { type: "reset" }
  | { type: "setAuth"; value: NakamaSocketState["authed"] }
  | { type: "setNakamaID"; value: string | undefined }
  | { type: "setSocket"; value: NakamaSocketState["socket"] }
  | { type: "updateFriendChats"; chats: NakamaSocketState["friendChats"] }
  | {
      type: "updateFriendBlurbs"
      blurbs: NonNullable<useBootstrapNakamaDataQuery$data["currentUser"]>["mostRecentDistinctChats"]["friends"]
    }
  | { type: "updateFriendRequests"; requests: NakamaSocketState["friendRequests"] }
  | { type: "gotNotifications"; notifications: Notification[] }
  | { type: "gotStatusUpdate"; update: { joins?: Presence[]; leaves?: Presence[] } }
  | { type: "removeInvite"; gameplaySlug: string }
  | { type: "joinedGame"; gameplaySlug: string }
  | { type: "removeFriendRequest"; nakamaID: string }
  | { type: "appendHistoryToChat"; channel: Channel; history: IMessage[]; nextCursor?: string; previousCursor?: string }
  | { type: "connectRoomNameToChannel"; room: string; channel: Channel }
  | { type: "gotChannelMessages"; channelID: string; giftMessages: IMessage[]; sendMeta: { friendID?: string } }
  | { type: "updateSocialUpdateTime"; time: Date }
  | { type: "joinedChatRoom"; roomID: string }
  | { type: "leftChatRoom"; roomID: string }
  | { type: "updateUserInChatMessages"; channelID: string; nakamaID: string; user: NakamaChatMesssageUser }

export type NakamaLiveEvents = {
  channelMessage: ChannelMessage
  onNewFriendRequest: Notification
  onMatchPresence: MatchPresenceEvent
  onMatchData: MatchData
  onNotification: Notification
}

function socketStateReducer(state: NakamaSocketState, action: SocketAction): NakamaSocketState {
  switch (action.type) {
    case "reset":
      return { ...initialState }
    case "setAuth":
      return { ...state, authed: action.value }
    case "setSocket":
      return { ...state, socket: action.value }
    case "setNakamaID":
      if (state.nakamaID === action.value) return state
      return { ...state, nakamaID: action.value }

    case "updateFriendChats":
      return { ...state, friendChats: action.chats }

    // ---------------------------- Online/Offline Friends ----------------------------

    case "gotStatusUpdate": {
      let madeChanges = false

      action.update.joins?.forEach((b) => {
        const alreadyThere = state.onlineViaNakamaID.has(b.user_id)
        if (!alreadyThere) {
          state.onlineViaNakamaID.add(b.user_id)
          state.onlineViaUsername.add(b.username)
          madeChanges = true
        }
      })

      action.update.leaves?.forEach((b) => {
        const alreadyThere = state.onlineViaNakamaID.has(b.user_id)
        if (alreadyThere) {
          state.onlineViaNakamaID.delete(b.user_id)
          state.onlineViaUsername.delete(b.username)
          madeChanges = true
        }
      })

      if (madeChanges)
        return {
          ...state,
          onlineViaNakamaID: new Set([...state.onlineViaNakamaID]),
          onlineViaUsername: new Set([...state.onlineViaUsername]),
        }
      return state
    }

    case "updateFriendBlurbs": {
      if (!state.friendChats) throw new Error("Cant have blurbs without friendChats set up")

      let friendBadgeCount = state.friendBadgeCount
      const friendChatsMap = new Map([...state.friendChats!.values()].map((f) => [f.id, f]))
      action.blurbs.forEach((b) => {
        const f = friendChatsMap.get(b.puzmoUser.id)
        if (f) {
          f.mostRecentMessage = b.content
          f.mostRecentMessageTime = b.createdAt
          f.seen = b.seen
          if (!b.seen) {
            friendBadgeCount = (friendBadgeCount || 0) + 1
          }
        }
      })

      return { ...state, friendChats: new Set([...friendChatsMap.values()]), friendBadgeCount }
    }

    // ---------------------------- Notifications ----------------------------
    // ---------------------------- /w Invites ----------------------------

    case "gotNotifications": {
      let somethingChanged = false

      const notifications = action.notifications

      const invites = notifications.filter((n) => (n.code || -1) === notificationTypes.gameInvite)
      if (invites.length) {
        somethingChanged = true
        invites.forEach((i) => {
          if (i.sender_id && i.create_time && i.content)
            state.invitesViaNakamaID.set(i.sender_id, {
              toGameplaySlug: (i.content as any).toGameplaySlug,
              toGameSlug: (i.content as any).toGameSlug,
              nakamaID: i.sender_id,
              dateSent: new Date(i.create_time),
            })
        })
      }

      if (!somethingChanged) return state
      else return { ...state, invitesViaNakamaID: new Map([...state.invitesViaNakamaID]) }
    }

    case "joinedGame": {
      const inviteValues = [...state.invitesViaNakamaID.values()]
      const foundInvite = inviteValues.find((i) => i.toGameplaySlug === action.gameplaySlug)
      if (!foundInvite) return state

      const newValues = inviteValues.filter((i) => i.toGameplaySlug !== action.gameplaySlug)
      return { ...state, invitesViaNakamaID: new Map(newValues.map((i) => [i.nakamaID, i])) }
    }

    case "removeInvite": {
      const inviteValues = [...state.invitesViaNakamaID.values()]
      const newValues = inviteValues.filter((i) => i.toGameplaySlug !== action.gameplaySlug)
      return { ...state, invitesViaNakamaID: new Map(newValues.map((i) => [i.nakamaID, i])) }
    }

    // ---------------------------- Friend Requests ----------------------------

    case "updateFriendRequests": {
      return { ...state, friendRequests: action.requests }
    }

    case "removeFriendRequest": {
      if (!state.friendRequests) return state
      const newRequests = [...state.friendRequests].filter((r) => r.userId !== action.nakamaID)
      return { ...state, friendRequests: new Set(newRequests) }
    }

    // ---------------------------- Chat ----------------------------

    case "joinedChatRoom": {
      return { ...state, currentChatRoom: action.roomID }
    }

    case "leftChatRoom": {
      return { ...state, currentChatRoom: undefined }
    }

    case "connectRoomNameToChannel":
      return { ...state, roomToChannelID: new Map([...state.roomToChannelID.entries(), [action.room, action.channel]]) }

    case "appendHistoryToChat": {
      const channel = action.channel
      const existing = state.chatHistory.has(channel.id)
      if (!existing) {
        state.chatHistory.set(channel.id, action)
      } else {
        const existing = state.chatHistory.get(channel.id)!
        state.chatHistory.set(channel.id, {
          channel,
          history: [...existing.history, ...action.history],
          previousCursor: action.previousCursor,
          nextCursor: action.nextCursor,
        })
      }

      return { ...state, chatHistory: new Map([...state.chatHistory.entries()]) }
    }

    case "gotChannelMessages": {
      const channelID = action.channelID

      // Prepend the messages to the start of history for this channel
      const existingChannel = state.chatHistory.has(channelID)
      if (existingChannel) {
        const existing = state.chatHistory.get(channelID)!
        state.chatHistory.set(channelID, {
          channel: existing.channel,
          history: [...action.giftMessages, ...existing.history],
          previousCursor: existing.previousCursor,
          nextCursor: existing.nextCursor,
        })
      }

      let friendBadgeCount = state.friendBadgeCount

      // Update the friend blurb for this channel
      const hasFriendUpdate = state.friendChats && action.sendMeta.friendID
      if (state.friendChats && action.sendMeta.friendID) {
        const friendChatsMap = new Map([...state.friendChats.values()].map((f) => [f.sender.nakamaID, f]))
        const friendChat = friendChatsMap.get(action.sendMeta.friendID)

        if (friendChat) {
          const latestMessage = [...action.giftMessages].sort((a, b) => b.createdAt.toString().localeCompare(a.createdAt.toString()))[0]
          friendChat.mostRecentMessage = latestMessage.text
          friendChat.mostRecentMessageTime = latestMessage.createdAt.toString()
          const senderIsNotViewer = latestMessage.user._id !== state.nakamaID
          const inChat = state.roomToChannelID.get(state.currentChatRoom || "-")?.id === channelID
          const seen = senderIsNotViewer && !inChat
          if (!seen) {
            friendChat.seen = false
            friendBadgeCount = (friendBadgeCount || 0) + 1
          }
        } else {
          console.log("Got message from friend, but no friend chat exists")
        }
      }

      return {
        ...state,
        chatHistory: new Map([...state.chatHistory.entries()]),
        friendChats: hasFriendUpdate && state.friendChats ? new Set([...state.friendChats.values()]) : state.friendChats,
        friendBadgeCount,
      }
    }

    case "updateUserInChatMessages": {
      const { channelID, user } = action

      const existingChannel = state.chatHistory.has(channelID)
      if (existingChannel) {
        const existing = state.chatHistory.get(channelID)!
        state.chatHistory.set(channelID, {
          channel: existing.channel,
          history: existing.history.map((m) => {
            if (m.user._id === action.user.nakamaID) {
              return { ...m, user: { _id: user.nakamaID, avatar: user.avatarURL, name: user.username } }
            } else {
              return m
            }
          }),
          previousCursor: existing.previousCursor,
          nextCursor: existing.nextCursor,
        })

        return { ...state, chatHistory: new Map([...state.chatHistory.entries()]) }
      }

      return state
    }

    case "updateSocialUpdateTime": {
      return { ...state, socialUpdateTime: action.time }
    }

    // default: {
    //   // console.log(`Unknown type: ${c.type}`)
    //   const exhaustiveCheck: never = action.type
    //   throw new Error(exhaustiveCheck)
    // }
  }
}

/** Things we want to handle WRT the socket connectivity:
 *
 * 1. There should only be one socket connection per session (dupes would trigger the server-side disconnect)
 * 2. We need to handle switching users / logging in / logging out, which requires dropping the socket and reconnecting with a new session
 * 3. We need to handle the socket disconnecting, which requires dropping the socket, maybe creating a new session and reconnecting
 * 4. When booted for being in a single session, we should not auto-reconnect. Ideally we have a UI which allows you to say "I closed the other tabs" though.
 * 5. We need to add our hooks to the socket, so we can actually do the state management above
 * 6. A way to get the session, which is needed for using the nakama rest API for things like friending / group accepts etc
 *
 * Disconnects can be detected by:
 *  - socket.ondisconnect
 *  - socket.connect throwing
 */

export function useNakamaSocket() {
  const showingNakamaLogs = typeof localStorage !== "undefined" && localStorage.getItem("showNakamaLogs") === "true"
  const log = useCallback((...args: any[]) => (showingNakamaLogs ? console.log("🎮🔒", ...args) : () => {}), [showingNakamaLogs])

  const [socketState, socketStateDispatch] = useReducer(socketStateReducer, initialState)

  const { environment, reTriggerUserInfo } = useReactContext(AppContext)
  const user = useGetUser()
  // const viewerNakamaID = user.currentUser?.nakamaID || user.userState?.nakamaLogin

  const relayEnv = useRelayEnvironment()
  const client = useMemo(() => new Client(...createClientParamsFromEnv(environment)), [environment])

  const socket = useRef<Socket & { adaptor: WebSocketAdapterText; nakamaLogin: string }>(
    client.createSocket(...createSocketParamsFromEnv(environment)) as any
  )

  /** Looks into the socket object to see if it is connected */
  const socketIsConnected = useCallback(() => socket.current && !!socket.current.adaptor && socket.current.adaptor.isOpen(), [socket])
  /** Looks into the socket object to see if it is connected */
  const getRawSocketObj = useCallback<() => WebSocket | undefined>(
    // @ts-expect-error
    () => socket.current && !!socket.current.adaptor && socket.current.adaptor._socket,
    [socket]
  )

  /** Digs into the real socket obj and figures out if it is still connecting */
  const socketIsConnecting = useCallback(() => {
    const s = getRawSocketObj()
    if (s && s.readyState === WebSocket.CONNECTING) return true
    return false
  }, [getRawSocketObj])

  /** Closes the socket via its own APIs */
  const socketClose = useCallback(() => socket.current && socket.current.adaptor.close(), [socket])

  /** Re-creates the websocket, if it doesn't have the same login as the current one */
  const recreateSocket = useCallback(() => {
    if (socket.current.nakamaLogin === user.userState?.nakamaLogin) {
      log("NOOPing on recreate because its the same user")
      return
    }

    if (socketIsConnected()) {
      socketClose()
    }

    socket.current = client.createSocket(...createSocketParamsFromEnv(environment)) as any
    socket.current.nakamaLogin = user.userState?.nakamaLogin || ""
    socketStateDispatch({ type: "reset" })
  }, [user.userState?.nakamaLogin, socketIsConnected, client, environment, log, socketClose])

  const session = useRef<Session | undefined>(undefined)

  const authenticating = useRef(false) // [authenticating, setAuthenticating] = useState(false)

  // Lets other components subscribe to specific socket events
  const socketEvents = useRef(createEventEmitter<NakamaLiveEvents>())
  const useSocketEventsSubscription = useMemo(() => makeUseEmitterSubscription<NakamaLiveEvents>(socketEvents.current), [])

  const { setIsInSingleSession } = useReactContext(singleNakamaSessionContext)

  const userTracker = useMemo(
    () =>
      createChatUserTracker(relayEnv, user, (channelID, nakamaID, user) => {
        socketStateDispatch({ type: "updateUserInChatMessages", channelID, nakamaID, user })
      }),
    [relayEnv, user]
  )

  const connectWithUserState = useCallback(() => {
    if (isTests) return Promise.resolve({} as Session)
    // if (session.current) return Promise.resolve(session.current!)

    // No session, start the login process
    const isAnon = user.type === "anon"
    const email = user.userState.nakamaLogin
    const username = user.type === "anon" ? user.userState.ownerID : `${user.currentUser.username}#${user.currentUser.usernameID}`
    const hasCredentials = email && user.userState.nakamaPassword
    if (!hasCredentials) {
      console.log("Cant connect, no credentials")
      return Promise.reject(new Error("No credentials"))
    }

    socketStateDispatch({ type: "setAuth", value: "connecting" })
    return client.authenticateEmail(email, user.userState.nakamaPassword, isAnon, username)
  }, [
    user.currentUser?.username,
    user.currentUser?.usernameID,
    user.type,
    user.userState.nakamaLogin,
    user.userState.nakamaPassword,
    user.userState.ownerID,
    client,
  ])

  const getSession = useCallback(async () => {
    // like us, they use a two stage JWT - so roughly, a session token (short lived) and a refresh token (longer lived)
    // we can refresh the session when the short one has expired with the long one

    // https://heroiclabs.com/docs/nakama/client-libraries/javascript/#session-lifecycle
    if (session.current) {
      const isExpired = session.current.isexpired(Date.now() / 1000)

      // Already set up, return straight away if not expired
      if (!isExpired) return session.current

      if (isExpired) {
        log("Nakama session expired, refreshing")
        socketStateDispatch({ type: "setAuth", value: "refreshing" })
        const newSession = await client.sessionRefresh(session.current)

        log("Refreshed session")
        socketStateDispatch({ type: "setAuth", value: "connected" })
        session.current = newSession

        return newSession
      }

      // If it's the refresh token which expired, fall through to the login system
      // because we can't refresh anymore
      if (session.current.isrefreshexpired(Date.now() / 1000)) {
        log("Nakama refresh token expired, re-logging in")
      }
    }

    // Currently authenticating, return a promise that will resolve when the auth is complete
    if (socketState.authed === "connecting" || socketState.authed === "refreshing") {
      log("Waiting for new session to complete")
      return new Promise<Session>((resolve) => {
        const timer = setInterval(() => {
          if (session) {
            clearInterval(timer)
            // TODO: We should maybe make session | undefiend in getSession
            authenticating.current = false
          } else {
            console.log("Waiting for auth to complete")
          }
        }, 100)
      })
    }

    return connectWithUserState()
  }, [session, authenticating, connectWithUserState, client, log, socketState.authed])

  // Disconnect, and reconnect when you switch users
  useEffect(() => {
    if (socketState.authed !== "connected") return
    log("Switching user due to nakama login change")

    recreateSocket()
    connectWithUserState()
  }, [log, connectWithUserState, client, recreateSocket, user.userState.nakamaLogin, user.userState.nakamaPassword, socketState.authed])

  const userLookup = useCallback(
    (nakamaID: string, channelID: string): UserPrimitives | undefined => {
      // gotta go async
      const userMaybe = userTracker.getUserInfo(channelID, nakamaID)
      if (userMaybe && "username" in userMaybe) return userMaybe
    },
    [userTracker]
  )

  const backupURL = environment.CDNPath("assets/puzicon.png")
  const writeMessages: SocketStateContextType["writeMessages"] = useCallback(
    async (channelID, messages) => {
      // This will send through new messages to the history via the onchannelmessage further down
      for (const message of messages) {
        if (message.text) await socket.current.writeChatMessage(channelID, { text: message.text })
      }
    },
    [socket]
  )

  const getHistoryForChannel = useCallback(
    async (channel: Channel, previousCursor?: string) => {
      const liveSession = await getSession()
      if (!liveSession) return
      const history = await client.listChannelMessages(liveSession, channel.id, 50, false, previousCursor)
      socketStateDispatch({
        type: "appendHistoryToChat",
        channel,
        previousCursor: history.prev_cursor,
        nextCursor: history.next_cursor,
        history: (history.messages || []).map((m) => makeNakamaMsgToGiftChatMsg(userLookup, backupURL, m)).filter(notNull),
      })
    },
    [backupURL, getSession, userLookup, client]
  )

  const joinChat = useCallback(
    async (room: string, type: number, persistence: boolean, hidden: boolean) => {
      if (socketState.roomToChannelID.has(room)) return socketState.roomToChannelID.get(room)

      const channel = await socket.current.joinChat(room, type, persistence, hidden)
      socketStateDispatch({ type: "joinedChatRoom", roomID: room })
      socketStateDispatch({ type: "connectRoomNameToChannel", room, channel })

      await getHistoryForChannel(channel)
    },
    [socket, socketState.roomToChannelID, getHistoryForChannel]
  )

  // System-wide hooks to socket events
  useEffect(() => {
    const liveSocket = socket.current
    liveSocket.ondisconnect = (ev) => {
      socketStateDispatch({ type: "setAuth", value: "disconnected" })
      setTimeout(() => {
        // Resets the socket, and reconnects
        recreateSocket()

        // We want to re-auth as if you're in a match we want to stay connected
        getSession().then((s) => {
          liveSocket.connect(s, true).catch(() => {
            console.error("Failed to reconnect from a disconnect")
            socketStateDispatch({ type: "setAuth", value: "disconnected" })
          })
        })
      }, 500)
    }

    // https://heroiclabs.com/docs/nakama/concepts/sockets/#socket-events

    liveSocket.onnotification = (notification) => {
      // You've have puzzmo open in another tab, this means we need to show that
      // note: this currently doesn't work!
      if (notification.code === notificationTypes.singleSessionDisconnect) {
        setIsInSingleSession(true)
      }

      // Right now this pulls out invites also? Not quite sure the value of that
      socketStateDispatch({ type: "gotNotifications", notifications: [notification] })

      // Allows other components to subscribe to this event, notably the playgame screen
      socketEvents.current.emit("onNotification", notification)

      if (notification.code === notificationTypes.gameInvite) {
        // @ts-ignore
        const gameplaySlug = notification.content?.toGameplaySlug
        if (gameplaySlug) setTimeout(() => socketStateDispatch({ type: "removeInvite", gameplaySlug }), inviteTimeoutMS)
      }

      // We goyt a notification about a friend request, we need the data to show it
      else if (notification.code === notificationTypes.userRequest) {
        updateFriendRequests(relayEnv, socketStateDispatch)
      }

      // We got a notification about an accepted friend request, we need to update the current user
      else if (notification.code === notificationTypes.friendRequestAccepted) {
        reTriggerUserInfo()
      } else if (notification.code === notificationTypes.someoneOpenedChat) {
        // We want to start up a chat with this person, this gives us
        // the chance to get messages from someone without having to
        // open the chat first!

        if (!notification.content) return

        const { username } = typeof notification.content === "string" ? JSON.parse(notification.content) : notification.content
        if (!username) return

        const nakamaID = notification.sender_id
        if (!nakamaID) return

        // Connect to their chat so we get notifications, and grab their first 25 messages as a pre-loader
        // in case you open the channel
        joinChat(nakamaID, 2, true, false)
      }
    }

    liveSocket.onstatuspresence = (presence) => {
      socketStateDispatch({ type: "gotStatusUpdate", update: presence })
    }

    liveSocket.onstreampresence = (presence) => {
      socketStateDispatch({ type: "gotStatusUpdate", update: presence })
    }

    liveSocket.onmatchpresence = (presence) => {
      socketEvents.current.emit("onMatchPresence", presence)
    }

    liveSocket.onmatchdata = (data) => {
      // Forward this to the 'useCoopWebhook' which does the coordinating between
      // the iframe and the app
      socketEvents.current.emit("onMatchData", data)
    }

    liveSocket.onchannelpresence = (presence) => {
      socketStateDispatch({ type: "gotStatusUpdate", update: presence })
    }

    liveSocket.onchannelmessage = (message) => {
      // socketEvents.current.emit("channelMessage", message)
      const giftMessage = makeNakamaMsgToGiftChatMsg(userLookup, backupURL, message)
      if (!giftMessage) {
        console.error("Got a message we couldn't parse", message)
        return
      }

      const sendMeta = {
        // Attach the friendID to the message so we can use it to update the blurbs
        friendID: message.room_name
          ? undefined
          : message.user_id_one === user.currentUser?.nakamaID
          ? message.user_id_two
          : message.user_id_one,
      }
      socketStateDispatch({ type: "gotChannelMessages", channelID: message.channel_id, giftMessages: [giftMessage], sendMeta })
    }

    // socket.onstreamdata = (data) => {
    //   // console.log("Got a stream data")
    //   // console.log(data)
    // }
  }, [
    log,
    environment,
    getSession,
    socket,
    socketState.authed,
    relayEnv,
    joinChat,
    userLookup,
    backupURL,
    reTriggerUserInfo,
    setIsInSingleSession,
    recreateSocket,
    user.currentUser?.nakamaID,
  ])

  const connectWebsocket = useCallback(() => {
    log(`Connecting to the socket`)
    socketStateDispatch({ type: "setAuth", value: "connecting" })

    getSession()
      .then((s) => {
        session.current = s
        socketStateDispatch({ type: "setAuth", value: "connected" })
        socketStateDispatch({ type: "setNakamaID", value: s.user_id })

        // Connect to the websocket instantly
        socket.current
          .connect(s, true)
          .then((s) => {
            if (s !== session.current) return

            socketStateDispatch({ type: "setSocket", value: "connected" })
            log(`Connected to the socket, registering interest in friends' activity`)

            // Register that we want to know how online our friends are
            socket.current.followUsers((user.currentUser?.friendNakamaIDs as string[]) || []).then((status) => {
              socketStateDispatch({ type: "gotStatusUpdate", update: { joins: status.presences } })
            })
          })

          .catch((err) => {
            console.error("Failed to connect to socket, trying again in 2s", err)
            setTimeout(() => {
              recreateSocket()

              console.error("Restarting socket connection")
              getSession()
            }, 2000)
          })
      })
      .catch((err) => {
        console.error("Could not connect to the server, trying again in 2s", err)

        setTimeout(() => {
          recreateSocket()

          console.error("Restarting socket connection")
          getSession()
        }, 2000)
      })
  }, [getSession, log, recreateSocket, user.currentUser?.friendNakamaIDs])

  // The main connect to websocke if you are logged in effect
  useEffect(() => {
    if (isTests) return

    const isAnon = user.type === "anon"
    if (isAnon) return

    if (socketIsConnected()) return
    if (socketIsConnecting()) return
    if (socket.current.nakamaLogin === user.userState?.nakamaLogin) return

    log(`Connecting to the socket with ${user.userState.nakamaLogin} - auth was ${socketState.authed}`)
    socket.current.nakamaLogin = user.userState?.nakamaLogin || ""
    connectWebsocket()
  }, [connectWebsocket, log, socketIsConnected, socketIsConnecting, socketState.authed, user.type, user.userState.nakamaLogin])

  return {
    socketState,
    socketStateDispatch,
    getSession,
    socket: socket.current,
    client,
    useSocketEventsSubscription,
    joinChat,
    writeMessages,
    getHistoryForChannel,
    connectWebsocket,
  }
}

export const socketStateContext = createContext<SocketStateContextType>({} as any)

export function useNakama() {
  return useContext(socketStateContext)
}

const createClientParamsFromEnv = (environment: Environment) => {
  const serverKey = environment.nakamaKey()
  const host = environment.nakamaAPIUrl().split(":")[1].replace("//", "")
  const port = environment.nakamaAPIUrl().split(":").pop()
  const isDev = host.includes("localhost")
  const useSSL = !isDev
  return [serverKey, host, port, useSSL] as const
}

const createSocketParamsFromEnv = (environment: Environment) => {
  const host = environment.nakamaAPIUrl().split(":")[1].replace("//", "")
  const wantsLogs = typeof localStorage !== "undefined" && localStorage.getItem("showNakamaLogs") === "true"
  const isDev = host.includes("localhost")
  const useSSL = !isDev

  return [useSSL, wantsLogs] as const
}

export const useNakamaStateSelector = <R,>(selector: (state: NakamaSocketState) => R): R =>
  useContextSelector(socketStateContext, (s) => selector(s.socketState!))

const makeNakamaMsgToGiftChatMsg = (
  userLookup: (nID: string, channelID: string) => UserPrimitives | undefined,
  fallbackAvatar: string | undefined,
  m: ChannelMessage
) => {
  if (!m.message_id || !m.content || !(m.content as any).text || !m.username || !m.sender_id || !m.create_time) {
    return null
  }

  const userFromLookup = userLookup(m.sender_id, m.channel_id!)
  const msg: IMessage = {
    _id: m.message_id,
    text: (m.content as any).text,
    user: {
      _id: m.sender_id,
      name: m.username.split("#")[0],
      avatar: userFromLookup?.avatarURL || fallbackAvatar,
    },
    createdAt: new Date(m.create_time),
  }
  return msg
}

function notNull<T>(value: T): value is NonNullable<T> {
  return !!value
}
