/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import {
  BASE_PADDING,
  Row,
  BodyText,
  H4,
  PuzmoButton,
  BASE_MARGIN,
  InternalLink,
  ShadowView,
  BASE_RADIUS,
  AdminButton,
} from "$components/DesignSystem"
import { BookIcon } from "$components/icons/BookIcon"
import { CogIcon } from "$components/icons/CogIcon"
import { OverlayContext } from "$components/socialOverlay/OverlayContext"
import { SOCIAL_OVERLAY_WIDTH } from "$components/socialOverlay/SocialOverlay"
import { useNavigation } from "@react-navigation/native"
import Checkbox from "expo-checkbox"
import * as React from "react"
import { useCallback, useContext } from "react"
import { Platform, TouchableOpacity, View } from "react-native"
import { useTheme } from "styled-rn"
import { useContextSelector } from "use-context-selector"

import { AppContext } from "../../AppContext"
import { useGetUser } from "../CoreContext"

export const NavDropdown = (props: { height: number; closeDropdown: () => void }) => {
  const { height, closeDropdown } = props
  // No stack context! We could be showing in the modal which _is not_ in the main stack
  const nav = useNavigation()
  const theme = useTheme()
  const { apiClient } = useContext(AppContext)
  const user = useGetUser()
  const isAdmin = user && user.type === "user" && user.currentUser.roles.includes("admin")
  const hasSocialOverlay = useContextSelector(OverlayContext, (c) => c.socialOverlay)

  // Always close dropDown when route changes
  React.useEffect(() => {
    const unsubscribe = nav.addListener("state", () => {
      closeDropdown()
    })
    return unsubscribe
  }, [closeDropdown, nav])

  const logout = useCallback(async () => {
    await apiClient.logout()
    nav.navigate("Today", {})
  }, [apiClient, nav])

  return (
    <ShadowView
      style={{
        backgroundColor: theme.alwaysDark,
        position: "absolute",
        top: height,
        right: BASE_MARGIN + (hasSocialOverlay ? SOCIAL_OVERLAY_WIDTH : 0),
        width: 280,
        padding: BASE_PADDING,
        borderRadius: BASE_RADIUS,
      }}
    >
      <InternalLink screen="UserProfileSettings">
        <PuzmoButton
          iconComponent={<CogIcon width={24} fill="alwaysDark" />}
          title="My Profile & Statistics"
          bgKey="a_anchor"
          colorKey="alwaysDark"
          style={{ width: "100%", marginBottom: 12 }}
        />
      </InternalLink>

      <InternalLink screen="Account">
        <PuzmoButton
          iconComponent={<BookIcon width={24} fill="alwaysDark" />}
          title="Account Settings"
          bgKey="key"
          colorKey="alwaysDark"
          style={{ width: "100%", marginBottom: 12 }}
        />
      </InternalLink>

      <Row>
        <View style={{ width: "100%", flex: 2 }}>
          <InternalLink screen="UserSwitch">
            <PuzmoButton block title="Switch player" bgKey="a_puzmo" colorKey="alwaysDark" style={{ width: "100%", marginRight: 6 }} />
          </InternalLink>
        </View>

        <PuzmoButton
          type="secondary"
          block
          title="Log out"
          colorKey="alwaysLight"
          onPress={logout}
          style={{ width: "100%", flex: 1, marginLeft: 12 }}
        />
      </Row>

      {!isAdmin ? null : <AdminDropdown close={closeDropdown} />}
    </ShadowView>
  )
}

// Used in the Dev page
export const AdminDropdown = (props: { close: () => void }) => {
  const { close } = props
  const ctx = useContext(AppContext)

  const toggleAdminUI = () => {
    if (Platform.OS !== "web") return
    toggle("showDebugUI", true)
  }

  /** To replicate on a private browser

    localStorage.setItem("showRelayLogs", "true")

    localStorage.setItem("showNakamaLogs", "true")
    localStorage.setItem("showIPCLogs", "true")

   */

  const toggleNakamaLogs = () => {
    if (Platform.OS !== "web") return
    toggle("showNakamaLogs")
  }

  const toggleRelayLogs = () => {
    if (Platform.OS !== "web") return
    toggle("showRelayLogs")
  }

  const toggleIPCLogs = () => {
    if (Platform.OS !== "web") return
    toggle("showIPCLogs")
  }

  const toggleShowInDev = () => {
    if (Platform.OS !== "web") return
    toggle("openInDev", true)
  }

  const toggleShowSocialSidebar = () => {
    if (Platform.OS !== "web") return
    toggle("showSocialSidebar", true)
  }

  const toggle = (key: string, noRestart?: true) => {
    if (localStorage.getItem(key) === "true") {
      localStorage.removeItem(key)
    } else {
      localStorage.setItem(key, "true")
    }
    if (!noRestart) document.location.reload()
    if (noRestart) ctx.reTriggerUserInfo()
  }

  const showingNakamaLogs = typeof localStorage !== "undefined" && localStorage.getItem("showNakamaLogs") === "true"
  const showingRelayLogs = typeof localStorage !== "undefined" && localStorage.getItem("showRelayLogs") === "true"
  const showingIPCLogs = typeof localStorage !== "undefined" && localStorage.getItem("showIPCLogs") === "true"
  const theme = useTheme()
  const studioFG = theme.alwaysDark

  const gotoAdmin = useCallback(() => {
    close()
    window.open(ctx.environment.adminRoot() + "admin", "_blank")
  }, [ctx.environment, close])

  const gotoSubmissions = useCallback(() => {
    close()
    window.open(ctx.environment.adminRoot() + "submit/puzmo", "_blank")
  }, [ctx.environment, close])

  const buttonStyle = { height: 28, paddingHorizontal: 6, borderWidth: 1, borderBottomWidth: 0, borderColor: theme.fg }
  const toggleStyle = {
    color: theme.fg,
    opacity: 0.75,
    fontSize: 10,
    fontFamily: "ui-monospace, 'Cascadia Code', 'Source Code Pro', Menlo, Consolas, 'DejaVu Sans Mono', monospace",
  }

  return (
    <View style={{ marginTop: BASE_MARGIN, borderRadius: BASE_RADIUS, padding: BASE_PADDING, backgroundColor: theme.a_bg }}>
      <H4 marginLess style={{ marginBottom: BASE_MARGIN, color: theme.fg }}>
        Admin
      </H4>
      <AdminButton
        title={"Go to admin" + (ctx.environment.isStaging() ? " (staging)" : "")}
        onPress={gotoAdmin}
        style={{ width: "100%", marginBottom: 6 }}
      />

      <AdminButton
        title={"Go to submissions" + (ctx.environment.isStaging() ? " (staging)" : "")}
        onPress={gotoSubmissions}
        style={{ width: "100%", marginBottom: 6 }}
      />

      <InternalLink screen="Dev">
        <AdminButton title={"Go to env overrides"} style={{ width: "100%", marginBottom: 6 }} />
      </InternalLink>

      {Platform.OS === "web" && (
        <>
          <TouchableOpacity accessibilityRole="button" onPress={() => toggleShowSocialSidebar()}>
            <Row
              centerH
              style={{
                ...buttonStyle,
                borderTopWidth: 1,
                borderTopLeftRadius: BASE_RADIUS,
                borderTopRightRadius: BASE_RADIUS,
              }}
            >
              <Checkbox color={studioFG} style={{ marginRight: 10 }} value={ctx.showDebugSocialSidebar} />
              <BodyText style={toggleStyle}>Toggle Social/Game Sidebar JSON</BodyText>
            </Row>
          </TouchableOpacity>

          <TouchableOpacity accessibilityRole="button" onPress={() => toggleAdminUI()}>
            <Row centerH style={{ ...buttonStyle }}>
              <Checkbox color={studioFG} style={{ marginRight: 10 }} value={ctx.showDebugUI} />
              <BodyText style={toggleStyle}>Toggle debug UI info</BodyText>
            </Row>
          </TouchableOpacity>

          <TouchableOpacity accessibilityRole="button" onPress={() => toggleShowInDev()}>
            <Row centerH style={{ ...buttonStyle }}>
              <Checkbox color={studioFG} style={{ marginRight: 10 }} value={ctx.showOpenInDev} />
              <BodyText style={toggleStyle}>Toggle "Open in Dev" button</BodyText>
            </Row>
          </TouchableOpacity>

          <TouchableOpacity accessibilityRole="button" onPress={() => toggleNakamaLogs()}>
            <Row centerH style={{ ...buttonStyle }}>
              <Checkbox color={studioFG} style={{ marginRight: 10 }} value={showingNakamaLogs} />
              <BodyText style={toggleStyle}>Toggle Nakama logs</BodyText>
            </Row>
          </TouchableOpacity>

          <TouchableOpacity accessibilityRole="button" onPress={() => toggleRelayLogs()}>
            <Row centerH style={{ ...buttonStyle }}>
              <Checkbox color={studioFG} style={{ marginRight: 10 }} value={showingRelayLogs} />
              <BodyText style={toggleStyle}>Toggle Relay logs</BodyText>
            </Row>
          </TouchableOpacity>

          <TouchableOpacity accessibilityRole="button" onPress={() => toggleIPCLogs()}>
            <Row
              centerH
              style={{
                ...buttonStyle,
                borderBottomWidth: 1,
                borderBottomLeftRadius: BASE_RADIUS,
                borderBottomRightRadius: BASE_RADIUS,
              }}
            >
              <Checkbox color={studioFG} style={{ marginRight: 10 }} value={showingIPCLogs} />
              <BodyText style={toggleStyle}>Toggle IPC logs</BodyText>
            </Row>
          </TouchableOpacity>
        </>
      )}
    </View>
  )
}
