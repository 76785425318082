import React, { useCallback } from "react"

import { GameUIAction, TimeDisplay, AllInternalMessages, useSubscribeToGameEvent } from "./useSetupPlayGameContext"

import { GameSettingsUIComponents, Collaborator } from "$consts/hostAPI"

export function useRunGameSubscriptions(gameUIDispatch: React.Dispatch<GameUIAction>, cacheKey: string) {
  const updateSidebarMarkdown = useCallback(
    (data: any) => {
      gameUIDispatch({ type: "updateSidebarContent", content: data.markdown || " " })
    },
    [gameUIDispatch]
  )
  useSubscribeToGameEvent(cacheKey, "SIDEBAR_UPDATE", updateSidebarMarkdown)

  const onTick = useCallback(
    (data: { display: TimeDisplay }) => {
      gameUIDispatch({ type: "timerTick", timeDisplay: data.display })
    },
    [gameUIDispatch]
  )
  useSubscribeToGameEvent(cacheKey, "TIMER_TICK", onTick)

  const onComplete = useCallback(
    (data: AllInternalMessages["SHOW_GAME_COMPLETE_SCREEN"]) => {
      let body = data.resultsMD
      if (body === undefined) {
        body = "### Could not get completion information for this game.\n\nGot: " + JSON.stringify(data, null, 2)
      }
      gameUIDispatch({ type: "complete", isComplete: true, showRetry: false, content: body, results: data.results })
    },
    [gameUIDispatch]
  )
  useSubscribeToGameEvent(cacheKey, "SHOW_GAME_COMPLETE_SCREEN", onComplete)

  const onCollabChange = useCallback(
    (data: Collaborator[]) => {
      const consistentOrder = data.sort((a, b) => a.nID.charCodeAt(3) - b.nID.charCodeAt(3))
      gameUIDispatch({ type: "setCollabs", collabs: consistentOrder })
    },
    [gameUIDispatch]
  )
  useSubscribeToGameEvent(cacheKey, "COLLAB_USERS_CHANGED", onCollabChange)

  const setSettings = useCallback(
    (res: { components: GameSettingsUIComponents[]; settings: any }) => {
      gameUIDispatch({ type: "initializeGameSettings", components: res.components, settings: res.settings })
    },
    [gameUIDispatch]
  )
  useSubscribeToGameEvent(cacheKey, "INITIALIZE_SETTINGS", setSettings)
}
