import { possibleModalsFromURL } from "$components/auth/AuthModal"
import * as Font from "expo-font"
import * as Linking from "expo-linking"
import * as SplashScreen from "expo-splash-screen"
import { useEffect, useRef, useState } from "react"
import { Platform } from "react-native"

import type { PuzmoClient } from "../lib/apiClient"

// This is URL hash/search based routing: you can have URLs like:

// https://puzzmo.com/#login
// https://puzzmo.com/#signup
// https://puzzmo.com/#invite-1234

// We'll also pull out if the URL comes from a partner branded sub-site, like:

// https://puzzmo.com/+/sf-chronicle/today
//

export type AppLaunchContextType = {
  // We should default to showing this modal if this is set
  autoOpenModal: (typeof possibleModalsFromURL)[number] | null
  // An invite token slug
  inviteToken: string | null
  // A root partner slug, if this is here we should always show the partner branding
  partnerSlug: string | null
}

export default function useSetupAsync(client: PuzmoClient) {
  const [isLoadingComplete, setLoadingComplete] = useState(false)

  const launchMeta = useRef<AppLaunchContextType>({
    autoOpenModal: null,
    inviteToken: null,
    partnerSlug: null,
  })

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    async function setup() {
      try {
        SplashScreen.preventAutoHideAsync()

        const [initialURL, _, __] = await Promise.all([
          // Gets the launch data from the URL
          Linking.getInitialURL(),

          loadFonts(),

          // Sets up the local client (secure keychain access for JWTs is async)
          client.setup(),
        ])

        // Pull out a partner slug from the URL
        if (initialURL && initialURL.includes("/+/")) {
          const [, partnerSlug] = initialURL.split("/+/")
          launchMeta.current.partnerSlug = partnerSlug.split("/")[0]
        }

        // Pull out  invites from the hash
        if (initialURL && initialURL.includes("#")) {
          const [, hash] = initialURL.split("#")
          const hashComponents = hash.split("-")

          if (possibleModalsFromURL.includes(hashComponents[0] as any)) {
            launchMeta.current.autoOpenModal = hashComponents[0] as any
          }

          if (hashComponents[0] === "invite") {
            launchMeta.current.inviteToken = hashComponents[1]
          }
        }
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e)
      } finally {
        setLoadingComplete(true)
        SplashScreen.hideAsync()
      }
    }

    setup()
  }, [client])

  return [isLoadingComplete, client, launchMeta.current] as const
}

const loadFonts = async () => {
  if (Platform.OS === "web") {
    // Use the dynamic aspects of the web to speed up our boot
    // process, basically letting us declare the fonts and have the app
    // load them asynchronously when needed.

    const fonts = document.createElement("style")
    const fontsRoot = "/assets/fonts"
    fonts.innerHTML = `
    * {
      -webkit-font-smoothing: antialiased;
      font-smoothing: antialiased;
    }
    @font-face {
      font-family: RedHatMono-Bold;
      src: url(${fontsRoot}/RedHatMono-Bold.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: RedHatMono-BoldItalic;
      src: url(${fontsRoot}/RedHatMono-BoldItalic.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: RedHatMono-Italic;
      src: url(${fontsRoot}/RedHatMono-Italic.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: RedHatMono-Light;
      src: url(${fontsRoot}/RedHatMono-Light.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: RedHatMono-LightItalic;
      src: url(${fontsRoot}/RedHatMono-LightItalic.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: RedHatMono-Medium;
      src: url(${fontsRoot}/RedHatMono-Medium.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: RedHatMono-MediumItalic;
      src: url(${fontsRoot}/RedHatMono-MediumItalic.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: RedHatMono-Regular;
      src: url(${fontsRoot}/RedHatMono-Regular.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: RedHatMono-SemiBold;
      src: url(${fontsRoot}/RedHatMono-SemiBold.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: RedHatMono-SemiBoldItalic;
      src: url(${fontsRoot}/RedHatMono-SemiBoldItalic.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Poppins-Black;
      src: url(${fontsRoot}/Poppins-Black.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Poppins-BlackItalic;
      src: url(${fontsRoot}/Poppins-BlackItalic.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Poppins-Bold;
      src: url(${fontsRoot}/Poppins-Bold.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Poppins-BoldItalic;
      src: url(${fontsRoot}/Poppins-BoldItalic.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Poppins-ExtraBold;
      src: url(${fontsRoot}/Poppins-ExtraBold.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Poppins-ExtraBoldItalic;
      src: url(${fontsRoot}/Poppins-ExtraBoldItalic.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Poppins-ExtraLight;
      src: url(${fontsRoot}/Poppins-ExtraLight.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Poppins-ExtraLightItalic;
      src: url(${fontsRoot}/Poppins-ExtraLightItalic.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Poppins-Italic;
      src: url(${fontsRoot}/Poppins-Italic.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Poppins-Light;
      src: url(${fontsRoot}/Poppins-Light.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Poppins-LightItalic;
      src: url(${fontsRoot}/Poppins-LightItalic.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Poppins-Medium;
      src: url(${fontsRoot}/Poppins-Medium.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Poppins-MediumItalic;
      src: url(${fontsRoot}/Poppins-MediumItalic.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Poppins-Regular;
      src: url(${fontsRoot}/Poppins-Regular.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Poppins-SemiBold;
      src: url(${fontsRoot}/Poppins-SemiBold.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Poppins-SemiBoldItalic;
      src: url(${fontsRoot}/Poppins-SemiBoldItalic.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Poppins-Thin;
      src: url(${fontsRoot}/Poppins-Thin.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Poppins-ThinItalic;
      src: url(${fontsRoot}/Poppins-ThinItalic.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Zodiak-Black;
      src: url(${fontsRoot}/Zodiak-Black.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Zodiak-BlackItalic;
      src: url(${fontsRoot}/Zodiak-BlackItalic.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Zodiak-Bold;
      src: url(${fontsRoot}/Zodiak-Bold.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Zodiak-BoldItalic;
      src: url(${fontsRoot}/Zodiak-BoldItalic.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Zodiak-Extrabold;
      src: url(${fontsRoot}/Zodiak-Extrabold.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Zodiak-ExtraboldItalic;
      src: url(${fontsRoot}/Zodiak-ExtraboldItalic.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Zodiak-Italic;
      src: url(${fontsRoot}/Zodiak-Italic.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Zodiak-Light;
      src: url(${fontsRoot}/Zodiak-Light.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Zodiak-LightItalic;
      src: url(${fontsRoot}/Zodiak-LightItalic.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Zodiak-Regular;
      src: url(${fontsRoot}/Zodiak-Regular.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Zodiak-Thin;
      src: url(${fontsRoot}/Zodiak-Thin.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Zodiak-ThinItalic;
      src: url(${fontsRoot}/Zodiak-ThinItalic.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Zodiak-Variable;
      src: url(${fontsRoot}/Zodiak-Variable.ttf);
      font-display: auto;
    }
    @font-face {
      font-family: Zodiak-VariableItalic;
      src: url(${fontsRoot}/Zodiak-VariableItalic.ttf);
      font-display: auto;
      font-weight: 400 800;
      font-style: normal;
    }
    @font-face {
      font-family: LeagueSpartan;
      src: url(${fontsRoot}/LeagueSpartanVariable.ttf) format('truetype');
      font-display: auto;
      font-weight: 200 800;
      font-style: normal;
    }

    a {
      text-decoration: none !important;
    }
    `
    document.head.appendChild(fonts)
  } else {
    return Font.loadAsync({
      // TODO: When in Expo go, use URLs?

      "Poppins-Black": require("../../assets/fonts/Poppins-Black.ttf"),
      "Poppins-BlackItalic": require("../../assets/fonts/Poppins-BlackItalic.ttf"),
      "Poppins-Bold": require("../../assets/fonts/Poppins-Bold.ttf"),
      "Poppins-BoldItalic": require("../../assets/fonts/Poppins-BoldItalic.ttf"),
      "Poppins-ExtraBold": require("../../assets/fonts/Poppins-ExtraBold.ttf"),
      "Poppins-ExtraBoldItalic": require("../../assets/fonts/Poppins-ExtraBoldItalic.ttf"),
      "Poppins-ExtraLight": require("../../assets/fonts/Poppins-ExtraLight.ttf"),
      "Poppins-ExtraLightItalic": require("../../assets/fonts/Poppins-ExtraLightItalic.ttf"),
      "Poppins-Italic": require("../../assets/fonts/Poppins-Italic.ttf"),
      "Poppins-Light": require("../../assets/fonts/Poppins-Light.ttf"),
      "Poppins-LightItalic": require("../../assets/fonts/Poppins-LightItalic.ttf"),
      "Poppins-Medium": require("../../assets/fonts/Poppins-Medium.ttf"),
      "Poppins-MediumItalic": require("../../assets/fonts/Poppins-MediumItalic.ttf"),
      "Poppins-Regular": require("../../assets/fonts/Poppins-Regular.ttf"),
      "Poppins-SemiBold": require("../../assets/fonts/Poppins-SemiBold.ttf"),
      "Poppins-SemiBoldItalic": require("../../assets/fonts/Poppins-SemiBoldItalic.ttf"),
      "Poppins-Thin": require("../../assets/fonts/Poppins-Thin.ttf"),
      "Poppins-ThinItalic": require("../../assets/fonts/Poppins-ThinItalic.ttf"),

      "Zodiak-Black": require("../../assets/fonts/Zodiak-Black.ttf"),
      "Zodiak-BlackItalic": require("../../assets/fonts/Zodiak-BlackItalic.ttf"),
      "Zodiak-Bold": require("../../assets/fonts/Zodiak-Bold.ttf"),
      "Zodiak-BoldItalic": require("../../assets/fonts/Zodiak-BoldItalic.ttf"),
      "Zodiak-Extrabold": require("../../assets/fonts/Zodiak-Extrabold.ttf"),
      "Zodiak-ExtraboldItalic": require("../../assets/fonts/Zodiak-ExtraboldItalic.ttf"),
      "Zodiak-Italic": require("../../assets/fonts/Zodiak-Italic.ttf"),
      "Zodiak-Light": require("../../assets/fonts/Zodiak-Light.ttf"),
      "Zodiak-LightItalic": require("../../assets/fonts/Zodiak-LightItalic.ttf"),
      "Zodiak-Regular": require("../../assets/fonts/Zodiak-Regular.ttf"),
      "Zodiak-Thin": require("../../assets/fonts/Zodiak-Thin.ttf"),
      "Zodiak-ThinItalic": require("../../assets/fonts/Zodiak-ThinItalic.ttf"),
      "Zodiak-Variable": require("../../assets/fonts/Zodiak-Variable.ttf"),
      "Zodiak-VariableItalic": require("../../assets/fonts/Zodiak-VariableItalic.ttf"),

      // for native RN
      // "LeagueSpartan-Black": require("../../assets/fonts/LeagueSpartan-Black.ttf"),
      // "LeagueSpartan-Bold": require("../../assets/fonts/LeagueSpartan-Bold.ttf"),
      // "LeagueSpartan-ExtraBold": require("../../assets/fonts/LeagueSpartan-ExtraBold.ttf"),
      // "LeagueSpartan-ExtraLight": require("../../assets/fonts/LeagueSpartan-ExtraLight.ttf"),
      // "LeagueSpartan-Light": require("../../assets/fonts/LeagueSpartan-Light.ttf"),
      // "LeagueSpartan-Medium": require("../../assets/fonts/LeagueSpartan-Medium.ttf"),
      // "LeagueSpartan-Regular": require("../../assets/fonts/LeagueSpartan-Regular.ttf"),
      // "LeagueSpartan-SemiBold": require("../../assets/fonts/LeagueSpartan-SemiBold.ttf"),
      // "LeagueSpartan-Thin": require("../../assets/fonts/LeagueSpartan-Thin.ttf"),

      LeagueSpartan: require("../../assets/fonts/LeagueSpartanVariable.ttf"),
    })
  }
}
