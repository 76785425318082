// @ts-nocheck

import * as React from "react"
import Svg, { SvgProps, Defs, Path, Use, G } from "react-native-svg"

// Note: this doesn't have native support: need to
// use react-native-svg-transformer
const LoginTypingPublic = (props: SvgProps) => {
  return (
    <Svg viewBox="0 0 355 365" {...props}>
      <Defs>
        <G id="LoginTypingPublic-puzmoKeyboard-NO-BGt46" overflow="visible">
          <Use xlinkHref="#LoginTypingPublic-puzmoBody" transform="matrix(.992 .125 -.125 .992 -244.25 -88.1)">
            <animateTransform
              attributeName="transform"
              additive="replace"
              type="translate"
              repeatCount="indefinite"
              dur="2.4s"
              keyTimes="0;.23599;.236;.51399;.514;1"
              values="-168.258,-2.337;-168.258,-2.337;-168.15,-2.25;-168.15,-2.25;-168.258,-2.337;-168.258,-2.337"
              calcMode="discrete"
            />
            <animateTransform
              attributeName="transform"
              additive="sum"
              type="rotate"
              repeatCount="indefinite"
              dur="2.4s"
              keyTimes="0;.23599;.236;.51399;.514;1"
              values="7.194,0,0;7.194,0,0;0,0,0;0,0,0;7.194,0,0;7.194,0,0"
              calcMode="discrete"
            />
            <animateTransform
              attributeName="transform"
              additive="sum"
              type="translate"
              repeatCount="indefinite"
              dur="2.4s"
              keyTimes="0;.23599;.236;.51399;.514;1"
              values="-86.1,-75.55;-86.1,-75.55;-86.25,-75.65;-86.25,-75.65;-86.1,-75.55;-86.1,-75.55"
              calcMode="discrete"
            />
          </Use>
          <Use xlinkHref="#LoginTypingPublic-PuzmoHandsType-Firstd72" transform="translate(-248.75 48.8)" />
          <G display="none">
            <Use xlinkHref="#LoginTypingPublic-puzmoeyef0" transform="matrix(.72 .694 -.694 .72 -179.4 -44.35)" />
            <Use xlinkHref="#LoginTypingPublic-puzmoeyef0" transform="matrix(.72 .694 -.694 .72 -211.65 -56.9)" />
            <Use xlinkHref="#LoginTypingPublic-puzmoMouthf4" transform="matrix(.698 .716 -.716 .698 -220.35 -47.35)" />
            <animate attributeName="display" repeatCount="indefinite" dur="2.4s" keyTimes="0;.514;1" values="none;inline;inline" />
          </G>
          <G display="none">
            <Use xlinkHref="#LoginTypingPublic-puzmoeyeo17d72" transform="matrix(.908 .419 -.419 .908 -150.55 -14.5)" />
            <Use xlinkHref="#LoginTypingPublic-puzmoeyeo17d72" transform="matrix(.908 .419 -.419 .908 -193.6 -14.4)" />
            <Use xlinkHref="#LoginTypingPublic-puzmoMouthf4" transform="matrix(.924 .382 -.382 .924 -198.15 -2.55)" />
            <animate attributeName="display" repeatCount="indefinite" dur="2.4s" keyTimes="0;.236;.514;1" values="none;inline;none;none" />
          </G>
          <G>
            <Use xlinkHref="#LoginTypingPublic-puzmoeyef0" transform="matrix(.72 .694 -.694 .72 -179.4 -44.35)" />
            <Use xlinkHref="#LoginTypingPublic-puzmoeyef0" transform="matrix(.72 .694 -.694 .72 -211.65 -56.9)" />
            <Use xlinkHref="#LoginTypingPublic-puzmoMouthf4" transform="matrix(.698 .716 -.716 .698 -220.35 -47.35)" />
            <animate attributeName="display" repeatCount="indefinite" dur="2.4s" keyTimes="0;.236;1" values="inline;none;none" />
          </G>
        </G>
        <G id="LoginTypingPublic-puzmoeye_0.0" overflow="visible">
          <G>
            <Use xlinkHref="#LoginTypingPublic-Layer1_0_FILL" />
          </G>
        </G>
        <G id="LoginTypingPublic-puzmoeyef0" overflow="visible">
          <Use xlinkHref="#LoginTypingPublic-puzmoeye_0.0" />
        </G>
        <G id="LoginTypingPublic-puzmoMouth_0.4" overflow="visible">
          <G>
            <Use xlinkHref="#LoginTypingPublic-Layer2_0_1_STROKES" />
          </G>
        </G>
        <G id="LoginTypingPublic-puzmoMouthf4" overflow="visible">
          <Use xlinkHref="#LoginTypingPublic-puzmoMouth_0.4" />
        </G>
        <G id="LoginTypingPublic-puzmoeye_0.4" overflow="visible">
          <G>
            <Use xlinkHref="#LoginTypingPublic-Layer3_0_1_STROKES" />
          </G>
        </G>
        <G id="LoginTypingPublic-puzmoeyeo17d72" overflow="visible">
          <G display="none">
            <Use xlinkHref="#LoginTypingPublic-puzmoeye_0.4" />
            <animate
              attributeName="display"
              repeatCount="indefinite"
              dur="2.4s"
              keyTimes="0;.292;.347;.403;.458;.514;.569;.625;.681;.736;.792;.847;.903;.958;1"
              values="none;inline;none;inline;none;inline;none;inline;none;inline;none;inline;none;inline;inline"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#LoginTypingPublic-puzmoeye_0.0" />
            <animate
              attributeName="display"
              repeatCount="indefinite"
              dur="2.4s"
              keyTimes="0;.236;.292;.347;.403;.458;.514;.569;.625;.681;.736;.792;.847;.903;.958;1"
              values="none;inline;none;inline;none;inline;none;inline;none;inline;none;inline;none;inline;none;none"
            />
          </G>
        </G>
        <G id="PuzmoHandsType First_1" overflow="visible">
          <G>
            <Use xlinkHref="#LoginTypingPublic-Layer4_0_1_STROKES" />
          </G>
        </G>
        <G id="PuzmoHandsType First_1.3" overflow="visible">
          <G>
            <Use xlinkHref="#LoginTypingPublic-Layer5_0_1_STROKES" />
          </G>
        </G>
        <G id="PuzmoHandsType First_1.9" overflow="visible">
          <G>
            <Use xlinkHref="#LoginTypingPublic-Layer6_0_1_STROKES" />
          </G>
        </G>
        <G id="PuzmoHandsType First_1.12" overflow="visible">
          <G>
            <Use xlinkHref="#LoginTypingPublic-Layer7_0_1_STROKES" />
          </G>
        </G>
        <G id="PuzmoHandsType First_1.15" overflow="visible">
          <G>
            <Use xlinkHref="#LoginTypingPublic-Layer8_0_1_STROKES" />
          </G>
        </G>
        <G id="PuzmoHandsType First_1.18" overflow="visible">
          <G>
            <Use xlinkHref="#LoginTypingPublic-Layer9_0_1_STROKES" />
          </G>
        </G>
        <G id="LoginTypingPublic-PuzmoHandsType-Firstd72" overflow="visible">
          <G display="none">
            <Use xlinkHref="#PuzmoHandsType First_1.18" />
            <animate
              attributeName="display"
              repeatCount="indefinite"
              dur="2.4s"
              keyTimes="0;.25;.333;.583;.667;.917;1"
              values="none;inline;none;inline;none;inline;inline"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#PuzmoHandsType First_1.15" />
            <animate
              attributeName="display"
              repeatCount="indefinite"
              dur="2.4s"
              keyTimes="0;.208;.25;.542;.583;.875;.917;1"
              values="none;inline;none;inline;none;inline;none;none"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#PuzmoHandsType First_1.12" />
            <animate
              attributeName="display"
              repeatCount="indefinite"
              dur="2.4s"
              keyTimes="0;.167;.208;.5;.542;.833;.875;1"
              values="none;inline;none;inline;none;inline;none;none"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#PuzmoHandsType First_1.9" />
            <animate
              attributeName="display"
              repeatCount="indefinite"
              dur="2.4s"
              keyTimes="0;.125;.167;.458;.5;.792;.833;1"
              values="none;inline;none;inline;none;inline;none;none"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#PuzmoHandsType First_1.3" />
            <animate
              attributeName="display"
              repeatCount="indefinite"
              dur="2.4s"
              keyTimes="0;.042;.125;.375;.458;.708;.792;1"
              values="none;inline;none;inline;none;inline;none;none"
            />
          </G>
          <G>
            <Use xlinkHref="#PuzmoHandsType First_1" />
            <animate
              attributeName="display"
              repeatCount="indefinite"
              dur="2.4s"
              keyTimes="0;.042;.333;.375;.667;.708;1"
              values="inline;none;inline;none;inline;none;none"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#LoginTypingPublic-fxTypet9o9d72" transform="translate(-.4 -5.2)" />
            <Use xlinkHref="#LoginTypingPublic-fxTypet9o9d72" transform="matrix(-1 0 0 1 156.4 -5.2)" />
            <Use xlinkHref="#LoginTypingPublic-fxTypet9o9d72" transform="matrix(.818 .576 -.576 .818 19.2 -9.1)" />
            <Use xlinkHref="#LoginTypingPublic-fxTypet9o9d72" transform="matrix(-1 0 0 1 160.4 -14.4)" />
            <Use xlinkHref="#LoginTypingPublic-fxTypet3o18d72" transform="matrix(.844 -.537 .537 .844 -23.1 5.1)" />
            <animate
              attributeName="display"
              repeatCount="indefinite"
              dur="2.4s"
              keyTimes="0;.25;.333;.583;.667;.917;1"
              values="none;inline;none;inline;none;inline;inline"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#LoginTypingPublic-fxTypet9o9d72" transform="translate(-.4 -5.2)" />
            <Use xlinkHref="#LoginTypingPublic-fxTypet9o9d72" transform="matrix(-1 0 0 1 156.4 -5.2)" />
            <Use xlinkHref="#LoginTypingPublic-fxTypet9o9d72" transform="matrix(.818 .576 -.576 .818 19.2 -9.1)" />
            <Use xlinkHref="#LoginTypingPublic-fxTypet3o15d72" transform="matrix(-1 0 0 1 160.4 -14.4)" />
            <Use xlinkHref="#LoginTypingPublic-fxTypeo15d72" transform="matrix(.844 -.537 .537 .844 -23.1 5.1)" />
            <animate
              attributeName="display"
              repeatCount="indefinite"
              dur="2.4s"
              keyTimes="0;.208;.25;.542;.583;.875;.917;1"
              values="none;inline;none;inline;none;inline;none;none"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#LoginTypingPublic-fxTypet9o9d72" transform="translate(-.4 -5.2)" />
            <Use xlinkHref="#LoginTypingPublic-fxTypet9o9d72" transform="matrix(-1 0 0 1 156.4 -5.2)" />
            <Use xlinkHref="#LoginTypingPublic-fxTypet3o12d72" transform="matrix(.818 .576 -.576 .818 19.2 -9.1)" />
            <Use xlinkHref="#LoginTypingPublic-fxTypeo12d72" transform="matrix(-1 0 0 1 160.4 -14.4)" />
            <animate
              attributeName="display"
              repeatCount="indefinite"
              dur="2.4s"
              keyTimes="0;.167;.208;.5;.542;.833;.875;1"
              values="none;inline;none;inline;none;inline;none;none"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#LoginTypingPublic-fxTypet9o9d72" transform="translate(-.4 -5.2)" />
            <Use xlinkHref="#LoginTypingPublic-fxTypet9o9d72" transform="matrix(-1 0 0 1 156.4 -5.2)" />
            <Use xlinkHref="#LoginTypingPublic-fxTypeo9d72" transform="matrix(.818 .576 -.576 .818 19.2 -9.1)" />
            <animate
              attributeName="display"
              repeatCount="indefinite"
              dur="2.4s"
              keyTimes="0;.125;.167;.458;.5;.792;.833;1"
              values="none;inline;none;inline;none;inline;none;none"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#LoginTypingPublic-fxTypet3o3d72" transform="translate(-.4 -5.2)" />
            <Use xlinkHref="#LoginTypingPublic-fxTypeo3d72" transform="matrix(-.885 -.465 -.465 .885 176.7 -5.05)" />
            <animate
              attributeName="display"
              repeatCount="indefinite"
              dur="2.4s"
              keyTimes="0;.042;.125;.375;.458;.708;.792;1"
              values="none;inline;none;inline;none;inline;none;none"
            />
          </G>
          <G>
            <Use xlinkHref="#LoginTypingPublic-fxTyped72" transform="translate(-.4 -5.2)" />
            <animate
              attributeName="display"
              repeatCount="indefinite"
              dur="2.4s"
              keyTimes="0;.042;.333;.375;.667;.708;1"
              values="inline;none;inline;none;inline;none;none"
            />
          </G>
        </G>
        <G id="LoginTypingPublic-puzmoBody_0.0" overflow="visible">
          <G>
            <Use xlinkHref="#LoginTypingPublic-Layer10_0_FILL" />
          </G>
        </G>
        <G id="LoginTypingPublic-puzmoBody" overflow="visible">
          <Use xlinkHref="#LoginTypingPublic-puzmoBody_0.0" />
        </G>
        <G id="LoginTypingPublic-fxType_0.0" overflow="visible">
          <G>
            <Use xlinkHref="#LoginTypingPublic-Layer11_0_1_STROKES" />
          </G>
        </G>
        <G id="LoginTypingPublic-fxTyped72" overflow="visible">
          <Use xlinkHref="#LoginTypingPublic-fxType_0.0" />
        </G>
        <G id="LoginTypingPublic-fxType_0.3" overflow="visible">
          <G>
            <Use xlinkHref="#LoginTypingPublic-Layer12_0_1_STROKES" />
          </G>
        </G>
        <G id="LoginTypingPublic-fxTypet3o3d72" overflow="visible">
          <G display="none">
            <Use xlinkHref="#LoginTypingPublic-fxType_0.3" />
            <animate
              attributeName="display"
              repeatCount="indefinite"
              dur="2.4s"
              keyTimes="0;.042;.083;.125;.167;.208;.25;.292;.333;.375;.417;.458;.5;.542;.583;.625;.667;.708;.75;.792;.833;.875;.917;.958;1"
              values="none;inline;none;inline;none;inline;none;inline;none;inline;none;inline;none;inline;none;inline;none;inline;none;inline;none;inline;none;inline;inline"
            />
          </G>
        </G>
        <G id="LoginTypingPublic-fxTypeo3d72" overflow="visible">
          <G display="none">
            <Use xlinkHref="#LoginTypingPublic-fxType_0.3" />
            <animate
              attributeName="display"
              repeatCount="indefinite"
              dur="2.4s"
              keyTimes="0;.083;.125;.167;.208;.25;.292;.333;.375;.417;.458;.5;.542;.583;.625;.667;.708;.75;.792;.833;.875;.917;.958;1"
              values="none;inline;none;inline;none;inline;none;inline;none;inline;none;inline;none;inline;none;inline;none;inline;none;inline;none;inline;none;none"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#LoginTypingPublic-fxType_0.0" />
            <animate
              attributeName="display"
              repeatCount="indefinite"
              dur="2.4s"
              keyTimes="0;.042;.083;.125;.167;.208;.25;.292;.333;.375;.417;.458;.5;.542;.583;.625;.667;.708;.75;.792;.833;.875;.917;.958;1"
              values="none;inline;none;inline;none;inline;none;inline;none;inline;none;inline;none;inline;none;inline;none;inline;none;inline;none;inline;none;inline;inline"
            />
          </G>
        </G>
        <G id="LoginTypingPublic-fxTypet9o9d72" overflow="visible" />
        <G id="LoginTypingPublic-fxTypeo9d72" overflow="visible">
          <G display="none">
            <Use xlinkHref="#LoginTypingPublic-fxType_0.0" />
            <animate attributeName="display" repeatCount="indefinite" dur="2.4s" keyTimes="0;.125;1" values="none;inline;inline" />
          </G>
        </G>
        <G id="LoginTypingPublic-fxTypet3o12d72" overflow="visible">
          <G display="none">
            <Use xlinkHref="#LoginTypingPublic-fxType_0.3" />
            <animate attributeName="display" repeatCount="indefinite" dur="2.4s" keyTimes="0;.167;1" values="none;inline;inline" />
          </G>
        </G>
        <G id="LoginTypingPublic-fxTypeo12d72" overflow="visible">
          <G display="none">
            <Use xlinkHref="#LoginTypingPublic-fxType_0.0" />
            <animate attributeName="display" repeatCount="indefinite" dur="2.4s" keyTimes="0;.167;1" values="none;inline;inline" />
          </G>
        </G>
        <G id="LoginTypingPublic-fxTypet3o15d72" overflow="visible">
          <G display="none">
            <Use xlinkHref="#LoginTypingPublic-fxType_0.3" />
            <animate attributeName="display" repeatCount="indefinite" dur="2.4s" keyTimes="0;.208;1" values="none;inline;inline" />
          </G>
        </G>
        <G id="LoginTypingPublic-fxTypeo15d72" overflow="visible">
          <G display="none">
            <Use xlinkHref="#LoginTypingPublic-fxType_0.0" />
            <animate attributeName="display" repeatCount="indefinite" dur="2.4s" keyTimes="0;.208;1" values="none;inline;inline" />
          </G>
        </G>
        <G id="LoginTypingPublic-fxTypet3o18d72" overflow="visible">
          <G display="none">
            <Use xlinkHref="#LoginTypingPublic-fxType_0.3" />
            <animate
              attributeName="display"
              repeatCount="indefinite"
              dur="2.4s"
              keyTimes="0;.25;.292;.333;.375;.417;.458;.5;.542;.583;.625;.667;.708;.75;.792;.833;.875;.917;.958;1"
              values="none;inline;none;inline;none;inline;none;inline;none;inline;none;inline;none;inline;none;inline;none;inline;none;none"
            />
          </G>
        </G>
        <G id="LoginTypingPublic-Layer10_0_FILL">
          <Path
            fill="#FFBB03"
            stroke="none"
            d="M148.1 20.75Q122.4 -.9 86.05 .05 49.2 -.75 23.3 20.85 11.3 31.9 5.65 45.15 .2 57.9 -.3 74.95 -1.15 107.75 22.65 129.7 49.15 151.35 87.4 151.35 122.35 151.45 146.85 129.55 171.9 106.45 172.5 74.7 172.8 42.35 148.1 20.75Z"
          />
        </G>
        <G id="LoginTypingPublic-Layer1_0_FILL">
          <Path
            fill="#191F2A"
            stroke="none"
            d="M15.8 11.9Q16.75 8.6 15.15 5.25 13.55 1.9 10.35 .6 7.15 -.7 4.2 .7 1.25 2.1 .3 5.45 -.65 8.8 .95 12.1 2.6 15.45 5.75 16.75 9 18.15 11.9 16.7 14.9 15.3 15.8 11.9Z"
          />
        </G>
        <Path
          id="LoginTypingPublic-Layer12_0_1_STROKES"
          stroke="#FFBB03"
          strokeWidth="4"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M-20.4 48.8L-20 48.8M-17.6 29.2L-17.2 29.2"
        />
        <Path
          id="LoginTypingPublic-Layer11_0_1_STROKES"
          stroke="#FFBB03"
          strokeWidth="4"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M-16 30.8L-11.2 35.2M-18.4 46.8L-12.4 44.8"
        />
        <Path
          id="LoginTypingPublic-Layer9_0_1_STROKES"
          stroke="#FFBB03"
          strokeWidth="15"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M137.55 .8Q145.35 7 149.1 13.5 152.85 20 152 26 151.2 31.95 144.05 34.8 136.95 37.65 121.95 40.7M21.65 4Q16.25 11.2 14.15 14.2 12.05 17.15 9.1 23.4 6.15 29.55 13.45 31.75 20.8 33.85 36.45 27.2"
        />
        <Path
          id="LoginTypingPublic-Layer8_0_1_STROKES"
          stroke="#FFBB03"
          strokeWidth="15"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M138.75 4Q144.15 11.2 146.25 14.2 148.35 17.15 151.3 23.4 154.25 29.55 146.95 31.75 139.6 33.85 123.95 27.2M22.85 .8Q15.05 7 11.3 13.5 7.55 20 8.4 26 9.2 31.95 16.35 34.8 23.45 37.65 38.45 40.7"
        />
        <Path
          id="LoginTypingPublic-Layer7_0_1_STROKES"
          stroke="#FFBB03"
          strokeWidth="15"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M137.55 .8Q145.35 7 149.1 13.5 152.85 20 152 26 151.2 31.95 144.05 34.8 136.95 37.65 121.95 40.7M21.65 4Q16.25 11.2 14.15 14.2 12.05 17.15 9.1 23.4 6.15 29.55 13.45 31.75 20.8 33.85 36.45 27.2"
        />
        <Path
          id="LoginTypingPublic-Layer6_0_1_STROKES"
          stroke="#FFBB03"
          strokeWidth="15"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M138.75 4Q144.15 11.2 146.25 14.2 148.35 17.15 151.3 23.4 154.25 29.55 146.95 31.75 139.6 33.85 123.95 27.2M22.85 .8Q15.05 7 11.3 13.5 7.55 20 8.4 26 9.2 31.95 16.35 34.8 23.45 37.65 38.45 40.7"
        />
        <Path
          id="LoginTypingPublic-Layer5_0_1_STROKES"
          stroke="#FFBB03"
          strokeWidth="15"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M137.55 .8Q145.35 7 149.1 13.5 152.85 20 152 26 151.2 31.95 144.05 34.8 136.95 37.65 121.95 40.7M21.65 4Q16.25 11.2 14.15 14.2 12.05 17.15 9.1 23.4 6.15 29.55 13.45 31.75 20.8 33.85 36.45 27.2"
        />
        <Path
          id="LoginTypingPublic-Layer4_0_1_STROKES"
          stroke="#FFBB03"
          strokeWidth="15"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M138.75 4Q144.15 11.2 146.25 14.2 148.35 17.15 151.3 23.4 154.25 29.55 146.95 31.75 139.6 33.85 123.95 27.2M22.85 .8Q15.05 7 11.3 13.5 7.55 20 8.4 26 9.2 31.95 16.35 34.8 23.45 37.65 38.45 40.7"
        />
        <Path
          id="LoginTypingPublic-Layer3_0_1_STROKES"
          stroke="#191F2A"
          strokeWidth="9"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M2.4 12.45L14.4 6.45"
        />
        <Path
          id="LoginTypingPublic-Layer2_0_1_STROKES"
          stroke="#191F2A"
          strokeWidth="9"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M31.1 20.05Q36.1 20.7 41.15 18.15 45.65 15.85 47.7 12.45"
        />
      </Defs>
      <G id="LoginTypingPublic-Scene-1t46" overflow="visible">
        <Use xlinkHref="#LoginTypingPublic-puzmoKeyboard-NO-BGt46" transform="translate(346 178)" />
      </G>
    </Svg>
  )
}

export { LoginTypingPublic }
