import { encode, decode } from "base-64"
import { useColorScheme, Platform } from "react-native"
import { SafeAreaProvider } from "react-native-safe-area-context"
import * as Sentry from "sentry-expo"
import { ThemeProvider } from "styled-rn"

import { Puzzmo } from "./src/Puzzmo"
import { createEnvironment, currentSHAForDeploy } from "./src/lib/environment"
import { WebPasswordScreen } from "./src/screens/WebPasswordScreen"

import { themeForApp } from "$consts/theme"

if (!global.btoa) {
  global.btoa = encode
}

if (!global.atob) {
  global.atob = decode
}

// https://github.com/facebook/create-react-app/issues/11773
if (!global.process) {
  // @ts-ignore
  global.process = {}
}

const env = createEnvironment()
if (!env.isDev()) {
  try {
    Sentry.init({
      dsn: "https://7eadc119ffc04266a1e1334a793783ad@o1223952.ingest.sentry.io/6368499",
      debug: !env.isStaging(),
      environment: env.isStaging() ? "staging" : "production",
      release: currentSHAForDeploy,
    })
  } catch (e) {
    console.log("Sentry init failed", e)
  }
}

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/serviceWorker.js")
    .then(() => {
      console.log("Service worker registered!")
    })
    .catch((error) => {
      console.warn("Error registering service worker:")
      console.warn(error)
    })
}

// Password protect the web

export default function App() {
  const colorScheme = useColorScheme()
  const theme = themeForApp(colorScheme || "light", {} as any)

  if (Platform.OS === "web" && !localStorage.getItem("has_access")) {
    return (
      <SafeAreaProvider>
        <ThemeProvider theme={theme}>
          <WebPasswordScreen />
        </ThemeProvider>
      </SafeAreaProvider>
    )
  }
  return (
    <SafeAreaProvider>
      <Puzzmo />
    </SafeAreaProvider>
  )
}
