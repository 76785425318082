/* eslint-disable relay/unused-fields */
import { useBootstrapNakamaDataFriendRequestsQuery } from "$relay/useBootstrapNakamaDataFriendRequestsQuery.graphql"
import { useBootstrapNakamaDataQuery } from "$relay/useBootstrapNakamaDataQuery.graphql"
import { useEffect } from "react"
import { fetchQuery, graphql, useRelayEnvironment } from "react-relay"
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment"

import { SocketAction, useNakama } from "./useNakama"
import { useGetUser } from "../CoreContext"

const bootstrapQuery = graphql`
  query useBootstrapNakamaDataQuery {
    currentUser {
      socialLastViewedAt

      incomingRequests: nFriends(state: 2) {
        friends {
          user {
            ...FriendRequestView
            userId
            username
            puzmoUser {
              id
            }
          }
        }
      }

      mostRecentDistinctChats {
        friends {
          puzmoUser {
            id
            username
            usernameID
            nakamaID
            avatarURL
            ...ChatRow
          }
          lastChatMessageWasViewer

          createdAt
          content
          seen
        }
      }

      nFriends(state: 0) {
        friends {
          user {
            userId
            puzmoUser {
              id
              username
              usernameID
              nakamaID
              avatarURL
              ...ChatRow
            }
          }
        }
      }
    }
  }
`

const updateRequestsQuery = graphql`
  query useBootstrapNakamaDataFriendRequestsQuery {
    currentUser @required(action: THROW) {
      incomingRequests: nFriends(state: 2) {
        friends {
          user {
            username
            userId
            ...FriendRequestView
            userId
            puzmoUser {
              id
            }
          }
        }
      }
    }
  }
`

export const useBootstrapNakamaData = () => {
  const env = useRelayEnvironment()
  const user = useGetUser()
  const { socketStateDispatch } = useNakama()

  const fetchKey = `friends-${user.userState.nakamaLogin}-${user.currentUser?.friendNakamaIDs.join("-")}`

  useEffect(() => {
    fetchQuery<useBootstrapNakamaDataQuery>(env, bootstrapQuery, {})
      .toPromise()
      .then((r) => {
        if (!r || !r.currentUser) return

        // These are people who you are friends of but have never messaged
        const allFriends = (r.currentUser.nFriends?.friends || []).map((f) => f.user)

        const people = allFriends.filter((p) => !!p.puzmoUser)
        const chats = new Set(
          people.map((p) => ({
            id: p.puzmoUser!.id,
            seen: false,
            sender: p.puzmoUser!,
            mostRecentMessage: null,
            mostRecentMessageTime: null,
            online: false,
          }))
        )

        const requests = new Set(r.currentUser.incomingRequests?.friends?.map((f) => f.user).filter((f) => !!f.puzmoUser))

        socketStateDispatch({ type: "updateSocialUpdateTime", time: r.currentUser.socialLastViewedAt })
        socketStateDispatch({ type: "updateFriendChats", chats })
        socketStateDispatch({ type: "updateFriendBlurbs", blurbs: r.currentUser.mostRecentDistinctChats.friends })
        socketStateDispatch({ type: "updateFriendRequests", requests })
      })
  }, [env, socketStateDispatch, fetchKey])
}

export const updateFriendRequests = (env: RelayModernEnvironment, socketStateDispatch: React.Dispatch<SocketAction>) =>
  fetchQuery<useBootstrapNakamaDataFriendRequestsQuery>(env, updateRequestsQuery, {})
    .toPromise()
    .then((r) => {
      if (!r || !r.currentUser) return

      const requests = new Set(r.currentUser.incomingRequests?.friends?.map((f) => f.user).filter((f) => !!f.puzmoUser))
      socketStateDispatch({ type: "updateFriendRequests", requests })
    })
