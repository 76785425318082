// https://css-tricks.com/understanding-event-emitters/
// Note the special casing of collab users - this is a message which normally

import { useEffect } from "react"

// goes from RN to the game, but we  want it in our UI at react level too for showing avatars etc
export type IncomingMessages = import("$consts/hostAPI").AllIncomingMessagesToApp | "COLLAB_USERS_CHANGED"
type Events = Partial<{
  [key in IncomingMessages]: Function[]
}>

export function createEventEmitter<EventMap extends Record<string, any>>() {
  const events: Partial<Record<keyof EventMap, Function[]>> = {}

  return {
    events,
    subscribe: (name: keyof EventMap, cb: Function) => {
      if (!events[name]) {
        events[name] = []
      }

      events[name]?.push(cb)

      return {
        unsubscribe: () => {
          events[name] && events[name]?.splice(events[name]!.indexOf(cb) >>> 0, 1)
        },
      }
    },
    emit: (name: keyof EventMap, ...args: any[]) => {
      if (!events[name]) return
      events[name]?.forEach((fn) => fn(...args))
    },
    _: "" as keyof EventMap,
  }
}

export const appEventsEmitter = createEventEmitter<Events>()

// Subscription hook that relies on our reducer to clear out old subscriptions.
// Whenever cacheKey changes.
export function makeUseEmitterSubscription<Map extends Record<string, any>>(messageEmitter: { subscribe: any }) {
  return <Key extends keyof Map>(cacheKey: string, key: Key, fn: (data: Map[Key]) => void) => {
    useEffect(() => {
      // only create a listener if we have a cacheKey.
      const listener = cacheKey ? messageEmitter.subscribe(key, fn) : null
      return () => {
        if (listener) return listener.unsubscribe()
      }
    }, [key, fn, cacheKey])
  }
}
