/**
 * @generated SignedSource<<f4e8ea870ea6babdeda3f2968e3688f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateUserProfileInput = {
  bio?: string | null;
  highlightedStatIDs?: ReadonlyArray<string> | null;
  links?: ReadonlyArray<string> | null;
  todayPageLeaderboardIDs?: ReadonlyArray<string> | null;
};
export type UserProfileStatsChooserAddToProfileMutation$variables = {
  input: UpdateUserProfileInput;
};
export type UserProfileStatsChooserAddToProfileMutation$data = {
  readonly updateUserProfile: {
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"UserProfileStatsChooser">;
    } | null;
  };
};
export type UserProfileStatsChooserAddToProfileMutation = {
  response: UserProfileStatsChooserAddToProfileMutation$data;
  variables: UserProfileStatsChooserAddToProfileMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserProfileStatsChooserAddToProfileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserProfile",
        "kind": "LinkedField",
        "name": "updateUserProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserProfileStatsChooser"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserProfileStatsChooserAddToProfileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserProfile",
        "kind": "LinkedField",
        "name": "updateUserProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fullAggregateStats",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserProfile",
                "kind": "LinkedField",
                "name": "publicProfile",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "highlightedStatIDs",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "54dd6507262f24f24ef74ede38232ab4",
    "id": null,
    "metadata": {},
    "name": "UserProfileStatsChooserAddToProfileMutation",
    "operationKind": "mutation",
    "text": "mutation UserProfileStatsChooserAddToProfileMutation(\n  $input: UpdateUserProfileInput!\n) {\n  updateUserProfile(input: $input) {\n    user {\n      ...UserProfileStatsChooser\n      id\n    }\n    id\n  }\n}\n\nfragment UserProfileStatsChooser on User {\n  id\n  fullAggregateStats\n  publicProfile {\n    id\n    highlightedStatIDs\n  }\n}\n"
  }
};
})();

(node as any).hash = "bc2d5c58676b691665c4bc787fbf6f7c";

export default node;
