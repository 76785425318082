import React from "react"
import { ViewStyle } from "react-native"
import Svg, { Circle, G, Path, Rect } from "react-native-svg"
import { useTheme } from "styled-rn"

// https://react-svgr.com/playground/?expandProps=none&icon=true&memo=true&native=true&typescript=true

export const GameIcon = (props: { fg?: string; bg?: string; gameslug: string; style?: ViewStyle }) => {
  const theme = useTheme()
  const fg = props.fg || theme.fg
  const bg = props.bg || theme.a_puzmo
  switch (props.gameslug) {
    case "crossword":
      return (
        <Svg viewBox="0 0 28 28" width={28} height={28} fill="none" {...props}>
          <G translateX={2} translateY={2}>
            <Path d="M22.5 1.5H1.5v21h21V1.5ZM12 1.5v21M1.5 12H22.5z" fill={bg} stroke={fg} strokeWidth={2.5} />
            <Path d="M22.5 11.5H12V22.5h10V10Z" fill={fg} />
          </G>
        </Svg>
      )

    case "really-bad-chess":
      return (
        <Svg viewBox="0 0 28 28" width={28} height={28} fill="none" {...props}>
          <Path
            d="M15.941 25.544a24.93 24.93 0 0 0 5.956-.719.105.105 0 0 0 .082-.102V22.81a.103.103 0 0 0-.038-.081.105.105 0 0 0-.088-.024c-2.154.4-4.121.597-5.912.597-2.113 0-4.084-.183-5.912-.54a.102.102 0 0 0-.088.025.106.106 0 0 0-.04.08v1.853c0 .047.033.088.077.102 1.768.485 3.755.722 5.963.722ZM12.838 5.56s-.004 0-.004.003A21.405 21.405 0 0 0 8.07 9.077c-1.411 1.377-2.768 3.008-4.077 4.884a.102.102 0 0 0 0 .122l1.041 1.493a.1.1 0 0 0 .109.044l7.279-1.733h.003c.722-.21 1.265-.713 1.628-1.523.34-.764.536-1.628.583-2.598h1.449c.067 1.451-.126 2.686-.574 3.707-.457 1.027-1.183 1.757-2.18 2.184a.093.093 0 0 0-.044.037L9.92 20.416a.111.111 0 0 0-.014.098.107.107 0 0 0 .078.064c2.005.441 3.989.662 5.956.662 2.005 0 4.03-.22 6.078-.662a.103.103 0 0 0 .082-.105 32.542 32.542 0 0 0-.41-4.558 40.962 40.962 0 0 0-.693-3.443c-.695-2.86-1.492-5.206-2.39-7.044a.101.101 0 0 0-.096-.058h-2.574l-2.229-2.89a.106.106 0 0 0-.118-.037l-.367.118a.106.106 0 0 0-.07.092l-.242 2.87-.074.037ZM9.965 8.724c.224-.22.526-.329.902-.329.336 0 .617.109.845.33.223.216.332.511.332.878 0 .366-.109.658-.332.878-.228.22-.51.33-.845.33-.38 0-.678-.11-.902-.33-.227-.22-.336-.515-.336-.878 0-.367.109-.662.336-.879Z"
            fill={bg}
          />
          <Path
            d="M.99 14.187c3.017-4.424 6.216-7.71 9.601-9.862L10.957.98l3.365-.778 2.384 3.17h3.055c1.06 1.913 2.05 4.511 2.968 7.8.917 3.289 1.374 6.264 1.374 8.938v6.572c-3.017.836-5.81 1.256-8.379 1.256-2.609 0-5.4-.42-8.38-1.256V20.11l2.385-2.93-.185-.301-5.69 1.613L.99 14.187Zm11.682-8.31a21.351 21.351 0 0 0-4.74 3.498c-1.407 1.374-2.761 2.998-4.067 4.87l1.04 1.493 7.278-1.732c.692-.2 1.213-.685 1.56-1.463.346-.778.539-1.663.579-2.661h1.65c.083 1.513-.114 2.8-.58 3.855-.468 1.055-1.212 1.801-2.232 2.24l-3.365 4.721c1.997.44 3.975.658 5.934.658 1.997 0 4.014-.218 6.054-.658a32.686 32.686 0 0 0-.409-4.545 43.348 43.348 0 0 0-.69-3.433c-.693-2.85-1.49-5.19-2.384-7.023h-2.627l-2.262-2.931-.367.12-.243 2.93-.13.06ZM9.676 8.869c.245-.239.572-.357.979-.357.365 0 .673.118.916.357.245.239.367.56.367.956 0 .4-.122.716-.367.955a1.26 1.26 0 0 1-.916.357c-.41 0-.734-.118-.979-.357-.243-.239-.365-.56-.365-.955 0-.396.122-.717.365-.956Zm6.053 16.793c1.997 0 3.974-.24 5.932-.717v-1.914c-2.163.401-4.137.598-5.932.598-2.12 0-4.097-.182-5.934-.54v1.853c1.756.48 3.735.72 5.934.72Z"
            fill={fg}
          />
        </Svg>
      )

    case "typeshift":
      return (
        <Svg viewBox="0 0 28 28" width={28} height={28} fill="none" {...props}>
          <G translateX={1} translateY={1}>
            <Path d="M26 11H 0V 15H 26V 11Z" fill={fg} />
            <Circle cx="10" cy="1" r="2" fill={fg} />
            <Circle cx="4" cy="7" r="2" fill={fg} />
            <Circle cx="16" cy="7" r="2" fill={fg} />
            <Circle cx="10" cy="7" r="2" fill={fg} />

            <Circle cx="16" cy="19" r="2" fill={fg} />
            <Circle cx="22" cy="19" r="2" fill={fg} />
            <Circle cx="4" cy="19" r="2" fill={fg} />
            <Circle cx="22" cy="25" r="2" fill={fg} />
          </G>
        </Svg>
      )

    case "spelltower":
      return (
        <Svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
          <G translateX={1} translateY={1}>
            <Path
              d="M9.2 16H2V23.2H9.2V16ZM16.4 16H9.2V23.2H16.4V16ZM23.6 16H16.4V23.2H23.6V16ZM9.2 8.8H2V16H9.2V8.8ZM23.6 8.8H16.4V16H23.6V8.8ZM23.6 1.6H16.4V8.8H23.6V1.6Z"
              fill={bg}
              stroke={fg}
              strokeWidth="2.81795"
            />
          </G>
        </Svg>
      )

    // @ts-ignore - this could happen
    case "flipart":
    case "flip-art":
      return (
        <Svg width={28} height={28} viewBox="0 0 28 28" fill="none" {...props}>
          <G translateX={2} translateY={0.5}>
            <Rect x={1} y={1} height={25} width={21} fill={bg} />
            <Path d="M15.5 17.5H2v7.5h13.5v-7.5ZM8 2H2v15.5h6V2ZM21.5 2H8v7.5h13.5V2ZM15.5 25h6v-15.5" stroke={fg} strokeWidth={2.5} />
          </G>
        </Svg>
      )
  }

  // This looks like a tofu square
  return (
    <Svg width={28} height={28} viewBox="0 0 28 28" fill="none" {...props}>
      <Rect x={4} y={4} height={20} width={20} stroke={fg} strokeWidth="2" />
    </Svg>
  )
}

export const PuzmonautIcon = (props: { fg?: string; bg?: string }) => {
  const theme = useTheme()
  const fg = props.fg || theme.fg
  const bg = props.bg || theme.a_puzmo

  return (
    <Svg width={28} height={28} fill="none">
      <G translateX={0.5} translateY={1}>
        <Path
          d="M12.69 3.33c-5.04 0-11.7 2.79-11.52 9.99.09 7.47 5.94 10.17 11.97 10.17 5.94 0 10.89-4.86 10.89-9.9 0-6.75-5.4-10.26-11.25-10.26Z"
          fill={bg}
          stroke={fg}
          strokeWidth={2.2}
          strokeMiterlimit={2}
          strokeLinejoin="round"
        />
        <Path
          d="M8.19 10.17c-.27-.18-.63-.18-.9 0a.99.99 90 00-.54.72 1.26 1.26 90 00.09.81c.09.27.36.45.54.63.18.09.27.09.45.09.18 0 .27 0 .45-.09.27-.09.45-.36.54-.72a1.26 1.26 90 00-.09-.81 1.26 1.26 90 00-.54-.63ZM15.03 8.1c-.27-.63-.9-.9-1.44-.63-.54.27-.72.9-.45 1.53.09.27.36.45.54.63.18.09.27.09.45.09.18 0 .27 0 .45-.09.27-.09.45-.36.54-.72a1.26 1.26 90 00-.09-.81ZM17.19 10.98c.81.27 1.8 4.59-2.52 6.57-4.05 1.89-6.93-1.26-6.75-2.25.18-.99 8.46-4.68 9.27-4.41ZM23.13 10.62c.99-.54 1.17-.63 1.89-1.44.81-.9 1.44-1.98.99-3.24-.18-.45-.45-.81-.81-1.17-.27-.27-.36-.54-.27-.99.09-.9.36-1.53.9-2.25.36-.45.09-1.17-.36-1.44-.54-.27-1.08-.09-1.44.36a5.58 5.58 90 00-1.08 2.43c-.09.54-.18 1.08-.09 1.62.09.54.36.99.72 1.44.18.27.45.54.54.63.09.27-.27.63-.45.81-.63.72-1.44.27-1.17 1.17.09.36-.09 2.43 1.08 1.71M4.23 19.26c-1.35.45-2.43 1.62-3.06 2.88-.36.9-.54 1.89.18 2.7.54.63 1.44.72 2.25.72.9.09 1.8.09 2.7 0 .54 0 1.08-.45 1.08-1.08 0-.54-.45-1.08-1.08-1.08-.9 0-1.71 0-2.61 0-.18 0-.45 0-.63 0-.27 0 .18 0 0 0-.09 0-.18 0-.27-.09-.18-.09.18.09 0 0-.27-.18.09.09 0 0 .09.09.09.18.09.09-.09-.09 0-.09 0 .09a.9.9 90 010-.09c0 .18 0 .18 0 .09 0 0 0-.09 0-.09 0 .18 0 .18 0 .09 0-.09 0-.18.09-.27 0 0 0-.09.09-.09-.09.09-.09.18 0 .09 0-.09.09-.18.09-.18a7.38 7.38 90 01.45-.72c0 0 .18-.18.18-.18a1.17 1.17 90 000 .09 4.05 4.05 90 01.18-.27c.09-.09.27-.27.36-.36.09-.09.09-.09-.09 0l.09-.09a3.06 3.06 90 01.18-.09c.09 0 .18-.09.27-.09-.18.09-.18.09-.09 0 0 0 .09 0 .09 0 .54-.18-.09-.36-.27-.99-.18-.54-.45-.9-.99-.72"
          fill={fg}
        />
      </G>
    </Svg>
  )
}
