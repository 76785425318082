import { BASE_MARGIN, H3, HR } from "$components/DesignSystem"
import { UserProfileStatsChooser$data, UserProfileStatsChooser$key } from "$relay/UserProfileStatsChooser.graphql"
import React, { useCallback, useMemo } from "react"
import { View } from "react-native"
import { graphql, useFragment, useMutation } from "react-relay"

import { UserStatsJSON } from "../../constants/statsAPI"
import { useContainerWidth } from "../../hooks/useContainerWidth"
import { secondsToHms } from "../../lib/secondsToHms"
import { numberFormatter } from "../../util/numberFormatter"
import { AnimatedPressableBase, BASE_PADDING, BodyText, H2, H4, MinusIcon, PlusIcon, Row, SquareButton } from "../DesignSystem"
import { GameIcon, PuzmonautIcon } from "../Thumbnails/games/GameIcon"
import { useTheme } from "styled-rn"

const Fragment = graphql`
  fragment UserProfileStatsChooser on User {
    id
    fullAggregateStats

    publicProfile {
      id
      highlightedStatIDs
    }
  }
`

const AddToProfile = graphql`
  mutation UserProfileStatsChooserAddToProfileMutation($input: UpdateUserProfileInput!) {
    updateUserProfile(input: $input) {
      user {
        ...UserProfileStatsChooser
      }
    }
  }
`

export const UserProfileStatsChooser = (props: { user: UserProfileStatsChooser$key }) => {
  const screenWidth = useContainerWidth()
  const colCount = useMemo(() => {
    if (screenWidth < 900) return 1
    if (screenWidth < 1200) return 2
    return 3
  }, [screenWidth])

  const user = useFragment(Fragment, props.user)
  const stats = allStats(user.fullAggregateStats)
  const columns = useMemo(() => {
    const keys = Object.keys(stats)
    const data = keys
      .map((key) => {
        const section = stats[key as keyof typeof stats]
        if (section.items.length === 0) return null
        return { ...section, key }
      })
      .filter(Boolean)

    // use colCount to init empty columns
    const cols = new Array(colCount).fill([]) as {
      title: string
      key: string
      icon: (props: { fg?: string; bg?: string }) => JSX.Element
      items: UserStatsSubSection
    }[][]
    const lengths = new Array(colCount).fill(0) as number[]

    // place data in columns so there is roughly equal number of items in each column
    for (let i = 0; i < data.length; i++) {
      const section = data[i]
      if (section === null) throw new Error("Section doesn't exist")

      const min = Math.min(...lengths)
      const minIndex = lengths.indexOf(min)
      cols[minIndex] = cols[minIndex].concat(section)
      lengths[minIndex] += section?.items.length || 0
    }

    return cols
  }, [stats, colCount])

  // @ts-ignore
  const showSection = columns.some((sections) => sections.find((s) => s?.items.length > 0))
  if (!showSection) return <BodyText>You haven't played any games yet, so we can't let you choose stats to highlight.</BodyText>
  return (
    <View
      style={{
        flexDirection: "row",
        flexWrap: "wrap",
      }}
    >
      {columns.map((sections, i) => (
        <View key={i} style={{ flex: 1 }}>
          {sections.map((section) => {
            if (!section) return null

            return (
              <View key={section.title} style={{ marginBottom: 40 }}>
                <View style={{ marginBottom: BASE_MARGIN, alignItems: "center", flexDirection: "row" }}>
                  {section.icon({ bg: "transparent" })}
                  <H4 style={{ marginLeft: BASE_MARGIN * 0.5 }} marginLess>
                    {section.title}
                  </H4>
                </View>
                <View>
                  {section.items.map((stat) => (
                    <Item key={stat.id} profile={user.publicProfile} stat={stat} section={section.key} />
                  ))}
                </View>
              </View>
            )
          })}
        </View>
      ))}
    </View>
  )
}

const Item = (props: { profile: UserProfileStatsChooser$data["publicProfile"]; stat: UserStatsSubSection[number]; section: string }) => {
  const existing = useMemo(() => props.profile?.highlightedStatIDs || [], [props.profile?.highlightedStatIDs])

  const isHighlighted = existing.includes(`${props.section}#${props.stat.id}`)
  const [addToProfile, loading] = useMutation(AddToProfile)
  const onclick = useCallback(() => {
    const highlightedStatIDs = !isHighlighted
      ? [...existing, `${props.section}#${props.stat.id}`]
      : existing.filter((f) => f !== `${props.section}#${props.stat.id}`)

    addToProfile({
      variables: {
        input: {
          highlightedStatIDs,
        },
      },

      optimisticResponse: !props.profile
        ? undefined
        : {
            updateUserProfile: {
              user: {
                id: props.profile.id,
                publicProfile: {
                  id: props.profile.id,
                  highlightedStatIDs,
                },
              },
            },
          },
    })
  }, [isHighlighted, existing, props.section, props.stat.id, props.profile, addToProfile])

  const hasTheLimitOfHighlightedStats = existing.length >= 5 && !isHighlighted

  return (
    <AnimatedPressableBase
      onPress={loading ? () => null : onclick}
      disabled={!isHighlighted && hasTheLimitOfHighlightedStats}
      key={props.stat.id}
      style={{ marginBottom: 4, alignItems: "center", flexDirection: "row" }}
    >
      {loading ? (
        <SquareButton pointerEvents="none" bgKey="key" disabled style={{ marginRight: 8, opacity: 0.3 }} />
      ) : isHighlighted ? (
        <SquareButton pointerEvents="none" bgKey="key" style={{ marginRight: 8 }}>
          <MinusIcon colorKey="keyFG" size={12} />
        </SquareButton>
      ) : (
        <SquareButton bgKey="key" pointerEvents="none" style={{ marginRight: 8 }}>
          <PlusIcon colorKey="keyFG" size={12} />
        </SquareButton>
      )}
      <BodyText>{props.stat.msg}</BodyText>
    </AnimatedPressableBase>
  )
}

export const NotableStatsSection = (props: { user: UserProfileStatsChooser$key }) => {
  const user = useFragment(Fragment, props.user)
  if (!user.publicProfile?.highlightedStatIDs) return null
  const stats = allStats(user.fullAggregateStats)

  return (
    <View style={{ marginTop: BASE_MARGIN }}>
      <H3>Notable Statistics</H3>
      <HR />
      {user.publicProfile.highlightedStatIDs.map((id) => (
        <NotableStatItem key={id} id={id} stats={stats} highlightedStatIDs={user.publicProfile?.highlightedStatIDs || []} />
      ))}
    </View>
  )
}

const NotableStatItem = (props: { id: string; stats: ReturnType<typeof allStats>; highlightedStatIDs: readonly string[] }) => {
  const [section, stat] = props.id.split("#")
  const sectionStats = props.stats[section as keyof typeof props.stats]
  const statItem = sectionStats?.items.find((item) => item.id === parseInt(stat, 10))
  const theme = useTheme()

  if (!statItem) return null
  return (
    <Row key={props.id} style={{ marginBottom: BASE_PADDING }} centerH>
      {sectionStats.icon({ bg: "transparent", fg: theme.fg })}
      <View style={{ marginLeft: BASE_MARGIN }}>
        <BodyText size="small" style={{ marginBottom: -3, opacity: 0.5 }}>
          {sectionStats.title}
        </BodyText>
        <BodyText>{statItem.msg}</BodyText>
      </View>
    </Row>
  )
}

// The IDs have to be immutable and no dupes!
// Hover the numbers to see the names in the UserStatsJSON

const n = numberFormatter

export type UserStatsSubSection = { msg: string; id: number }[]
export const allStats = (userStats: UserStatsJSON) => {
  if (!userStats || !userStats.site) {
    return {} as Record<string, { title: string; icon: () => JSX.Element; items: UserStatsSubSection }>
  }

  const site: UserStatsSubSection = userStats.site[0]
    ? ([
        !!userStats.site[0] && { msg: `Hours played: ${n(Math.round(userStats.site[0] / 60 / 60))}`, id: 0 },
        { msg: `Total points: ${n(userStats.site[1])}`, id: 1 },
        { msg: `Games played: ${n(userStats.site[2])}`, id: 2 },
        { msg: `Words found: ${n(userStats.site[3])}`, id: 3 },
        userStats.site[4] && { msg: `Most Sunday points: ${n(userStats.site[4])}`, id: 5 },
        userStats.site[6] && { msg: `Most Monday points: ${n(userStats.site[6])}`, id: 6 },
        userStats.site[8] && { msg: `Most Tuesday points: ${n(userStats.site[8])}`, id: 7 },
        userStats.site[10] && { msg: `Most Wednesday points: ${n(userStats.site[10])}`, id: 8 },
        userStats.site[12] && { msg: `Most Thursday points: ${n(userStats.site[12])}`, id: 9 },
        userStats.site[14] && { msg: `Most Friday points: ${n(userStats.site[14])}`, id: 10 },
        userStats.site[16] && { msg: `Most Saturday points: ${n(userStats.site[16])}`, id: 11 },

        userStats.site[18] && { msg: `First places on Daily Score: ${n(userStats.site[18])}`, id: 12 },
        userStats.site[19] && { msg: `Second places on Daily Score: ${n(userStats.site[19])}`, id: 13 },
        userStats.site[20] && { msg: `Third places on Daily Score: ${n(userStats.site[20])}`, id: 14 },

        userStats.site[21] && { msg: `Top 10 places on Daily Score: ${n(userStats.site[21])}`, id: 15 },
        userStats.site[22] && { msg: `Top 100 places on Daily Score: ${n(userStats.site[22])}`, id: 16 },
        userStats.site[23] && { msg: `Top 250 places on Daily Score: ${n(userStats.site[23])}`, id: 17 },
      ].filter(Boolean) as UserStatsSubSection)
    : []

  const sptw: UserStatsSubSection =
    userStats.sptw && userStats.sptw[11]
      ? ([
          { msg: `Games played: ${n(userStats.sptw[11])}`, id: 0 },
          userStats.sptw[31] && { msg: `Full clears: ${n(userStats.sptw[31])}`, id: 14 },
          userStats.sptw[2] && { msg: `Fastest full clear: ${secondsToHms(userStats.sptw[2])}`, id: 2 },
          { msg: `Top score: ${n(userStats.sptw[0])}`, id: 1 },
          { msg: `Best word: ${userStats.sptw[4]} (${n(userStats.sptw[5])})`, id: 3 },
          { msg: `Longest word: ${userStats.sptw[7]} (${userStats.sptw[8]} tiles)`, id: 4 },
          { msg: `Words found: ${n(userStats.sptw[10])}`, id: 5 },
          { msg: `Average word length: ${userStats.sptw[12]}`, id: 6 },
          { msg: `Average words per game: ${userStats.sptw[13]}`, id: 7 },
          !!userStats.sptw[15] && { msg: `Number of 8+ letter words found: ${n(userStats.sptw[15])}`, id: 8 },
          !!userStats.sptw[16] && { msg: `Number of 9+ letter words found: ${n(userStats.sptw[16])}`, id: 9 },
          !!userStats.sptw[17] && { msg: `Number of 10+ letter words found: ${n(userStats.sptw[17])}`, id: 10 },
          !!userStats.sptw[18] && { msg: `Number of 11+ letter words found: ${n(userStats.sptw[18])}`, id: 11 },
          !!userStats.sptw[19] && { msg: `Number of 12+ letter words found: ${n(userStats.sptw[19])}`, id: 12 },
          !!userStats.sptw[20] && { msg: `Number of 13+ letter words found: ${n(userStats.sptw[20])}`, id: 13 },
        ].filter(Boolean) as UserStatsSubSection)
      : []

  const rbc: UserStatsSubSection =
    userStats.rbc && userStats.rbc[6]
      ? ([
          { msg: `Games played: ${userStats.rbc[6]}`, id: 0 },
          { msg: `Best Score: ${n(userStats.rbc[32])}`, id: 17 },
          { msg: `Best Streak: ${n(userStats.rbc[31])}`, id: 18 },
          { msg: `Least moves: ${userStats.rbc[0]}`, id: 1 },
          { msg: `Fastest win: ${secondsToHms(userStats.rbc[2])}`, id: 2 },
          { msg: `Most pieces taken: ${userStats.rbc[4]}`, id: 3 },
          { msg: `Least pieces lost: ${userStats.rbc[5]}`, id: 4 },
          { msg: `Total pieces taken: ${userStats.rbc[7]}`, id: 5 },
          { msg: `Total pieces lost: ${userStats.rbc[8]}`, id: 6 },
          { msg: `Total moves made: ${userStats.rbc[10]}`, id: 7 },
          { msg: `Most moves in a game: ${userStats.rbc[13]}`, id: 8 },

          !!userStats.rbc[12] && { msg: `En Passant Takes: ${userStats.rbc[12]}`, id: 9 },
          !!userStats.rbc[11] && { msg: `Total Castles: ${userStats.rbc[11]}`, id: 10 },

          !!userStats.rbc[14] && { msg: `Checkmates with pawns: ${userStats.rbc[14]}`, id: 11 },
          !!userStats.rbc[15] && { msg: `Checkmates with bishops: ${userStats.rbc[15]}`, id: 12 },
          !!userStats.rbc[16] && { msg: `Checkmates with knights: ${userStats.rbc[16]}`, id: 13 },
          !!userStats.rbc[17] && { msg: `Checkmates with rooks: ${userStats.rbc[17]}`, id: 14 },
          !!userStats.rbc[18] && { msg: `Checkmates with queens: ${userStats.rbc[18]}`, id: 15 },
          !!userStats.rbc[19] && { msg: `Checkmates with your king: ${userStats.rbc[19]}`, id: 16 },
        ].filter(Boolean) as UserStatsSubSection)
      : []

  const tysh: UserStatsSubSection =
    userStats.tysh && userStats.tysh[1]
      ? ([
          { msg: `Games played: ${userStats.tysh[1]}`, id: 0 },
          { msg: `Best Score: ${n(userStats.tysh[28])}`, id: 9 },
          { msg: `Best Streak: ${n(userStats.tysh[27])}`, id: 8 },
          { msg: `Most words found: ${userStats.tysh[0]}`, id: 1 },
          !!userStats.tysh[25] && { msg: `Words found: ${n(userStats.tysh[25])}`, id: 6 },
          !!userStats.tysh[26] && { msg: `WPM: ${n(userStats.tysh[26])}`, id: 7 },
          !!userStats.tysh[2] && { msg: `Core words only solves: ${userStats.tysh[2]}`, id: 2 },
          !!userStats.tysh[3] && { msg: `No core word solves: ${userStats.tysh[3]}`, id: 3 },

          !!userStats.tysh[6] && { msg: `Fastest Monday solve: ${secondsToHms(userStats.tysh[6])}`, id: 10 },
          !!userStats.tysh[8] && { msg: `Fastest Tuesday solve: ${secondsToHms(userStats.tysh[8])}`, id: 11 },
          !!userStats.tysh[10] && { msg: `Fastest Wednesday solve: ${secondsToHms(userStats.tysh[10])}`, id: 12 },
          !!userStats.tysh[12] && { msg: `Fastest Thursday solve: ${secondsToHms(userStats.tysh[12])}`, id: 13 },
          !!userStats.tysh[14] && { msg: `Fastest Friday solve: ${secondsToHms(userStats.tysh[14])}`, id: 14 },
          !!userStats.tysh[16] && { msg: `Fastest Saturday solve: ${secondsToHms(userStats.tysh[16])}`, id: 15 },
          !!userStats.tysh[4] && { msg: `Fastest Sunday solve: ${secondsToHms(userStats.tysh[4])}`, id: 16 },
        ].filter(Boolean) as UserStatsSubSection)
      : []

  const xwd: UserStatsSubSection =
    userStats.xwd && userStats.xwd[0]
      ? ([
          { msg: `Games played: ${userStats.xwd[0]}`, id: 0 },
          { msg: `Best Score: ${n(userStats.xwd[55])}`, id: 6 },
          { msg: `Best Streak: ${n(userStats.xwd[54])}`, id: 7 },

          !!userStats.xwd[2] && { msg: `People collaborated with: ${n(userStats.xwd[2])}`, id: 2 },
          { msg: `Hints used: ${userStats.xwd[1]}`, id: 1 },
          { msg: `Clues filled: ${userStats.xwd[3]}`, id: 3 },
          { msg: `Letters revealed: ${userStats.xwd[5]}`, id: 4 },
          !!userStats.xwd[6] && { msg: `Draft letters left around: ${n(userStats.xwd[6])}`, id: 5 },
        ].filter(Boolean) as UserStatsSubSection)
      : []

  const flp: UserStatsSubSection =
    userStats.flp && userStats.flp[0]
      ? ([
          { msg: `Games played: ${userStats.flp[9]}`, id: 0 },
          { msg: `Best Score: ${n(userStats.flp[11])}`, id: 9 },
          { msg: `Best Streak: ${n(userStats.flp[10])}`, id: 10 },

          { msg: `Rotations: ${userStats.flp[0]}`, id: 1 },
          !!userStats.flp[2] && { msg: `Perfect Monday solves: ${n(userStats.flp[2])}`, id: 2 },
          !!userStats.flp[3] && { msg: `Perfect Tuesday solves: ${n(userStats.flp[3])}`, id: 3 },
          !!userStats.flp[4] && { msg: `Perfect Wednesday solves: ${n(userStats.flp[4])}`, id: 4 },
          !!userStats.flp[5] && { msg: `Perfect Thursday solves: ${n(userStats.flp[5])}`, id: 5 },
          !!userStats.flp[6] && { msg: `Perfect Friday solves: ${n(userStats.flp[6])}`, id: 6 },
          !!userStats.flp[7] && { msg: `Perfect Saturday solves: ${n(userStats.flp[7])}`, id: 7 },
          !!userStats.flp[8] && { msg: `Perfect Sunday solves: ${n(userStats.flp[8])}`, id: 8 },
        ].filter(Boolean) as UserStatsSubSection)
      : []

  return {
    site: {
      title: "Site-wide",
      icon: (props: { fg?: string; bg?: string } = {}) => <PuzmonautIcon fg={props.fg} bg={props.bg} />,
      items: site,
    },
    xwd: {
      title: "Crossword",
      icon: (props: { fg?: string; bg?: string } = {}) => <GameIcon fg={props.fg} bg={props.bg} gameslug="crossword" />,
      items: xwd,
    },
    rbc: {
      title: "Really Bad Chess",
      icon: (props: { fg?: string; bg?: string } = {}) => <GameIcon fg={props.fg} bg={props.bg} gameslug="really-bad-chess" />,
      items: rbc,
    },
    sptw: {
      title: "SpellTower",
      icon: (props: { fg?: string; bg?: string } = {}) => <GameIcon fg={props.fg} bg={props.bg} gameslug="spelltower" />,
      items: sptw,
    },
    tysh: {
      title: "Typeshift",
      icon: (props: { fg?: string; bg?: string } = {}) => <GameIcon fg={props.fg} bg={props.bg} gameslug="typeshift" />,
      items: tysh,
    },
    flp: {
      title: "Flipart",
      icon: (props: { fg?: string; bg?: string } = {}) => <GameIcon fg={props.fg} bg={props.bg} gameslug="flip-art" />,
      items: flp,
    },
  }
}

export const exampleUserStatIDForUserStats = (stats: UserStatsJSON) => {
  const { site, xwd, rbc, sptw, tysh, flp } = stats

  return ["site#1", "xwd#0", "rbc#0", "sptw#2"]
}
