// @ts-nocheck

import Svg, { SvgProps, Defs, Path, Use, G } from "react-native-svg"
import { useTheme } from "styled-rn"

import { Animation, useAnimateSvg } from "./useAnimateSvg"

// see scripts/extractAnimationData.ts for how this was generated
const animationData = [
  {
    repeatCount: "indefinite",
    dur: 1200,
    keyTimes: [0, 600, 700, 1000],
    values: ["inline", "none", "none", "inline"],
    attribute: "display",
  },
  {
    repeatCount: "indefinite",
    dur: 1200,
    keyTimes: [0, 600, 700, 1000],
    values: ["none", "inline", "inline", "none"],
    attribute: "display",
  },
] as Animation[]

const LoginWaiting = (props: SvgProps) => {
  const theme = useTheme()
  const states = useAnimateSvg(animationData)

  return (
    <Svg viewBox="0 0 355 365" {...props}>
      <Defs>
        <G id="LoginWaiting-main" overflow="visible">
          <Use xlinkHref="#LoginWaiting-puzmoBody" transform="translate(-254.4 -77.9)" />
          <Use xlinkHref="#LoginWaiting-arms_resting" />
          <G {...states[0]}>
            <Use xlinkHref="#LoginWaiting-puzmoeyef0" transform="matrix(.924 .382 -.382 .924 -149.4 -43.25)" />
            <Use xlinkHref="#LoginWaiting-puzmoeyef0" transform="matrix(.924 .382 -.382 .924 -200.4 -41.4)" />
          </G>
          <G {...states[1]}>
            <Use xlinkHref="#LoginWaiting-puzmoeyef4o7" transform="matrix(.924 .382 -.382 .924 -149.4 -43.25)" />
            <Use xlinkHref="#LoginWaiting-puzmoeyef4o7" transform="matrix(.924 .382 -.382 .924 -200.4 -41.4)" />
          </G>
          <Use xlinkHref="#LoginWaiting-puzmoMouthf0" transform="matrix(.924 .382 -.382 .924 -192.55 -26.55)" />
        </G>
        <G id="LoginWaiting-puzmoeye_0.0" overflow="visible">
          <Use xlinkHref="#LoginWaiting-open_eyes" />
        </G>
        <G id="LoginWaiting-puzmoeyef0" overflow="visible">
          <Use xlinkHref="#LoginWaiting-puzmoeye_0.0" />
        </G>
        <G id="LoginWaiting-puzmoMouth_0.0" overflow="visible">
          <Use xlinkHref="#LoginWaiting-open_mouth_fill" />
          <Use xlinkHref="#LoginWaiting-open_mouth_stroke" />
        </G>
        <G id="LoginWaiting-puzmoMouthf0" overflow="visible">
          <Use xlinkHref="#LoginWaiting-puzmoMouth_0.0" />
        </G>
        <G id="LoginWaiting-puzmoeye_0.4" overflow="visible">
          <Use xlinkHref="#LoginWaiting-Layer4_0_1_STROKES" />
        </G>
        <G id="LoginWaiting-puzmoeyef4o7" overflow="visible">
          <Use xlinkHref="#LoginWaiting-puzmoeye_0.4" />
        </G>
        <G id="LoginWaiting-puzmoBody_0.0" overflow="visible">
          <Use xlinkHref="#LoginWaiting-Layer5_0_FILL" />
        </G>
        <G id="LoginWaiting-puzmoBody" overflow="visible">
          <Use xlinkHref="#LoginWaiting-puzmoBody_0.0" />
        </G>
        <G id="LoginWaiting-Layer5_0_FILL">
          <Path
            fill={theme.a_puzmo}
            stroke="none"
            d="M148.1 20.75Q122.4 -.9 86.05 .05 49.2 -.75 23.3 20.85 11.3 31.9 5.65 45.15 .2 57.9 -.3 74.95 -1.15 107.75 22.65 129.7 49.15 151.35 87.4 151.35 122.35 151.45 146.85 129.55 171.9 106.45 172.5 74.7 172.8 42.35 148.1 20.75Z"
          />
        </G>
        <G id="LoginWaiting-open_mouth_fill">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M46.6 40.1Q58 33.85 63.55 24 69.1 14.1 64.7 0 52.85 3 34.3 11.1 15.7 19.25 0 29.85 7.8 42.35 21.45 44.3 35.15 46.3 46.6 40.1Z"
          />
        </G>
        <Path
          id="LoginWaiting-open_eyes"
          fill={theme.alwaysDark}
          stroke="none"
          d="M5.75 16.75Q9 18.15 11.9 16.7 14.9 15.3 15.8 11.9 16.75 8.6 15.15 5.25 13.55 1.9 10.35 .6 7.15 -.7 4.2 .7 1.25 2.1 .3 5.45 -.65 8.8 .95 12.1 2.6 15.45 5.75 16.75Z"
        />
        <Path
          id="LoginWaiting-Layer4_0_1_STROKES"
          stroke={theme.alwaysDark}
          strokeWidth={9}
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M2.4 12.4L14.4 6.4"
        />
        <Path
          id="LoginWaiting-open_mouth_stroke"
          stroke={theme.alwaysDark}
          strokeWidth={9}
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M0 29.85Q15.7 19.25 34.3 11.1 52.85 3 64.7 0 69.1 14.1 63.55 24 58 33.85 46.6 40.1 35.15 46.3 21.45 44.3 7.8 42.35 0 29.85Z"
        />
        <Path
          id="LoginWaiting-arms_resting"
          stroke={theme.a_puzmo}
          strokeWidth={15}
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M-105.6 48.8Q-97.8 54.4 -93.2 61.1 -89.2 66.9 -88.1 72.6 -86.65 80.45 -99.2 81.5 -106.2 82.05 -120.8 81.2M-231.1 48.8Q-238.9 54.4 -243.5 61.1 -247.5 66.9 -248.6 72.6 -250.05 80.45 -237.5 81.5 -230.5 82.05 -215.9 81.2"
        />
      </Defs>
      <Use xlinkHref="#LoginWaiting-main" transform="translate(346 178)" />
    </Svg>
  )
}

export { LoginWaiting }
