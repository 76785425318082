/**
 * @generated SignedSource<<c8bb70e2bcdd88641d4eed59bd4aaccd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserRelationship = "Friends" | "NoConnection" | "Self" | "UserHasBlockedViewer" | "UserHasSentFriendRequest" | "ViewerHasSentFriendRequest" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UserDisplayPopoverContent$data = {
  readonly fullAggregateStats: any | null;
  readonly id: string;
  readonly name: string | null;
  readonly publicProfile: {
    readonly highlightedStatIDs: ReadonlyArray<string>;
    readonly id: string;
  } | null;
  readonly relationshipToViewer: UserRelationship;
  readonly username: string;
  readonly usernameID: string;
  readonly " $fragmentSpreads": FragmentRefs<"UserAvatar">;
  readonly " $fragmentType": "UserDisplayPopoverContent";
};
export type UserDisplayPopoverContent$key = {
  readonly " $data"?: UserDisplayPopoverContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserDisplayPopoverContent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "myUserStateID"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserDisplayPopoverContent",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullAggregateStats",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "viewerID",
          "variableName": "myUserStateID"
        }
      ],
      "kind": "ScalarField",
      "name": "relationshipToViewer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "usernameID",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "publicProfile",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "highlightedStatIDs",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserAvatar"
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "08a29ff96b65703ab7e647e092c612d6";

export default node;
