import Svg, { SvgProps, Use, Defs, Path, G } from "react-native-svg"
import { useTheme } from "styled-rn"

import { Animation, useAnimateSvg } from "./useAnimateSvg"

const animationData = [
  {
    repeatCount: "indefinite",
    attribute: "display",
    dur: 600,
    keyTimes: [0, 170, 600],
    values: ["none", "inline", "inline"],
  },
  {
    repeatCount: "indefinite",
    attribute: "display",
    dur: 600,
    keyTimes: [0, 100, 170, 600],
    values: ["none", "inline", "inline", "none"],
  },
  {
    repeatCount: "indefinite",
    attribute: "display",
    dur: 600,
    keyTimes: [0, 33, 100, 600],
    values: ["none", "inline", "inline", "none"],
  },
  {
    repeatCount: "indefinite",
    attribute: "display",
    dur: 600,
    keyTimes: [0, 33, 600],
    values: ["inline", "inline", "none"],
  },
] as Animation[]

const runOnceData = [...animationData]
runOnceData.forEach((a) => (a.repeatCount = 0))

export const LoginError = (props: SvgProps & { runOnce?: true }) => {
  const theme = useTheme()
  const states = useAnimateSvg(props.runOnce ? runOnceData : animationData)

  return (
    <Svg viewBox="0 0 355 365" {...props}>
      <Defs>
        <G id="LoginError-Layer4_0_FILL">
          <Path
            fill={theme.a_puzmo}
            stroke="none"
            d="M239.75 120.85Q214.05 99.2 177.7 100.15 140.85 99.35 114.95 120.95 102.95 132 97.3 145.25 91.85 158 91.35 175.05 90.7 199.35 103.65 217.7L123.15 217.7 103.65 217.7Q108.15 224.1 114.3 229.8 140.8 251.45 179.05 251.45 214 251.55 238.5 229.65 244.7 223.95 249.4 217.7L230.55 217.7 249.4 217.7Q263.7 198.7 264.15 174.8 264.45 142.45 239.75 120.85M197.8 147.6Q199.55 145.9 201.95 145.85 204.45 145.9 206.15 147.55 207.9 149.15 207.85 151.6 207.85 154 206.1 155.7 204.4 157.45 201.95 157.4 199.5 157.45 197.75 155.75 196.1 154.05 196.1 151.65 196.05 149.25 197.8 147.6M168.6 140.6Q168.6 143 166.85 144.7 165.15 146.45 162.7 146.4 160.25 146.45 158.5 144.75 156.85 143.05 156.85 140.65 156.8 138.25 158.55 136.6 160.3 134.9 162.7 134.85 165.2 134.9 166.9 136.55 168.65 138.15 168.6 140.6M182.55 194.55Q181.65 195.25 180.45 195.3 179.2 195.4 178.55 194.55 164.6 182 157.5 181.25 153.15 181 149.8 185.85L146.5 191.4Q145.7 192.65 144.8 193.05 143.6 193.45 142.3 192.9 141.05 192.1 141 191.2 140.25 190 141.1 188.65L144.7 182.55Q144.65 182.2 144.95 182.2 150.2 174.45 157.95 175.1 165.95 175.45 180.55 188.3L192.7 177.8Q193.55 177.1 194.8 177 195.75 176.95 196.7 177.8L211.1 188.8Q212.05 189.3 212.15 190.85 212.2 191.7 211.7 193 210.8 194 209.6 194.05 208.35 194.15 207.45 193.65L195 184.1 182.55 194.55Z"
          />
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M180.45 195.3Q181.65 195.25 182.55 194.55L195 184.1 207.45 193.65Q208.35 194.15 209.6 194.05 210.8 194 211.7 193 212.2 191.7 212.15 190.85 212.05 189.3 211.1 188.8L196.7 177.8Q195.75 176.95 194.8 177 193.55 177.1 192.7 177.8L180.55 188.3Q165.95 175.45 157.95 175.1 150.2 174.45 144.95 182.2 144.65 182.2 144.7 182.55L141.1 188.65Q140.25 190 141 191.2 141.05 192.1 142.3 192.9 143.6 193.45 144.8 193.05 145.7 192.65 146.5 191.4L149.8 185.85Q153.15 181 157.5 181.25 164.6 182 178.55 194.55 179.2 195.4 180.45 195.3M166.85 144.7Q168.6 143 168.6 140.6 168.65 138.15 166.9 136.55 165.2 134.9 162.7 134.85 160.3 134.9 158.55 136.6 156.8 138.25 156.85 140.65 156.85 143.05 158.5 144.75 160.25 146.45 162.7 146.4 165.15 146.45 166.85 144.7M201.95 145.85Q199.55 145.9 197.8 147.6 196.05 149.25 196.1 151.65 196.1 154.05 197.75 155.75 199.5 157.45 201.95 157.4 204.4 157.45 206.1 155.7 207.85 154 207.85 151.6 207.9 149.15 206.15 147.55 204.45 145.9 201.95 145.85Z"
          />
        </G>
        <G id="LoginError-Layer3_0_FILL">
          <Path
            fill={theme.a_puzmo}
            stroke="none"
            d="M238.25 120.85Q212.55 99.2 176.2 100.15 139.35 99.35 113.45 120.95 101.45 132 95.8 145.25 90.35 158 89.85 175.05 89.2 199.35 102.15 217.7L121.65 217.7 102.15 217.7Q106.65 224.1 112.8 229.8 139.3 251.45 177.55 251.45 212.5 251.55 237 229.65 243.2 223.95 247.9 217.7L229.05 217.7 247.9 217.7Q262.2 198.7 262.65 174.8 262.95 142.45 238.25 120.85M191.3 147.6Q193.05 145.9 195.45 145.85 197.95 145.9 199.65 147.55 201.4 149.15 201.35 151.6 201.35 154 199.6 155.7 197.9 157.45 195.45 157.4 193 157.45 191.25 155.75 189.6 154.05 189.6 151.65 189.55 149.25 191.3 147.6M173.95 195.3Q172.7 195.4 172.05 194.55 158.1 182 151 181.25 146.65 181 143.3 185.85L140 191.4Q139.2 192.65 138.3 193.05 137.1 193.45 135.8 192.9 134.55 192.1 134.5 191.2 133.75 190 134.6 188.65L138.2 182.55Q138.15 182.2 138.45 182.2 143.7 174.45 151.45 175.1 159.45 175.45 174.05 188.3L186.2 177.8Q187.05 177.1 188.3 177 189.25 176.95 190.2 177.8L204.6 188.8Q205.55 189.3 205.65 190.85 205.7 191.7 205.2 193 204.3 194 203.1 194.05 201.85 194.15 200.95 193.65L188.5 184.1 176.05 194.55Q175.15 195.25 173.95 195.3M160.35 144.7Q158.65 146.45 156.2 146.4 153.75 146.45 152 144.75 150.35 143.05 150.35 140.65 150.3 138.25 152.05 136.6 153.8 134.9 156.2 134.85 158.7 134.9 160.4 136.55 162.15 138.15 162.1 140.6 162.1 143 160.35 144.7Z"
          />
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M156.2 146.4Q158.65 146.45 160.35 144.7 162.1 143 162.1 140.6 162.15 138.15 160.4 136.55 158.7 134.9 156.2 134.85 153.8 134.9 152.05 136.6 150.3 138.25 150.35 140.65 150.35 143.05 152 144.75 153.75 146.45 156.2 146.4M172.05 194.55Q172.7 195.4 173.95 195.3 175.15 195.25 176.05 194.55L188.5 184.1 200.95 193.65Q201.85 194.15 203.1 194.05 204.3 194 205.2 193 205.7 191.7 205.65 190.85 205.55 189.3 204.6 188.8L190.2 177.8Q189.25 176.95 188.3 177 187.05 177.1 186.2 177.8L174.05 188.3Q159.45 175.45 151.45 175.1 143.7 174.45 138.45 182.2 138.15 182.2 138.2 182.55L134.6 188.65Q133.75 190 134.5 191.2 134.55 192.1 135.8 192.9 137.1 193.45 138.3 193.05 139.2 192.65 140 191.4L143.3 185.85Q146.65 181 151 181.25 158.1 182 172.05 194.55M195.45 145.85Q193.05 145.9 191.3 147.6 189.55 149.25 189.6 151.65 189.6 154.05 191.25 155.75 193 157.45 195.45 157.4 197.9 157.45 199.6 155.7 201.35 154 201.35 151.6 201.4 149.15 199.65 147.55 197.95 145.9 195.45 145.85Z"
          />
        </G>
        <G id="LoginError-Layer2_0_FILL">
          <Path
            fill={theme.a_puzmo}
            stroke="none"
            d="M269.6 174.8Q269.9 142.45 245.2 120.85 219.5 99.2 183.15 100.15 146.3 99.35 120.4 120.95 108.4 132 102.75 145.25 97.3 158 96.8 175.05 96.15 199.35 109.1 217.7L128.6 217.7 109.1 217.7Q113.6 224.1 119.75 229.8 146.25 251.45 184.5 251.45 219.45 251.55 243.95 229.65 250.15 223.95 254.85 217.7L236 217.7 254.85 217.7Q269.15 198.7 269.6 174.8M209.75 147.6Q211.5 145.9 213.9 145.85 216.4 145.9 218.1 147.55 219.85 149.15 219.8 151.6 219.8 154 218.05 155.7 216.35 157.45 213.9 157.4 211.45 157.45 209.7 155.75 208.05 154.05 208.05 151.65 208 149.25 209.75 147.6M174.65 146.4Q172.2 146.45 170.45 144.75 168.8 143.05 168.8 140.65 168.75 138.25 170.5 136.6 172.25 134.9 174.65 134.85 177.15 134.9 178.85 136.55 180.6 138.15 180.55 140.6 180.55 143 178.8 144.7 177.1 146.45 174.65 146.4M190.5 194.55Q176.55 182 169.45 181.25 165.1 181 161.75 185.85L158.45 191.4Q157.65 192.65 156.75 193.05 155.55 193.45 154.25 192.9 153 192.1 152.95 191.2 152.2 190 153.05 188.65L156.65 182.55Q156.6 182.2 156.9 182.2 162.15 174.45 169.9 175.1 177.9 175.45 192.5 188.3L204.65 177.8Q205.5 177.1 206.75 177 207.7 176.95 208.65 177.8L223.05 188.8Q224 189.3 224.1 190.85 224.15 191.7 223.65 193 222.75 194 221.55 194.05 220.3 194.15 219.4 193.65L206.95 184.1 194.5 194.55Q193.6 195.25 192.4 195.3 191.15 195.4 190.5 194.55Z"
          />
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M169.45 181.25Q176.55 182 190.5 194.55 191.15 195.4 192.4 195.3 193.6 195.25 194.5 194.55L206.95 184.1 219.4 193.65Q220.3 194.15 221.55 194.05 222.75 194 223.65 193 224.15 191.7 224.1 190.85 224 189.3 223.05 188.8L208.65 177.8Q207.7 176.95 206.75 177 205.5 177.1 204.65 177.8L192.5 188.3Q177.9 175.45 169.9 175.1 162.15 174.45 156.9 182.2 156.6 182.2 156.65 182.55L153.05 188.65Q152.2 190 152.95 191.2 153 192.1 154.25 192.9 155.55 193.45 156.75 193.05 157.65 192.65 158.45 191.4L161.75 185.85Q165.1 181 169.45 181.25M170.45 144.75Q172.2 146.45 174.65 146.4 177.1 146.45 178.8 144.7 180.55 143 180.55 140.6 180.6 138.15 178.85 136.55 177.15 134.9 174.65 134.85 172.25 134.9 170.5 136.6 168.75 138.25 168.8 140.65 168.8 143.05 170.45 144.75M213.9 145.85Q211.5 145.9 209.75 147.6 208 149.25 208.05 151.65 208.05 154.05 209.7 155.75 211.45 157.45 213.9 157.4 216.35 157.45 218.05 155.7 219.8 154 219.8 151.6 219.85 149.15 218.1 147.55 216.4 145.9 213.9 145.85Z"
          />
        </G>
        <G id="LoginError-Layer1_0_FILL">
          <Path
            fill={theme.a_puzmo}
            stroke="none"
            d="M234.25 120.85Q208.55 99.2 172.2 100.15 135.35 99.35 109.45 120.95 97.45 132 91.8 145.25 86.35 158 85.85 175.05 85.2 199.35 98.15 217.7L117.65 217.7 98.15 217.7Q102.65 224.1 108.8 229.8 135.3 251.45 173.55 251.45 208.5 251.55 233 229.65 239.2 223.95 243.9 217.7L225.05 217.7 243.9 217.7Q258.2 198.7 258.65 174.8 258.95 142.45 234.25 120.85M187.3 147.6Q189.05 145.9 191.45 145.85 193.95 145.9 195.65 147.55 197.4 149.15 197.35 151.6 197.35 154 195.6 155.7 193.9 157.45 191.45 157.4 189 157.45 187.25 155.75 185.6 154.05 185.6 151.65 185.55 149.25 187.3 147.6M172.05 194.55Q171.15 195.25 169.95 195.3 168.7 195.4 168.05 194.55 154.1 182 147 181.25 142.65 181 139.3 185.85L136 191.4Q135.2 192.65 134.3 193.05 133.1 193.45 131.8 192.9 130.55 192.1 130.5 191.2 129.75 190 130.6 188.65L134.2 182.55Q134.15 182.2 134.45 182.2 139.7 174.45 147.45 175.1 155.45 175.45 170.05 188.3L182.2 177.8Q183.05 177.1 184.3 177 185.25 176.95 186.2 177.8L200.6 188.8Q201.55 189.3 201.65 190.85 201.7 191.7 201.2 193 200.3 194 199.1 194.05 197.85 194.15 196.95 193.65L184.5 184.1 172.05 194.55M158.1 140.6Q158.1 143 156.35 144.7 154.65 146.45 152.2 146.4 149.75 146.45 148 144.75 146.35 143.05 146.35 140.65 146.3 138.25 148.05 136.6 149.8 134.9 152.2 134.85 154.7 134.9 156.4 136.55 158.15 138.15 158.1 140.6M156.35 144.7Q158.1 143 158.1 140.6 158.15 138.15 156.4 136.55 154.7 134.9 152.2 134.85 149.8 134.9 148.05 136.6 146.3 138.25 146.35 140.65 146.35 143.05 148 144.75 149.75 146.45 152.2 146.4 154.65 146.45 156.35 144.7M191.45 145.85Q189.05 145.9 187.3 147.6 185.55 149.25 185.6 151.65 185.6 154.05 187.25 155.75 189 157.45 191.45 157.4 193.9 157.45 195.6 155.7 197.35 154 197.35 151.6 197.4 149.15 195.65 147.55 193.95 145.9 191.45 145.85Z"
          />
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M169.95 195.3Q171.15 195.25 172.05 194.55L184.5 184.1 196.95 193.65Q197.85 194.15 199.1 194.05 200.3 194 201.2 193 201.7 191.7 201.65 190.85 201.55 189.3 200.6 188.8L186.2 177.8Q185.25 176.95 184.3 177 183.05 177.1 182.2 177.8L170.05 188.3Q155.45 175.45 147.45 175.1 139.7 174.45 134.45 182.2 134.15 182.2 134.2 182.55L130.6 188.65Q129.75 190 130.5 191.2 130.55 192.1 131.8 192.9 133.1 193.45 134.3 193.05 135.2 192.65 136 191.4L139.3 185.85Q142.65 181 147 181.25 154.1 182 168.05 194.55 168.7 195.4 169.95 195.3Z"
          />
        </G>
        <Path
          id="LoginError-Layer4_0_1_STROKES"
          stroke={theme.a_puzmo}
          strokeWidth="15"
          strokeLinejoin="miter"
          strokeLinecap="square"
          strokeMiterlimit="60"
          fill="none"
          d="M249.4 217.7L272.2 217.7 272.2 244.6M103.65 217.7L81.5 217.7 81.5 244.6M103.65 217.7L123.15 217.7M249.4 217.7L230.55 217.7"
        />
        <Path
          id="LoginError-Layer3_0_1_STROKES"
          stroke={theme.a_puzmo}
          strokeWidth="15"
          strokeLinejoin="miter"
          strokeLinecap="square"
          strokeMiterlimit="60"
          fill="none"
          d="M247.9 217.7L270.7 217.7 270.7 244.6M102.15 217.7L80 217.7 80 244.6M102.15 217.7L121.65 217.7M247.9 217.7L229.05 217.7"
        />
        <Path
          id="LoginError-Layer2_0_1_STROKES"
          stroke={theme.a_puzmo}
          strokeWidth="15"
          strokeLinejoin="miter"
          strokeLinecap="square"
          strokeMiterlimit="60"
          fill="none"
          d="M254.85 217.7L277.65 217.7 277.65 244.6M86.95 244.6L86.95 217.7 109.1 217.7 128.6 217.7M254.85 217.7L236 217.7"
        />
        <Path
          id="LoginError-Layer1_0_1_STROKES"
          stroke={theme.alwaysDark}
          strokeWidth="4"
          strokeLinejoin="miter"
          strokeLinecap="square"
          strokeMiterlimit="60"
          fill="none"
          d="M197.35 151.6Q197.35 154 195.6 155.7 193.9 157.45 191.45 157.4 189 157.45 187.25 155.75 185.6 154.05 185.6 151.65 185.55 149.25 187.3 147.6 189.05 145.9 191.45 145.85 193.95 145.9 195.65 147.55 197.4 149.15 197.35 151.6ZM158.1 140.6Q158.1 143 156.35 144.7 154.65 146.45 152.2 146.4 149.75 146.45 148 144.75 146.35 143.05 146.35 140.65 146.3 138.25 148.05 136.6 149.8 134.9 152.2 134.85 154.7 134.9 156.4 136.55 158.15 138.15 158.1 140.6Z"
        />
        <Path
          id="LoginError-Layer1_0_2_STROKES"
          stroke={theme.a_puzmo}
          strokeWidth="15"
          strokeLinejoin="miter"
          strokeLinecap="square"
          strokeMiterlimit="60"
          fill="none"
          d="M243.9 217.7L266.7 217.7 266.7 244.6M98.15 217.7L76 217.7 76 244.6M98.15 217.7L117.65 217.7M243.9 217.7L225.05 217.7"
        />
      </Defs>
      <G id="LoginError-Scene-1t135" overflow="visible">
        <G {...states[0]}>
          <Use xlinkHref="#LoginError-Layer4_0_FILL" />
          <Use xlinkHref="#LoginError-Layer4_0_1_STROKES" />
        </G>
        <G {...states[1]}>
          <Use xlinkHref="#LoginError-Layer3_0_FILL" />
          <Use xlinkHref="#LoginError-Layer3_0_1_STROKES" />
        </G>
        <G {...states[2]}>
          <Use xlinkHref="#LoginError-Layer2_0_FILL" />
          <Use xlinkHref="#LoginError-Layer2_0_1_STROKES" />
        </G>
        <G {...states[3]}>
          <Use xlinkHref="#LoginError-Layer1_0_FILL" />
          <Use xlinkHref="#LoginError-Layer1_0_1_STROKES" />
          <Use xlinkHref="#LoginError-Layer1_0_2_STROKES" />
        </G>
      </G>
    </Svg>
  )
}
