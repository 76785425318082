/**
 * @generated SignedSource<<070896a6ac92b3f7ff7afe8387246565>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateGamePlayedInput = {
  additionalTimeAddedSecs?: number | null;
  boardState?: string | null;
  cheatsUsed?: number | null;
  collabUserReferences: ReadonlyArray<string | null>;
  elapsedTimeSecs?: number | null;
  hintsUsed?: number | null;
  metric1?: number | null;
  metric2?: number | null;
  metric3?: number | null;
  metric4?: number | null;
  metricStrings?: ReadonlyArray<string> | null;
  pauses?: number | null;
};
export type GamePlayUpdateMutationsGameStateMutation$variables = {
  id: string;
  input: UpdateGamePlayedInput;
};
export type GamePlayUpdateMutationsGameStateMutation$data = {
  readonly updateGameState: {
    readonly id: string;
    readonly puzzle: {
      readonly " $fragmentSpreads": FragmentRefs<"CurrentUserPuzzleThumbnail">;
    };
  } | null;
};
export type GamePlayUpdateMutationsGameStateMutation = {
  response: GamePlayUpdateMutationsGameStateMutation$data;
  variables: GamePlayUpdateMutationsGameStateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GamePlayUpdateMutationsGameStateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GamePlayed",
        "kind": "LinkedField",
        "name": "updateGameState",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Puzzle",
            "kind": "LinkedField",
            "name": "puzzle",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CurrentUserPuzzleThumbnail"
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GamePlayUpdateMutationsGameStateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GamePlayed",
        "kind": "LinkedField",
        "name": "updateGameState",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Puzzle",
            "kind": "LinkedField",
            "name": "puzzle",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "gameID",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "puzzle",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "GamePlayed",
                "kind": "LinkedField",
                "name": "currentAccountGamePlayed",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "boardState",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ownerID",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "completed",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "metric1",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "metric2",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "metric3",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "metric4",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "metricStrings",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "viewerPlayed",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Game",
                "kind": "LinkedField",
                "name": "game",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "jsPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "assetsSha",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "thumbnailJSPath",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "thumbnailGlobalFunction",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cb118732bd4f33f0c78284cb5a538e3f",
    "id": null,
    "metadata": {},
    "name": "GamePlayUpdateMutationsGameStateMutation",
    "operationKind": "mutation",
    "text": "mutation GamePlayUpdateMutationsGameStateMutation(\n  $id: ID!\n  $input: UpdateGamePlayedInput!\n) {\n  updateGameState(id: $id, input: $input) {\n    puzzle {\n      ...CurrentUserPuzzleThumbnail\n      id\n    }\n    id\n  }\n}\n\nfragment CurrentUserPuzzleThumbnail on Puzzle {\n  id\n  name\n  gameID\n  puzzle\n  slug\n  currentAccountGamePlayed {\n    id\n    boardState\n    slug\n    ownerID\n    completed\n    metric1\n    metric2\n    metric3\n    metric4\n    metricStrings\n    viewerPlayed\n  }\n  game {\n    id\n    slug\n    jsPath\n    assetsSha\n    thumbnailJSPath\n    thumbnailGlobalFunction\n  }\n}\n"
  }
};
})();

(node as any).hash = "f300d2625d4e58bff411bd2a4c9fa30b";

export default node;
