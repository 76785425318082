/**
 * @generated SignedSource<<f9727844a879ce853e66dacd996fdc03>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserDisplayPopoverContentQuery$variables = {
  myUserStateID: string;
  userIDOrUsername: string;
  usernameID?: string | null;
};
export type UserDisplayPopoverContentQuery$data = {
  readonly user: {
    readonly " $fragmentSpreads": FragmentRefs<"UserDisplayPopoverContent">;
  } | null;
};
export type UserDisplayPopoverContentQuery = {
  response: UserDisplayPopoverContentQuery$data;
  variables: UserDisplayPopoverContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "myUserStateID"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userIDOrUsername"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "usernameID"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "userIDOrUsername"
  },
  {
    "kind": "Variable",
    "name": "usernameID",
    "variableName": "usernameID"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserDisplayPopoverContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserDisplayPopoverContent"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UserDisplayPopoverContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullAggregateStats",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "viewerID",
                "variableName": "myUserStateID"
              }
            ],
            "kind": "ScalarField",
            "name": "relationshipToViewer",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "usernameID",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProfile",
            "kind": "LinkedField",
            "name": "publicProfile",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "highlightedStatIDs",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "avatarURL",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d8722d9ce7f10053709b596178139582",
    "id": null,
    "metadata": {},
    "name": "UserDisplayPopoverContentQuery",
    "operationKind": "query",
    "text": "query UserDisplayPopoverContentQuery(\n  $userIDOrUsername: ID!\n  $usernameID: String\n  $myUserStateID: String!\n) {\n  user(id: $userIDOrUsername, usernameID: $usernameID) {\n    ...UserDisplayPopoverContent\n    id\n  }\n}\n\nfragment UserAvatar on User {\n  username\n  usernameID\n  avatarURL\n}\n\nfragment UserDisplayPopoverContent on User {\n  id\n  fullAggregateStats\n  relationshipToViewer(viewerID: $myUserStateID)\n  username\n  usernameID\n  name\n  publicProfile {\n    id\n    highlightedStatIDs\n  }\n  ...UserAvatar\n}\n"
  }
};
})();

(node as any).hash = "3a238e36df1e87e6eddeb554d5fce3b6";

export default node;
