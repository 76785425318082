import { BASE_MARGIN } from "$components/DesignSystem"
import { View } from "react-native"

import { AnimatedPressableBase, ModalForm } from "../DesignSystem"
import { LOGIN_TRANSITION_DURATION } from "./AuthModal"

export function LoginSignupToggle(props: {
  updateViewMode: (mode: "login" | "signup", delay?: number) => void
  active: "login" | "signup"
}) {
  return (
    <View
      style={{
        flexDirection: "row",
      }}
    >
      <AnimatedPressableBase
        style={{
          marginRight: BASE_MARGIN * 2,
        }}
        onPress={() => (props.active === "login" ? null : props.updateViewMode("login", LOGIN_TRANSITION_DURATION))}
      >
        <ModalForm.Title
          style={{
            opacity: props.active === "login" ? 1 : 0.25,
            textDecorationLine: props.active === "login" ? "underline" : undefined,
            // @ts-ignore web only
            textDecorationThickness: 2,
          }}
        >
          Log in
        </ModalForm.Title>
      </AnimatedPressableBase>
      <AnimatedPressableBase onPress={() => (props.active === "signup" ? null : props.updateViewMode("signup", LOGIN_TRANSITION_DURATION))}>
        <ModalForm.Title
          style={{
            opacity: props.active === "signup" ? 1 : 0.25,
            textDecorationLine: props.active === "signup" ? "underline" : undefined,
            // @ts-ignore web only
            textDecorationThickness: 2,
          }}
        >
          Sign up
        </ModalForm.Title>
      </AnimatedPressableBase>
    </View>
  )
}
