import type { EndUserUserInput, useUserEditMutation as UseUserEditMutation } from "$relay/useUserEditMutation.graphql"
import { useCallback } from "react"
import { graphql, useMutation } from "react-relay"
import { useGetUser } from "../CoreContext"

const mutation = graphql`
  mutation useUserEditMutation($ID: ID!, $input: EndUserUserInput!) {
    updateUser(id: $ID, input: $input) {
      ...PuzzmoCurrentUserFragment
    }
  }
`

export function useSimpleUserEditMutation() {
  const [commit, loading] = useMutation<UseUserEditMutation>(mutation)
  return [
    useCallback(
      (ID: string, input: EndUserUserInput) => {
        return commit({
          variables: { ID, input },
        })
      },
      [commit]
    ),
    loading,
  ] as const
}

// NOOPs when not logged in etc
export function useUpdateLastSocialInteractionMutation(mode: "friends" | "groups") {
  const user = useGetUser()

  const [commit, loading] = useMutation<UseUserEditMutation>(mutation)
  return [
    useCallback(() => {
      if (user.type === "anon") return
      const userID = user.currentUser.id
      return commit({
        variables: {
          ID: userID,
          input: {
            socialLastViewedAt: mode === "friends" ? new Date().toISOString() : undefined,
            groupLastViewedAt: mode === "groups" ? new Date().toISOString() : undefined,
          },
        },
      })
    }, [commit, user.currentUser?.id, user.type, mode]),
    loading,
  ] as const
}
