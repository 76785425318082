import React from "react"
import { View } from "react-native"
import { useTheme } from "styled-rn"
import { useContextSelector } from "use-context-selector"

import { BASE_RADIUS, InlineText, BASE_PADDING, BASE_MARGIN, BodyTextProps } from "./DesignSystem"
import { playGameContext } from "../hooks/useSetupPlayGameContext"

export const TimerInfo = React.memo((props: BodyTextProps & { style?: any }) => {
  const timeDisplay = useContextSelector(playGameContext, (s) => s.gameUIState.timeDisplay)
  const theme = useTheme()

  return (
    <>
      <InlineText key="timer" {...props}>
        {timeDisplay[0]}
      </InlineText>
      {timeDisplay[1] ? (
        <View
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingVertical: BASE_PADDING / 3,
            paddingHorizontal: BASE_PADDING / 2,
            borderRadius: BASE_RADIUS,
            marginLeft: BASE_MARGIN / 3,
            backgroundColor: `${theme.a_puzmo}33`,
          }}
        >
          <InlineText bold size="small" style={{ color: theme.a_puzmo, ...props.style }}>
            +{timeDisplay[1]}
          </InlineText>
        </View>
      ) : null}
    </>
  )
})
