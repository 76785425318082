import { BodyText, MainContainer, Screen, Centered, ThinButton, Code } from "$components/DesignSystem"
import { LoginError } from "$components/svgAnimations/LoginError"
import React, { useEffect, useState } from "react"
import { View } from "react-native"
import Svg, { SvgProps, Path } from "react-native-svg"

import { secondsToHms } from "../lib/secondsToHms"

export const CriticalFailureScreen = (props: { errorMessage: string; stack?: string; retry: () => void }) => {
  const [secsTillRetry, setSecsTillRetry] = useState(20)
  const [retryTime, setRetryTime] = useState(new Date().getTime() + secsTillRetry * 1000)
  const [countdown, setCountDown] = useState(secsTillRetry * 1000)

  // This only works on web
  //   const reload = () => document.location.reload()

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(retryTime - new Date().getTime())
    }, 1000)

    return () => clearInterval(interval)
  }, [retryTime])

  if (countdown < 0) {
    props.retry()
  }

  const guestimates: any = {
    "Load failed": "You couldn't reach Puzzmo's API, perhaps you (or it) are offline?",
  }

  return (
    <Screen>
      <Centered>
        <MainContainer style={{ maxWidth: 600 }}>
          <View style={{ alignItems: "center", justifyContent: "center" }}>
            <LoginError width={240} runOnce />
          </View>
          <BodyText style={{ marginBottom: 8 }}>Critical Issue with Puzzmo</BodyText>

          <BodyText bold>{guestimates[props.errorMessage] || props.errorMessage}</BodyText>
          <BodyText>Re-trying in {secsTillRetry} seconds.</BodyText>

          {props.stack && <Code>{props.stack}</Code>}

          <View style={{ marginTop: 20 }}>
            <ThinButton title={`Try refresh (${secondsToHms(countdown / 1000)})`} onPress={props.retry} />
          </View>
        </MainContainer>
      </Centered>
    </Screen>
  )
}

const ConfusedPuzmonaut = (props: SvgProps) => (
  <Svg width={41} height={25} fill="none" {...props}>
    <Path
      d="M21.13 1.937h.007c4.642-.034 8.886 1.867 10.989 5.395.426.715.799 1.784 1.065 2.886.266 1.1.399 2.12.399 2.7 0 5.233-5.34 10.571-11.986 10.571-1.603 0-3.98-.686-6.06-1.435a53.499 53.499 0 0 1-3.526-1.417c-.8-.636-1.711-1.87-2.442-3.385-.749-1.554-1.236-3.258-1.252-4.667v-.006c-.067-3.84 1.672-6.453 4.137-8.147 2.507-1.722 5.784-2.495 8.669-2.495Z"
      stroke="#000"
      strokeWidth={2}
    />
    <Path d="M9 16c-.833.333-5.5 1-7.5-1l6-8.5M32 7c1.952 7.429 10.931 8.357 7.027 13" stroke="#000" strokeWidth={2} />
    <Path
      d="M15.037 8.79a1.393 1.393 0 0 0-1.204-.037c-.383.183-.656.531-.748.952-.09.366-.036.75.128 1.117.164.366.438.64.766.805.2.11.42.164.639.164.2 0 .382-.036.565-.128.383-.183.656-.53.748-.951.09-.366.036-.75-.128-1.117a1.725 1.725 0 0 0-.766-.805ZM24.268 5.751c-.383-.805-1.258-1.17-1.988-.841-.711.347-.985 1.262-.62 2.068.164.347.438.64.766.805a1.326 1.326 0 0 0 1.204.037c.383-.183.656-.531.747-.952a1.687 1.687 0 0 0-.109-1.117ZM25.205 15.555c.784.406 2.767 1.315-2.033 2.592-4.503 1.188-5.191.095-4.813-.852.375-.933 6.048-2.149 6.846-1.74Z"
      fill="#141620"
    />
  </Svg>
)
