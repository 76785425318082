import { Avatar, InternalLink } from "$components/DesignSystem"
import { UserAvatar$key } from "$relay/UserAvatar.graphql"
import React from "react"
import { PixelRatio, ViewStyle } from "react-native"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { useTheme } from "styled-rn"

import { useGetUser } from "../../CoreContext"

const fragment = graphql`
  fragment UserAvatar on User {
    username
    usernameID
    avatarURL
  }
`

export type AvatarProps = {
  size?: number
  noLink?: boolean
  style?: ViewStyle
}

// Relay powered
export const UserAvatar = React.memo((props: { user: UserAvatar$key } & AvatarProps) => {
  const user = useFragment(fragment, props.user)
  return <UserAvatar_RAW {...props} user={user} />
})

type RawUserAvatarProps = { user: { username: string; usernameID: string; avatarURL: string } } & AvatarProps

// For edge cases (like avatars coming in from collab)
export const UserAvatar_RAW = (props: RawUserAvatarProps) => {
  const theme = useTheme()
  const loggedInUser = useGetUser()

  const user = props.user
  // if (!user) return <Avatar size={props.size} style={props.style} />
  if (!user) return null

  const isUser =
    loggedInUser.type === "user" &&
    loggedInUser.currentUser.username === props.user.username &&
    loggedInUser.currentUser.usernameID === props.user.usernameID

  const borderColor = isUser ? theme.player : theme.key

  const pixelSize = PixelRatio.getPixelSizeForLayoutSize(props.size || 0)
  if (props.noLink)
    return (
      <Avatar
        source={{ uri: user.avatarURL, width: pixelSize, height: pixelSize }}
        size={props.size}
        style={{ borderColor, ...props.style }}
      />
    )

  return (
    <InternalLink screen="UserProfile" routeOpts={{ username: user.username, usernameID: user.usernameID }}>
      <Avatar
        source={{ uri: user.avatarURL, width: pixelSize, height: pixelSize }}
        size={props.size}
        style={{ borderColor, ...props.style }}
      />
    </InternalLink>
  )
}
