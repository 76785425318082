import { ModalInviteAcceptInviteMutation } from "$relay/ModalInviteAcceptInviteMutation.graphql"
import { ModalInviteQuery } from "$relay/ModalInviteQuery.graphql"
import React, { Suspense, useCallback, useContext } from "react"
import { Platform, View } from "react-native"
import { useLazyLoadQuery, useMutation } from "react-relay"
import { graphql } from "relay-runtime"

import { AuthModalContext } from "./AuthModal"
import type { AuthModes } from "./AuthModal"
import { AppContext } from "../../../AppContext"
import { useGetUser } from "../../CoreContext"
import { navigateMainNavigator } from "../../navigation/navigateMainNavigator"
import { BASE_MARGIN, BodyText, ModalForm, Padding, PuzmoButton, Row } from "../DesignSystem"
import { StrokedTick } from "../icons/Tick"
import { UserAvatar } from "../profiles/UserAvatar"

const Query = graphql`
  query ModalInviteQuery($id: ID!) {
    invite(id: $id) {
      sender {
        ...UserAvatar
        name
        username
        id
      }
      friendSender
      includedSubscription
      group {
        name
        slug
        slugID
      }
    }
  }
`

export const ModalInviteAcceptInvite = graphql`
  mutation ModalInviteAcceptInviteMutation($id: ID!) {
    acceptInvite(id: $id) {
      message
      failed
      success
    }
  }
`

export const ModalInviteInfo = (props: { updateViewMode: (mode: AuthModes) => void }) => {
  return (
    <Suspense fallback={<ModalLoading />}>
      <_ModalInviteInfo updateViewMode={props.updateViewMode} />
    </Suspense>
  )
}

const ModalLoading = () => {
  return (
    <View>
      <ModalForm.Title style={{ marginBottom: BASE_MARGIN }}>Your invite</ModalForm.Title>
      <View style={{ height: 200 }}>
        <BodyText>Downloading your invite...</BodyText>
      </View>
    </View>
  )
}

const NoInviteFound = () => {
  return (
    <View>
      <ModalForm.Title>Your invite</ModalForm.Title>
      <View style={{ height: 200 }}>
        <BodyText>Downloading your invite...</BodyText>
      </View>
    </View>
  )
}

const _ModalInviteInfo = (props: { updateViewMode: (mode: AuthModes) => void }) => {
  const { launchInfo } = useContext(AppContext)
  const modalCtx = useContext(AuthModalContext)

  const user = useGetUser()
  const data = useLazyLoadQuery<ModalInviteQuery>(Query, { id: launchInfo!.inviteToken! })

  const currentUserAlreadySignedUp = user.type === "user"

  const [acceptInvite, loadingAccept] = useMutation<ModalInviteAcceptInviteMutation>(ModalInviteAcceptInvite)

  const isYourInvite = currentUserAlreadySignedUp && data?.invite?.sender.id === user.currentUser.id
  const buttonClicked = useCallback(() => {
    if (isYourInvite) return
    if (currentUserAlreadySignedUp) {
      acceptInvite({
        variables: { id: launchInfo!.inviteToken! },
        onCompleted: () => {
          modalCtx.closeModal()
          if (data.invite!.group) {
            navigateMainNavigator("Group", { groupSlugID: data.invite!.group!.slugID, groupSlug: data.invite!.group!.slug })
          }
        },
      })
    } else {
      props.updateViewMode("signup")
    }
  }, [acceptInvite, data.invite, isYourInvite, launchInfo, modalCtx, props, currentUserAlreadySignedUp])

  if (!data.invite) return <NoInviteFound />

  const message = currentUserAlreadySignedUp
    ? isYourInvite
      ? "this is your invite for other people, when others accept the invite they will also:"
        ? data.invite.group
        : "has invited you to join their group! Accepting this invite will also:"
      : "has sent you a friend invite, clicking accept will:"
    : "has invited you to sign up to Puzzmo. Alas, this does mean filling in a small form, but it also means you:"

  const senderName = data.invite.sender.name || data.invite.sender.username

  return (
    <View>
      <ModalForm.Title>Your invite</ModalForm.Title>

      <Row>
        <Padding px={1}>
          <UserAvatar user={data.invite.sender} size={40} />
        </Padding>
        <BodyText>
          <>
            <BodyText customColorKey="key">{senderName} </BodyText>
            {message}
          </>
        </BodyText>
      </Row>

      {data.invite.friendSender && (
        <Row marginT>
          <Padding px={1}>
            <View style={{ position: "relative", left: 8 }}>
              <StrokedTick width={40} />
            </View>
          </Padding>
          <BodyText>
            add <BodyText customColorKey="key">{senderName}</BodyText> as a friend
          </BodyText>
        </Row>
      )}

      {data.invite.group && (
        <Row marginT>
          <Padding px={1}>
            <View style={{ position: "relative", left: 8 }}>
              <StrokedTick width={40} />
            </View>
          </Padding>
          <BodyText>
            add you to their group <BodyText customColorKey="key">{data.invite.group.name}</BodyText> automatically
          </BodyText>
        </Row>
      )}

      {data.invite.includedSubscription && (
        <Row marginT>
          <Padding px={1}>
            <StrokedTick width={40} />
          </Padding>
          <BodyText>will be gifted a years of Puzzmo paid membership, no ads for you!</BodyText>
        </Row>
      )}

      <PuzmoButton
        block
        title={currentUserAlreadySignedUp ? "Accept invite" : "Let's get started"}
        onPress={buttonClicked}
        bgKey="a_puzmo"
        color="alwaysDark"
        disabled={isYourInvite || loadingAccept}
        style={{ marginTop: 24 }}
      />
    </View>
  )
}
