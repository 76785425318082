import { Placement, hide, shift, useFloating, flip, autoPlacement } from "@floating-ui/react-native"
import { Portal } from "@gorhom/portal"
import { useFocusEffect } from "@react-navigation/native"
import React, { Suspense, useCallback, useEffect, useRef, useState } from "react"
import { Animated, Platform, View } from "react-native"

import { BASE_PADDING } from "../DesignSystem"
import Hoverable from "../Hoverable"
import { PulsingLoader } from "../PulsingLoader"
import { debounce } from "ts-debounce"

export interface PopoverProps extends React.PropsWithChildren {
  render: () => React.ReactNode
  placementPreference?: Placement
  children: JSX.Element
  disabled?: boolean
}

export function useFade(open: boolean, onFinish?: () => void, stillInFlight?: boolean) {
  const animation = useState(new Animated.Value(0))[0]
  const opacity = animation.interpolate({ inputRange: [0, 1], outputRange: [0, 1] })
  const scale = animation.interpolate({ inputRange: [0, 1], outputRange: [0.8, 1] })
  // if we're in flight, cancel any animations
  if (stillInFlight) {
    animation.stopAnimation()
    animation.setValue(0)
  } else if (open) {
    Animated.timing(animation, {
      isInteraction: false,
      toValue: 1,
      useNativeDriver: true,
      duration: 125,
      delay: 125,
    }).start()
  } else {
    Animated.timing(animation, {
      isInteraction: false,
      toValue: 0,
      useNativeDriver: true,
      duration: 125,
      delay: 0,
    }).start(({ finished }) => {
      if (finished && onFinish) {
        onFinish()
      }
    })
  }

  return { opacity, scale }
}

export const Popover = ({ disabled, children, render, placementPreference }: PopoverProps) => {
  const [open, setOpen] = useState(false)
  const [exiting, setExiting] = useState(false)

  const props = useFloating({
    middleware: [flip(), shift(), hide({ strategy: "referenceHidden" }), hide({ strategy: "escaped" })],
    placement: placementPreference,
    sameScrollView: false,
  })
  const { x, y, refs, middlewareData } = props
  const stillInFlight = y === null || x === null

  useEffect(() => {
    if (disabled) {
      setOpen(false)
    }
  }, [disabled])

  // listen for all scroll events on the page, hide all popovers if they're open
  // This is kind of a lot of scroll event listeners but I don't see another way
  // to do this :(
  useEffect(() => {
    if (Platform.OS !== "web") return
    const listener = debounce(
      () => {
        setExiting(true)
      },
      250,
      { isImmediate: true }
    )
    window.addEventListener("scroll", listener as any, true)
    return () => {
      window.removeEventListener("scroll", listener as any, true)
    }
  }, [])

  // close on route change
  useFocusEffect(
    useCallback(() => {
      return () => {
        setOpen(false)
      }
    }, [])
  )

  useEffect(() => {
    if (middlewareData.hide?.referenceHidden || middlewareData.hide?.escaped) {
      setOpen(false)
    }
  }, [middlewareData.hide?.referenceHidden, middlewareData.hide?.escaped])

  return (
    <>
      <Hoverable
        onHoverIn={() => {
          if (disabled) return
          setExiting(false)
          setOpen(true)
        }}
        onHoverOut={() => {
          setExiting(true)
        }}
      >
        <View ref={refs.setReference}>{children}</View>
      </Hoverable>
      {open && (
        <Portal>
          <Suspense
            fallback={
              <View
                pointerEvents="none"
                ref={refs.setFloating}
                style={{
                  position: "absolute",
                  top: y ?? 0,
                  left: x ?? 0,
                  opacity: exiting || stillInFlight ? 0 : 1,
                }}
              >
                <PulsingLoader width={60} />
              </View>
            }
          >
            <View pointerEvents="none" ref={refs.setFloating} style={{ position: "absolute", top: y ?? 0, left: x ?? 0 }}>
              <PopoverContent render={render} onFadeEnd={() => setOpen(false)} stillInFlight={stillInFlight} show={open && !exiting} />
            </View>
          </Suspense>
        </Portal>
      )}
    </>
  )
}

function PopoverContent(props: { show: boolean; onFadeEnd: () => void; stillInFlight: boolean; render: () => React.ReactNode }) {
  const { opacity, scale } = useFade(props.show, props.onFadeEnd, props.stillInFlight)

  return (
    <Animated.View
      pointerEvents="none"
      style={{
        padding: BASE_PADDING / 2,
        opacity,
        transform: [{ scale }],
      }}
    >
      {props.render()}
    </Animated.View>
  )
}
export { PopoverTooltip } from "./PopoverTooltip"
