// This is duplicated in updateGroupForCompletedGames inside the API - should it be?
export const ALL_GAMES = ["crossword", "spelltower", "really-bad-chess", "typeshift", "flip-art"] as const

// As we can send notifications, they need a code,
// duped in the api, minus ones come from the system
export const notificationTypes = {
  someoneOpenedChat: -1,
  userRequest: -2,
  friendRequestAccepted: -3,
  singleSessionDisconnect: -7,
  gameInvite: 1,
  openMultiplayerRequest: 2,
} as const

export const inviteTimeoutMS = 60 * 1000 // 60s
