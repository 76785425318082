import Svg, { Path } from "react-native-svg"
import { useTheme } from "styled-rn"

import { IconProps } from "./SvgIcons"

export const CalendarIcon = (props: IconProps) => {
  const theme = useTheme()

  return (
    <Svg width={props.width} viewBox="0 0 23 25" fill="none">
      <Path
        fill={theme[props.fill]}
        d="M16.543 22.726H5.923a3.4 3.4 0 0 1-3.415-3.41V11.27h17.45v8.045a3.397 3.397 0 0 1-3.415 3.411ZM5.545 4.584V5.68a1.12 1.12 0 0 0 1.139 1.136 1.12 1.12 0 0 0 1.138-1.136V4.544h6.827V5.68a1.12 1.12 0 0 0 1.138 1.136 1.12 1.12 0 0 0 1.138-1.136V4.584c1.706.189 3.034 1.63 3.034 3.37V9H2.509V7.954c0-1.743 1.327-3.181 3.036-3.37Zm16.69 3.37c0-2.993-2.353-5.455-5.31-5.644V1.137A1.12 1.12 0 0 0 15.787 0a1.12 1.12 0 0 0-1.138 1.137v1.137H7.822V1.137A1.12 1.12 0 0 0 6.684 0a1.12 1.12 0 0 0-1.139 1.137V2.31c-2.96.189-5.31 2.651-5.31 5.644V19.32A5.676 5.676 0 0 0 5.923 25h10.62a5.676 5.676 0 0 0 5.688-5.68l.004-11.366Z"
      />
      <Path
        fill={theme[props.fill]}
        d="M14.27 17.73H8.2a1.12 1.12 0 0 0-1.138 1.137A1.12 1.12 0 0 0 8.2 20.004h6.07a1.12 1.12 0 0 0 1.139-1.137 1.122 1.122 0 0 0-1.139-1.137ZM14.27 14.013H8.2a1.12 1.12 0 0 0-1.138 1.136A1.12 1.12 0 0 0 8.2 16.287h6.07a1.12 1.12 0 0 0 1.139-1.137 1.122 1.122 0 0 0-1.139-1.136Z"
      />
    </Svg>
  )
}
