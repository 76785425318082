import { LoginForm } from "$components/auth/LoginForm"
import React, { createContext, useEffect, useCallback, useContext, useState } from "react"
import { View } from "react-native"

import { CreateNewPlayerOnAccountForm } from "./CreateNewPlayerForm"
import { ForgotPassword } from "./ForgotPassword"
import { ModalInviteInfo } from "./ModalInvite"
import { SignupForm } from "./SignupForm"
import { AppContext } from "../../../AppContext"
import { BASE_PADDING } from "../DesignSystem"
import { ModalDisplay } from "../ModalDisplay"

export type AuthModalContextType = {
  triggerLogin: () => void
  triggerSignup: () => void
  triggerNewPlayer: () => void
  closeModal: () => void
}

export const AuthModalContext = createContext<AuthModalContextType>({} as any)
export const possibleModalsFromURL = ["login", "signup", "forgot-password", "invite"] as const
export type AuthModes = "newPlayer" | "login" | "signup" | "forgot-password" | "invite"

export const LOGIN_TRANSITION_DURATION = 250
export const AuthModal = (props: { children: any }) => {
  const { launchInfo } = useContext(AppContext)
  const defaultToShow = launchInfo.autoOpenModal
  const [viewMode, setViewMode] = useState<AuthModes | "">(defaultToShow || "")
  const [transitioning, setTransitioning] = useState(false)
  const showModal = viewMode !== ""

  const updateViewMode = useCallback((newMode: typeof viewMode, delay?: number) => {
    if (delay) {
      setTransitioning(true)
      setTimeout(() => {
        setTransitioning(false)
        setViewMode(newMode)
      }, delay)
      return
    }
    setViewMode(newMode)
  }, [])

  const triggerLogin = useCallback(() => {
    setViewMode("login")
  }, [])

  const triggerSignup = useCallback(() => {
    setViewMode("signup")
  }, [])

  const triggerNewPlayer = useCallback(() => {
    setViewMode("newPlayer")
  }, [])

  const closeModal = useCallback(() => {
    setViewMode("")
  }, [])

  const lastViewMode = React.useRef(viewMode)
  useEffect(() => {
    lastViewMode.current = viewMode
  }, [viewMode])

  return (
    <AuthModalContext.Provider value={{ triggerNewPlayer, triggerLogin, triggerSignup, closeModal }}>
      {showModal && (
        <ModalDisplay
          modal={
            <View style={{ position: "relative", padding: BASE_PADDING * 2 }}>
              {viewMode === "login" && (
                <LoginForm
                  transitionOut={transitioning}
                  transitionOnEnter={Boolean(lastViewMode.current && lastViewMode.current !== viewMode)}
                  updateViewMode={updateViewMode}
                />
              )}
              {viewMode === "signup" && (
                <SignupForm
                  transitionOut={transitioning}
                  transitionOnEnter={Boolean(lastViewMode.current && lastViewMode.current !== viewMode)}
                  updateViewMode={updateViewMode}
                />
              )}
              {viewMode === "newPlayer" && <CreateNewPlayerOnAccountForm />}
              {viewMode === "invite" && <ModalInviteInfo updateViewMode={updateViewMode} />}
              {viewMode === "forgot-password" && <ForgotPassword updateViewMode={updateViewMode} />}
            </View>
          }
          close={closeModal}
          show={showModal}
        />
      )}
      {props.children}
    </AuthModalContext.Provider>
  )
}
