import Svg, { Path } from "react-native-svg"
import { useTheme } from "styled-rn"

import { IconProps } from "./SvgIcons"

export const TagIcon = (props: IconProps) => {
  const theme = useTheme()

  return (
    <Svg width={props.width} viewBox="0 0 16 16" fill="none">
      <Path
        d="M15.5355 8.20662L7.79338 0.464526C7.53498 0.154842 7.12273 0 6.70949 0H1.54842C0.670651 0 0 0.670651 0 1.54842V6.70949C0 7.12273 0.154842 7.53498 0.464526 7.79338L8.20662 15.5355C8.5163 15.8452 8.92955 16 9.29051 16C9.65148 16 10.0647 15.8452 10.3744 15.5355L15.5355 10.3744C16.1548 9.80632 16.1548 8.7747 15.5355 8.20662ZM3.61265 4.64526C3.04456 4.64526 2.58004 4.18073 2.58004 3.61265C2.58004 3.04456 3.04456 2.58004 3.61265 2.58004C4.18073 2.58004 4.64526 3.04456 4.64526 3.61265C4.64526 4.18073 4.18073 4.64526 3.61265 4.64526Z"
        fill={theme[props.fill]}
      />
    </Svg>
  )
}
