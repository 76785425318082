import { BodyText, H3, MainContainer, Screen, Centered, Form, ThinButton } from "$components/DesignSystem"
import { PuzmonautIcon } from "$components/Thumbnails/games/GameIcon"
import React from "react"
import { Button, View } from "react-native"

export const WebPasswordScreen = () => {
  const [hasPassword, setHasPassword] = React.useState(false)

  const onChange = (password: string) => {
    if (password.toLowerCase().replace(/ /g, "") === "puzzlebox") {
      localStorage.setItem("has_access", "true")
      setHasPassword(true)
    } else {
      localStorage.removeItem("has_access")
      setHasPassword(false)
    }
  }

  const onKeyPress = (e: any) => {
    if (e.nativeEvent.key === "Enter") {
      onSubmit()
    }
  }

  const onSubmit = () => {
    document.location.reload()
  }

  return (
    <Screen>
      <Centered>
        <MainContainer style={{ maxWidth: 600, marginTop: 80 }}>
          <BodyText style={{ marginBottom: 28 }}>
            <PuzmonautIcon /> Puzzmo is currently password locked
          </BodyText>
          <Form.TextInput
            placeholder="..."
            autoCapitalize="none"
            autoCorrect={false}
            onChangeText={onChange}
            autoFocus
            onKeyPress={onKeyPress}
          />
          <View style={{ marginTop: 20 }}>
            <ThinButton title={hasPassword ? "Continue" : "Submit"} onPress={onSubmit} disabled={!hasPassword} />
          </View>
        </MainContainer>
      </Centered>
    </Screen>
  )
}
