/**
 * @generated SignedSource<<bea76a8f4fd8e51c0cedc61b953d8838>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NeedsVerificationMutation$variables = {};
export type NeedsVerificationMutation$data = {
  readonly requestVerifyPasswordEmailResendForCurrentUser: boolean | null;
};
export type NeedsVerificationMutation = {
  response: NeedsVerificationMutation$data;
  variables: NeedsVerificationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "requestVerifyPasswordEmailResendForCurrentUser",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NeedsVerificationMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NeedsVerificationMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "81cdacdb9b00efd85847f22b80fb8b38",
    "id": null,
    "metadata": {},
    "name": "NeedsVerificationMutation",
    "operationKind": "mutation",
    "text": "mutation NeedsVerificationMutation {\n  requestVerifyPasswordEmailResendForCurrentUser\n}\n"
  }
};
})();

(node as any).hash = "da91054b3af8464bd0a5c57633e9a5cc";

export default node;
