import { createContext, useMemo, useState } from "react"

import { BodyText } from "../DesignSystem"

export type SingleSessionBlocker = {
  setIsInSingleSession: (set: boolean) => void
}

export const singleNakamaSessionContext = createContext<SingleSessionBlocker>({} as any)

export function NakamaSingleSessionProvider({ children }: { children: React.ReactNode }) {
  const [isInSingleSession, setIsInSingleSession] = useState(false)
  const value = useMemo(() => ({ setIsInSingleSession }), [setIsInSingleSession])

  return isInSingleSession ? (
    <BodyText style={{ color: "white" }}>Single session blocked</BodyText>
  ) : (
    <>
      <singleNakamaSessionContext.Provider value={value}>{children}</singleNakamaSessionContext.Provider>
    </>
  )
}
