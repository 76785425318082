import * as React from "react"
import Svg, { Path } from "react-native-svg"
import { useTheme } from "styled-rn"

import { IconProps } from "./SvgIcons"

export const CogIcon = (props: IconProps) => {
  const theme = useTheme()
  return (
    <Svg viewBox="0 0 22 22" width={props.width}>
      <Path
        d="M11.508 14.383a3.8 3.8 0 0 1-2.108-.682 3.85 3.85 0 0 1-1.38-1.745 3.885 3.885 0 0 1 .877-4.181 3.793 3.793 0 0 1 4.163-.797 3.83 3.83 0 0 1 1.702 1.427c.415.635.635 1.38.632 2.14a3.869 3.869 0 0 1-1.143 2.743 3.803 3.803 0 0 1-2.743 1.095Zm7.773-1.451c.134-.427.23-.864.288-1.307a.89.89 0 0 1 .624-.726l1.071-.258a.88.88 0 0 0 .54-.39.894.894 0 0 0 .116-.658l-.608-2.774a.874.874 0 0 0-.383-.55.86.86 0 0 0-.656-.112l-1.008.323a.905.905 0 0 1-.944-.371 7.223 7.223 0 0 0-1.807-2.032.871.871 0 0 1-.304-.984l.336-1.049a.876.876 0 0 0-.592-1.096l-2.703-.92a.857.857 0 0 0-.91.321.875.875 0 0 0-.145.308l-.368.984a.887.887 0 0 1-.816.63 8.21 8.21 0 0 0-2.559.58.937.937 0 0 1-1.056-.145l-.767-.807a.88.88 0 0 0-.962-.193.88.88 0 0 0-.286.193L3.383 3.77a.888.888 0 0 0-.192.97c.045.108.11.206.192.288l.736.823a.875.875 0 0 1 .08 1.016 6.29 6.29 0 0 0-.48 1.226c-.125.434-.221.876-.288 1.322a.874.874 0 0 1-.688.758l-1.055.258a.878.878 0 0 0-.696.713.894.894 0 0 0 .008.352l.624 2.758a.89.89 0 0 0 .387.561.876.876 0 0 0 .668.117l1.056-.259a.89.89 0 0 1 .943.387 8.361 8.361 0 0 0 1.808 1.952.838.838 0 0 1 .304.968l-.32 1.048a.894.894 0 0 0 .274.947.878.878 0 0 0 .302.166l2.703.855a.855.855 0 0 0 .927-.278.874.874 0 0 0 .16-.303l.336-1.032a.887.887 0 0 1 .816-.613 8.196 8.196 0 0 0 2.575-.58.936.936 0 0 1 .991.193l.752.823a.88.88 0 0 0 .962.193.88.88 0 0 0 .286-.193l2.063-1.92a.886.886 0 0 0 .26-.629.892.892 0 0 0-.26-.629l-.736-.822a.874.874 0 0 1-.128-1.016c.185-.393.345-.797.48-1.21l.048-.049Z"
        fill={theme[props.fill]}
      />
    </Svg>
  )
}
