import { CloseIcon, AnimatedPressableBase, BASE_MARGIN } from "$components/DesignSystem"
import { BackArrow } from "$components/icons/BackArrow"
import { OverlayContext } from "$components/socialOverlay/OverlayContext"
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs"
import { HeaderBackButton } from "@react-navigation/elements"
import { NavigationContainer, DarkTheme, useNavigation } from "@react-navigation/native"
import { NativeStackNavigationOptions } from "@react-navigation/native-stack"
import { HeaderBackButtonProps } from "@react-navigation/native-stack/lib/typescript/src/types"
import * as React from "react"
import { View } from "react-native"
import { styled, useTheme } from "styled-rn"
import { useContextSelector } from "use-context-selector"

import { Stack, NAV_HEIGHT, REACT_NAV_HEADER_MARGIN } from "./index"
import { useGetUser } from "../CoreContext"
import { useNakamaStateSelector } from "../hooks/useNakama"

const ChatScreen = React.lazy(() => import("../screens/ChatScreen"))
const GroupScreen = React.lazy(() => import("../screens/GroupScreen"))
const SocialFriendsScreen = React.lazy(() => import("../screens/Social/FriendsScreen"))
const SocialFriendsScreenAnon = React.lazy(() => import("../screens/Social/FriendsScreenAnon"))
const SocialGroupsScreen = React.lazy(() => import("../screens/Social/GroupsScreen"))
const SocialGroupsScreenAnon = React.lazy(() => import("../screens/Social/GroupsScreenAnon"))
const SocialLoadingScreen = React.lazy(() => import("../screens/Social/SocialLoadingScreen"))

const IconWrapper = styled.View({
  height: "100%",
  paddingHorizontal: REACT_NAV_HEADER_MARGIN,
  alignItems: "center",
  justifyContent: "center",
})

function CloseButton() {
  const setSocialOverlay = useContextSelector(OverlayContext, (c) => c.setSocialOverlay)

  return (
    <AnimatedPressableBase onPress={() => setSocialOverlay(false)}>
      <IconWrapper>
        <CloseIcon size={18} colorKey="alwaysLight" />
      </IconWrapper>
    </AnimatedPressableBase>
  )
}

const CustomHeader = (props: HeaderBackButtonProps) => {
  const nav = useNavigation()

  if (!props.canGoBack) {
    return null
  }

  return (
    <HeaderBackButton
      backImage={() => (
        <IconWrapper style={{ paddingLeft: 0 }}>
          <BackArrow width={18} fill="alwaysLight" />
        </IconWrapper>
      )}
      onPress={() => {
        // Play game screen should always push to Today, but we use this
        // header for navigating through settings and social screens which
        // have a meaningful stack that players might want to move back through.
        if (props.canGoBack) {
          nav.goBack()
        }
      }}
    />
  )
}
// This only exists on web desktop ATM, it's the little popover chat window
export const ChatNav = (props: Omit<Parameters<typeof NavigationContainer>[0], "children">) => {
  const theme = useTheme()

  return (
    <NavigationContainer {...props} theme={DarkTheme}>
      <React.Suspense fallback={<SocialLoadingScreen />}>
        <Stack.Navigator
          screenOptions={
            {
              headerLeft: (props) => {
                return <CustomHeader {...props} />
              },
              headerRight: () => (
                <View style={{ marginRight: BASE_MARGIN }}>
                  <CloseButton />
                </View>
              ),
              cardStyle: {
                flex: 1,
              },
              statusBarStyle: "dark",
              headerStyle: {
                height: NAV_HEIGHT,
                backgroundColor: theme.alwaysDark,
                borderLeftColor: `${theme.alwaysLight}33`,
                borderLeftWidth: 1,
              },
              headerShadowVisible: false,
              headerTitleStyle: {
                fontFamily: "Poppins-Bold",
                color: theme.alwaysLight,
              },
              headerBackTitleStyle: {
                fontFamily: "Poppins-Bold",
              },
              headerTitleAlign: "center",
            } as NativeStackNavigationOptions
          }
        >
          <Stack.Screen name="ChatSelector" options={{ title: "Play Together" }} component={SocialTab} />
          <Stack.Screen name="Chat" component={ChatScreen} />
          <Stack.Screen name="Group" component={GroupScreen} />
        </Stack.Navigator>
      </React.Suspense>
    </NavigationContainer>
  )
}
const SocialTabs = createBottomTabNavigator()
const WrappedSocialFriendsScreen = (props: any) => {
  const user = useGetUser()

  return (
    <React.Suspense fallback={<SocialLoadingScreen />}>
      {user.type === "user" ? <SocialFriendsScreen {...props} /> : <SocialFriendsScreenAnon />}
    </React.Suspense>
  )
}
const WrappedSocialGroupsScreen = (props: any) => {
  const user = useGetUser()

  return (
    <React.Suspense fallback={<SocialLoadingScreen />}>
      {user.type === "user" ? <SocialGroupsScreen {...props} /> : <SocialGroupsScreenAnon />}
    </React.Suspense>
  )
}

export const SocialTab = () => {
  const theme = useTheme()

  const groupBadgeCount = useNakamaStateSelector((state) => state.groupBadgeCount)
  const friendBadgeCount = useNakamaStateSelector((state) => state.friendBadgeCount)
  return (
    <SocialTabs.Navigator
      screenOptions={{
        tabBarStyle: { borderTopWidth: 0, backgroundColor: theme.alwaysDark },
        tabBarInactiveBackgroundColor: "transparent",
        tabBarActiveBackgroundColor: `${theme.alwaysLight}33`,
      }}
    >
      <SocialTabs.Screen
        name="Friends"
        component={WrappedSocialFriendsScreen}
        options={{
          headerShown: false,
          tabBarBadge: friendBadgeCount,
          tabBarIconStyle: { display: "none" },
          tabBarLabelStyle: {
            margin: 0,
            color: theme.alwaysLight,
            fontFamily: "LeagueSpartan",
            fontSize: 18,
          },
          title: "Friends",
        }}
      />
      <SocialTabs.Screen
        name="Groups"
        component={WrappedSocialGroupsScreen}
        options={{
          tabBarBadge: groupBadgeCount,
          headerShown: false,
          tabBarIconStyle: { display: "none" },

          tabBarLabelStyle: {
            margin: 0,
            color: theme.alwaysLight,
            fontFamily: "LeagueSpartan",
            fontSize: 18,
          },
          title: "Groups",
        }}
      />
    </SocialTabs.Navigator>
  )
}
