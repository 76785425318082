import { useEffect } from "react"
import { Platform } from "react-native"
import { useTheme } from "styled-rn"

// We need a way to inject trace amounts of CSS into a web version of the page.

// Right now this is only to handle how firefox differs in how it handles white-space
// in SVG text rendering.

export const useWebCSSInjector = () => {
  const theme = useTheme()

  useEffect(() => {
    if (Platform.OS !== "web") return
    if (!document.getElementById("web-css-injector")) {
      const style = document.createElement("style")
      style.id = "web-css-injector"
      style.textContent = `
.crossword-thumbnail text { white-space: initial; }
.game-thumbnail * { pointer-events: none !important; user-select: none !important; }
*::selection {
  background: ${theme.key};
  color: ${theme.keyFG};
}
body {
  background-color: ${theme.alwaysDark};
}
`
      document.body.appendChild(style)
    }
  })
}
