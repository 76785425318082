import Svg, { SvgProps, Use, Defs, Path, G } from "react-native-svg"
import { useTheme } from "styled-rn"

export function PuzmoLoadingFaceOnlySinging(props: SvgProps) {
  const theme = useTheme()

  return (
    <Svg id="PuzmoLoadingFaceOnly-PuzmoEXORTS2" viewBox="0 0 200 200" {...props}>
      <Defs>
        <G id="loadline-export_0.111" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer27_0_FILL" />
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer27_0_1_STROKES" />
          </G>
        </G>
        <G id="loadline-export_0.113" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer28_0_FILL" />
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer28_0_1_STROKES" />
          </G>
        </G>
        <G id="loadline-export_0.115" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer29_0_FILL" />
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer29_0_1_STROKES" />
          </G>
        </G>
        <G id="loadline-export_0.117" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer30_0_FILL" />
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer30_0_1_STROKES" />
          </G>
        </G>
        <G id="loadline-export_0.121" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer31_0_FILL" />
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer31_0_1_STROKES" />
          </G>
        </G>
        <G id="loadline-export_0.124" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer32_0_FILL" />
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer32_0_1_STROKES" />
          </G>
        </G>
        <G id="loadline-export_0.126" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer33_0_FILL" />
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer33_0_1_STROKES" />
          </G>
        </G>
        <G id="loadline-export_0.129" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer34_0_FILL" />
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer34_0_1_STROKES" />
          </G>
        </G>
        <G id="loadline-export_0.139" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer35_0_FILL" />
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer35_0_1_STROKES" />
          </G>
        </G>
        <G id="loadline-export_0.142" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer36_0_FILL" />
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer36_0_1_STROKES" />
          </G>
        </G>
        <G id="loadline-export_0.144" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer37_0_FILL" />
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer37_0_1_STROKES" />
          </G>
        </G>
        <G id="loadline-export_0.146" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer38_0_FILL" />
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer38_0_1_STROKES" />
          </G>
        </G>
        <G id="loadline-export_0.148" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer39_0_FILL" />
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer39_0_1_STROKES" />
          </G>
        </G>
        <G id="loadline-export_0.152" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer40_0_FILL" />
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer40_0_1_STROKES" />
          </G>
        </G>
        <G id="loadline-export_0.155" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer41_0_FILL" />
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer41_0_1_STROKES" />
          </G>
        </G>
        <G id="loadline-export_0.157" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer42_0_FILL" />
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer42_0_1_STROKES" />
          </G>
        </G>
        <G id="loadline-export_0.160" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer43_0_FILL" />
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer43_0_1_STROKES" />
          </G>
        </G>
        <G id="loadline-export_0.169" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer44_0_FILL" />
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer44_0_1_STROKES" />
          </G>
        </G>
        <G id="PuzmoLoadingFaceOnly-loadline-export" overflow="visible">
          <G display="none">
            <Use xlinkHref="#loadline-export_0.169" />
            <animate attributeName="display" repeatCount="indefinite" dur="1.9s" keyTimes="0;.94;1" values="none;inline;inline" />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.160" />
            <animate attributeName="display" repeatCount="indefinite" dur="1.9s" keyTimes="0;.88;.94;1" values="none;inline;none;none" />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.157" />
            <animate attributeName="display" repeatCount="indefinite" dur="1.9s" keyTimes="0;.83;.88;1" values="none;inline;none;none" />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.155" />
            <animate attributeName="display" repeatCount="indefinite" dur="1.9s" keyTimes="0;.77;.83;1" values="none;inline;none;none" />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.152" />
            <animate attributeName="display" repeatCount="indefinite" dur="1.9s" keyTimes="0;.72;.77;1" values="none;inline;none;none" />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.148" />
            <animate attributeName="display" repeatCount="indefinite" dur="1.9s" keyTimes="0;.66;.72;1" values="none;inline;none;none" />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.146" />
            <animate attributeName="display" repeatCount="indefinite" dur="1.9s" keyTimes="0;.61;.66;1" values="none;inline;none;none" />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.144" />
            <animate attributeName="display" repeatCount="indefinite" dur="1.9s" keyTimes="0;.55;.61;1" values="none;inline;none;none" />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.142" />
            <animate attributeName="display" repeatCount="indefinite" dur="1.9s" keyTimes="0;.5;.55;1" values="none;inline;none;none" />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.139" />
            <animate attributeName="display" repeatCount="indefinite" dur="1.9s" keyTimes="0;.44;.5;1" values="none;inline;none;none" />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.129" />
            <animate attributeName="display" repeatCount="indefinite" dur="1.9s" keyTimes="0;.38;.44;1" values="none;inline;none;none" />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.126" />
            <animate attributeName="display" repeatCount="indefinite" dur="1.9s" keyTimes="0;.33;.38;1" values="none;inline;none;none" />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.124" />
            <animate attributeName="display" repeatCount="indefinite" dur="1.9s" keyTimes="0;.27;.33;1" values="none;inline;none;none" />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.121" />
            <animate attributeName="display" repeatCount="indefinite" dur="1.9s" keyTimes="0;.22;.27;1" values="none;inline;none;none" />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.117" />
            <animate attributeName="display" repeatCount="indefinite" dur="1.9s" keyTimes="0;.16;.22;1" values="none;inline;none;none" />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.115" />
            <animate attributeName="display" repeatCount="indefinite" dur="1.9s" keyTimes="0;.11;.16;1" values="none;inline;none;none" />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.113" />
            <animate attributeName="display" repeatCount="indefinite" dur="1.9s" keyTimes="0;.05;.11;1" values="none;inline;none;none" />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.111" />
            <animate attributeName="display" repeatCount="indefinite" dur="1.9s" keyTimes="0;.05;1" values="inline;none;none" />
          </G>
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer44_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M244.75 2.15Q243.95 2.9 243.95 3.8 243.95 4.8 244.75 5.45 245.65 6.25 246.75 6.2 247.9 6.2 248.7 5.4 249.45 4.7 249.3 3.75 249.3 2.7 248.6 2.05 247.7 1.4 246.5 1.4 245.5 1.4 244.75 2.15Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer43_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M245.95 1.8Q245.95 1.1 245.5 .55 245.05 0 244.45 -.05 243.75 -.05 243.2 .45 242.8 1 242.8 1.75 242.75 2.5 243.15 3 243.6 3.55 244.3 3.55 244.95 3.65 245.45 3.05 245.95 2.55 245.95 1.8Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer42_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M246.35 1.9Q246.35 1.2 245.9 .65 245.45 .1 244.85 .05 244.15 .05 243.6 .55 243.2 1.1 243.2 1.85 243.15 2.6 243.55 3.1 244 3.65 244.7 3.65 245.35 3.75 245.85 3.15 246.35 2.65 246.35 1.9Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer41_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M247.75 2.1Q247.75 1.15 247.15 .4 246.55 -.3 245.75 -.35 244.8 -.35 244.1 .3 243.55 1 243.55 2 243.45 3 244.05 3.7 244.65 4.45 245.55 4.45 246.4 4.55 247.1 3.75 247.75 3.1 247.75 2.1Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer40_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M246.35 1.9Q246.35 1.2 245.9 .65 245.45 .1 244.85 .05 244.15 .05 243.6 .55 243.2 1.1 243.2 1.85 243.15 2.6 243.55 3.1 244 3.65 244.7 3.65 245.35 3.75 245.85 3.15 246.35 2.65 246.35 1.9Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer39_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M245.95 1.8Q245.95 1.1 245.5 .55 245.05 0 244.45 -.05 243.75 -.05 243.2 .45 242.8 1 242.8 1.75 242.75 2.5 243.15 3 243.6 3.55 244.3 3.55 244.95 3.65 245.45 3.05 245.95 2.55 245.95 1.8Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer38_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M246.35 1.9Q246.35 1.2 245.9 .65 245.45 .1 244.85 .05 244.15 .05 243.6 .55 243.2 1.1 243.2 1.85 243.15 2.6 243.55 3.1 244 3.65 244.7 3.65 245.35 3.75 245.85 3.15 246.35 2.65 246.35 1.9Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer37_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M247.75 2.1Q247.75 1.15 247.15 .4 246.55 -.3 245.75 -.35 244.8 -.35 244.1 .3 243.55 1 243.55 2 243.45 3 244.05 3.7 244.65 4.45 245.55 4.45 246.4 4.55 247.1 3.75 247.75 3.1 247.75 2.1Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer36_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M250.3 2.8Q250.3 1.75 249.4 .9 248.55 .1 247.4 .05 246.05 .05 245.05 .8 244.25 1.55 244.25 2.7 244.1 3.8 244.95 4.6 245.85 5.45 247.1 5.45 248.35 5.55 249.35 4.65 250.3 3.9 250.3 2.8Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer35_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M253.5 3.8Q253.5 2.9 252.7 2.15 251.95 1.4 250.95 1.4 249.75 1.4 248.85 2.05 248.15 2.7 248.15 3.75 248 4.7 248.75 5.4 249.55 6.2 250.7 6.2 251.8 6.25 252.7 5.45 253.5 4.8 253.5 3.8Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer34_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M251.95 .55Q251.5 1.1 251.5 1.8 251.5 2.55 252 3.05 252.5 3.65 253.15 3.55 253.85 3.55 254.3 3 254.7 2.5 254.65 1.75 254.65 1 254.25 .45 253.7 -.05 253 -.05 252.4 0 251.95 .55Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer33_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M251.55 .65Q251.1 1.2 251.1 1.9 251.1 2.65 251.6 3.15 252.1 3.75 252.75 3.65 253.45 3.65 253.9 3.1 254.3 2.6 254.25 1.85 254.25 1.1 253.85 .55 253.3 .05 252.6 .05 252 .1 251.55 .65Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer32_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M250.3 .4Q249.7 1.15 249.7 2.1 249.7 3.1 250.35 3.75 251.05 4.55 251.9 4.45 252.8 4.45 253.4 3.7 254 3 253.9 2 253.9 1 253.35 .3 252.65 -.35 251.7 -.35 250.9 -.3 250.3 .4Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer31_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M251.55 .65Q251.1 1.2 251.1 1.9 251.1 2.65 251.6 3.15 252.1 3.75 252.75 3.65 253.45 3.65 253.9 3.1 254.3 2.6 254.25 1.85 254.25 1.1 253.85 .55 253.3 .05 252.6 .05 252 .1 251.55 .65Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer30_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M251.95 .55Q251.5 1.1 251.5 1.8 251.5 2.55 252 3.05 252.5 3.65 253.15 3.55 253.85 3.55 254.3 3 254.7 2.5 254.65 1.75 254.65 1 254.25 .45 253.7 -.05 253 -.05 252.4 0 251.95 .55Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer29_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M251.55 .65Q251.1 1.2 251.1 1.9 251.1 2.65 251.6 3.15 252.1 3.75 252.75 3.65 253.45 3.65 253.9 3.1 254.3 2.6 254.25 1.85 254.25 1.1 253.85 .55 253.3 .05 252.6 .05 252 .1 251.55 .65Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer28_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M250.3 .4Q249.7 1.15 249.7 2.1 249.7 3.1 250.35 3.75 251.05 4.55 251.9 4.45 252.8 4.45 253.4 3.7 254 3 253.9 2 253.9 1 253.35 .3 252.65 -.35 251.7 -.35 250.9 -.3 250.3 .4Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer27_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M248.05 .9Q247.15 1.75 247.15 2.8 247.15 3.9 248.1 4.65 249.1 5.55 250.35 5.45 251.6 5.45 252.5 4.6 253.35 3.8 253.2 2.7 253.2 1.55 252.4 .8 251.4 .05 250.05 .05 248.9 .1 248.05 .9Z"
          />
        </G>
        <Path
          id="PuzmoLoadingFaceOnly-Layer44_0_1_STROKES"
          stroke={theme.alwaysDark}
          strokeWidth="1"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M253.4 -2.95Q253.2 -1.75 251.95 -1.75 250.7 -1.75 250.5 -2.95M245.3 -2.95Q245.1 -1.75 243.85 -1.75 242.6 -1.75 242.4 -2.95"
        />
        <Path
          id="PuzmoLoadingFaceOnly-Layer43_0_1_STROKES"
          stroke={theme.alwaysDark}
          strokeWidth="1"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M252.9 -3.85Q252.7 -2.65 251.45 -2.65 250.2 -2.65 250 -3.85M244.8 -3.85Q244.6 -2.65 243.35 -2.65 242.1 -2.65 241.9 -3.85"
        />
        <Path
          id="PuzmoLoadingFaceOnly-Layer42_0_1_STROKES"
          stroke={theme.alwaysDark}
          strokeWidth="1"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M252.9 -3.85Q252.7 -2.65 251.45 -2.65 250.2 -2.65 250 -3.85M244.8 -3.85Q244.6 -2.65 243.35 -2.65 242.1 -2.65 241.9 -3.85"
        />
        <Path
          id="PuzmoLoadingFaceOnly-Layer41_0_1_STROKES"
          stroke={theme.alwaysDark}
          strokeWidth="1"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M252.9 -3.85Q252.7 -2.65 251.45 -2.65 250.2 -2.65 250 -3.85M244.8 -3.85Q244.6 -2.65 243.35 -2.65 242.1 -2.65 241.9 -3.85"
        />
        <Path
          id="PuzmoLoadingFaceOnly-Layer40_0_1_STROKES"
          stroke={theme.alwaysDark}
          strokeWidth="1"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M252.9 -3.85Q252.7 -2.65 251.45 -2.65 250.2 -2.65 250 -3.85M244.8 -3.85Q244.6 -2.65 243.35 -2.65 242.1 -2.65 241.9 -3.85"
        />
        <Path
          id="PuzmoLoadingFaceOnly-Layer39_0_1_STROKES"
          stroke={theme.alwaysDark}
          strokeWidth="1"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M252.9 -3.85Q252.7 -2.65 251.45 -2.65 250.2 -2.65 250 -3.85M244.8 -3.85Q244.6 -2.65 243.35 -2.65 242.1 -2.65 241.9 -3.85"
        />
        <Path
          id="PuzmoLoadingFaceOnly-Layer38_0_1_STROKES"
          stroke={theme.alwaysDark}
          strokeWidth="1"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M252.9 -3.85Q252.7 -2.65 251.45 -2.65 250.2 -2.65 250 -3.85M244.8 -3.85Q244.6 -2.65 243.35 -2.65 242.1 -2.65 241.9 -3.85"
        />
        <Path
          id="PuzmoLoadingFaceOnly-Layer37_0_1_STROKES"
          stroke={theme.alwaysDark}
          strokeWidth="1"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M252.9 -3.85Q252.7 -2.65 251.45 -2.65 250.2 -2.65 250 -3.85M244.8 -3.85Q244.6 -2.65 243.35 -2.65 242.1 -2.65 241.9 -3.85"
        />
        <Path
          id="PuzmoLoadingFaceOnly-Layer36_0_1_STROKES"
          stroke={theme.alwaysDark}
          strokeWidth="1"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M252.9 -3.1Q252.7 -1.9 251.45 -1.9 250.2 -1.9 250 -3.1M244.8 -3.1Q244.6 -1.9 243.35 -1.9 242.1 -1.9 241.9 -3.1"
        />
        <Path
          id="PuzmoLoadingFaceOnly-Layer35_0_1_STROKES"
          stroke={theme.alwaysDark}
          strokeWidth="1"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M244.05 -2.95Q244.25 -1.75 245.5 -1.75 246.75 -1.75 246.95 -2.95M252.15 -2.95Q252.35 -1.75 253.6 -1.75 254.85 -1.75 255.05 -2.95"
        />
        <Path
          id="PuzmoLoadingFaceOnly-Layer34_0_1_STROKES"
          stroke={theme.alwaysDark}
          strokeWidth="1"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M244.55 -3.85Q244.75 -2.65 246 -2.65 247.25 -2.65 247.45 -3.85M252.65 -3.85Q252.85 -2.65 254.1 -2.65 255.35 -2.65 255.55 -3.85"
        />
        <Path
          id="PuzmoLoadingFaceOnly-Layer33_0_1_STROKES"
          stroke={theme.alwaysDark}
          strokeWidth="1"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M244.55 -3.85Q244.75 -2.65 246 -2.65 247.25 -2.65 247.45 -3.85M252.65 -3.85Q252.85 -2.65 254.1 -2.65 255.35 -2.65 255.55 -3.85"
        />
        <Path
          id="PuzmoLoadingFaceOnly-Layer32_0_1_STROKES"
          stroke={theme.alwaysDark}
          strokeWidth="1"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M244.55 -3.85Q244.75 -2.65 246 -2.65 247.25 -2.65 247.45 -3.85M252.65 -3.85Q252.85 -2.65 254.1 -2.65 255.35 -2.65 255.55 -3.85"
        />
        <Path
          id="PuzmoLoadingFaceOnly-Layer31_0_1_STROKES"
          stroke={theme.alwaysDark}
          strokeWidth="1"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M244.55 -3.85Q244.75 -2.65 246 -2.65 247.25 -2.65 247.45 -3.85M252.65 -3.85Q252.85 -2.65 254.1 -2.65 255.35 -2.65 255.55 -3.85"
        />
        <Path
          id="PuzmoLoadingFaceOnly-Layer30_0_1_STROKES"
          stroke={theme.alwaysDark}
          strokeWidth="1"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M244.55 -3.85Q244.75 -2.65 246 -2.65 247.25 -2.65 247.45 -3.85M252.65 -3.85Q252.85 -2.65 254.1 -2.65 255.35 -2.65 255.55 -3.85"
        />
        <Path
          id="PuzmoLoadingFaceOnly-Layer29_0_1_STROKES"
          stroke={theme.alwaysDark}
          strokeWidth="1"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M244.55 -3.85Q244.75 -2.65 246 -2.65 247.25 -2.65 247.45 -3.85M252.65 -3.85Q252.85 -2.65 254.1 -2.65 255.35 -2.65 255.55 -3.85"
        />
        <Path
          id="PuzmoLoadingFaceOnly-Layer28_0_1_STROKES"
          stroke={theme.alwaysDark}
          strokeWidth="1"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M244.55 -3.85Q244.75 -2.65 246 -2.65 247.25 -2.65 247.45 -3.85M252.65 -3.85Q252.85 -2.65 254.1 -2.65 255.35 -2.65 255.55 -3.85"
        />
        <Path
          id="PuzmoLoadingFaceOnly-Layer27_0_1_STROKES"
          stroke={theme.alwaysDark}
          strokeWidth="1"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M244.55 -3.1Q244.75 -1.9 246 -1.9 247.25 -1.9 247.45 -3.1M252.65 -3.1Q252.85 -1.9 254.1 -1.9 255.35 -1.9 255.55 -3.1"
        />
      </Defs>
      <G id="PuzmoLoadingFaceOnly-Scene-1" overflow="visible">
        <Use xlinkHref="#PuzmoLoadingFaceOnly-loadline-export" transform="matrix(4 0 0 4 -882.6 100.1)" />
      </G>
    </Svg>
  )
}
