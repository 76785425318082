import Svg, { Path } from "react-native-svg"
import { useTheme } from "styled-rn"

import { IconProps } from "./SvgIcons"

export const BtnSubscribe = (props: IconProps) => {
  const theme = useTheme()

  return (
    <Svg width={props.width} viewBox="0 0 17 17" fill="none">
      <Path
        fill={theme[props.fill]}
        d="M11.59 2.814c-.32-.677-1.073-.995-1.681-.709-.606.287-.84 1.071-.52 1.749.142.3.374.542.655.683.175.088.362.133.547.133.165 0 .328-.036.478-.107.319-.15.55-.445.632-.809.07-.306.03-.64-.112-.94ZM3.69 5.24a1.165 1.165 0 0 0-1.025-.027c-.319.15-.55.445-.632.809-.069.306-.03.64.112.94.142.3.374.543.654.683.176.088.363.133.548.133.165 0 .328-.036.478-.107.319-.15.55-.445.631-.809.07-.305.03-.64-.111-.94a1.462 1.462 0 0 0-.655-.683ZM11.102 13.816c5.038-2.327 3.91-7.366 2.966-7.66-.944-.293-10.534 3.98-10.763 5.086-.228 1.105 3.063 4.76 7.797 2.573Z"
      />
    </Svg>
  )
}
