import { Picker } from "@react-native-picker/picker"
import { Link } from "@react-navigation/native"
import { LinearGradient } from "expo-linear-gradient"
import * as WebBrowser from "expo-web-browser"
import React, { Children, useContext, ForwardedRef, useState, useCallback, useRef, useMemo } from "react"
import flattenChildren from "react-keyed-flatten-children"
import {
  TouchableHighlight,
  ImageSourcePropType,
  Platform,
  TouchableHighlightProps,
  PressableProps,
  Text,
  ImageProps,
  View,
  ViewStyle,
  Dimensions,
  ScrollView,
  SafeAreaView,
  GestureResponderEvent,
  Pressable,
  Animated,
  TextStyle,
  TextInputProps,
} from "react-native"
import { styled, StyledProps, Theme, useTheme } from "styled-rn"

import { BtnSubscribe } from "./icons/BtnSubscribe"
import { AppContext } from "../../AppContext"
import { RootStackParamList } from "../../types"
import { useGetUser } from "../CoreContext"
import { mobileMinWidth } from "../constants/Layout"
import { blendColors } from "../lib/blendColors"
import { userIsAdmin } from "../lib/userUtils"
import { omit } from "../util/omit"

export const BASE_PADDING = 12
export const BASE_MARGIN = 12
export const BASE_RADIUS = 5
export interface HeaderProps extends StyledProps {
  marginLess?: true
}

export const H2 = styled.Text(
  ({ theme, marginLess }: HeaderProps) => {
    const fontSize = 54 * (Dimensions.get("window").width > mobileMinWidth ? 1 : 0.6666)
    return {
      fontFamily: "Zodiak-bold",
      fontSize,
      lineHeight: fontSize * 0.8,
      marginTop: marginLess ? 0 : BASE_MARGIN * 2,
      marginBottom: marginLess ? 0 : BASE_MARGIN * 0.5,
      color: theme.a_headerText,
    }
  },
  { attrs: { accessibilityRole: "heading", accessibilityLevel: 2 } as any }
)

export const H3 = styled.Text(
  ({ theme, marginLess }: HeaderProps) => {
    const fontSize = Dimensions.get("window").width > mobileMinWidth ? 36 : 28
    return {
      fontFamily: "Zodiak-bold",
      fontSize,
      lineHeight: fontSize * 0.9,
      marginTop: marginLess ? 0 : BASE_MARGIN,
      marginBottom: marginLess ? 0 : BASE_MARGIN * 0.5,
      color: theme.a_headerText,
    }
  },
  { attrs: { accessibilityRole: "heading", accessibilityLevel: 3 } as any }
)

export const H4 = styled.Text(
  ({ theme, marginLess }: HeaderProps) => {
    const fontSize = Dimensions.get("window").width > mobileMinWidth ? 24 : 18
    return {
      fontFamily: "Zodiak-bold",
      fontSize,
      // Headings should have tight line height
      lineHeight: fontSize,
      marginTop: marginLess ? 0 : BASE_MARGIN,
      marginBottom: marginLess ? 0 : BASE_MARGIN,
      color: theme.a_headerText,
    }
  },
  { attrs: { accessibilityRole: "heading", accessibilityLevel: 4 } as any }
)

export const H5 = styled.Text(
  ({ theme, marginLess }: HeaderProps) => {
    const fontSize = Dimensions.get("window").width > mobileMinWidth ? 20 : 18
    return {
      marginTop: marginLess ? 0 : BASE_MARGIN / 2,
      marginBottom: 0,
      fontFamily: "LeagueSpartan",
      fontSize,
      color: theme.a_headerText,
      fontWeight: "600",
      lineHeight: fontSize,
    }
  },
  { attrs: { accessibilityRole: "heading", accessibilityLevel: 5 } as any }
)
type UiHeaderProps = StyledProps & {
  inverse?: boolean
  customColorKey?: keyof Theme
}

export const UiH2 = styled.Text(
  ({ theme, inverse, customColorKey }: UiHeaderProps) => {
    const fontSize = 28
    return {
      display: "flex",
      fontFamily: "LeagueSpartan",
      fontSize,
      color: customColorKey ? theme[customColorKey] : inverse ? theme.a_bg : theme.fg,
      fontWeight: "600",
      lineHeight: fontSize,
    }
  },
  { attrs: { accessibilityRole: "heading", accessibilityLevel: 2 } as any }
)

export const UiH3 = styled.Text(
  ({ theme, inverse, customColorKey }: UiHeaderProps) => {
    const fontSize = 24
    return {
      display: "flex",
      fontFamily: "LeagueSpartan",
      fontSize,
      color: customColorKey ? theme[customColorKey] : inverse ? theme.a_bg : theme.fg,
      fontWeight: "600",
      lineHeight: fontSize,
    }
  },
  { attrs: { accessibilityRole: "heading", accessibilityLevel: 3 } as any }
)

export const UiH4 = styled.Text(
  ({ theme, inverse, customColorKey }: UiHeaderProps) => {
    const fontSize = 16
    return {
      display: "flex",
      fontFamily: "LeagueSpartan",
      fontSize,
      color: customColorKey ? theme[customColorKey] : inverse ? theme.a_bg : theme.fg,
      fontWeight: "600",
      lineHeight: fontSize,
    }
  },
  { attrs: { accessibilityRole: "heading", accessibilityLevel: 4 } as any }
)

export interface HRProps extends StyledProps {
  marginLess?: true
}

export const HR = styled.View<HRProps>(({ theme, marginLess }) => ({
  marginTop: marginLess ? undefined : BASE_MARGIN / 2,
  marginBottom: marginLess ? undefined : BASE_MARGIN,
  height: 1,
  backgroundColor: theme.a_headerText,
  opacity: 0.5,
}))

export const DashedHR = styled.View(({ theme }) => ({
  marginTop: BASE_MARGIN,
  marginBottom: BASE_MARGIN,
  height: 1,
  borderBottomWidth: 1,
  borderBottomColor: theme.a_headerText,
  borderStyle: "dashed",
  opacity: 0.5,
  zIndex: 0,
}))

export type BodyTextProps = {
  bold?: boolean
  light?: boolean
  size?: "small" | "medium" | "large"
  inverse?: boolean
  customColorKey?: keyof Theme
  customBackgroundKey?: keyof Theme
}
type BodyTextPropsWithStyledProps = BodyTextProps & StyledProps

const sizeMap = {
  small: 14,
  medium: 18,
  large: 24,
}

export const InlineText = styled.Text(
  ({
    bold,
    light,
    theme,
    customColorKey,
    size,
  }: {
    bold?: boolean
    light?: boolean
    size?: "small" | "medium" | "large"
    theme: Theme
    customColorKey?: keyof Theme
  } & BodyTextPropsWithStyledProps) => ({
    fontFamily: "LeagueSpartan",
    fontWeight: light ? "300" : bold ? "600" : undefined,
    pointerEvents: "none",
    userSelect: "none",
    fontSize: sizeMap[size || "medium"],
    color: customColorKey ? theme[customColorKey] : theme.fg,
  })
)

export const BodyText = styled.Text(({ theme, bold, size, light, inverse, customColorKey }: BodyTextPropsWithStyledProps) => ({
  fontFamily: "LeagueSpartan",
  fontSize: sizeMap[size || "medium"],
  color: customColorKey ? theme[customColorKey] : inverse ? theme.a_bg : theme.fg,
  fontWeight: light ? "300" : bold ? "600" : undefined,
  lineHeight: sizeMap[size || "medium"] * 1.3333,
}))

export const UserTagText = styled.Text(({ theme }: BodyTextPropsWithStyledProps) => ({
  fontFamily: "LeagueSpartan",
  fontSize: sizeMap["medium"],
  color: theme.keyFG,
  backgroundColor: theme.key,
  fontWeight: "600",
  lineHeight: sizeMap["medium"],
  padding: 4,
  paddingBottom: 2,
}))

export const UserTagTextInline = styled(UserTagText, ({ theme }) => ({
  backgroundColor: theme.a_inlineTag,
}))

export const HiddenText = styled.Text(({ theme, bold, size, light }: BodyTextPropsWithStyledProps) => ({
  fontFamily: "LeagueSpartan",
  fontSize: sizeMap[size || "medium"],
  color: theme.fg,
  fontWeight: light ? "300" : bold ? "600" : undefined,
  borderRadius: BASE_RADIUS,
  padding: 4,
}))

export const BodyTextAnchor = styled(
  BodyText,
  ({ theme }) =>
    ({
      color: theme.a_anchor,
      // underline text on web, bold on mobile
      fontWeight: Platform.OS === "web" ? undefined : "600",
      textDecorationLine: Platform.OS === "web" ? "underline" : undefined,
    } as const),
  {
    attrs: { selectable: false },
  }
)

export const SubtitleText = styled.Text(({ theme }) => ({
  fontFamily: "LeagueSpartan",
  fontWeight: "300",
  fontSize: 18,
  color: theme.fg,
  lineHeight: 22,
}))

export const AdminTextInput = styled.TextInput(({ theme }) => ({
  color: theme.fg,
  backgroundColor: `${theme.fg}22`,
  borderColor: theme.key,
  borderWidth: 2,
  padding: BASE_PADDING,
  height: 30,
}))

export const ScreenWrapper = React.forwardRef((props: { style?: any; children?: any }, ref: ForwardedRef<ScrollView>) => {
  return (
    <>
      <ScrollView ref={ref} contentInsetAdjustmentBehavior="automatic" style={props.style}>
        <SafeAreaView>{props.children}</SafeAreaView>
      </ScrollView>
    </>
  )
})

export const Screen = styled(ScreenWrapper, ({ theme }: StyledProps) => ({
  backgroundColor: theme.a_bg,
  minHeight: "100%",
}))

export const AppScrollView = styled.ScrollView(({ theme }) => ({
  backgroundColor: theme.a_bg,
}))

export const MainContainer = styled.View(({ theme }) => ({
  flex: 1,
  maxWidth: 1440,
  paddingLeft: BASE_PADDING * 2,
  paddingRight: BASE_PADDING * 2,
  paddingBottom: BASE_PADDING * 2,
  paddingTop: BASE_PADDING * 3,
  width: "100%",
  margin: "auto",
  marginHorizontal: "auto",
}))

export const Box = styled.View(({ theme }) => ({
  backgroundColor: theme.a_bg,
  padding: BASE_PADDING * 2,
}))

export const PauseIcon = ({ paused, colorKey = "fg" }: { paused: boolean; colorKey?: keyof Theme }) => {
  const theme = useTheme()
  const size = 15

  return (
    <View style={{ flexDirection: "row", justifyContent: "center", height: size, alignItems: "center", width: size }}>
      {paused ? (
        <View
          style={{
            position: "absolute",
            width: 0,
            bottom: 0,
            left: 3,
            height: 0,
            borderTopWidth: 7,
            borderRightWidth: 0,
            borderBottomWidth: 7,
            borderLeftWidth: 10,
            borderTopColor: "transparent",
            borderRightColor: "transparent",
            borderBottomColor: "transparent",
            borderLeftColor: theme[colorKey],
          }}
        />
      ) : (
        <>
          <View style={{ width: 3, height: "80%", marginRight: 4, backgroundColor: theme[colorKey] }} />
          <View style={{ width: 3, height: "80%", backgroundColor: theme[colorKey] }} />
        </>
      )}
    </View>
  )
}

export const ChevronIcon = ({ size, colorKey }: { size?: number; colorKey?: keyof Theme }) => {
  const theme = useTheme()

  const color = colorKey ? theme[colorKey] : theme.fg

  if (!size) size = 24

  return (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
        width: size,
        height: size,
      }}
    >
      <View
        style={{
          position: "absolute",
          borderRadius: 3,
          height: size * 0.6666,
          right: size * 0.25,
          width: 2,
          backgroundColor: color,
          transform: [{ rotate: "45deg" }],
        }}
      />
      <View
        style={{
          position: "absolute",
          borderRadius: 3,
          height: size * 0.6666,
          left: size * 0.25,
          width: 2,
          backgroundColor: color,
          transform: [{ rotate: "-45deg" }],
        }}
      />
    </View>
  )
}

export const CloseIcon = ({ size, colorKey }: { size?: number; colorKey?: keyof Theme }) => {
  const theme = useTheme()

  const color = colorKey ? theme[colorKey] : theme.fg

  if (!size) size = 18

  return (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
        width: size,
        height: size,
      }}
    >
      <View
        style={{
          position: "absolute",
          borderRadius: 3,
          height: size * 1.3,
          width: 2,
          backgroundColor: color,
          transform: [{ rotate: "45deg" }],
        }}
      />
      <View
        style={{
          position: "absolute",
          borderRadius: 3,
          height: size * 1.3,
          width: 2,
          backgroundColor: color,
          transform: [{ rotate: "-45deg" }],
        }}
      />
    </View>
  )
}

export const PlusIcon = ({ size, colorKey, style }: { size?: number; colorKey?: keyof Theme; style?: ViewStyle }) => {
  const theme = useTheme()
  const color = colorKey ? theme[colorKey] : theme.fg
  if (!size) size = 24

  return (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
        width: size,
        height: size,
        ...(style || {}),
      }}
    >
      <View
        style={{
          position: "absolute",
          height: 3,
          width: size,
          backgroundColor: color,
        }}
      />
      <View
        style={{
          position: "absolute",
          height: size,
          width: 3,
          backgroundColor: color,
        }}
      />
    </View>
  )
}

export const MinusIcon = ({ size, colorKey, style }: { size?: number; colorKey?: keyof Theme; style?: ViewStyle }) => {
  const theme = useTheme()
  const color = colorKey ? theme[colorKey] : theme.fg
  if (!size) size = 24

  return (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
        width: size,
        height: size,
        ...(style || {}),
      }}
    >
      <View
        style={{
          position: "absolute",
          height: 3,
          width: size,
          backgroundColor: color,
        }}
      />
    </View>
  )
}
export const RightArrowIcon = ({ size, colorKey }: { size?: number; colorKey?: keyof Theme }) => {
  const theme = useTheme()
  const color = colorKey ? theme[colorKey] : theme.fg
  if (!size) size = 24

  return (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
        width: size,
        height: size,
      }}
    >
      <View
        style={{
          position: "absolute",
          height: size * 0.25,
          width: size * 0.75,
          right: size * 0.25,
          backgroundColor: color,
        }}
      />
      <View
        style={{
          position: "absolute",
          width: 0,
          right: 0,
          height: 0,
          borderTopWidth: size * 0.5,
          borderRightWidth: 0,
          borderBottomWidth: size * 0.5,
          borderLeftWidth: size * 0.5,
          borderTopColor: "transparent",
          borderRightColor: "transparent",
          borderBottomColor: "transparent",
          borderLeftColor: color,
        }}
      />
    </View>
  )
}

export const LeftArrowIcon = ({ size, colorKey }: { size?: number; colorKey?: keyof Theme }) => {
  const theme = useTheme()
  const color = colorKey ? theme[colorKey] : theme.fg
  if (!size) size = 24

  return (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
        width: size,
        height: size,
      }}
    >
      <View
        style={{
          position: "absolute",
          height: size * 0.25,
          width: size * 0.75,
          left: size * 0.25,
          backgroundColor: color,
        }}
      />
      <View
        style={{
          position: "absolute",
          width: 0,
          left: 0,
          height: 0,
          borderTopWidth: size * 0.5,
          borderRightWidth: size * 0.5,
          borderBottomWidth: size * 0.5,
          borderLeftWidth: 0,
          borderTopColor: "transparent",
          borderRightColor: color,
          borderBottomColor: "transparent",
          borderLeftColor: "transparent",
        }}
      />
    </View>
  )
}

// Hamburger drawn with views
export const Hamburger = ({
  open,
  gapSize = 6,
  size = 26,
  colorKey,
}: {
  gapSize?: number
  size?: number
  colorKey?: keyof Theme
  open: boolean
}) => {
  const theme = useTheme()

  const padding = 2
  const width = size + padding * 2
  const barHeight = 2
  const gap = gapSize
  const totalHeight = (barHeight + gap) * 3 + gap

  return (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
        paddingHorizontal: padding,
        paddingTop: open ? 0 : gap,
        width,
        height: totalHeight,
      }}
    >
      {open ? (
        <CloseIcon colorKey={colorKey || "alwaysLight"} size={size / 1.25} />
      ) : (
        [0, 1, 2].map((i) => (
          <View
            key={i}
            style={{
              borderRadius: 100,
              width: "100%",
              height: barHeight,
              backgroundColor: theme[colorKey || "alwaysLight"],
              marginBottom: gap,
            }}
          />
        ))
      )}
    </View>
  )
}

interface AvatarProps extends ImageProps {
  size?: number
  style?: any
  unround?: boolean
  borderColor?: string
}

export const Avatar = (props: AvatarProps) => {
  const size = props.size || 40
  const borderSize = size >= 60 ? 3 : size >= 24 ? 2 : 1
  const sizeDiff = borderSize * 4
  const borderRadius = props.unround ? 0 : 9999

  return (
    <AvatarOuter
      borderColor={props.borderColor}
      style={{ borderRadius, position: "relative", width: size, height: size, borderWidth: borderSize, ...props.style }}
    >
      <AvatarInner
        {...props}
        defaultSource={props.source as any}
        style={{ borderRadius, width: size - sizeDiff, height: size - sizeDiff }}
      />
    </AvatarOuter>
  )
}

const AvatarOuter = styled.View(({ theme, borderColor }: StyledProps & { theme: Theme; borderColor?: string | undefined }) => ({
  borderColor: borderColor || theme.player,
  alignItems: "center",
  justifyContent: "center",
}))

const AvatarInner = styled.Image(({ theme }) => ({
  backgroundColor: theme.a_bg,
}))

export const TextInput = styled.TextInput(({ theme }) => ({
  fontFamily: "LeagueSpartan",
  fontWeight: "300",
  fontSize: 22,
  marginTop: BASE_MARGIN * 2,
  color: theme.fg,
  backgroundColor: `${theme.fg}22`,
  borderRadius: 0,
  padding: BASE_PADDING * 0.5,
}))

const DevBG = styled.View(({ theme }) => ({
  borderColor: "red",
  backgroundColor: "#eeaaaa",
  borderWidth: 2,
  borderRadius: BASE_RADIUS,
  padding: BASE_PADDING,
  marginBottom: BASE_MARGIN * 2,
}))

export const DevSection = (props: { children: any[] | any }) => {
  const ctx = useContext(AppContext)
  const user = useGetUser()
  const isAdmin = userIsAdmin(user)
  if (!isAdmin || !ctx.showDebugUI) return null
  return (
    <DevBG>
      <SubtitleText style={{ backgroundColor: "#eeaaaa", color: "black", marginBottom: 4 }}>This is only shown to devs</SubtitleText>
      {props.children}
    </DevBG>
  )
}

export const DevCode = (props: { title: string; obj: any }) => {
  return (
    <DevSection>
      <BodyText style={{ backgroundColor: "#eeaaaa", color: "black", marginBottom: 4 }}>{props.title}</BodyText>
      <Code style={{ backgroundColor: "#eeaaaa", color: "black", marginBottom: 4 }}>{JSON.stringify(props.obj, null, 2)}</Code>
    </DevSection>
  )
}

export const Code = styled.Text(({ theme }) => ({
  fontFamily: Platform.OS === "web" ? "monospace" : undefined,
  whiteSpace: "pre-wrap",
}))

export const ButtonText = styled.Text(({ theme, colorKey }: { colorKey?: keyof Theme; theme: Theme }) => ({
  color: colorKey ? theme[colorKey] : theme.fg,
  fontFamily: "LeagueSpartan",
  fontWeight: "600",
  fontSize: 14,
  marginBottom: 4,
  marginTop: 7,
  pointerEvents: "none",
  userSelect: "none",
}))

const ANIM_DURATION = 125
function usePressAnimation(
  onPressIn?: ((e: GestureResponderEvent) => void) | null,
  onPressOut?: ((e: GestureResponderEvent) => void) | null
) {
  const animation = useState(new Animated.Value(1))[0] // Makes animated value
  const opacity = animation.interpolate({ inputRange: [0, 1], outputRange: [0.75, 1] })
  const scale = animation.interpolate({ inputRange: [0, 1], outputRange: [1.05, 1] })

  const startTime = useRef<number | null>(null)
  const pressIn = useCallback(
    (e: GestureResponderEvent) => {
      if (onPressIn) onPressIn(e)

      // set start time in MS
      startTime.current = Date.now()
      Animated.timing(animation, {
        toValue: 0,
        useNativeDriver: true,
        duration: ANIM_DURATION,
      }).start(() => {
        startTime.current = null
      })
    },
    [animation, onPressIn]
  )

  const pressOut = useCallback(
    (e: GestureResponderEvent) => {
      if (onPressOut) onPressOut(e)
      setTimeout(
        () => {
          Animated.timing(animation, {
            toValue: 1,
            useNativeDriver: true,
            duration: ANIM_DURATION,
          }).start()
        },
        startTime.current ? Math.max(0, ANIM_DURATION - (Date.now() - startTime.current)) : 0
      )
    },
    [animation, onPressOut]
  )

  return { opacity, scale, pressIn, pressOut }
}

export function useBounceAnimation() {
  const animation = useState(new Animated.Value(1))[0] // Makes animated value
  const scale = animation.interpolate({ inputRange: [0, 1], outputRange: [1.05, 1] })

  const triggerBounce = useCallback(() => {
    Animated.sequence([
      Animated.timing(animation, {
        toValue: 0,
        useNativeDriver: true,
        duration: ANIM_DURATION,
      }),
      Animated.timing(animation, {
        toValue: 1,
        useNativeDriver: true,
        duration: ANIM_DURATION,
      }),
    ]).start()
  }, [animation])

  return { triggerBounce, scale }
}

/** A thin button with a hitslop to hit 44px */
export type ThinButtonProps = PressableProps & {
  title: string | React.ReactNode
  bgKey?: keyof Theme
  bgColor?: string
  iconAlign?: "left" | "right"
  colorKey?: keyof Theme
  color?: string
  iconComponent?: React.ReactNode
  titleStyle?: TextStyle
  style?: ViewStyle
  block?: boolean
  type?: "primary" | "secondary"
}

export const ThinButton = (props: ThinButtonProps) => {
  const type = props.type || "primary"

  // If we have any of the touchable props, use TouchableOpacity, otherwise use View
  // If we do not hae touchable props, likely there is a warpper component that handles a touch event.
  // In that case, we don't want to add a touchable to the view, as it will not propagate the touch event correctly.
  const BtnEl = props.onLongPress || props.onPress || props.onPressIn || props.onPressOut ? Pressable : View
  const AnimatedBtnEl = Animated.createAnimatedComponent(BtnEl)
  const theme = useTheme()

  const { opacity, scale, pressIn, pressOut } = usePressAnimation(props.onPressIn, props.onPressOut)

  let backgroundColor = props.bgColor || (props.bgKey ? theme[props.bgKey] : theme.fg)
  if (type === "secondary") {
    backgroundColor = "transparent"
  }
  const textColor = props.color || (props.colorKey ? theme[props.colorKey] : theme.a_bg)

  return (
    <AnimatedBtnEl
      {...props}
      onPressIn={pressIn}
      onPressOut={pressOut}
      hitSlop={{ top: 10, bottom: 10 }}
      style={[
        {
          backgroundColor,
          borderWidth: type === "secondary" ? 1 : undefined,
          borderColor: type === "secondary" ? textColor : undefined,
          borderRadius: BASE_RADIUS,
          height: 28,
          paddingLeft: 10,
          paddingRight: 10,
          flexDirection: props.iconAlign === "right" ? "row-reverse" : "row",
          alignItems: "center",
          width: props.block ? "100%" : "auto",
          justifyContent: props.block ? "center" : undefined,
          ...(props.style ? props.style : {}),
        },
        { opacity: props.disabled ? 0.3 : opacity },
        { transform: [{ scale }] },
      ]}
    >
      {props.iconComponent && props.iconComponent}
      {typeof props.title === "string" ? (
        <ButtonText
          style={{
            [props.iconAlign === "right" ? "marginRight" : "marginLeft"]: props.iconComponent ? 6 : 0,
            color: textColor,
            textAlign: props.block ? "center" : undefined,
            ...(props.titleStyle ? props.titleStyle : {}),
          }}
        >
          {props.title}
        </ButtonText>
      ) : (
        props.title
      )}
    </AnimatedBtnEl>
  )
}

/** Chunkier buttons */
export const PuzmoButton: React.FC<ThinButtonProps> = (props) => {
  return (
    <ThinButton
      {...props}
      style={{
        height: 34,
        ...(props.style || {}),
      }}
      titleStyle={{
        fontSize: 18,
        ...(props.titleStyle || {}),
      }}
    />
  )
}

export type AdminButtonProps = PressableProps & {
  title: string | React.ReactNode
  disabled?: boolean
  style?: ViewStyle
}

export const AdminButton = (props: AdminButtonProps) => {
  const theme = useTheme()
  return (
    <Pressable
      {...props}
      onPressIn={props.onPressIn}
      onPressOut={props.onPressOut}
      hitSlop={{ top: 10, bottom: 10 }}
      style={[
        {
          borderColor: theme.fg,
          borderWidth: 1,
          borderRadius: BASE_RADIUS,
          height: 28,
          paddingLeft: 8,
          paddingRight: 8,
          justifyContent: "center",
          ...(props.style ? props.style : {}),
        },
        { opacity: props.disabled ? 0.3 : 1 },
      ]}
    >
      {typeof props.title === "string" ? (
        <Text
          style={{
            color: theme.fg,
            fontSize: 10,
            fontFamily: "ui-monospace, 'Cascadia Code', 'Source Code Pro', Menlo, Consolas, 'DejaVu Sans Mono', monospace",
          }}
        >
          {props.title}
        </Text>
      ) : (
        props.title
      )}
    </Pressable>
  )
}

export const SubscribeButton = (props: (typeof PuzmoButton)["defaultProps"]) => (
  <PuzmoButton
    iconComponent={<BtnSubscribe width={16} fill="alwaysDark" />}
    title="Subscribe"
    bgKey="a_puzmo"
    color="alwaysDark"
    style={{ flexShrink: 1, flexGrow: 1 }}
    titleStyle={{ marginLeft: "auto", marginRight: "auto", paddingRight: 24 }}
    {...props}
  />
)

export const AnimatedPressableBase: React.FC<
  PressableProps & {
    children: React.ReactNode
    disabled?: boolean
    style?: ViewStyle
  }
> = (props) => {
  const AnimatedBtnEl = Animated.createAnimatedComponent(Pressable)

  const { opacity, scale, pressIn, pressOut } = usePressAnimation(props.onPressIn, props.onPressOut)

  return (
    <AnimatedBtnEl
      {...props}
      onPressIn={pressIn}
      onPressOut={pressOut}
      hitSlop={{ top: 10, bottom: 10 }}
      pointerEvents={props.disabled ? "none" : "auto"}
      tabIndex={props.disabled ? -1 : 0}
      selectable={false}
      style={[
        {
          // @ts-ignore
          cursor: "pointer",
          ...(props.style ? props.style : {}),
        },
        { opacity: props.disabled ? 0.5 : opacity },
        { transform: [{ scale }] },
      ]}
    >
      {props.children}
    </AnimatedBtnEl>
  )
}

const AnimatedPressable = Animated.createAnimatedComponent(Pressable)

/** A thin square button with a hitslop to hit for 44px */
export const SquareButton: React.FC<
  TouchableHighlightProps & {
    bgKey?: keyof Theme
    pointerEvents?: "box-none" | "none" | "box-only" | "auto"
    style?: ViewStyle
    children?: React.ReactNode
  }
> = (props) => {
  const theme = useTheme()
  const { opacity, scale, pressIn, pressOut } = usePressAnimation(props.onPressIn, props.onPressOut)

  return (
    <AnimatedPressable
      pointerEvents={props.pointerEvents || "auto"}
      hitSlop={{ top: 10, bottom: 10 }}
      {...props}
      style={[
        {
          backgroundColor: props.bgKey ? theme[props.bgKey] : "transparent",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: BASE_RADIUS,
          height: 26,
          width: 26,
          // @ts-ignore - web only
          userSelect: false,
          ...(props.style ? props.style : {}),
        },
        { opacity: props.disabled ? 0.3 : opacity },
        { transform: [{ scale }] },
      ]}
      onPressIn={pressIn}
      onPressOut={pressOut}
    >
      {props.children}
    </AnimatedPressable>
  )
}

export const ThinButtonGhost = (props: { title: string; iconSrc?: ImageSourcePropType; iconComponent?: React.ReactNode; style?: any }) => (
  <ThinButton
    iconComponent={props.iconComponent}
    title={props.title}
    style={{ ...(props.style ?? {}), opacity: 0.2, borderColor: "black", borderWidth: 1 }}
    colorKey="g_textDark"
    bgColor="background"
    disabled
  />
)

interface RowProps extends StyledProps {
  bottomBorder?: boolean
  centerV?: true
  centerH?: true
  marginT?: true
  wrap?: true
}

export const Row = styled.View(({ bottomBorder, theme, centerV, centerH, marginT, wrap }: RowProps) => {
  const style: ViewStyle = { flexDirection: "row" }

  if (bottomBorder) {
    style.borderBottomColor = `${theme.a_headerText}66`
    style.borderBottomWidth = 1
  }
  if (centerV) style.justifyContent = "center"
  if (centerH) style.alignItems = "center"
  if (marginT) style.marginTop = BASE_MARGIN * 2
  if (wrap) style.flexWrap = "wrap"
  return style
})

export const Col = styled.View(({ bottomBorder, theme, centerV, centerH, marginT }: RowProps) => {
  const style: ViewStyle = {}

  if (bottomBorder) {
    style.borderBottomColor = theme.fg
    style.borderBottomWidth = 1
  }
  // UNTESTED, but probably right
  if (centerV) style.alignItems = "center"
  if (centerH) style.justifyContent = "center"
  if (marginT) style.marginTop = BASE_MARGIN * 2
  return style
})

export const Nav = {
  SubtitleText: styled.Text(({ theme }) => ({
    fontFamily: "LeagueSpartan",
    fontWeight: "200",
    fontSize: 16,
    color: theme.alt2,
    backgroundColor: theme.alwaysDark,
  })),
}

export const TodayItemBanner = (props: { title: string }) => {
  const theme = useTheme()
  return (
    <Row style={{ marginTop: BASE_MARGIN, marginBottom: BASE_MARGIN / 4 }}>
      <BodyText
        bold
        style={{ backgroundColor: theme.fg, paddingHorizontal: 8, paddingVertical: 2, paddingBottom: 0, marginBottom: 4 }}
        inverse
      >
        {props.title}
      </BodyText>
      <View
        style={{
          width: 0,
          height: 0,
          backgroundColor: "transparent",
          borderStyle: "solid",
          borderRightWidth: 13,
          borderTopWidth: 26,
          borderRightColor: "transparent",
          borderTopColor: theme.fg,
        }}
      ></View>
    </Row>
  )
}

/** Basically useful for whenever you're sending someone outside of the RN app  */
export const ExternalLink = (props: { to: string; children: any; aProps?: React.HTMLProps<HTMLAnchorElement> }) => {
  if (Platform.OS === "web") {
    return (
      <a href={props.to} target="_blank" {...props.aProps}>
        {props.children}
      </a>
    )
  } else {
    const buttonPress = () => WebBrowser.openBrowserAsync(props.to)
    return (
      <TouchableHighlight onPress={buttonPress} accessibilityRole="link" accessibilityHint={`Open ${props.to} in your browser`}>
        {props.children}
      </TouchableHighlight>
    )
  }
}

export const UserLink = (props: { user: { username: string; usernameID: string; name?: string }; children: any }) => {
  const theme = useTheme()
  const loggedInUser = useGetUser()

  const user = props.user
  if (!user) return null

  const isUser =
    loggedInUser.type === "user" &&
    loggedInUser.currentUser.username === props.user.username &&
    loggedInUser.currentUser.usernameID === props.user.usernameID

  const color = isUser ? theme.player : theme.keyStrong

  return (
    <InternalLink screen="UserProfile" routeOpts={{ username: user.username, usernameID: user.usernameID }}>
      <Text style={{ color }}>{props.children}</Text>
    </InternalLink>
  )
}

export const InternalLink = <Route extends keyof RootStackParamList>(props: {
  screen: Route
  onPress?: () => void
  routeOpts?: RootStackParamList[Route]
  children: any
  disabled?: boolean
  style?: ViewStyle
  wrapperStyle?: ViewStyle
  target?: "_blank"
}) => {
  return (
    <AnimatedPressableBase
      onPress={props.onPress || undefined}
      disabled={props.disabled}
      pointerEvents={props.disabled ? "none" : "auto"}
      style={props.wrapperStyle}
    >
      <Link
        disabled={props.disabled}
        style={props.style || {}}
        to={{ screen: props.screen, params: props.routeOpts }}
        target={props.target}
      >
        {props.children}
      </Link>
    </AnimatedPressableBase>
  )
}

export const Border = styled.View(({ theme }) => ({
  borderBottomColor: theme.a_headerText,
  height: 0,
  borderBottomWidth: 1,
}))

export const BorderCenter = ({ style }: { style?: ViewStyle }) => (
  <View style={[{ justifyContent: "center", flexGrow: 1 }, style]}>
    <Border />
  </View>
)

/** All the form UI elements */

export const Form = {
  Title: styled.Text(({ theme }) => ({
    fontFamily: "LeagueSpartan",
    fontWeight: "600",
    fontSize: 24,
    marginTop: BASE_MARGIN * 2,
    color: theme.fg,
  })),

  ValueName: styled.Text(({ theme }) => ({
    fontFamily: "LeagueSpartan",
    fontWeight: "300",
    fontSize: 15,
    lineHeight: 15,
    color: theme.fg,
    marginBottom: BASE_MARGIN / 3,
  })),

  Subtitle: styled.Text(({ theme }) => ({
    fontFamily: "LeagueSpartan",
    fontWeight: "300",
    fontSize: 14,
    lineHeight: 14 * 1.4,
    color: theme.fg + "90",
    marginBottom: BASE_MARGIN / 3,
  })),

  TextInput: styled.TextInput(({ theme }) => ({
    fontFamily: "LeagueSpartan",
    fontWeight: "300",
    fontSize: 22,
    placeholderTextColor: `${theme.fg}44`,

    color: theme.fg,
    backgroundColor: `${theme.fg}22`,
    borderRadius: 0,
    padding: BASE_PADDING * 0.5,
    paddingBottom: BASE_PADDING * 0.25,
  })),

  ErrorMessage: styled.Text(({ theme }) => ({
    fontFamily: "LeagueSpartan",
    fontWeight: "300",
    fontSize: 18,
    color: theme.error,
    marginTop: BASE_MARGIN / 2,
  })),

  SearchInputBase: styled.TextInput(({ theme }) => ({
    fontFamily: "LeagueSpartan",
    fontWeight: "300",
    fontSize: 18,
    color: theme.fg,
    borderRadius: BASE_RADIUS,
    backgroundColor: `${theme.fg}22`,
    paddingVertical: BASE_PADDING * 0.5,
    paddingHorizontal: BASE_PADDING,
  })),

  SearchInput: (
    props: TextInputProps & {
      showClearButton?: boolean
      onClear?: () => void
      wrapperStyle?: ViewStyle
    }
  ) => {
    const [searchKey, setSearchKey] = useState(0)
    const clearSearch = () => {
      if (props.onClear) props.onClear()
      setSearchKey(searchKey + 1)
    }

    const theme = useTheme()
    const omitted = useMemo(() => ["showClearButton", "onClear", "wrapperStyle"], [])

    return (
      <View style={{ position: "relative", justifyContent: "center", ...props.wrapperStyle }}>
        <Form.SearchInputBase
          numberOfLines={1}
          placeholderTextColor={`${theme.fg}88`}
          style={{
            backgroundColor: theme.fg + "22",
            paddingRight: props.showClearButton ? 100 : 0,
          }}
          {...omit(props, omitted)}
        />
        {props.showClearButton && (
          <ThinButton
            style={{
              position: "absolute",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              right: 0,
            }}
            bgColor={`${theme.alwaysDark}33`}
            colorKey="alwaysDark"
            title="Clear search"
            onPress={clearSearch}
          />
        )}
      </View>
    )
  },
}

export const Select = ({
  value,
  onChange,
  values,
  displays,
}: {
  value: string
  onChange: (value: string) => void
  values: string[]
  displays: string[]
}) => {
  const theme = useTheme()
  return (
    <View style={{ alignContent: "center", justifyContent: "center" }}>
      <Picker
        style={{
          borderRadius: BASE_RADIUS,
          backgroundColor: theme.key,
          borderWidth: 0,
          paddingTop: 10,
          paddingLeft: 4,
          paddingBottom: 7,
          // @ts-ignore
          WebkitAppearance: "none",
          cursor: "pointer",
        }}
        selectedValue={value}
        onValueChange={(itemValue) => onChange(itemValue)}
      >
        {values.map((o, i) => (
          <Picker.Item key={i} value={o} label={displays[i]} />
        ))}
      </Picker>
      <View pointerEvents="none" style={{ backgroundColor: theme.key, position: "absolute", right: 2, paddingRight: 8 }}>
        <ChevronIcon size={18} colorKey={"keyFG"} />
      </View>
      <View
        pointerEvents="none"
        style={{
          justifyContent: "center",
          position: "absolute",
          left: 2,
          top: 2,
          bottom: 2,
          paddingLeft: 8,
          borderRadius: BASE_RADIUS + 2,
          backgroundColor: theme.key,
        }}
      >
        <Text selectable={false} style={{ fontFamily: "LeagueSpartan", fontWeight: "600", fontSize: 18 }}>
          {displays[values.indexOf(value)]}
        </Text>
      </View>
    </View>
  )
}

export const CheckboxBar = ({ label, checked, onChange }: { label: string; checked: boolean; onChange: (checked: boolean) => void }) => {
  const theme = useTheme()
  return (
    <AnimatedPressableBase style={{ width: "100%" }} onPress={() => onChange(!checked)}>
      <View
        style={{
          backgroundColor: checked ? theme.keyStrong : theme.key,
          borderRadius: 6,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Text
          selectable={false}
          style={{
            fontFamily: "LeagueSpartan",
            fontWeight: "600",
            fontSize: 18,
            lineHeight: 18,
            textAlignVertical: "center",
            flex: 1,
            paddingTop: 10,
            paddingBottom: 7,
            paddingHorizontal: 10,
            // @ts-ignore
            userSelect: "none",
          }}
        >
          {label}
        </Text>
        <View
          style={{
            paddingTop: 10,
            paddingBottom: 7,
            flexShrink: 0,
            flexGrow: 0,
            width: 60,
            justifyContent: "center",
            alignItems: "center",
            borderLeftColor: theme.a_bg,
            borderLeftWidth: 2,
            borderStyle: "dashed",
          }}
        >
          <Text
            style={{
              fontSize: 18,
              lineHeight: 18,
              textAlignVertical: "center",
              fontFamily: "LeagueSpartan",
              textTransform: "uppercase",
              // @ts-ignore
              userSelect: "none",
            }}
          >
            {checked ? "on" : "off"}
          </Text>
        </View>
      </View>
    </AnimatedPressableBase>
  )
}

export const Centered = styled.View(() => ({
  justifyContent: "center",
  alignItems: "center",
}))

export const ShadowView = styled.View(({ theme }) => ({
  shadowColor: theme.alwaysDark,
  shadowOffset: {
    width: 0,
    height: 6,
  },
  shadowOpacity: 0.33,
  shadowRadius: 18,
  elevation: 5,
}))

export const ModalFrame = styled.View(({ theme }) => ({
  backgroundColor: theme.alwaysDark,
  borderRadius: 12,
  shadowColor: blendColors(theme.alwaysDark, "#000000", 0.25),
  shadowOffset: {
    width: 0,
    height: 4,
  },
  shadowOpacity: 0.5,
  shadowRadius: 4,
  elevation: 5,
  maxWidth: 600,
  maxHeight: "80%",
  marginHorizontal: 24,
  width: "100%",
}))

export const ModalShadowBox = styled.View(({ theme }) => ({
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: `${theme.a_bg}88`,
}))

interface FormInput extends StyledProps {
  error?: boolean
  loading?: boolean
  last?: boolean
}

export const ModalForm = {
  FormItemTitle: styled.Text(({ theme }) => ({
    fontFamily: "LeagueSpartan",
    fontWeight: "300",
    fontSize: 14,
    color: theme.alwaysLight,
  })),

  Title: styled.Text(
    ({ theme }) => ({
      fontFamily: "Zodiak-bold",
      fontSize: 36,
      marginBottom: BASE_MARGIN,
      color: theme.alwaysLight,
    }),
    { attrs: { accessibilityRole: "heading", accessibilityLevel: 3 } as any }
  ),

  Subtitle: styled.Text(({ theme }) => ({
    fontFamily: "LeagueSpartan",
    fontWeight: "300",
    fontSize: 14,
    color: theme.alwaysLight,
    marginBottom: BASE_MARGIN / 2,
  })),

  ErrorMessage: styled.Text(({ theme }) => ({
    fontFamily: "LeagueSpartan",
    fontWeight: "300",
    fontSize: 18,
    color: theme.error,
    marginTop: BASE_MARGIN / 2,
  })),

  TextInput: styled.TextInput(({ theme, error, last, loading }: FormInput) => ({
    fontFamily: "LeagueSpartan",
    fontWeight: "300",
    fontSize: 22,
    placeholderTextColor: `${theme.fg}44`,
    opacity: loading ? 0.5 : 1,
    color: theme.fg,
    backgroundColor: `${theme.fg}22`,
    borderBottomWidth: 2,
    marginBottom: last ? 0 : BASE_MARGIN * 1.5,
    borderColor: error ? theme.error : "transparent",
    padding: BASE_PADDING * 0.5,
    paddingBottom: BASE_PADDING * 0.25,
  })),
}

export interface PaddingProps extends StyledProps {
  p?: number
  px?: number
  py?: number
}

export const Padding = styled.View(({ p, px, py }: PaddingProps) => {
  if (p !== undefined) {
    return { padding: p * BASE_PADDING }
  }

  return {
    paddingLeft: (px || 0) * BASE_PADDING,
    paddingRight: (px || 0) * BASE_PADDING,
    paddingTop: (py || 0) * BASE_PADDING,
    paddingBottom: (py || 0) * BASE_PADDING,
  }
})

export function Gap({
  gap = BASE_PADDING,
  direction = "vertical",
  style,
  flex = direction === "horizontal" ? 1 : undefined,
  divider,
  children,
}: {
  gap?: number
  direction?: "vertical" | "horizontal"
  style?: ViewStyle
  flex?: number
  divider?: React.ReactNode
  children?: React.ReactNode
}) {
  const kids = flattenChildren(children)
  const flexDirection = direction === "vertical" ? "column" : "row"
  const styleKey = direction === "vertical" ? "height" : "width"

  if (kids.length === 1) return <View style={[{ width: "100%" }, style]}>{children}</View>

  return (
    <View style={[{ flexDirection }, style]}>
      {Children.map(kids, (child, index) => {
        return (
          <React.Fragment key={index}>
            {index > 0 ? divider || <View style={{ [styleKey]: gap }} /> : null}
            <View style={{ flex }}>{child}</View>
          </React.Fragment>
        )
      })}
    </View>
  )
}

export function TopGradientShadow(props: { height: number }) {
  const theme = useTheme()
  return (
    <View
      style={{
        height: "100%",
        top: "-100%",
        position: "absolute",
        left: 0,
        right: 0,
      }}
      pointerEvents="none"
    >
      <LinearGradient
        colors={[`${theme.a_bg}00`, theme.a_bg, theme.a_bg]}
        locations={[0, 0.8, 1]}
        style={{
          position: "absolute",
          width: "100%",
          bottom: 0,
          left: 0,
          right: 0,
          height: props.height,
        }}
      />
    </View>
  )
}

export const TableWrapper = styled.View(({ theme }) => ({
  borderRadius: BASE_RADIUS,
  flexDirection: "column",
  overflow: "hidden",
  width: "100%",
}))

interface TableRowProps extends StyledProps {
  even: boolean
  theme: Theme
}

export const TableRow = styled.View(({ theme, even }: TableRowProps) => ({
  flexDirection: "row",
  flexGrow: 1,
  flexShrink: 1,
  maxWidth: "100%",
  paddingHorizontal: BASE_PADDING * 0.5,
  backgroundColor: even ? theme.a_table : theme.a_tableAlt,
  justifyContent: "space-between",
}))
