import { useNavigation } from "@react-navigation/native"
import React, { useEffect, useState } from "react"
import { Platform, TextStyle } from "react-native"

import { StackContext } from "../../../types"
import { secondsToColonSeparated } from "../../lib/secondsToHms"
import { BodyText } from "../DesignSystem"
import { PopoverTooltip } from "../popovers/PopoverTooltip"

export const DayInfoCountdownBar = (props: { endOfDay: number; style: TextStyle }) => {
  const [countDown, setCountDown] = useState(props.endOfDay - new Date().getTime())

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(props.endOfDay - new Date().getTime())
    }, 1000)

    return () => clearInterval(interval)
  }, [props.endOfDay])

  const navigation = useNavigation<StackContext>()

  if (countDown < 0) {
    // navigation.reset({ index: 0, routes: [{ name: "Today" }] })
    // navigation.popToTop()
    if (Platform.OS === "web") document.location.reload()
    return <BodyText style={props.style}>(reloading)</BodyText>
  }

  return (
    <PopoverTooltip text="Time remaining in the day">
      <BodyText selectable={false} style={{ ...props.style, opacity: 0.5, marginTop: 4 }}>
        {secondsToColonSeparated(countDown / 1000)}
      </BodyText>
    </PopoverTooltip>
  )
}
