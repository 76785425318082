import { ThinButton } from "$components/DesignSystem"
import { StripeButtonsBillingFragment$key } from "$relay/StripeButtonsBillingFragment.graphql"
import { StripeButtonsCreateSubscriptionMutation, SubscriptionTimePeriod } from "$relay/StripeButtonsCreateSubscriptionMutation.graphql"
import { StripeButtonsManageBillingMutation } from "$relay/StripeButtonsManageBillingMutation.graphql"
import React, { useCallback, useContext } from "react"
import { graphql, useFragment, useMutation } from "react-relay"

import { useGetUser } from "../../CoreContext"
import { AuthModalContext } from "../auth/AuthModal"
import { TagIcon } from "../icons/TagIcon"

export const useStripeSignupActions = (accountSlug: string | undefined, config: { type?: SubscriptionTimePeriod; failPath?: string }) => {
  const [createSubscription, loading] = useMutation<StripeButtonsCreateSubscriptionMutation>(graphql`
    mutation StripeButtonsCreateSubscriptionMutation(
      $successURL: String!
      $failURL: String!
      $accountSlug: String!
      $type: SubscriptionTimePeriod
    ) {
      createStripeSubscriptionLink(successURL: $successURL, failURL: $failURL, accountSlug: $accountSlug, type: $type)
    }
  `)

  // This will only work on web - Platform.OS === "web" (for search)
  const urlRoot = document.location.protocol + "//" + document.location.host

  const user = useGetUser()

  const { triggerSignup } = useContext(AuthModalContext)

  // This callback accepts a random event (so it can be used in onPress) but allows for
  // overriding the root account. This is so that the sign up process can run without needing a re-render
  // to do pull off sending the user to stripe after completion

  const startSubscription = useCallback(
    (_evt: object, accountIDOverride?: string) => {
      if (!accountIDOverride && user.type === "anon") {
        triggerSignup()
      } else {
        if (!accountSlug && !accountIDOverride) throw new Error("No account slug given for a real user!")
        createSubscription({
          variables: {
            successURL: urlRoot + "/success",
            failURL: urlRoot + (config.failPath || "/me"),
            accountSlug: accountIDOverride || accountSlug!,
            type: config.type,
          },
          onCompleted: (data) => {
            if (data.createStripeSubscriptionLink) {
              document.location = data.createStripeSubscriptionLink
            } else {
              console.error("Failed")
            }
          },
        })
      }
    },
    [accountSlug, config.failPath, config.type, createSubscription, triggerSignup, urlRoot, user.type]
  )
  return [startSubscription, loading] as const
}

export const SignUpButton = (props: { type?: SubscriptionTimePeriod; accountSlug?: string }) => {
  const [startSubscription, loading] = useStripeSignupActions(props.accountSlug, { type: props.type })

  return (
    <ThinButton
      iconComponent={<TagIcon width={12} fill="keyFG" />}
      bgKey="key"
      colorKey="keyFG"
      title="Sign up"
      disabled={loading}
      onPress={startSubscription}
    />
  )
}

export const ManageBillingButton = (props: { user: StripeButtonsBillingFragment$key }) => {
  const user = useFragment(
    graphql`
      fragment StripeButtonsBillingFragment on User {
        accounts {
          slug
        }
      }
    `,
    props.user
  )

  const [manageBillingLink, loadingManage] = useMutation<StripeButtonsManageBillingMutation>(graphql`
    mutation StripeButtonsManageBillingMutation($returnURL: String!, $accountSlug: String!) {
      getManageBillingLink(returnURL: $returnURL, accountSlug: $accountSlug)
    }
  `)

  const urlRoot = typeof document !== "undefined" ? document.location.protocol + "//" + document.location.host : "puzmo://"

  const manageBillingPress = () =>
    manageBillingLink({
      variables: {
        returnURL: urlRoot + "/me",
        accountSlug: user.accounts[0]!.slug,
      },
      onCompleted: (data) => {
        if (data.getManageBillingLink) {
          // @ts-ignore
          document.location = data.getManageBillingLink
        } else {
          console.error("Failed")
        }
      },
    })

  return <ThinButton bgKey="player" color="empty" title="Manage billing" disabled={loadingManage} onPress={manageBillingPress} />
}
