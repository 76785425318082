/**
 * @generated SignedSource<<df73a1159bd34fe2919c90bdcee03ac6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateGamePlayedInput = {
  additionalTimeAddedSecs?: number | null;
  boardState?: string | null;
  cheatsUsed?: number | null;
  collabUserReferences: ReadonlyArray<string | null>;
  elapsedTimeSecs?: number | null;
  hintsUsed?: number | null;
  metric1?: number | null;
  metric2?: number | null;
  metric3?: number | null;
  metric4?: number | null;
  metricStrings?: ReadonlyArray<string> | null;
  pauses?: number | null;
};
export type GamePlayUpdateMutationsGameStateSanUpdateMutation$variables = {
  id: string;
  input: UpdateGamePlayedInput;
};
export type GamePlayUpdateMutationsGameStateSanUpdateMutation$data = {
  readonly updateGameState: {
    readonly elapsedTimeSecs: number;
    readonly id: string;
  } | null;
};
export type GamePlayUpdateMutationsGameStateSanUpdateMutation = {
  response: GamePlayUpdateMutationsGameStateSanUpdateMutation$data;
  variables: GamePlayUpdateMutationsGameStateSanUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GamePlayed",
    "kind": "LinkedField",
    "name": "updateGameState",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "elapsedTimeSecs",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GamePlayUpdateMutationsGameStateSanUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GamePlayUpdateMutationsGameStateSanUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "068ecaa8c32897ed50521b1406702f54",
    "id": null,
    "metadata": {},
    "name": "GamePlayUpdateMutationsGameStateSanUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation GamePlayUpdateMutationsGameStateSanUpdateMutation(\n  $id: ID!\n  $input: UpdateGamePlayedInput!\n) {\n  updateGameState(id: $id, input: $input) {\n    id\n    elapsedTimeSecs\n  }\n}\n"
  }
};
})();

(node as any).hash = "b3a705d486277603dfad10899a2704f4";

export default node;
