import { useContext, useEffect } from "react"
import { StyledRNThemeContext, useTheme } from "styled-rn"

import { useGetUser } from "../../CoreContext"
import { themeForApp } from "../../constants/theme"
import useColorScheme from "../../hooks/useColorScheme"

// Themes get cached inside styled-rn, which is very reasonable - but
// we have quite a few cases where the theme can dynamically change. This
// component is a workaround for that by reading the main user, comparing
// the names of the themes, and if they differ, it updates the theme via
// the context API.

export const DynamicThemeChanger = (props: { children: React.ReactNode }) => {
  const currentTheme = useTheme()
  const user = useGetUser()
  const colorScheme = useColorScheme()
  const themeCtx = useContext(StyledRNThemeContext)

  useEffect(() => {
    const theme = themeForApp(colorScheme, user.currentUser)
    if (themeCtx && theme.name !== currentTheme.name) {
      themeCtx.setTheme(theme)
    }
  }, [user, currentTheme, colorScheme, themeCtx])

  return <>{props.children}</>
}
