/**
 * @generated SignedSource<<0ac14c8b906dc9789fc0720ec8fa2239>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useBootstrapNakamaDataQuery$variables = {};
export type useBootstrapNakamaDataQuery$data = {
  readonly currentUser: {
    readonly incomingRequests: {
      readonly friends: ReadonlyArray<{
        readonly user: {
          readonly puzmoUser: {
            readonly id: string;
          } | null;
          readonly userId: string;
          readonly username: string;
          readonly " $fragmentSpreads": FragmentRefs<"FriendRequestView">;
        };
      }> | null;
    } | null;
    readonly mostRecentDistinctChats: {
      readonly friends: ReadonlyArray<{
        readonly content: string;
        readonly createdAt: any;
        readonly lastChatMessageWasViewer: boolean;
        readonly puzmoUser: {
          readonly avatarURL: string;
          readonly id: string;
          readonly nakamaID: string;
          readonly username: string;
          readonly usernameID: string;
          readonly " $fragmentSpreads": FragmentRefs<"ChatRow">;
        };
        readonly seen: boolean;
      }>;
    };
    readonly nFriends: {
      readonly friends: ReadonlyArray<{
        readonly user: {
          readonly puzmoUser: {
            readonly avatarURL: string;
            readonly id: string;
            readonly nakamaID: string;
            readonly username: string;
            readonly usernameID: string;
            readonly " $fragmentSpreads": FragmentRefs<"ChatRow">;
          } | null;
          readonly userId: string;
        };
      }> | null;
    } | null;
    readonly socialLastViewedAt: any | null;
  } | null;
};
export type useBootstrapNakamaDataQuery = {
  response: useBootstrapNakamaDataQuery$data;
  variables: useBootstrapNakamaDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "socialLastViewedAt",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "state",
    "value": 2
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "usernameID",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nakamaID",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatarURL",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "puzmoUser",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v3/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChatRow"
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastChatMessageWasViewer",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "seen",
  "storageKey": null
},
v13 = [
  {
    "kind": "Literal",
    "name": "state",
    "value": 0
  }
],
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "puzmoUser",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v3/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useBootstrapNakamaDataQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": "incomingRequests",
            "args": (v1/*: any*/),
            "concreteType": "NakamaFriendsList",
            "kind": "LinkedField",
            "name": "nFriends",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NakamaUserState",
                "kind": "LinkedField",
                "name": "friends",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NakamaUser",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "FriendRequestView"
                      },
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "puzmoUser",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "nFriends(state:2)"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ChatBlurbPreviewTypes",
            "kind": "LinkedField",
            "name": "mostRecentDistinctChats",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ChatUserBlurb",
                "kind": "LinkedField",
                "name": "friends",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v13/*: any*/),
            "concreteType": "NakamaFriendsList",
            "kind": "LinkedField",
            "name": "nFriends",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NakamaUserState",
                "kind": "LinkedField",
                "name": "friends",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NakamaUser",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "nFriends(state:0)"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useBootstrapNakamaDataQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": "incomingRequests",
            "args": (v1/*: any*/),
            "concreteType": "NakamaFriendsList",
            "kind": "LinkedField",
            "name": "nFriends",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NakamaUserState",
                "kind": "LinkedField",
                "name": "friends",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NakamaUser",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "puzmoUser",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v3/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "nFriends(state:2)"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ChatBlurbPreviewTypes",
            "kind": "LinkedField",
            "name": "mostRecentDistinctChats",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ChatUserBlurb",
                "kind": "LinkedField",
                "name": "friends",
                "plural": true,
                "selections": [
                  (v14/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v13/*: any*/),
            "concreteType": "NakamaFriendsList",
            "kind": "LinkedField",
            "name": "nFriends",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NakamaUserState",
                "kind": "LinkedField",
                "name": "friends",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NakamaUser",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "nFriends(state:0)"
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0bdf635329a2612d469cff4466992619",
    "id": null,
    "metadata": {},
    "name": "useBootstrapNakamaDataQuery",
    "operationKind": "query",
    "text": "query useBootstrapNakamaDataQuery {\n  currentUser {\n    socialLastViewedAt\n    incomingRequests: nFriends(state: 2) {\n      friends {\n        user {\n          ...FriendRequestView\n          userId\n          username\n          puzmoUser {\n            id\n          }\n        }\n      }\n    }\n    mostRecentDistinctChats {\n      friends {\n        puzmoUser {\n          id\n          username\n          usernameID\n          nakamaID\n          avatarURL\n          ...ChatRow\n        }\n        lastChatMessageWasViewer\n        createdAt\n        content\n        seen\n      }\n    }\n    nFriends(state: 0) {\n      friends {\n        user {\n          userId\n          puzmoUser {\n            id\n            username\n            usernameID\n            nakamaID\n            avatarURL\n            ...ChatRow\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment ChatRow on User {\n  username\n  usernameID\n  nakamaID\n  avatarURL\n  name\n  ...UserAvatar\n  ...UserDisplay\n}\n\nfragment FriendRequestView on NakamaUser {\n  userId\n  puzmoUser {\n    ...FriendRequestViewUser\n    id\n  }\n}\n\nfragment FriendRequestViewUser on User {\n  id\n  username\n  usernameID\n  nakamaID\n  ...UserDisplay\n}\n\nfragment UserAvatar on User {\n  username\n  usernameID\n  avatarURL\n}\n\nfragment UserDisplay on User {\n  id\n  username\n  usernameID\n  avatarURL\n}\n"
  }
};
})();

(node as any).hash = "37d73f8987c8921089b305cd6b6baaf6";

export default node;
