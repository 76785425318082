// This component should be _rarely_ used! It makes an API call just to get
// a user's avatar. If you're using this component, you really need to be on
// some isolated bit of the page where getting the user's avatar via this API
// call is the only route.

import { NakamaIDAvatarQuery } from "$relay/NakamaIDAvatarQuery.graphql"
import { Suspense, useContext } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

import { AvatarProps, UserAvatar, UserAvatar_RAW } from "./UserAvatar"
import { AppContext } from "../../../AppContext"

// Right now this is only used in the nav bar for showing an invite in the nav bar

export const NakamaIDAvatar = (props: { nakamaID: string } & AvatarProps) => {
  const environment = useContext(AppContext).environment
  return (
    <Suspense
      fallback={
        <UserAvatar_RAW noLink user={{ username: "", usernameID: "", avatarURL: environment.CDNPath("assets/puzicon.png") }} {...props} />
      }
    >
      <_NakamaIDAvatar {...props} />
    </Suspense>
  )
}

const _NakamaIDAvatar = (props: { nakamaID: string } & AvatarProps) => {
  const { userFromNakamaID } = useLazyLoadQuery<NakamaIDAvatarQuery>(
    graphql`
      query NakamaIDAvatarQuery($id: String!) {
        userFromNakamaID(nakamaID: $id) {
          ...UserAvatar
        }
      }
    `,
    { id: props.nakamaID }
  )

  if (!userFromNakamaID) return null
  return <UserAvatar {...props} user={userFromNakamaID} />
}
