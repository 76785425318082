import * as Updates from "expo-updates"
import { Platform } from "react-native"

import { createStorage } from "./storage"

// @ts-ignore - jest is a global variable
export const isTests = typeof jest !== "undefined"

// The file script/stampSHA will update this value to be the SHA for the
// file during deploys. Note that this URL is hard-coded in the studio codebase for
// puzzle previews also.
export const currentSHAForDeploy = "f4ed910f3b5b746f617b3ccb9286252dcb4b3312"

export const createEnvironment = () => {
  const env = {
    apiRoot: "https://www.puzzmo.com/_api/prod/",
    nakamaKey: "NakamaPuzmoPublicKey",
    nakamaAPIUrl: "https://multiplayer.puzzmo.com:443",
    embedIframeRoute: `https://cdn.puzzmo.com/games/embed/${currentSHAForDeploy}.html`,
    webroot: "https://puzzmo.com/",
    CDNRoot: "https://cdn.puzzmo.com/",
  }

  const isLocal = Platform.OS === "web" ? document.location.host.includes("localhost") || document.location.port : false
  const isStaging =
    Platform.OS === "web" ? document.location.host.includes("staging") || isLocal : Updates.releaseChannel.startsWith("staging")

  if (isStaging) {
    env.apiRoot = "https://staging.puzzmo.com/_api/staging/"
    env.nakamaAPIUrl = "https://multiplayer-staging.puzzmo.com:443"
    env.embedIframeRoute = `https://cdn.puzzmo.com/games/embed/${currentSHAForDeploy}.html`
    env.webroot = "https://staging.puzzmo.com/"
    env.CDNRoot = "https://cdn.puzzmo.com/"
  }

  if (isLocal) {
    env.webroot = "http://localhost:19006/"
  }

  return {
    isDev: () => env.apiRoot.includes("localhost"),
    isStaging: () => isStaging,
    apiRoot: () => env.apiRoot,
    embedURL: () => env.embedIframeRoute,
    graphQL: () => env.apiRoot + "graphql",
    nakamaKey: () => env.nakamaKey,
    nakamaAPIUrl: () => env.nakamaAPIUrl,
    adminRoot: () => (isStaging ? "https://studio-staging.puzzmo.com/" : "https://studio.puzzmo.com/"),
    webRoot: () => env.webroot,
    thumbnailRoot: () => (isStaging ? "https://thumbs.puzzmo.com/" : "https://thumbs.puzzmo.com/"),
    CDNPath: (path: string) => `${env.CDNRoot}${path}`,
    setupDevOverrides: async () => {
      const storage = createStorage()

      const apiRoot = await storage.get("APIRoot")
      if (apiRoot) {
        env.apiRoot = apiRoot
      }

      const nakamaRoot = await storage.get("NakamaRoot")
      if (nakamaRoot) {
        env.nakamaAPIUrl = nakamaRoot
        if (nakamaRoot.includes("localhost")) env.nakamaKey = "defaultkey"
      }

      const embedIframeRoute = await storage.get("EmbedIFrame")
      if (embedIframeRoute) {
        env.embedIframeRoute = embedIframeRoute
      }
    },
  }
}

export type Environment = ReturnType<typeof createEnvironment>
