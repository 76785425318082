import Svg, { SvgProps, Use, Defs, Path, G } from "react-native-svg"
import { useTheme } from "styled-rn"

export function PuzmoLoadingFaceOnly(props: SvgProps) {
  const theme = useTheme()

  return (
    <Svg id="PuzmoLoadingFaceOnly-PuzmoEXORTS2" viewBox="0 0 200 200" {...props}>
      <Defs>
        <G id="loadline-export_0.0" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer1_0_FILL" />
          </G>
        </G>
        <G id="loadline-export_0.2" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer2_0_FILL" />
          </G>
        </G>
        <G id="loadline-export_0.5" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer3_0_FILL" />
          </G>
        </G>
        <G id="loadline-export_0.15" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer4_0_FILL" />
          </G>
        </G>
        <G id="loadline-export_0.17" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer5_0_FILL" />
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer5_0_1_STROKES" />
          </G>
        </G>
        <G id="loadline-export_0.20" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer6_0_FILL" />
          </G>
        </G>
        <G id="loadline-export_0.26" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer7_0_FILL" />
          </G>
        </G>
        <G id="loadline-export_0.31" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer8_0_FILL" />
          </G>
        </G>
        <G id="loadline-export_0.36" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer9_0_FILL" />
          </G>
        </G>
        <G id="loadline-export_0.40" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer10_0_FILL" />
          </G>
        </G>
        <G id="loadline-export_0.42" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer11_0_FILL" />
          </G>
        </G>
        <G id="loadline-export_0.55" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer12_0_FILL" />
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer12_0_1_STROKES" />
          </G>
        </G>
        <G id="loadline-export_0.57" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer13_0_FILL" />
          </G>
        </G>
        <G id="loadline-export_0.59" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer14_0_FILL" />
          </G>
        </G>
        <G id="loadline-export_0.61" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer15_0_FILL" />
          </G>
        </G>
        <G id="loadline-export_0.63" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer16_0_FILL" />
          </G>
        </G>
        <G id="loadline-export_0.70" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer17_0_FILL" />
          </G>
        </G>
        <G id="loadline-export_0.72" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer18_0_FILL" />
          </G>
        </G>
        <G id="loadline-export_0.75" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer19_0_FILL" />
          </G>
        </G>
        <G id="loadline-export_0.87" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer20_0_FILL" />
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer20_0_1_STROKES" />
          </G>
        </G>
        <G id="loadline-export_0.89" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer21_0_FILL" />
          </G>
        </G>
        <G id="loadline-export_0.94" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer22_0_FILL" />
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer22_0_1_STROKES" />
          </G>
        </G>
        <G id="loadline-export_0.96" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer23_0_FILL" />
          </G>
        </G>
        <G id="loadline-export_0.98" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer24_0_FILL" />
          </G>
        </G>
        <G id="loadline-export_0.105" overflow="visible">
          <G>
            <Use xlinkHref="#PuzmoLoadingFaceOnly-Layer25_0_FILL" />
          </G>
        </G>
        <G id="PuzmoLoadingFaceOnly-loadline-export" overflow="visible">
          <G display="none">
            <Use xlinkHref="#loadline-export_0.98" />
            <animate attributeName="display" repeatCount="1" fill="freeze" dur="5.733s" keyTimes="0;.57;1" values="none;inline;inline" />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.96" />
            <animate
              attributeName="display"
              repeatCount="1"
              fill="freeze"
              dur="5.733s"
              keyTimes="0;.558;.57;1"
              values="none;inline;none;none"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.94" />
            <animate
              attributeName="display"
              repeatCount="1"
              fill="freeze"
              dur="5.733s"
              keyTimes="0;.547;.558;1"
              values="none;inline;none;none"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.89" />
            <animate
              attributeName="display"
              repeatCount="1"
              fill="freeze"
              dur="5.733s"
              keyTimes="0;.517;.547;1"
              values="none;inline;none;none"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.87" />
            <animate
              attributeName="display"
              repeatCount="1"
              fill="freeze"
              dur="5.733s"
              keyTimes="0;.506;.517;1"
              values="none;inline;none;none"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.75" />
            <animate
              attributeName="display"
              repeatCount="1"
              fill="freeze"
              dur="5.733s"
              keyTimes="0;.436;.506;1"
              values="none;inline;none;none"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.72" />
            <animate
              attributeName="display"
              repeatCount="1"
              fill="freeze"
              dur="5.733s"
              keyTimes="0;.419;.436;1"
              values="none;inline;none;none"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.70" />
            <animate
              attributeName="display"
              repeatCount="1"
              fill="freeze"
              dur="5.733s"
              keyTimes="0;.407;.419;1"
              values="none;inline;none;none"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.63" />
            <animate
              attributeName="display"
              repeatCount="1"
              fill="freeze"
              dur="5.733s"
              keyTimes="0;.366;.407;1"
              values="none;inline;none;none"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.61" />
            <animate
              attributeName="display"
              repeatCount="1"
              fill="freeze"
              dur="5.733s"
              keyTimes="0;.355;.366;1"
              values="none;inline;none;none"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.59" />
            <animate
              attributeName="display"
              repeatCount="1"
              fill="freeze"
              dur="5.733s"
              keyTimes="0;.343;.355;1"
              values="none;inline;none;none"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.57" />
            <animate
              attributeName="display"
              repeatCount="1"
              fill="freeze"
              dur="5.733s"
              keyTimes="0;.331;.343;1"
              values="none;inline;none;none"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.55" />
            <animate
              attributeName="display"
              repeatCount="1"
              fill="freeze"
              dur="5.733s"
              keyTimes="0;.32;.331;1"
              values="none;inline;none;none"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.42" />
            <animate
              attributeName="display"
              repeatCount="1"
              fill="freeze"
              dur="5.733s"
              keyTimes="0;.244;.32;1"
              values="none;inline;none;none"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.40" />
            <animate
              attributeName="display"
              repeatCount="1"
              fill="freeze"
              dur="5.733s"
              keyTimes="0;.233;.244;1"
              values="none;inline;none;none"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.36" />
            <animate
              attributeName="display"
              repeatCount="1"
              fill="freeze"
              dur="5.733s"
              keyTimes="0;.209;.233;1"
              values="none;inline;none;none"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.31" />
            <animate
              attributeName="display"
              repeatCount="1"
              fill="freeze"
              dur="5.733s"
              keyTimes="0;.18;.209;1"
              values="none;inline;none;none"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.26" />
            <animate
              attributeName="display"
              repeatCount="1"
              fill="freeze"
              dur="5.733s"
              keyTimes="0;.151;.18;1"
              values="none;inline;none;none"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.20" />
            <animate
              attributeName="display"
              repeatCount="1"
              fill="freeze"
              dur="5.733s"
              keyTimes="0;.116;.151;1"
              values="none;inline;none;none"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.17" />
            <animate
              attributeName="display"
              repeatCount="1"
              fill="freeze"
              dur="5.733s"
              keyTimes="0;.099;.116;1"
              values="none;inline;none;none"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.15" />
            <animate
              attributeName="display"
              repeatCount="1"
              fill="freeze"
              dur="5.733s"
              keyTimes="0;.087;.099;1"
              values="none;inline;none;none"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.5" />
            <animate
              attributeName="display"
              repeatCount="1"
              fill="freeze"
              dur="5.733s"
              keyTimes="0;.029;.087;1"
              values="none;inline;none;none"
            />
          </G>
          <G display="none">
            <Use xlinkHref="#loadline-export_0.2" />
            <animate
              attributeName="display"
              repeatCount="1"
              fill="freeze"
              dur="5.733s"
              keyTimes="0;.012;.029;1"
              values="none;inline;none;none"
            />
          </G>
          <G>
            <Use xlinkHref="#loadline-export_0.0" />
            <animate attributeName="display" repeatCount="1" fill="freeze" dur="5.733s" keyTimes="0;.012;1" values="inline;none;none" />
          </G>
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer25_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M252.9 -.75Q252.6 -.85 252.45 -.8 250.7 -.5 248 .55 245.3 1.7 243 3.15 242.75 3.25 242.75 3.55 242.65 3.8 242.8 4.05 243.9 6.05 246.15 6.5 248.35 6.9 250.05 5.95 251.9 5.05 252.8 3.65 253.8 2.05 253.2 -.35 253.1 -.6 252.9 -.75M250.75 -3.6Q250.95 -4.1 250.7 -4.55 250.4 -5.1 250.05 -5.4 249.5 -5.65 248.95 -5.4 248.55 -5.1 248.45 -4.7 248.25 -4.15 248.45 -3.55 248.7 -3.05 249.2 -2.85 249.7 -2.6 250.15 -2.8 250.65 -3.05 250.75 -3.6M240.8 -1.6Q240.6 -1.1 240.9 -.45 241.1 0 241.6 .35 242.1 .55 242.5 .35 243 .05 243.25 -.5 243.3 -.95 243.15 -1.5 242.95 -2 242.4 -2.25 241.95 -2.45 241.4 -2.25 241 -2.05 240.8 -1.6Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer24_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M252.8 -2.5Q252.45 -2.6 252.25 -2.55 250.3 -2 247.3 -.45 244.35 1.1 241.9 3 241.6 3.15 241.65 3.5 241.55 3.75 241.75 4.05 243.25 6.25 245.85 6.5 248.4 6.7 250.25 5.45 252.3 4.2 253.15 2.5L253.2 2.5Q254.15 .55 253.2 -2.1 253.05 -2.35 252.8 -2.5M247.3 -5.8Q247.15 -5.2 247.4 -4.65 247.7 -4.15 248.2 -4 248.75 -3.8 249.2 -4.05 249.7 -4.35 249.75 -4.9 249.9 -5.4 249.6 -5.9 249.25 -6.4 248.8 -6.65 248.25 -6.85 247.75 -6.55 247.35 -6.25 247.3 -5.8M242.55 -1.1Q242.55 -1.55 242.35 -2.1 242.1 -2.6 241.55 -2.8 241.05 -2.95 240.55 -2.7 240.15 -2.45 240 -2 239.85 -1.45 240.2 -.85 240.45 -.4 241 -.15 241.5 .05 241.9 -.2 242.35 -.55 242.55 -1.1Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer23_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M252.8 -2.5Q252.45 -2.6 252.25 -2.55 250.3 -2 247.3 -.45 244.35 1.1 241.9 3 241.6 3.15 241.65 3.5 241.55 3.75 241.75 4.05 243.25 6.25 245.85 6.5 248.4 6.7 250.25 5.45 252.3 4.2 253.15 2.5L253.2 2.5Q254.15 .55 253.2 -2.1 253.05 -2.35 252.8 -2.5M247.15 -5.45Q247 -5 247.4 -4.55 247.65 -4.25 248.25 -4.05 248.8 -4 249.2 -4.25 249.7 -4.65 249.9 -5.1 249.9 -5.45 249.7 -5.9 249.4 -6.3 248.85 -6.35 248.3 -6.4 247.75 -6.1 247.3 -5.85 247.15 -5.45M242.65 -1.25Q242.65 -1.6 242.45 -2.05 242.15 -2.45 241.6 -2.5 241.05 -2.55 240.5 -2.25 240.05 -2 239.9 -1.6 239.75 -1.15 240.15 -.7 240.4 -.4 241 -.2 241.55 -.15 241.95 -.4 242.45 -.8 242.65 -1.25Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer22_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M253.2 -2.1Q253.05 -2.35 252.8 -2.5 252.45 -2.6 252.25 -2.55 250.3 -2 247.3 -.45 244.35 1.1 241.9 3 241.6 3.15 241.65 3.5 241.55 3.75 241.75 4.05 243.25 6.25 245.85 6.5 248.4 6.7 250.25 5.45 252.3 4.2 253.15 2.5L253.2 2.5Q254.15 .55 253.2 -2.1Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer21_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M252.8 -2.5Q252.45 -2.6 252.25 -2.55 250.3 -2 247.3 -.45 244.35 1.1 241.9 3 241.6 3.15 241.65 3.5 241.55 3.75 241.75 4.05 243.25 6.25 245.85 6.5 248.4 6.7 250.25 5.45 252.3 4.2 253.15 2.5L253.2 2.5Q254.15 .55 253.2 -2.1 253.05 -2.35 252.8 -2.5M247.3 -5.8Q247.15 -5.2 247.4 -4.65 247.7 -4.15 248.2 -4 248.75 -3.8 249.2 -4.05 249.7 -4.35 249.75 -4.9 249.9 -5.4 249.6 -5.9 249.25 -6.4 248.8 -6.65 248.25 -6.85 247.75 -6.55 247.35 -6.25 247.3 -5.8M242.55 -1.1Q242.55 -1.55 242.35 -2.1 242.1 -2.6 241.55 -2.8 241.05 -2.95 240.55 -2.7 240.15 -2.45 240 -2 239.85 -1.45 240.2 -.85 240.45 -.4 241 -.15 241.5 .05 241.9 -.2 242.35 -.55 242.55 -1.1Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer20_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M253.2 -2.1Q253.05 -2.35 252.8 -2.5 252.45 -2.6 252.25 -2.55 250.3 -2 247.3 -.45 244.35 1.1 241.9 3 241.6 3.15 241.65 3.5 241.55 3.75 241.75 4.05 243.25 6.25 245.85 6.5 248.4 6.7 250.25 5.45 252.3 4.2 253.15 2.5L253.2 2.5Q254.15 .55 253.2 -2.1Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer19_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M252.8 -2.5Q252.45 -2.6 252.25 -2.55 250.3 -2 247.3 -.45 244.35 1.1 241.9 3 241.6 3.15 241.65 3.5 241.55 3.75 241.75 4.05 243.25 6.25 245.85 6.5 248.4 6.7 250.25 5.45 252.3 4.2 253.15 2.5L253.2 2.5Q254.15 .55 253.2 -2.1 253.05 -2.35 252.8 -2.5M247.3 -5.8Q247.15 -5.2 247.4 -4.65 247.7 -4.15 248.2 -4 248.75 -3.8 249.2 -4.05 249.7 -4.35 249.75 -4.9 249.9 -5.4 249.6 -5.9 249.25 -6.4 248.8 -6.65 248.25 -6.85 247.75 -6.55 247.35 -6.25 247.3 -5.8M242.55 -1.1Q242.55 -1.55 242.35 -2.1 242.1 -2.6 241.55 -2.8 241.05 -2.95 240.55 -2.7 240.15 -2.45 240 -2 239.85 -1.45 240.2 -.85 240.45 -.4 241 -.15 241.5 .05 241.9 -.2 242.35 -.55 242.55 -1.1Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer18_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M251.8 -1.6Q251.5 -1.7 251.3 -1.6 249.6 -1.2 246.95 0 244.45 1.2 242.35 2.75 242.1 2.85 242.15 3.15 242.05 3.4 242.3 3.65 243.8 5.55 246.1 5.85 248.4 6.1 250 5.05 251.7 4.1 252.4 2.6L252.45 2.6Q253.15 1.05 252.15 -1.2 252 -1.45 251.8 -1.6M249.2 -6.45Q248.7 -6.65 248.2 -6.45 247.75 -6.2 247.6 -5.7 247.45 -5.15 247.7 -4.6 247.9 -4.05 248.45 -3.9 249 -3.65 249.45 -3.9 249.95 -4.1 250.05 -4.65 250.25 -5.15 249.95 -5.65 249.7 -6.2 249.2 -6.45M242.6 -1.35Q242.7 -1.8 242.5 -2.35 242.25 -2.9 241.75 -3.15 241.2 -3.35 240.7 -3.15 240.3 -2.9 240.1 -2.4 239.95 -1.9 240.2 -1.3 240.45 -.8 240.95 -.55 241.45 -.3 241.9 -.55 242.4 -.8 242.6 -1.35Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer17_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M244.3 .8Q243.3 1.7 243.6 3.15 243.65 4.25 244.8 5.1 246.05 6 247.5 5.75 249.15 5.65 250.15 4.5 251.1 3.6 251 2.35 250.9 1.1 249.7 .3 248.45 -.5 246.9 -.35 245.45 -.1 244.3 .8M249.7 -3.8Q250 -3.45 250.35 -3.55 250.8 -3.55 251 -3.9 251.25 -4.15 251.15 -4.55 251.15 -4.9 250.85 -5.15 250.55 -5.35 250.15 -5.35 249.75 -5.25 249.55 -5 249.35 -4.7 249.35 -4.3 249.4 -3.95 249.7 -3.8M242.15 -3.75Q242.2 -3.45 242.45 -3.15 242.8 -2.95 243.15 -2.95 243.6 -3 243.8 -3.3 244.05 -3.6 244 -3.95 244 -4.35 243.65 -4.55 243.35 -4.8 243 -4.75 242.65 -4.7 242.35 -4.4 242.15 -4.15 242.15 -3.75Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer16_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M244.05 .15Q243 1 243.15 2.45 243.15 3.55 244.2 4.5 245.4 5.45 246.85 5.35 248.5 5.35 249.6 4.3 250.65 3.45 250.65 2.2 250.65 .95 249.45 .05 248.25 -.8 246.7 -.8 245.25 -.65 244.05 .15M250.4 -3.7Q250.85 -3.7 251.1 -4 251.35 -4.25 251.3 -4.65 251.3 -5 251.05 -5.25 250.75 -5.5 250.35 -5.5 249.95 -5.45 249.7 -5.2 249.45 -4.95 249.45 -4.55 249.45 -4.2 249.75 -4 250.05 -3.65 250.4 -3.7M242.25 -4.5Q242.25 -4.2 242.5 -3.9 242.8 -3.65 243.15 -3.65 243.6 -3.65 243.85 -3.95 244.1 -4.2 244.1 -4.55 244.1 -4.95 243.8 -5.2 243.5 -5.45 243.15 -5.45 242.8 -5.4 242.5 -5.15 242.25 -4.9 242.25 -4.5Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer15_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M251.3 -5.05Q251.3 -5.4 251.05 -5.65 250.75 -5.9 250.35 -5.9 249.95 -5.85 249.7 -5.6 249.45 -5.35 249.45 -4.95 249.45 -4.6 249.75 -4.4 250.05 -4.05 250.4 -4.1 250.85 -4.1 251.1 -4.4 251.35 -4.65 251.3 -5.05M246.7 -1.2Q245.25 -1.05 244.05 -.25 243 .6 243.15 2.05 243.15 3.15 244.2 4.1 245.4 5.05 246.85 4.95 248.5 4.95 249.6 3.9 250.65 3.05 250.65 1.8 250.65 .55 249.45 -.35 248.25 -1.2 246.7 -1.2M242.25 -4.9Q242.25 -4.6 242.5 -4.3 242.8 -4.05 243.15 -4.05 243.6 -4.05 243.85 -4.35 244.1 -4.6 244.1 -4.95 244.1 -5.35 243.8 -5.6 243.5 -5.85 243.15 -5.85 242.8 -5.8 242.5 -5.55 242.25 -5.3 242.25 -4.9Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer14_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M244.05 1.7Q243 2.55 243.15 4 243.15 5.1 244.2 6.05 245.4 7 246.85 6.9 248.5 6.9 249.6 5.85 250.65 5 250.65 3.75 250.65 2.5 249.45 1.6 248.25 .75 246.7 .75 245.25 .9 244.05 1.7M250.5 -4.1Q250.05 -4.05 249.7 -3.75 249.5 -3.5 249.45 -3.1L249.45 -2.9Q249.5 -2.6 249.7 -2.3 250.05 -2 250.5 -2 251 -2 251.3 -2.35 251.6 -2.65 251.6 -3.05 251.6 -3.5 251.25 -3.8 250.9 -4.1 250.5 -4.1M242.15 -3Q242.15 -2.65 242.4 -2.3 242.75 -2 243.2 -2 243.7 -2 244 -2.35 244.3 -2.65 244.3 -3.05 244.3 -3.5 243.95 -3.8 243.6 -4.1 243.2 -4.1 242.75 -4.05 242.4 -3.75 242.15 -3.45 242.15 -3Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer13_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M246.75 -2.55Q245.45 -2.4 244.3 -1.65 243.35 -.85 243.45 .5 243.45 1.5 244.4 2.4 245.55 3.3 246.95 3.15 248.45 3.15 249.45 2.2 250.5 1.4 250.5 .25 250.5 -.9 249.35 -1.8 248.2 -2.55 246.75 -2.55M251.9 -7.75Q251.4 -8.2 250.75 -8.2 250.15 -8.1 249.6 -7.65 249.15 -7.2 249.15 -6.5 249.15 -5.95 249.6 -5.45 250.15 -5 250.75 -5 251.55 -5 252 -5.55 252.45 -5.95 252.45 -6.6 252.45 -7.3 251.9 -7.75M241.55 -6.5Q241.55 -5.95 242 -5.45 242.55 -5 243.15 -5 243.95 -5 244.4 -5.55 244.85 -5.95 244.85 -6.6 244.85 -7.3 244.3 -7.75 243.8 -8.2 243.15 -8.2 242.55 -8.1 242 -7.65 241.55 -7.2 241.55 -6.5Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer12_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M247 2.2Q246.15 2.25 245.55 2.8 244.95 3.45 244.95 4.2 244.95 5.05 245.65 5.6 246.3 6.25 247.15 6.15 248.05 6.15 248.7 5.55 249.25 5 249.15 4.15 249.15 3.3 248.6 2.75 247.9 2.2 247 2.2Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer11_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M253.4 .5Q253.2 .25 252.9 .2 250.95 -.15 247.6 -.2 244.3 -.2 241.25 .3 240.9 .35 240.75 .6 240.55 .85 240.6 1.2 240.95 3.85 243.1 5.25 245.25 6.6 247.55 6.4 249.85 6.2 251.45 5.05L251.5 5.05Q253.2 3.8 253.55 1.05 253.55 .75 253.4 .5M251.85 -3Q252.2 -3.4 252.15 -4 252.15 -4.6 251.8 -5 251.4 -5.4 250.85 -5.4 250.35 -5.35 250 -4.95 249.65 -4.5 249.65 -3.95 249.65 -3.35 250.05 -2.95 250.45 -2.5 250.95 -2.55 251.5 -2.55 251.85 -3M241.85 -2.9Q242.25 -2.45 242.75 -2.5 243.3 -2.5 243.65 -2.95 244 -3.35 244 -3.95 244 -4.55 243.6 -4.95 243.2 -5.35 242.7 -5.35 242.2 -5.3 241.8 -4.9 241.45 -4.5 241.5 -3.85 241.5 -3.3 241.85 -2.9Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer10_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M249.15 .55Q246.2 .55 243.4 1 243.1 1.05 242.95 1.25 242.8 1.5 242.8 1.8 243.15 4.2 245.1 5.45 247.05 6.7 249.1 6.5 251.2 6.35 252.65 5.3L252.7 5.3Q254.25 4.15 254.55 1.65 254.55 1.4 254.45 1.15 254.25 .95 254 .9 252.2 .6 249.15 .55M251.6 -4.55Q251.25 -4.1 251.25 -3.55 251.25 -2.95 251.65 -2.55 252.05 -2.1 252.55 -2.15 253.1 -2.15 253.45 -2.6 253.8 -3 253.75 -3.6 253.75 -4.2 253.4 -4.6 253 -5 252.45 -5 251.95 -4.95 251.6 -4.55M243.45 -2.5Q243.85 -2.05 244.35 -2.1 244.9 -2.1 245.25 -2.55 245.6 -2.95 245.6 -3.55 245.6 -4.15 245.2 -4.55 244.8 -4.95 244.3 -4.95 243.8 -4.9 243.4 -4.5 243.05 -4.1 243.1 -3.45 243.1 -2.9 243.45 -2.5Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer9_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M253.95 2.05Q253.85 .8 252.65 0 251.4 -.8 249.85 -.65 248.4 -.4 247.25 .5 246.25 1.4 246.55 2.85 246.6 3.95 247.75 4.8 249 5.7 250.45 5.45 252.1 5.35 253.1 4.2 254.05 3.3 253.95 2.05M254.15 -5.4Q253.65 -5.35 253.3 -4.95 252.95 -4.5 252.95 -3.95 252.95 -3.35 253.35 -2.95 253.75 -2.5 254.25 -2.55 254.8 -2.55 255.15 -3 255.5 -3.4 255.45 -4 255.45 -4.6 255.1 -5 254.7 -5.4 254.15 -5.4M246.05 -2.5Q246.6 -2.5 246.95 -2.95 247.3 -3.35 247.3 -3.95 247.3 -4.55 246.9 -4.95 246.5 -5.35 246 -5.35 245.5 -5.3 245.1 -4.9 244.75 -4.5 244.8 -3.85 244.8 -3.3 245.15 -2.9 245.55 -2.45 246.05 -2.5Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer8_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M253.4 3.7Q254 3 253.9 2 253.9 1 253.35 .3 252.65 -.35 251.7 -.35 250.9 -.3 250.3 .4 249.7 1.15 249.7 2.1 249.7 3.1 250.35 3.75 251.05 4.55 251.9 4.45 252.8 4.45 253.4 3.7M254.15 -5.4Q253.65 -5.35 253.3 -4.95 252.95 -4.5 252.95 -3.95 252.95 -3.35 253.35 -2.95 253.75 -2.5 254.25 -2.55 254.8 -2.55 255.15 -3 255.5 -3.4 255.45 -4 255.45 -4.6 255.1 -5 254.7 -5.4 254.15 -5.4M246.05 -2.5Q246.6 -2.5 246.95 -2.95 247.3 -3.35 247.3 -3.95 247.3 -4.55 246.9 -4.95 246.5 -5.35 246 -5.35 245.5 -5.3 245.1 -4.9 244.75 -4.5 244.8 -3.85 244.8 -3.3 245.15 -2.9 245.55 -2.45 246.05 -2.5Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer7_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M253.95 2.05Q253.85 .8 252.65 0 251.4 -.8 249.85 -.65 248.4 -.4 247.25 .5 246.25 1.4 246.55 2.85 246.6 3.95 247.75 4.8 249 5.7 250.45 5.45 252.1 5.35 253.1 4.2 254.05 3.3 253.95 2.05M254.15 -5.4Q253.65 -5.35 253.3 -4.95 252.95 -4.5 252.95 -3.95 252.95 -3.35 253.35 -2.95 253.75 -2.5 254.25 -2.55 254.8 -2.55 255.15 -3 255.5 -3.4 255.45 -4 255.45 -4.6 255.1 -5 254.7 -5.4 254.15 -5.4M246.05 -2.5Q246.6 -2.5 246.95 -2.95 247.3 -3.35 247.3 -3.95 247.3 -4.55 246.9 -4.95 246.5 -5.35 246 -5.35 245.5 -5.3 245.1 -4.9 244.75 -4.5 244.8 -3.85 244.8 -3.3 245.15 -2.9 245.55 -2.45 246.05 -2.5Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer6_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M253.4 3.7Q254 3 253.9 2 253.9 1 253.35 .3 252.65 -.35 251.7 -.35 250.9 -.3 250.3 .4 249.7 1.15 249.7 2.1 249.7 3.1 250.35 3.75 251.05 4.55 251.9 4.45 252.8 4.45 253.4 3.7M254.15 -5.4Q253.65 -5.35 253.3 -4.95 252.95 -4.5 252.95 -3.95 252.95 -3.35 253.35 -2.95 253.75 -2.5 254.25 -2.55 254.8 -2.55 255.15 -3 255.5 -3.4 255.45 -4 255.45 -4.6 255.1 -5 254.7 -5.4 254.15 -5.4M246.05 -2.5Q246.6 -2.5 246.95 -2.95 247.3 -3.35 247.3 -3.95 247.3 -4.55 246.9 -4.95 246.5 -5.35 246 -5.35 245.5 -5.3 245.1 -4.9 244.75 -4.5 244.8 -3.85 244.8 -3.3 245.15 -2.9 245.55 -2.45 246.05 -2.5Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer5_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M246.05 .7Q245.05 1.6 245.35 3.05 245.4 4.15 246.55 5 247.8 5.9 249.25 5.65 250.9 5.55 251.9 4.4 252.85 3.5 252.75 2.25 252.65 1 251.45 .2 250.2 -.6 248.65 -.45 247.2 -.2 246.05 .7Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer4_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M252.9 -.75Q252.6 -.85 252.45 -.8 250.7 -.5 248 .55 245.3 1.7 243 3.15 242.75 3.25 242.75 3.55 242.65 3.8 242.8 4.05 243.9 6.05 246.15 6.5 248.35 6.9 250.05 5.95 251.9 5.05 252.8 3.65 253.8 2.05 253.2 -.35 253.1 -.6 252.9 -.75M250.75 -3.6Q250.95 -4.1 250.7 -4.55 250.4 -5.1 250.05 -5.4 249.5 -5.65 248.95 -5.4 248.55 -5.1 248.45 -4.7 248.25 -4.15 248.45 -3.55 248.7 -3.05 249.2 -2.85 249.7 -2.6 250.15 -2.8 250.65 -3.05 250.75 -3.6M240.8 -1.6Q240.6 -1.1 240.9 -.45 241.1 0 241.6 .35 242.1 .55 242.5 .35 243 .05 243.25 -.5 243.3 -.95 243.15 -1.5 242.95 -2 242.4 -2.25 241.95 -2.45 241.4 -2.25 241 -2.05 240.8 -1.6Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer3_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M252.8 -2.5Q252.45 -2.6 252.25 -2.55 250.3 -2 247.3 -.45 244.35 1.1 241.9 3 241.6 3.15 241.65 3.5 241.55 3.75 241.75 4.05 243.25 6.25 245.85 6.5 248.4 6.7 250.25 5.45 252.3 4.2 253.15 2.5L253.2 2.5Q254.15 .55 253.2 -2.1 253.05 -2.35 252.8 -2.5M247.3 -5.8Q247.15 -5.2 247.4 -4.65 247.7 -4.15 248.2 -4 248.75 -3.8 249.2 -4.05 249.7 -4.35 249.75 -4.9 249.9 -5.4 249.6 -5.9 249.25 -6.4 248.8 -6.65 248.25 -6.85 247.75 -6.55 247.35 -6.25 247.3 -5.8M241 -.15Q241.5 .05 241.9 -.2 242.35 -.55 242.55 -1.1 242.55 -1.55 242.35 -2.1 242.1 -2.6 241.55 -2.8 241.05 -2.95 240.55 -2.7 240.15 -2.45 240 -2 239.85 -1.45 240.2 -.85 240.45 -.4 241 -.15Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer2_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M251.8 -1.6Q251.5 -1.7 251.3 -1.6 249.6 -1.2 246.95 0 244.45 1.2 242.35 2.75 242.1 2.85 242.15 3.15 242.05 3.4 242.3 3.65 243.8 5.55 246.1 5.85 248.4 6.1 250 5.05 251.7 4.1 252.4 2.6L252.45 2.6Q253.15 1.05 252.15 -1.2 252 -1.45 251.8 -1.6M249.2 -6.45Q248.7 -6.65 248.2 -6.45 247.75 -6.2 247.6 -5.7 247.45 -5.15 247.7 -4.6 247.9 -4.05 248.45 -3.9 249 -3.65 249.45 -3.9 249.95 -4.1 250.05 -4.65 250.25 -5.15 249.95 -5.65 249.7 -6.2 249.2 -6.45M242.6 -1.35Q242.7 -1.8 242.5 -2.35 242.25 -2.9 241.75 -3.15 241.2 -3.35 240.7 -3.15 240.3 -2.9 240.1 -2.4 239.95 -1.9 240.2 -1.3 240.45 -.8 240.95 -.55 241.45 -.3 241.9 -.55 242.4 -.8 242.6 -1.35Z"
          />
        </G>
        <G id="PuzmoLoadingFaceOnly-Layer1_0_FILL">
          <Path
            fill={theme.alwaysDark}
            stroke="none"
            d="M244.85 5.1Q246.1 6 247.55 5.75 249.2 5.65 250.2 4.5 251.15 3.6 251.05 2.35 250.95 1.1 249.75 .3 248.5 -.5 246.95 -.35 245.5 -.1 244.35 .8 243.35 1.7 243.65 3.15 243.7 4.25 244.85 5.1M251.2 -4.55Q251.2 -4.9 250.9 -5.15 250.6 -5.35 250.2 -5.35 249.8 -5.25 249.6 -5 249.4 -4.7 249.4 -4.3 249.45 -3.95 249.75 -3.8 250.05 -3.45 250.4 -3.55 250.85 -3.55 251.05 -3.9 251.3 -4.15 251.2 -4.55M242.2 -3.75Q242.25 -3.45 242.5 -3.15 242.85 -2.95 243.2 -2.95 243.65 -3 243.85 -3.3 244.1 -3.6 244.05 -3.95 244.05 -4.35 243.7 -4.55 243.4 -4.8 243.05 -4.75 242.7 -4.7 242.4 -4.4 242.2 -4.15 242.2 -3.75Z"
          />
        </G>
        <Path
          id="PuzmoLoadingFaceOnly-Layer22_0_1_STROKES"
          stroke={theme.alwaysDark}
          strokeWidth="1"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M240.15 -.65L242.3 -1.75M247.65 -4.55L249.75 -5.7"
        />
        <Path
          id="PuzmoLoadingFaceOnly-Layer20_0_1_STROKES"
          stroke={theme.alwaysDark}
          strokeWidth="1"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M240.15 -.65L242.3 -1.75M247.65 -4.55L249.75 -5.7"
        />
        <Path
          id="PuzmoLoadingFaceOnly-Layer12_0_1_STROKES"
          stroke={theme.alwaysDark}
          strokeWidth="1"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M250.05 -.6L252.45 -.6M241.6 -.6L244 -.6"
        />
        <Path
          id="PuzmoLoadingFaceOnly-Layer5_0_1_STROKES"
          stroke={theme.alwaysDark}
          strokeWidth="1"
          strokeLinejoin="round"
          strokeLinecap="round"
          fill="none"
          d="M243.6 -3.7L246 -3.7M252.05 -3.7L254.45 -3.7"
        />
      </Defs>
      <G id="PuzmoLoadingFaceOnly-Scene-1" overflow="visible">
        <Use xlinkHref="#PuzmoLoadingFaceOnly-loadline-export" transform="matrix(4 0 0 4 -882.6 100.1)" />
      </G>
    </Svg>
  )
}
