/**
 * @generated SignedSource<<0ab754ef8a4500b1ce0394c3cd862a26>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserProfileStatsChooser$data = {
  readonly fullAggregateStats: any | null;
  readonly id: string;
  readonly publicProfile: {
    readonly highlightedStatIDs: ReadonlyArray<string>;
    readonly id: string;
  } | null;
  readonly " $fragmentType": "UserProfileStatsChooser";
};
export type UserProfileStatsChooser$key = {
  readonly " $data"?: UserProfileStatsChooser$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserProfileStatsChooser">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserProfileStatsChooser",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullAggregateStats",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "publicProfile",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "highlightedStatIDs",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "9ceb02aa3371d87a0d58bc2465fbfd4b";

export default node;
