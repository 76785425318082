import { SOCIAL_OVERLAY_WIDTH } from "$components/socialOverlay/SocialOverlay"
import { OverlayContext } from "$components/socialOverlay/OverlayContext"
import { useWindowDimensions } from "react-native"
import { useContextSelector } from "use-context-selector"

// This hook is used to calculate the width of the container that holds the main
// site layout. This is used to calculate the number of columns or show/hide extra
// information. Always use this instead of directly using useWindowDimensions.
export function useContainerWidth() {
  const window = useWindowDimensions()
  const hasSocialOverlay = useContextSelector(OverlayContext, (c) => c.socialOverlay)
  return window.width - (hasSocialOverlay ? SOCIAL_OVERLAY_WIDTH : 0)
}
