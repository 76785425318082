/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { Calendar } from "$components/Calendar"
import { CalendarPopover } from "$components/CalendarPopover"
import {
  Row,
  ThinButton,
  Avatar,
  InternalLink,
  BodyTextAnchor,
  SubtitleText,
  Screen,
  BASE_MARGIN,
  BASE_PADDING,
  Hamburger,
  AnimatedPressableBase,
} from "$components/DesignSystem"
import { ModalDisplay } from "$components/ModalDisplay"
import { TodayLink } from "$components/TodayLink"
import { AuthModalContext } from "$components/auth/AuthModal"
import { PlayGameCollabAvatars } from "$components/gameplay/PlayGameCollabAvatars"
import { PlayGameNetworkStatusBadge } from "$components/gameplay/PlayGameNetworkStatusBadge"
import { MainSiteLoaderSmiley } from "$components/ghosts/MainSiteLoaderSmiley"
import { BackArrow } from "$components/icons/BackArrow"
import { PuzmoFullLengthIcon } from "$components/icons/PuzmoFullLengthIcon"
import { SpeechBubbleIcon } from "$components/icons/SpeechBubbleIcon"
import { InviteAvatar } from "$components/minis/InviteAvatar"
import { PopoverTooltip } from "$components/popovers/Popovers"
import { OverlayContext } from "$components/socialOverlay/OverlayContext"
import { LogoWave } from "$components/svgAnimations/LogoWave"
import { DayInfoBar } from "$components/today/DayInfoBar"
import { NeedsVerification } from "$components/today/NeedsVerification"
import { HeaderBackButton, getHeaderTitle, HeaderTitle } from "@react-navigation/elements"
import { NavigationContainer, DefaultTheme, DarkTheme, createNavigationContainerRef, Route, useIsFocused } from "@react-navigation/native"
import { NativeStackNavigationProp, createNativeStackNavigator, NativeStackNavigationOptions } from "@react-navigation/native-stack"
import * as React from "react"
import { memo, useCallback, useMemo, useContext, useState } from "react"
import { ColorSchemeName, Image, TouchableOpacity, View } from "react-native"
import { useTheme } from "styled-rn"
import { useContextSelector } from "use-context-selector"

import LinkingConfiguration from "./LinkingConfiguration"
import { NavDropdown } from "./NavDropdown"
import { SocialTab } from "./SocialNav"
import { RootStackParamList } from "../../types"
import { useGetPartner, useGetUser } from "../CoreContext"
import { useContainerWidth } from "../hooks/useContainerWidth"
import { useIsMobile } from "../hooks/useIsMobile"
import { useNakamaSocket, useNakamaStateSelector } from "../hooks/useNakama"
import { playGameContext } from "../hooks/useSetupPlayGameContext"
import { gameSlugToName } from "../lib/gameMeta"
import { getPlayerReadableDateString } from "../lib/getPlayerReadableDateString"
import { actionableNotificationsCounts } from "../screens/Social/Notifications"

const AccountSettingsScreen = React.lazy(() => import("../screens/AccountSettingsScreen"))
const AdminEditMarkdownScreen = React.lazy(() => import("../screens/AdminEditMarkdown"))
const ChatScreen = React.lazy(() => import("../screens/ChatScreen"))
const DevSettingsScreen = React.lazy(() => import("../screens/DevSettingsScreen"))
const ForgotPassword = React.lazy(() => import("../screens/ForgotPasswordChangeScreen"))
const GroupEditScreen = React.lazy(() => import("../screens/GroupEditScreen"))
const GroupScreen = React.lazy(() => import("../screens/GroupScreen"))
const NotFoundScreen = React.lazy(() => import("../screens/NotFoundScreen"))
const PlayGameRedirectPage = React.lazy(() => import("../screens/PlayGameRedirectScreen"))
const PlayGameScreen = React.lazy(() => import("../screens/PlayGameScreen"))
const SignoutScreen = React.lazy(() => import("../screens/SignoutScreen"))
const SubscribeScreen = React.lazy(() => import("../screens/SubscribeScreen"))
const SubscriptionSuccessScreen = React.lazy(() => import("../screens/SubscriptionSuccessScreen"))
const TodayRecapScreen = React.lazy(() => import("../screens/TodayRecapScreen"))
const TodayScreen = React.lazy(() => import("../screens/TodayScreen"))
const UserProfileScreen = React.lazy(() => import("../screens/UserProfileScreen"))
const UserProfileSettingsScreen = React.lazy(() => import("../screens/UserSettingsScreen"))
const UserSwitchScreen = React.lazy(() => import("../screens/UserSwitchScreen"))
const VerifyPage = React.lazy(() => import("../screens/VerifyEmailScreen"))

// Same value used by react-native as left right margin on nav header.
export const REACT_NAV_HEADER_MARGIN = 11
export const NAV_HEIGHT = 48

export const navigationRef = createNavigationContainerRef()

const Navigation = React.memo(({ colorScheme }: { colorScheme: ColorSchemeName }) => {
  // Basically when we route through the app, update right at the highest
  // level of the app whether we are in a game or not, so that info can live at
  // the point where both navigation hierarchies meet.

  const gameUIDispatch = useContextSelector(playGameContext, (s) => s.gameUIDispatch)
  const collabMatchID = useContextSelector(playGameContext, (s) =>
    s.gameUIState?.collab && "matchID" in s.gameUIState.collab ? s.gameUIState.collab.matchID : undefined
  )
  const { socket } = useNakamaSocket()

  const updatePlayingGameInfo = useCallback(() => {
    const route = navigationRef.getCurrentRoute()
    // On mobile these are presented as full screen pushes, so we don't want to wipe the
    // game state and disconnect from a match because you've gone into the social menu
    const isFriendsOrGroups = route?.name === "Friends" || route?.name === "Groups"

    // @ts-expect-error
    const gameSlug = route?.params?.gameSlug as string | undefined
    // @ts-expect-error
    const playGameSlug = route?.params?.playGameSlug as string | undefined
    if (gameSlug && playGameSlug) gameUIDispatch({ type: "setGameMeta", meta: { gameSlug, playGameSlug } })
    else if (!isFriendsOrGroups) {
      gameUIDispatch({ type: "reset" })
      if (collabMatchID) socket.leaveMatch(collabMatchID)
    }
  }, [gameUIDispatch, collabMatchID, socket])

  return (
    <NavigationContainer
      ref={navigationRef}
      onReady={updatePlayingGameInfo}
      onStateChange={updatePlayingGameInfo}
      linking={LinkingConfiguration}
      theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
    >
      <RootNavigator />
    </NavigationContainer>
  )
})

export default Navigation

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
export const Stack = createNativeStackNavigator<RootStackParamList>()

// Quick link to the routing table:
LinkingConfiguration

const DesktopRightHandInfo = memo((props: { toggle: () => void }) => {
  const user = useGetUser()
  const loggedIn = user.type !== "anon"
  const theme = useTheme()

  const setSocialOverlay = useContextSelector(OverlayContext, (c) => c.setSocialOverlay)
  const hasSocialOverlay = useContextSelector(OverlayContext, (c) => c.socialOverlay)

  const invites = useNakamaStateSelector((s) => s.invitesViaNakamaID)
  const latestInvite = invites.size > 0 && [...invites.values()].sort((a, b) => b.dateSent.getDate() - a.dateSent.getDate())[0]

  const friendBadge = useNakamaStateSelector((s) => s.friendBadgeCount)
  const currentUserHasNotifications =
    user.type === "user" && (user.currentUser?.hasMessages.friends || user.currentUser?.hasMessages.groups)

  const { triggerLogin, triggerSignup } = useContext(AuthModalContext)

  const avatarSource = useMemo(() => {
    return { uri: user.currentUser?.avatarURL, width: 30 * 2, height: 30 * 2 }
  }, [user.currentUser?.avatarURL])

  const SocialButton = (
    <View
      style={{
        paddingLeft: BASE_PADDING,
        marginBottom: -BASE_MARGIN,
        marginTop: -BASE_MARGIN,
        borderLeftColor: `${theme.alwaysLight}33`,
        borderLeftWidth: 1,
      }}
    >
      <ThinButton
        iconComponent={<Hamburger colorKey="alwaysDark" gapSize={3} size={16} open={false} />}
        iconAlign="right"
        title="Play Together"
        bgKey="alwaysLight"
        colorKey="alwaysDark"
        onPress={() => setSocialOverlay((v: boolean) => !v)}
      />

      {(friendBadge > 0 || currentUserHasNotifications) && (
        <View
          pointerEvents="none"
          style={{
            marginLeft: 4,
            marginRight: 4,
            marginTop: 1,
            width: 14,
            height: 14,
            backgroundColor: theme.key,
            borderRadius: 12,
            position: "absolute",
            top: -4,
            right: -8,
          }}
        />
      )}
    </View>
  )

  if (loggedIn) {
    return (
      <Row style={{ alignItems: "center", marginRight: BASE_MARGIN * 2 }}>
        <AnimatedPressableBase onPress={props.toggle} style={{ alignContent: "center" }}>
          <Row style={{ marginRight: hasSocialOverlay ? 0 : BASE_MARGIN, alignItems: "center" }}>
            <BodyTextAnchor style={{ textDecorationLine: "none", backgroundColor: "none", marginHorizontal: 8 }}>
              {user.currentUser?.name || user.currentUser.username}
            </BodyTextAnchor>
            <Avatar size={30} source={avatarSource} />
          </Row>
        </AnimatedPressableBase>

        {latestInvite && <InviteAvatar invite={latestInvite} />}

        {!hasSocialOverlay && SocialButton}
      </Row>
    )
  }

  return (
    <Row style={{ alignItems: "center", marginRight: BASE_MARGIN * 2 }}>
      <ThinButton title="Log in" bgKey="key" colorKey="keyFG" onPress={triggerLogin} style={{ marginRight: BASE_MARGIN }} />
      <ThinButton
        title="Sign Up"
        bgKey="key"
        colorKey="keyFG"
        onPress={triggerSignup}
        style={{ marginRight: BASE_MARGIN, paddingVertical: 4, paddingHorizontal: 8 }}
      />

      {!hasSocialOverlay && SocialButton}
    </Row>
  )
})

export const HeaderChatButton = () => {
  const [count, setCount] = useState(0)

  const checkNotifs = useCallback((notes: any[]) => {
    const types = actionableNotificationsCounts(notes)
    const newCount = types.friends + types.groups
    if (count !== newCount) setCount(types.friends + types.groups)
  }, [])

  // useNakamaNotifications(checkNotifs)

  return (
    <InternalLink screen="ChatSelector" routeOpts={undefined}>
      <SpeechBubbleIcon number={count} />
    </InternalLink>
  )
}

export const MobileRightHandInfo = memo((props: { routeName: keyof RootStackParamList | undefined; toggle: () => void }) => {
  const user = useGetUser()
  const loggedIn = user.type !== "anon"
  const { triggerSignup } = useContext(AuthModalContext)

  const gameUIDispatch = useContextSelector(playGameContext, (s) => s.gameUIDispatch)
  const showMenu = useContextSelector(playGameContext, (s) => s.gameUIState.showMenu)
  const avatarSize = 32

  const invites = useNakamaStateSelector((s) => s.invitesViaNakamaID)
  const latestInvite = invites.size > 0 && [...invites.values()].sort((a, b) => b.dateSent.getDate() - a.dateSent.getDate())[0]

  const menu = (
    <TouchableOpacity
      accessible
      accessibilityLabel={showMenu ? "Hide menu" : "Show menu"}
      accessibilityHint="Toggles the app menu"
      accessibilityState={{ expanded: showMenu }}
      accessibilityRole="togglebutton"
      onPress={() => {
        gameUIDispatch({ type: showMenu ? "hideMenu" : "showMenu" })
      }}
    >
      <View style={{ marginLeft: BASE_MARGIN * 0.5 }}>
        <Hamburger open={showMenu} />
      </View>
    </TouchableOpacity>
  )

  // if is in game, show speech bubble.
  // if not, show user profile.
  if (loggedIn) {
    return (
      <Row style={{ alignItems: "center", justifyContent: "center", marginRight: REACT_NAV_HEADER_MARGIN }}>
        {props.routeName === "PlayGame" ? (
          <>
            {latestInvite && <InviteAvatar invite={latestInvite} />}
            <HeaderChatButton />
            {menu}
          </>
        ) : (
          <>
            <TouchableOpacity
              onPress={props.toggle}
              style={{ alignContent: "center" }}
              accessibilityRole="togglebutton"
              accessibilityHint="Toggle user info dropdown"
            >
              <Avatar
                size={avatarSize}
                source={{
                  uri: user.currentUser.avatarURL,
                  width: avatarSize,
                  height: avatarSize,
                }}
              />
            </TouchableOpacity>
          </>
        )}
        {latestInvite && <InviteAvatar invite={latestInvite} />}
      </Row>
    )
  }

  return (
    <Row style={{ alignItems: "center", justifyContent: "center", marginRight: REACT_NAV_HEADER_MARGIN }}>
      <ThinButton
        title="Join"
        bgKey="key"
        colorKey="keyFG"
        onPress={triggerSignup}
        style={{ marginRight: BASE_MARGIN * 0.75, paddingVertical: 4, paddingHorizontal: 8 }}
      >
        Join
      </ThinButton>
      {props.routeName === "PlayGame" && menu}
    </Row>
  )
})

const baseTime = 1000 * 60 * 5
const LogoWithAnimation = memo((props: { width: number }) => {
  const theme = useTheme()
  const [animateKey, setAnimateKey] = useState(0)
  const isFocused = useIsFocused()
  const timer = React.useRef<NodeJS.Timeout>()
  React.useEffect(() => {
    function runAnimation(delay: number) {
      if (!isFocused) return
      timer.current = setTimeout(() => {
        setAnimateKey((v) => v + 1)
        runAnimation(Math.random() * baseTime + baseTime)
      }, delay)
    }

    runAnimation(baseTime * Math.random() + 5000)

    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }

      if (!isFocused) {
        setAnimateKey(0)
      }
    }
  }, [isFocused])

  return (
    <View style={{ position: "relative" }}>
      <PuzmoFullLengthIcon width={props.width} />
      {animateKey > 0 && (
        <View
          style={{
            width: props.width * 0.285,
            position: "absolute",
            top: -props.width * 0.05,
            bottom: 0,
            right: -props.width * 0.04,
          }}
        >
          <View
            style={{
              position: "absolute",
              top: 0,
              left: props.width * 0.05,
              right: props.width * 0.025,
              bottom: 0,
              backgroundColor: theme.alwaysDark,
            }}
          />
          <LogoWave
            key={animateKey}
            width={props.width * 0.285}
            pointerEvents="none"
            style={{
              position: "absolute",
            }}
          />
        </View>
      )}
    </View>
  )
})

export const DesktopLeftHandView = memo(() => {
  const theme = useTheme()
  const screenWidth = useContainerWidth()
  const smallLayout = screenWidth < 700
  const xSmallLayout = screenWidth < 500

  return (
    <Row centerV style={{ alignItems: "center", paddingLeft: BASE_PADDING * 2 }}>
      <TodayLink
        renderDisplay={(dateString: string) => {
          return (
            <PopoverTooltip text={`Go to the home page for ${dateString}`}>
              <LogoWithAnimation width={196} />
            </PopoverTooltip>
          )
        }}
      />
      <SubtitleText
        style={{
          fontSize: smallLayout ? 14 : 18,
          lineHeight: smallLayout ? 14 : 18,
          color: theme.alwaysLight,
          backgroundColor: "none",
          alignSelf: "flex-end",
          marginLeft: 4,
          marginBottom: 6,
          width: smallLayout ? 100 : 220,
          display: xSmallLayout ? "none" : "flex",
        }}
      >
        by Orta Therox{smallLayout ? "\n" : " "}& Zach Gage
      </SubtitleText>
    </Row>
  )
})

const Header = memo((props: { children: React.ReactNode }) => {
  const theme = useTheme()
  return (
    <View>
      <View
        style={{
          height: NAV_HEIGHT,
          backgroundColor: theme.alwaysDark,
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {props.children}
      </View>
      <DayInfoBar />
      <NeedsVerification />
    </View>
  )
})

const MobileTitle = memo((props: { title: string; routeName: keyof RootStackParamList }) => {
  const theme = useTheme()
  const gameUIDispatch = useContextSelector(playGameContext, (s) => s.gameUIDispatch)

  let title = (
    <HeaderTitle
      style={{
        fontFamily: "Poppins-Bold",
        color: theme.alwaysLight,
      }}
    >
      {props.title}
    </HeaderTitle>
  )

  if (props.routeName === "Today") {
    title = <LogoWithAnimation width={140} />
  }

  // if in game, tap shoudl open menu
  const showMenu = useContextSelector(playGameContext, (s) => s.gameUIState.showMenu)
  if (props.routeName === "PlayGame") {
    title = (
      <TouchableOpacity
        accessibilityRole="button"
        onPress={() => {
          gameUIDispatch({ type: showMenu ? "hideMenu" : "showMenu" })
        }}
      >
        {title}
      </TouchableOpacity>
    )
  }

  return (
    <View
      style={{
        position: "absolute",

        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {title}
    </View>
  )
})

const MobileLeftHandView = memo(
  (props: { routeName: keyof RootStackParamList; navigation: NativeStackNavigationProp<RootStackParamList> }) => {
    const { navigation, routeName } = props
    const showMenu = useContextSelector(playGameContext, (s) => s.gameUIState.showMenu)

    const isHome = routeName === "Today"
    const shouldLinkToToday = routeName === "PlayGame" || routeName === "TodayRecap"
    const userIsGuest = useGetUser().type === "anon"

    if (isHome) {
      return (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginLeft: REACT_NAV_HEADER_MARGIN,
          }}
        >
          {!userIsGuest && <HeaderChatButton />}
        </View>
      )
    }

    return (
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <HeaderBackButton
          disabled={showMenu}
          backImage={() => <BackArrow width={18} fill="alwaysLight" />}
          onPress={() => {
            // Play game screen should always push to Today, but we use this
            // header for navigating through settings and social screens which
            // have a meaningful stack that players might want to move back through.
            if (navigation.canGoBack() && !shouldLinkToToday) {
              navigation.goBack()
            } else {
              navigation.navigate("Today", {})
            }
          }}
        />
        <PlayGameNetworkStatusBadge />
        <PlayGameCollabAvatars />
      </View>
    )
  }
)

const RootNavigator = React.memo(() => {
  const [showAccountDropdown, setShowAccountDropdown] = useState(false)

  const close = React.useCallback(() => setShowAccountDropdown(false), [])
  const toggle = React.useCallback(() => setShowAccountDropdown((v) => !v), [])

  const isMobile = useIsMobile()
  const partner = useGetPartner()

  const defaultSettings = React.useMemo(() => {
    if (isMobile) {
      return {
        cardStyle: { flex: 1 },
        header: ({ route, options, navigation }) => {
          const title = getHeaderTitle(options, route.name)
          return (
            <Header>
              <MobileTitle title={title} routeName={route.name as keyof RootStackParamList} />
              <MobileLeftHandView
                navigation={navigation as NativeStackNavigationProp<RootStackParamList>}
                routeName={route.name as keyof RootStackParamList}
              />
              <MobileRightHandInfo routeName={route.name as keyof RootStackParamList} toggle={toggle} />
            </Header>
          )
        },
      } as NativeStackNavigationOptions
    }

    return {
      cardStyle: { flex: 1 },
      header: () => (
        <Header>
          <DesktopLeftHandView />
          {partner && (
            <Image
              source={{ uri: partner.logoLongWhite }}
              resizeMode="contain"
              style={{ width: 300, height: 30, marginTop: 10 }}
              accessibilityIgnoresInvertColors
            />
          )}
          <DesktopRightHandInfo toggle={toggle} />
        </Header>
      ),
    } as NativeStackNavigationOptions
  }, [isMobile, toggle, partner])

  const UserDropDown = React.useMemo(() => <NavDropdown height={NAV_HEIGHT - BASE_MARGIN / 2} closeDropdown={close} />, [close])

  return (
    <React.Suspense
      fallback={
        <Screen>
          <MainSiteLoaderSmiley />
        </Screen>
      }
    >
      <Stack.Navigator initialRouteName="Today" screenOptions={defaultSettings}>
        <Stack.Screen
          name="Today"
          component={TodayScreen}
          options={({ route }) => ({ title: `${route?.params?.day ? getPlayerReadableDateString(route.params.day) : "Today"}'s Puzzmo` })}
        />
        <Stack.Screen
          name="TodayRecap"
          options={({ route }) => ({
            title: `${route?.params?.day ? getPlayerReadableDateString(route.params.day) : "Today"}'s Puzzmo Recap`,
          })}
          component={TodayRecapScreen}
        />

        <Stack.Screen name="VerifyEmail" options={{ title: "Verify Email" }} component={VerifyPage} />
        <Stack.Screen name="ResetPassword" options={{ title: "Reset Password" }} component={ForgotPassword} />
        <Stack.Screen name="AdminEditMarkdown" options={{ title: "Edit Markdown" }} component={AdminEditMarkdownScreen} />

        <Stack.Screen
          name="PlayGame"
          options={({ route }) => {
            return { title: gameSlugToName(route.params.gameSlug) }
          }}
          component={PlayGameScreen}
        />
        <Stack.Screen name="PlayGameRedirect" component={PlayGameRedirectPage} />
        <Stack.Screen name="UserProfile" options={({ route }) => ({ title: route.params.username })} component={UserProfileScreen} />
        <Stack.Screen name="SubscriptionSuccess" component={SubscriptionSuccessScreen} />

        <Stack.Screen name="ChatSelector" component={SocialTab} options={{ title: "Play Together" }} />
        <Stack.Screen name="Chat" component={ChatScreen} />
        <Stack.Screen name="Group" component={GroupScreen} />
        <Stack.Screen name="GroupEdit" component={GroupEditScreen} />

        <Stack.Screen name="Dev" component={DevSettingsScreen} />
        <Stack.Screen name="Account" component={AccountSettingsScreen} options={{ title: "Settings & Account" }} />
        <Stack.Screen name="UserProfileSettings" component={UserProfileSettingsScreen} options={{ title: "My profile & Statistics" }} />

        <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: "Oops!" }} />
        <Stack.Screen name="UserSwitch" component={UserSwitchScreen} options={{ title: "Select Profile" }} />
        <Stack.Screen name="Subscribe" component={SubscribeScreen} />

        {/* These are unused */}
        <Stack.Screen name="SignOut" component={SignoutScreen} options={{ title: "Sign Out" }} />
        <Stack.Screen name="Logout" component={SignoutScreen} options={{ title: "Log out" }} />
      </Stack.Navigator>

      <ModalDisplay unframed modal={UserDropDown} close={close} show={showAccountDropdown} />
      <CalendarPopover>
        <Calendar />
      </CalendarPopover>
    </React.Suspense>
  )
})
