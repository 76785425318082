import { useCreateGameplayMutations } from "$components/gameplay/GamePlayUpdateMutations"
import React, { useCallback, useReducer, useEffect, useRef } from "react"
import { createContext } from "use-context-selector"

import { useRunGameSubscriptions } from "./useRunGameSubscriptions"
import { appEventsEmitter, makeUseEmitterSubscription } from "../lib/createEmitter"

import {
  GameSettingsUIComponents,
  Collaborator,
  MessagesSentFromEmbed,
  MessagesReceived,
  MessagesSentViaNakama,
  GameOverMessageUIComponent,
} from "$consts/hostAPI"

export type SidebarOverlayMode = null | "game_help" | "game_settings" | "game_complete"
type AnyMessageSent = MessagesReceived & MessagesSentViaNakama
export type AllInternalMessages = MessagesSentFromEmbed & { COLLAB_USERS_CHANGED: Collaborator[] }

export type PlayGameContextType = {
  gameUIState: GameUIState
  gameUIDispatch: React.Dispatch<GameUIAction>
  setSidebarMode: (mode: SidebarOverlayMode) => void
}

export type TimeDisplay = [elapsedTime: string, addedTime: string]

export const useSubscribeToGameEvent = makeUseEmitterSubscription<AllInternalMessages>(appEventsEmitter)

// Use this as a state machine for handling visibility of:
// - mobile menu screen
// - when the game is paused
// - whether or not game is in collab mode.
export const initialState: GameUIState = {
  completedGames: new Set(),
  gameSlug: undefined,
  playGameSlug: undefined,

  isPaused: false,
  showMenu: false,
  isOnline: true,
  collab: undefined,
  collabs: [],
  connectToGameFn: undefined,
  sendMessageToGameFn: undefined,
  collabNakamaIDs: new Set(),
  sidebarMode: null,
  timeDisplay: ["--:--", ""] as TimeDisplay,
  completeData: {
    isComplete: false,
    showRetry: false,
    content: "",
    results: [],
  },
  sidebarContent: "",
  gameSettings: { components: [], settings: {} },
}

function createInitialState() {
  return { ...initialState }
}

export type GameUIState = {
  // This is mainly kept around as a way to change the cache key on
  // today screen, which will trigger a new network request for things
  // like the social feed / group info
  completedGames: Set<string>

  gameSlug: string | undefined
  playGameSlug: string | undefined

  isPaused: boolean
  showMenu: boolean
  isOnline: boolean

  connectToGameFn: (() => void) | undefined
  sendMessageToGameFn: ((type: keyof AnyMessageSent, json: any) => void) | undefined

  collabs: Collaborator[]
  collabNakamaIDs: Set<string>
  sidebarContent: string
  sidebarMode: SidebarOverlayMode
  timeDisplay: TimeDisplay
  completeData: {
    isComplete: boolean
    showRetry: boolean
    content: string
    results: GameOverMessageUIComponent[]
  }
  gameSettings: { components: GameSettingsUIComponents[]; settings: any }
  collab:
    | undefined
    | { state: "connecting"; isHost: boolean }
    | { state: "connecting"; isHost: false; isWaitingOnHostToStart: true }
    | { state: "connected"; matchID: string; isDuplicateHost: true }
    | { state: "connected"; matchID: string; isHost: true }
    | { state: "connected"; matchID: string; isHost: false; hostFound: true }
    | { state: "connected"; matchID: string; isHost: false; hostFound: false }
    | { state: "error"; error: string }
}

export type GameUIAction =
  | { type: "pause" }
  | { type: "unpause" }
  | { type: "updateConnectToGameFn"; fn: () => void }
  | { type: "setSendMessageToGameFn"; fn: (type: keyof AnyMessageSent, json: any) => void }
  | { type: "showMenu" }
  | { type: "hideMenu" }
  | { type: "startCollab" }
  | { type: "updateCollab"; state: GameUIState["collab"] }
  | { type: "endCollab" }
  | { type: "setCollabs"; collabs: Collaborator[] }
  | { type: "timerTick"; timeDisplay: [elapsedTime: string, addedTime: string] }
  | { type: "setSidebarMode"; mode: SidebarOverlayMode }
  | ({ type: "complete" } & GameUIState["completeData"])
  | { type: "networkChanged"; online: boolean }
  | { type: "updateSidebarContent"; content: string }
  | { type: "initializeGameSettings"; components: GameSettingsUIComponents[]; settings: any }
  | { type: "updateGameSettings"; settings: any }
  // These two get called by the main navigator
  // They should clear out all existing app state related to the current game.
  | { type: "reset" }
  | {
      type: "setGameMeta"
      meta: { gameSlug: string; playGameSlug: string }
    }

function gameUIStateReducer(state: GameUIState, action: GameUIAction): GameUIState {
  switch (action.type) {
    case "setGameMeta":
      return {
        ...initialState,
        gameSlug: action.meta.gameSlug,
        playGameSlug: action.meta.playGameSlug,
        completedGames: state.completedGames,
        // These functions both get sent on first render of the play game screen.
        // Unfortunately, setGameMeta runs after the first render, so we need to
        // keep them around when setting the game.
        connectToGameFn: state.connectToGameFn,
        sendMessageToGameFn: state.sendMessageToGameFn,
      }

    case "reset":
      return { ...initialState, completedGames: state.completedGames }

    // We want to allow any component inside the iframe to be able to send messages to the game,
    // However, it's complex because both web and react native use pretty different techniques to talk to the game.
    // So, we have a context which includes a reference to the function that sends messages to the game.
    // The iframe or webview components are responsible for calling setsendToGameFn when they're setup.
    case "setSendMessageToGameFn":
      return { ...state, sendMessageToGameFn: action.fn }

    // we need to save this to context to pass it from useCoopWebhook to
    // the connect to game button.
    case "updateConnectToGameFn":
      return { ...state, connectToGameFn: action.fn }

    case "timerTick": {
      const newState = { ...state, timeDisplay: action.timeDisplay }
      return newState
    }

    case "setCollabs":
      return { ...state, collabs: action.collabs, collabNakamaIDs: new Set(action.collabs.map((c) => c.nID)) }

    // pausing always shows settings menu.
    case "pause":
      // noop if we're in collab mode or complete.
      if (state.collab || state.completeData.isComplete) return state
      return { ...state, isPaused: true, showMenu: true }

    case "initializeGameSettings": {
      return { ...state, gameSettings: { components: action.components, settings: action.settings } }
    }

    case "updateGameSettings": {
      return { ...state, gameSettings: { ...state.gameSettings, settings: action.settings } }
    }

    case "setSidebarMode": {
      const clear = state.sidebarMode === action.mode
      // Sidebar locked on complete mode if game is complete.
      if (state.completeData.isComplete && clear) {
        return { ...state, sidebarMode: "game_complete" }
      }

      return { ...state, sidebarMode: clear ? null : action.mode }
    }
    case "updateSidebarContent":
      return { ...state, sidebarContent: action.content }

    case "unpause":
      return { ...state, isPaused: false, showMenu: false }

    case "showMenu": {
      // only pause if we're not in collab mode.
      const forcePaused = !state.collab && !state.completeData.isComplete
      return { ...state, isPaused: forcePaused, showMenu: true }
    }

    case "hideMenu":
      return { ...state, isPaused: false, showMenu: false }

    case "startCollab":
      // Make sure to unpause when we start a collab session.
      return { ...state, isPaused: false, showMenu: false, collab: { state: "connecting", isHost: true } }

    case "updateCollab":
      // Internal state updates
      return { ...state, collab: action.state }

    case "endCollab":
      return { ...state, collab: undefined }

    case "networkChanged":
      return { ...state, isOnline: action.online }

    case "complete":
      if (!state.playGameSlug) return state

      return {
        ...state,
        isPaused: false,
        // showMenu only matters on mobile, we want to turn it on so user sees
        // game_complete info.
        showMenu: true,
        sidebarMode: "game_complete",
        completedGames: new Set([...state.completedGames, state.playGameSlug]),
        completeData: action,
      }
    // Don't need a default case, typescript will catch invalid action types.
  }
}

const sendMessageNoop = () => {}

export function useSetupPlayGameContext() {
  const [gameUIState, gameUIDispatch] = useReducer(gameUIStateReducer, null, createInitialState)

  const gameSlug = gameUIState.gameSlug
  const playGameSlug = gameUIState.playGameSlug

  const sendToGameFn = gameUIState.sendMessageToGameFn || sendMessageNoop
  const cacheKey = playGameSlug || ""

  // Sync game state -> app state
  useEffect(() => {
    sendToGameFn(gameUIState.isPaused ? "PAUSE_GAME" : "RESUME_GAME", {})
  }, [gameUIState.isPaused, sendToGameFn])

  // Sync app state -> game state
  useRunGameSubscriptions(gameUIDispatch, cacheKey)

  // Sync app state -> server state
  const { updateUserGameSettings } = useCreateGameplayMutations()
  const updateSettings = useCallback(
    (settings: any) => {
      if (!gameSlug) return
      updateUserGameSettings({
        variables: { input: { gameSlug, jsonString: JSON.stringify(settings) } },
      })
    },
    [updateUserGameSettings, gameSlug]
  )
  useSubscribeToGameEvent(cacheKey, "UPDATE_SETTINGS_FROM_EMBED", updateSettings)

  // Shortcut for setting sidebar mode
  const setSidebarMode = useCallback((mode: SidebarOverlayMode) => gameUIDispatch({ type: "setSidebarMode", mode }), [])

  return {
    setSidebarMode,
    gameUIState,
    gameUIDispatch,
  }
}

export const playGameContext = createContext<PlayGameContextType>(null as any)

export function PlayGameStateProvider(props: React.PropsWithChildren<object>) {
  const context = useSetupPlayGameContext()
  return <playGameContext.Provider value={context}>{props.children}</playGameContext.Provider>
}
