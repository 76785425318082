/**
 * @generated SignedSource<<c13944cc03eac0cfbcc35ff2341e37ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlayGameHeader$data = {
  readonly puzzle: {
    readonly game: {
      readonly displayName: string;
    };
    readonly name: string | null;
    readonly puzzle: string;
    readonly seriesNumber: number;
  };
  readonly " $fragmentType": "PlayGameHeader";
};
export type PlayGameHeader$key = {
  readonly " $data"?: PlayGameHeader$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlayGameHeader">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlayGameHeader",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Puzzle",
      "kind": "LinkedField",
      "name": "puzzle",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "puzzle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "seriesNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Game",
          "kind": "LinkedField",
          "name": "game",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GamePlayed",
  "abstractKey": null
};

(node as any).hash = "8d0ccbe3d5911def2a409eada35cc5f2";

export default node;
