import { Avatar, Row } from "$components/DesignSystem"
import React from "react"
import { Platform, Pressable, View } from "react-native"
import { Theme } from "$consts/hostAPI"
import { useTheme } from "styled-rn"

export const Avatars = (props: {
  avatarURLs: string[]
  selectedIndex: number
  startingIndex: number
  onSelect: (n: number) => void
  shape?: "circle" | "square"
  selectionColor: string
  disabled: boolean
}) => (
  <Row style={{ flexWrap: "wrap" }}>
    {props.avatarURLs.map((avatar, i) => {
      return (
        <Pressable key={avatar} onPress={() => props.onSelect(i + props.startingIndex)} disabled={props.disabled}>
          <View
            style={{
              width: 60,
              height: 60,
              marginRight: 10,
              marginBottom: 10,
              alignContent: "center",
              justifyContent: "center",
              opacity: props.disabled ? 0.5 : 1,
            }}
          >
            <Avatar
              source={{ uri: avatar }}
              size={55}
              unround={props.shape === "square"}
              style={{
                borderColor: i === props.selectedIndex - props.startingIndex ? props.selectionColor : "transparent",
              }}
            />
          </View>
        </Pressable>
      )
    })}
  </Row>
)

export const SelectedAvatar = (props: { borderColor?: string; shape?: "circle" | "square"; url: string; loading: boolean }) => {
  const isWeb = Platform.OS === "web"
  const fixedPosHackForWeb = isWeb ? { position: "sticky", top: 10 } : {}
  const theme = useTheme()

  return (
    <Avatar
      size={120}
      source={{ uri: props.url }}
      unround={props.shape === "square"}
      style={{
        marginRight: 24,
        opacity: props.loading ? 0.3 : 1,
        borderColor: props.borderColor || theme.player,
        ...fixedPosHackForWeb,
      }}
    />
  )
}
