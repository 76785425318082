/* eslint-disable relay/unused-fields */
import { Screen } from "$components/DesignSystem"
import { ErrorBoundaryWithRetry } from "$components/ErrorBoundaryWithRetry"
import { DynamicThemeChanger } from "$components/app/DynamicThemeChanger"
import { NakamaSingleSessionProvider } from "$components/app/NakamaSingleSessionBlocker"
import { AuthModal } from "$components/auth/AuthModal"
import { OverlayContextProvider } from "$components/socialOverlay/OverlayContext"
import { SocialOverlay } from "$components/socialOverlay/SocialOverlay"
import { DayInfoContextProvider } from "$components/today/DayInfoContext"
import { PuzzmoCurrentUserFragment$key } from "$relay/PuzzmoCurrentUserFragment.graphql"
import { PuzzmoCurrentUserStateFragment$key } from "$relay/PuzzmoCurrentUserStateFragment.graphql"
import { PuzzmoFragment$key } from "$relay/PuzzmoFragment.graphql"
import { PuzzmoQuery, PuzzmoQuery$data } from "$relay/PuzzmoQuery.graphql"
import { PortalProvider } from "@gorhom/portal"
import { StatusBar } from "expo-status-bar"
import React, { Suspense, useCallback, useContext, useEffect, useRef, useState } from "react"
import { View, Platform, ColorSchemeName } from "react-native"
import { useFragment, useLazyLoadQuery } from "react-relay"
import { graphql, RecordSource, Store } from "relay-runtime"
import * as Sentry from "sentry-expo"
import { ThemeProvider } from "styled-rn"

import { CoreContext, CurrentUser } from "./CoreContext"
import { NakamaSocketProvider } from "./components/app/NakamaSocketProvider"
import { themeForApp } from "./constants/theme"
import useColorScheme from "./hooks/useColorScheme"
import useSetupAsync, { AppLaunchContextType } from "./hooks/useSetupAsync"
import { PlayGameStateProvider } from "./hooks/useSetupPlayGameContext"
import { RelayProvider } from "./lib/RelayProvider"
import { useWebCSSInjector } from "./lib/WebCSSInjector"
import { createPuzmoClient, PuzmoClient } from "./lib/apiClient"
import { Environment } from "./lib/environment"
import Navigation from "./navigation"
import { CriticalFailureScreen } from "./screens/CriticalFailureScreen"
import { SocialDebugInfo } from "./screens/Social/SocialDebugInfo"
import { AppContext } from "../AppContext"

export const RootAppQuery = graphql`
  query PuzzmoQuery($partnerSlug: ID) {
    ...PuzzmoFragment

    publishingPartner(id: $partnerSlug) {
      id
      slug
      name
      logoLongBlack
      logoLongWhite
    }
  }
`

const AppFragment = graphql`
  fragment PuzzmoFragment on Query {
    currentUser {
      ...PuzzmoCurrentUserFragment
    }

    userState {
      ...PuzzmoCurrentUserStateFragment
    }
  }
`

// NOTE: This fragment needs to be fully included in the
//       query PlayGameScreenQuery - because that query
//       replaces object which is set by this - why?

const CurrentUserStateFragment = graphql`
  fragment PuzzmoCurrentUserStateFragment on UserState {
    id
    ownerID
    nakamaLogin
    nakamaPassword
    gameSettings
  }
`

const CurrentUserFragment = graphql`
  fragment PuzzmoCurrentUserFragment on User {
    id
    type
    accounts {
      id
      email
      users {
        id
        avatarURL
        name
        username
        usernameID
      }
    }
    roles
    username
    usernameID
    avatarURL
    name
    nakamaID
    notificationsCursor
    settings
    profilePrivacy
    friendNakamaIDs
    hasMessages {
      friends
      groups
    }
  }
`

export type AppContextType = {
  apiClient: PuzmoClient
  environment: Environment
  showDebugUI: boolean
  showOpenInDev: boolean
  showDebugSocialSidebar: boolean
  reTriggerUserInfo: () => void
  launchInfo: AppLaunchContextType
}

export function Puzzmo() {
  const [appRenderID, setAppRenderID] = useState(0)
  const reTriggerUserInfo = useCallback(() => {
    console.log("Re-rendering whole app")
    setAppRenderID((old) => old + 1)
  }, [setAppRenderID])

  const store = useRef(new Store(new RecordSource()))
  const apiClient = useRef(createPuzmoClient(reTriggerUserInfo))

  const [isLoadingComplete, setupAPIClient, launchInfo] = useSetupAsync(apiClient.current)

  const colorScheme = useColorScheme()
  const themeForErrorMessagesAndSetup = themeForApp(colorScheme, undefined)

  if (!isLoadingComplete || !setupAPIClient.isSetup()) {
    return (
      <Screen>
        <ThemeProvider theme={themeForErrorMessagesAndSetup}>{/* <MainSiteLoaderSmiley /> */}</ThemeProvider>
      </Screen>
    )
  } else {
    const showDebugUI = Platform.OS === "web" && localStorage.getItem("showDebugUI") === "true"
    const showOpenInDev = Platform.OS === "web" && localStorage.getItem("openInDev") === "true"
    const showSocialSidebar = Platform.OS === "web" && localStorage.getItem("showSocialSidebar") === "true"

    return (
      <AppContext.Provider
        value={{
          apiClient: setupAPIClient,
          environment: setupAPIClient.environment,
          showDebugUI,
          reTriggerUserInfo,
          launchInfo,
          showOpenInDev,
          showDebugSocialSidebar: showSocialSidebar,
        }}
      >
        <ThemeProvider theme={themeForErrorMessagesAndSetup}>
          <ErrorBoundaryWithRetry
            fallback={(error: Error, retry: () => void) => (
              <CriticalFailureScreen errorMessage={error.message} stack={error.stack} retry={retry} />
            )}
          >
            <RelayProvider store={store.current} client={apiClient.current}>
              <Suspense fallback={<Screen />}>
                <UserSetup userInfoFetchKey={appRenderID} partnerSlug={launchInfo.partnerSlug} />
              </Suspense>
            </RelayProvider>
          </ErrorBoundaryWithRetry>
        </ThemeProvider>
      </AppContext.Provider>
    )
  }
}

const UserSetup = (props: { userInfoFetchKey: number; partnerSlug: string | null }) => {
  const rootQuery = useLazyLoadQuery<PuzzmoQuery>(
    RootAppQuery,
    { partnerSlug: props.partnerSlug },
    { fetchPolicy: "store-and-network", fetchKey: props.userInfoFetchKey }
  )

  return <AppCore rootQuery={rootQuery} />
}

const AppCore = (props: { rootQuery: PuzzmoQuery$data }) => {
  // The useFragment API is oriented to a single file for the magic inference, but our needs
  // here are complex (so we can have mutations targeting specific parts to reload), so they
  // have to be typed manually.

  const data = useFragment<PuzzmoFragment$key>(AppFragment, props.rootQuery)
  const currentUser = useFragment<PuzzmoCurrentUserFragment$key>(CurrentUserFragment, data.currentUser)
  const userState = useFragment<PuzzmoCurrentUserStateFragment$key>(CurrentUserStateFragment, data.userState)

  const colorScheme = useColorScheme()
  const theme = themeForApp(colorScheme, currentUser as any)

  useWebCSSInjector()

  useEffect(() => {
    // For quick launch, you can see the other side of this in RelayProvider
    if (Platform.OS === "web") {
      localStorage.setItem("puzmoUserModel", JSON.stringify({ userState, currentUser }))
    }
  }, [data, currentUser, userState])

  useEffect(() => {
    Sentry.React.setUser({
      // @ts-ignore - both of these are unknowns
      id: currentUser ? currentUser.id : userState.id,
    })
  }, [data, currentUser, userState])

  const getUser = useCallback(() => {
    return { userState, currentUser, type: currentUser ? "user" : "anon" } as any as CurrentUser
  }, [currentUser, userState])

  return (
    <CoreContext.Provider value={{ getUser, partner: props.rootQuery.publishingPartner }}>
      <OverlayContextProvider>
        <DayInfoContextProvider>
          <ThemeProvider theme={theme}>
            <DynamicThemeChanger>
              <NakamaSingleSessionProvider>
                <NakamaSocketProvider>
                  <PlayGameStateProvider>
                    <PortalProvider>
                      <Content colorScheme={colorScheme} />
                    </PortalProvider>
                  </PlayGameStateProvider>
                </NakamaSocketProvider>
              </NakamaSingleSessionProvider>
            </DynamicThemeChanger>
          </ThemeProvider>
        </DayInfoContextProvider>
      </OverlayContextProvider>
    </CoreContext.Provider>
  )
}

const Content = React.memo((props: { colorScheme: ColorSchemeName }) => {
  const { showDebugSocialSidebar } = useContext(AppContext)

  return (
    <AuthModal>
      <View style={{ flex: 1, flexDirection: "row" }}>
        <Navigation colorScheme={props.colorScheme} />
        <SocialOverlay />

        {showDebugSocialSidebar && <SocialDebugInfo />}
      </View>
      <StatusBar />
    </AuthModal>
  )
})
