import { UserDisplayPopoverContent$key, UserRelationship } from "$relay/UserDisplayPopoverContent.graphql"
import { UserDisplayPopoverContentQuery } from "$relay/UserDisplayPopoverContentQuery.graphql"
import React from "react"
import { View } from "react-native"
import { graphql, useFragment, useLazyLoadQuery } from "react-relay"
import { styled, useTheme } from "styled-rn"

import { UserAvatar } from "./UserAvatar"
import { allStats, UserStatsSubSection } from "./UserProfileStatsChooser"
import { useGetUser } from "../../CoreContext"
import { blendColors } from "../../lib/blendColors"
import { BASE_RADIUS, BASE_MARGIN, UiH3, BASE_PADDING, BodyText } from "../DesignSystem"
import { Popover } from "../popovers/Popovers"

// Get details for the user
const Query = graphql`
  query UserDisplayPopoverContentQuery($userIDOrUsername: ID!, $usernameID: String, $myUserStateID: String!) {
    user(id: $userIDOrUsername, usernameID: $usernameID) {
      ...UserDisplayPopoverContent
    }
  }
`

const Fragment = graphql`
  fragment UserDisplayPopoverContent on User {
    id
    fullAggregateStats
    relationshipToViewer(viewerID: $myUserStateID)
    username
    usernameID
    name
    publicProfile {
      id
      highlightedStatIDs
    }
    ...UserAvatar
  }
`

type PopoverContentProps = {
  customLabel?: string
  id?: string
  username?: string
  usernameID?: string
}

export const UserDisplayPopoverContent = React.memo((props: PopoverContentProps) => {
  const userIDOrUsername = props.id || props.username
  const currentUser = useGetUser()
  if (!userIDOrUsername) throw new Error(`UserDisplayPopoverContent requires either an id or username, got ${JSON.stringify(props)}`)

  const { user } = useLazyLoadQuery<UserDisplayPopoverContentQuery>(Query, {
    userIDOrUsername,
    usernameID: props.usernameID,
    myUserStateID: currentUser.userState.id,
  })
  if (!user) return null
  return <UserDisplayPopoverContentRender user={user} customLabel={props.customLabel} />
})

export function UserDisplayPopoverContentRender(props: {
  user: UserDisplayPopoverContent$key
  highlightStatsOverride?: string[]
  aggregateStatsOverride?: any
  customLabel?: string
}) {
  const user = useFragment(Fragment, props.user)
  const theme = useTheme()

  const highlightedStats = props.highlightStatsOverride || user?.publicProfile?.highlightedStatIDs
  const stats = allStats((props.aggregateStatsOverride || user?.fullAggregateStats) ?? {})

  const noConnection = user?.relationshipToViewer === "NoConnection"
  type StatInfo = UserStatsSubSection[number] & { icon: (props: { bg: string; fg: string }) => JSX.Element; title: string }
  const statInfos = (highlightedStats || [])
    .map((statID) => {
      const [game, idx] = statID.split("#")
      const index = parseInt(idx, 10)
      if (!(game in stats)) return null

      // Fake the types, as they all have the same shape anyway
      const statHost = stats[game as "site"] as (typeof stats)["site"]
      const stat = statHost.items.find((i) => index === i.id)

      if (!stat) return null
      return { ...stat, title: statHost.title, icon: statHost.icon }
    })
    .filter(Boolean) as StatInfo[]

  return (
    <View
      style={{
        shadowColor: theme.alwaysDark,
        shadowOffset: {
          width: 0,
          height: 3,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        overflow: "hidden",
        borderRadius: BASE_RADIUS,
        minWidth: 280,
      }}
    >
      {statInfos.length > 0 && (
        <TooltipContainer
          style={{
            paddingBottom: BASE_PADDING * 1.5,
          }}
        >
          <View>
            {statInfos.map((stat, i) => {
              return (
                <View
                  key={i}
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: i !== 0 ? BASE_MARGIN : 0,
                  }}
                >
                  <stat.icon bg={"transparent"} fg={theme.alwaysDark} />
                  <View style={{ flexShrink: 1, marginLeft: BASE_MARGIN }}>
                    <BodyText style={{ marginBottom: -BASE_MARGIN * 0.33, opacity: 0.5 }} customColorKey="alwaysDark" size="small">
                      {stat.title}
                    </BodyText>
                    <BodyText customColorKey="alwaysDark">{stat ? stat.msg : "Not available"}</BodyText>
                  </View>
                </View>
              )
            })}
          </View>
        </TooltipContainer>
      )}
      <TooltipFooter
        style={{
          paddingVertical: noConnection ? BASE_PADDING * 2 : BASE_PADDING,
          justifyContent: "center",
        }}
      >
        <UiH3 customColorKey="alwaysLight">{user?.relationshipToViewer === "Friends" ? user?.name || user?.username : user?.username}</UiH3>
        {!props.customLabel && !noConnection && (
          <BodyText customColorKey="alwaysLight" style={{ opacity: 0.5 }}>
            {userRelationshipToString(user.relationshipToViewer)}
          </BodyText>
        )}

        {props.customLabel && (
          <BodyText customColorKey="alwaysLight" style={{ opacity: 0.5 }}>
            {props.customLabel}
          </BodyText>
        )}
      </TooltipFooter>
      {user && "__fragments" in user && (
        <View style={{ position: "absolute", right: -BASE_MARGIN * 0.5, bottom: -BASE_MARGIN * 0.5 }}>
          <UserAvatar user={user} size={96} noLink={true} />
        </View>
      )}
    </View>
  )
}

export function UserDisplayPopover(props: React.PropsWithChildren<PopoverContentProps>) {
  return (
    <Popover render={() => <UserDisplayPopoverContent {...props} />}>
      <View style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>{props.children}</View>
    </Popover>
  )
}

export const TooltipFooter = styled.View(({ theme }) => ({
  backgroundColor: blendColors(theme.alwaysDark, theme.a_puzmo, 0.4),
  paddingHorizontal: BASE_PADDING * 1.5,
  paddingVertical: BASE_PADDING,
  alignContent: "center",
}))

export const TooltipContainer = styled.View(({ theme }) => ({
  backgroundColor: theme.a_puzmo,
  padding: BASE_PADDING,
}))

export const userRelationshipToString = (relationship: UserRelationship) => {
  switch (relationship) {
    case "Friends":
      return "Friends"
    case "NoConnection":
      return "No Connection"
    case "UserHasBlockedViewer":
      return "You are blocked"
    case "UserHasSentFriendRequest":
      return "Received Friend Request"
    case "ViewerHasSentFriendRequest":
      return "Friend Request Received"
    case "Self":
      return "It's you!"
  }
}
