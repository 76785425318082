import * as SecureStore from "expo-secure-store"
import { Platform } from "react-native"

interface Storage {
  get: (item: string) => Promise<string | null>
  set: (item: string, value: string) => Promise<void>
  rm: (item: string) => Promise<void>
}

export const createStorage = (): Storage => {
  // its enough for now
  if (Platform.OS === "web") {
    return localStore()
  } else {
    return secureStore()
  }
}

export const secureStore: () => Storage = () => ({
  get: SecureStore.getItemAsync,
  set: SecureStore.setItemAsync,
  rm: SecureStore.deleteItemAsync,
})

export const localStore: () => Storage = () => ({
  get: (key) => Promise.resolve(localStorage.getItem(key)),
  set: (key, value) => Promise.resolve(localStorage.setItem(key, value)),
  rm: (key) => Promise.resolve(localStorage.removeItem(key)),
})
