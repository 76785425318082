import React from "react"
import { Modal, View, Pressable, ScrollView } from "react-native"
import { ThemeProvider, useTheme } from "styled-rn"

import { BASE_MARGIN, CloseIcon, ModalFrame, SquareButton } from "./DesignSystem"
import { useIsMobile } from "../hooks/useIsMobile"

import { themes } from "$consts/themes"

export const ModalDisplay = (props: {
  above?: React.ReactChild
  top?: React.ReactChild
  bottom?: React.ReactChild
  modal: React.ReactChild
  frameStyle?: any
  close: () => void
  show: boolean
  showCloseButton?: boolean
  opaque?: boolean
  animationType?: "none" | "slide" | "fade"
  unframed?: true
  preferHorizontalEdgeToEdge?: boolean
}) => {
  const theme = useTheme()
  const isMobile = useIsMobile()

  let showCloseButton = props.showCloseButton
  if (showCloseButton === undefined) showCloseButton = true

  // Note: It is impossible to click through behind modals. If we want to do this, we need to
  // drop the modal component and write our own. This is because react-native uses UIModalPresentationStyle
  // which does not allow for this.
  // https://github.com/react-native-modal/react-native-modal/issues/118
  return (
    <Modal animationType={props.animationType || "none"} transparent={true} visible={props.show} onRequestClose={props.close}>
      <Pressable
        onPress={props.close}
        pointerEvents="box-only"
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: props.unframed ? "transparent" : theme.a_bg + (props.opaque ? "" : "60"),
        }}
        focusable={false}
        accessibilityHint="Close modal"
        accessibilityRole="none"
      />
      {props.unframed ? (
        props.modal
      ) : (
        <View
          style={{
            flex: 1,
            alignItems: "center",
            marginTop: isMobile ? BASE_MARGIN * 2 : BASE_MARGIN * 5.5,
          }}
          pointerEvents="box-none"
        >
          {props.above ? props.above : null}
          <ModalFrame style={{ width: props.preferHorizontalEdgeToEdge ? "calc(100% - 24px)" : "calc(100% - 60px)", ...props.frameStyle }}>
            {showCloseButton && (
              <SquareButton
                onPress={props.close}
                style={{ zIndex: 10000, position: "absolute", top: 12, right: 12 }}
                accessibilityLabel="Close modal"
                accessibilityHint="Removes the modal overlay from your screen"
              >
                <CloseIcon size={15} colorKey="alwaysLight" />
              </SquareButton>
            )}

            <ThemeProvider theme={themes[1]}>
              {props.top ? props.top : null}
              <ScrollView>{props.modal}</ScrollView>
              {props.bottom ? props.bottom : null}
            </ThemeProvider>
          </ModalFrame>
        </View>
      )}
    </Modal>
  )
}
