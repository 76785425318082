/**
 * @generated SignedSource<<63a6aba0b3f7a1f38fb9acbf8904e3de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useBootstrapNakamaDataFriendRequestsQuery$variables = {};
export type useBootstrapNakamaDataFriendRequestsQuery$data = {
  readonly currentUser: {
    readonly incomingRequests: {
      readonly friends: ReadonlyArray<{
        readonly user: {
          readonly puzmoUser: {
            readonly id: string;
          } | null;
          readonly userId: string;
          readonly username: string;
          readonly " $fragmentSpreads": FragmentRefs<"FriendRequestView">;
        };
      }> | null;
    } | null;
  };
};
export type useBootstrapNakamaDataFriendRequestsQuery = {
  response: useBootstrapNakamaDataFriendRequestsQuery$data;
  variables: useBootstrapNakamaDataFriendRequestsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "state",
    "value": 2
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useBootstrapNakamaDataFriendRequestsQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "currentUser",
          "plural": false,
          "selections": [
            {
              "alias": "incomingRequests",
              "args": (v0/*: any*/),
              "concreteType": "NakamaFriendsList",
              "kind": "LinkedField",
              "name": "nFriends",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "NakamaUserState",
                  "kind": "LinkedField",
                  "name": "friends",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "NakamaUser",
                      "kind": "LinkedField",
                      "name": "user",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v2/*: any*/),
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "FriendRequestView"
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "User",
                          "kind": "LinkedField",
                          "name": "puzmoUser",
                          "plural": false,
                          "selections": [
                            (v3/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "nFriends(state:2)"
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "currentUser"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useBootstrapNakamaDataFriendRequestsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": "incomingRequests",
            "args": (v0/*: any*/),
            "concreteType": "NakamaFriendsList",
            "kind": "LinkedField",
            "name": "nFriends",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NakamaUserState",
                "kind": "LinkedField",
                "name": "friends",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NakamaUser",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "puzmoUser",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "usernameID",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "nakamaID",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "avatarURL",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "nFriends(state:2)"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a7e69e595b7664ae77fe93b5bccc01cf",
    "id": null,
    "metadata": {},
    "name": "useBootstrapNakamaDataFriendRequestsQuery",
    "operationKind": "query",
    "text": "query useBootstrapNakamaDataFriendRequestsQuery {\n  currentUser {\n    incomingRequests: nFriends(state: 2) {\n      friends {\n        user {\n          username\n          userId\n          ...FriendRequestView\n          puzmoUser {\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment FriendRequestView on NakamaUser {\n  userId\n  puzmoUser {\n    ...FriendRequestViewUser\n    id\n  }\n}\n\nfragment FriendRequestViewUser on User {\n  id\n  username\n  usernameID\n  nakamaID\n  ...UserDisplay\n}\n\nfragment UserDisplay on User {\n  id\n  username\n  usernameID\n  avatarURL\n}\n"
  }
};
})();

(node as any).hash = "5b815f1c443b93f1d1dfc6ea6d4d202a";

export default node;
