import React from "react"
import { View } from "react-native"
import { useTheme } from "styled-rn"

import { BodyText } from "../DesignSystem"

// Speech bubble drawn with views.
export const SpeechBubbleIcon = (props: { number: number; fontSize?: number }) => {
  const theme = useTheme()
  const fontSize = props.fontSize || 15
  return (
    <View style={{ position: "relative", marginRight: 6 }}>
      <View
        style={{
          position: "absolute",
          width: 0,
          bottom: -2,
          right: -3,
          height: 0,
          borderTopWidth: 6,
          borderRightWidth: 0,
          borderBottomWidth: 6,
          borderLeftWidth: 12,
          transform: [{ rotate: "45deg" }],

          borderTopColor: "transparent",
          borderRightColor: "transparent",
          borderBottomColor: "transparent",
          borderLeftColor: theme.alwaysLight,
        }}
      />

      <View
        style={{
          paddingHorizontal: 4,
          paddingTop: 5,
          paddingBottom: 3,
          minWidth: fontSize + 9,
          backgroundColor: theme.alwaysLight,
          borderRadius: 100,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <BodyText bold style={{ color: theme.alwaysDark, lineHeight: fontSize, fontSize: fontSize || undefined }}>
          {props.number}
        </BodyText>
      </View>
    </View>
  )
}
