import { ViewStyle } from "react-native"

import { Avatar } from "../DesignSystem"
import { UserAvatar_RAW } from "../profiles/UserAvatar"
import { UserDisplayPopover } from "../profiles/UserDisplayPopover"

import { Collaborator } from "$consts/hostAPI"
import { PopoverTooltip } from "../popovers/PopoverTooltip"

function getUsername(collab: Collaborator) {
  if (collab.type === "loading") return "Loading info"
  return `${collab.display}${collab.access === "host" ? " (host)" : ""}${collab.isSelf ? " (you)" : ""}`
}

export const CollabAvatar = (props: { collab: Collaborator; size?: number; style?: ViewStyle }) => {
  const { collab, style } = props

  if (collab.type === "user") {
    return (
      <UserDisplayPopover
        username={collab.username}
        customLabel={collab.access === "host" ? "Host" : undefined}
        usernameID={collab.usernameID}
      >
        <UserAvatar_RAW size={props.size} user={collab} style={{ borderColor: collab.color, ...style }} />
      </UserDisplayPopover>
    )
  }

  const tooltipText = getUsername(collab)

  if (collab.type === "guest")
    return (
      <PopoverTooltip text={tooltipText}>
        <Avatar
          size={props.size}
          style={{
            borderColor: collab.color,
            ...style,
          }}
          source={require("../../../assets/images/guest-smile.png")}
        />
      </PopoverTooltip>
    )

  // Left this separate, I think we want something more nuanced than just transparency?
  if (collab.type === "loading")
    return (
      <PopoverTooltip text={tooltipText}>
        <Avatar
          size={props.size}
          style={{
            borderColor: collab.color,
            ...style,
          }}
          source={require("../../../assets/images/guest-smile.png")}
        />
      </PopoverTooltip>
    )

  throw new Error("Unknown collab type: " + collab)
}
