/**
 * @generated SignedSource<<fd639b2c60b6d475bb05a32ac3bdbb59>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SubscriptionTimePeriod = "Annual" | "Monthly" | "%future added value";
export type StripeButtonsCreateSubscriptionMutation$variables = {
  accountSlug: string;
  failURL: string;
  successURL: string;
  type?: SubscriptionTimePeriod | null;
};
export type StripeButtonsCreateSubscriptionMutation$data = {
  readonly createStripeSubscriptionLink: string | null;
};
export type StripeButtonsCreateSubscriptionMutation = {
  response: StripeButtonsCreateSubscriptionMutation$data;
  variables: StripeButtonsCreateSubscriptionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountSlug"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "failURL"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "successURL"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "accountSlug",
        "variableName": "accountSlug"
      },
      {
        "kind": "Variable",
        "name": "failURL",
        "variableName": "failURL"
      },
      {
        "kind": "Variable",
        "name": "successURL",
        "variableName": "successURL"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "kind": "ScalarField",
    "name": "createStripeSubscriptionLink",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "StripeButtonsCreateSubscriptionMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "StripeButtonsCreateSubscriptionMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "142549099e20fb09ff12a4556b2b0cb3",
    "id": null,
    "metadata": {},
    "name": "StripeButtonsCreateSubscriptionMutation",
    "operationKind": "mutation",
    "text": "mutation StripeButtonsCreateSubscriptionMutation(\n  $successURL: String!\n  $failURL: String!\n  $accountSlug: String!\n  $type: SubscriptionTimePeriod\n) {\n  createStripeSubscriptionLink(successURL: $successURL, failURL: $failURL, accountSlug: $accountSlug, type: $type)\n}\n"
  }
};
})();

(node as any).hash = "70e7f9c01f6dc2fc85d865ba7bfe6f18";

export default node;
