import { ViewStyle } from "react-native"
import { useContextSelector } from "use-context-selector"

import { InternalLink } from "./DesignSystem"
import { DayInfoContext } from "./today/DayInfoContext"
import { getPlayerReadableDateString } from "../lib/getPlayerReadableDateString"

// This is a link that will navigate to today using the correct date parameters.
export function TodayLink(props: { style?: ViewStyle; renderDisplay: (dateString: string) => React.ReactNode }) {
  const dayState = useContextSelector(DayInfoContext, (c) => c.dayState)
  const dateString =
    !dayState || dayState?.isToday
      ? "Today"
      : getPlayerReadableDateString(dayState.day, {
          includeWeekday: false,
        })

  return (
    <InternalLink
      screen="Today"
      style={props.style || {}}
      routeOpts={
        !dayState || dayState?.isToday
          ? undefined
          : {
              day: dayState?.day?.slice(0, 10),
            }
      }
    >
      {props.renderDisplay(dateString)}
    </InternalLink>
  )
}
