import { PuzmoButton, Avatar, BodyText, ModalForm, BASE_MARGIN, Row } from "$components/DesignSystem"
import { ModalInviteAcceptInviteMutation } from "$relay/ModalInviteAcceptInviteMutation.graphql"
import React, { useCallback, useContext, useState } from "react"
import { Animated, View, Pressable, NativeSyntheticEvent, ScrollView, TextInputKeyPressEventData } from "react-native"
import { useMutation } from "react-relay"

import { AuthModes, AuthModalContext } from "./AuthModal"
import { LoginSignupToggle } from "./LoginSignupToggle"
import { MainAuthForm } from "./MainAuthForm"
import { ModalInviteAcceptInvite } from "./ModalInvite"
import { ModalMascot } from "./ModalMascot"
import { useTransitionInAndOut } from "./useTransitionInAndOut"
import { AppContext } from "../../../AppContext"
import { useGetUser } from "../../CoreContext"
import { useIsMobile } from "../../hooks/useIsMobile"
import { slugify } from "../../lib/slugify"
import { BackArrow } from "../icons/BackArrow"
import { BtnSubscribe } from "../icons/BtnSubscribe"
import { useStripeSignupActions } from "../profiles/StripeButtons"

import { themes } from "$consts/themes"

export function SignupForm(props: { transitionOut: boolean; transitionOnEnter: boolean; updateViewMode: (mode: AuthModes) => void }) {
  const mobile = useIsMobile()
  const { updateViewMode } = props
  const [email, _setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [submitted, setSubmitted] = useState(false)

  const setEmail = useCallback((email: string) => {
    _setEmail(email.trim().replace(/\s/g, "").toLowerCase())
  }, [])

  const onSubmit = useCallback(() => {
    setSubmitted(true)
  }, [])

  const goBack = useCallback(() => {
    setSubmitted(false)
    setEmail("")
    setPassword("")
  }, [setEmail])

  const { opacity, translate } = useTransitionInAndOut({
    transitionOnEnter: props.transitionOnEnter,
    transitionOut: props.transitionOut,
    side: "right",
  })

  if (submitted) {
    return <CustomizeAfterSignup goBack={goBack} email={email} password={password} />
  }

  return (
    <>
      <LoginSignupToggle updateViewMode={updateViewMode} active="signup" />

      <Animated.View style={{ opacity, transform: [{ translateX: translate }], flexDirection: mobile ? "column-reverse" : "row" }}>
        <View style={{ flex: mobile ? undefined : 5 }}>
          <MainAuthForm
            placeholders={{
              email: "Your email address",
              password: "Minimum 5 characters",
            }}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            actionButtonText={"Customize your profile!"}
            onSubmitLogin={onSubmit}
            loading={false}
            errorMessage={""}
            isCreate
          />
        </View>
        <ModalMascot screen="signup" publicText={email} privateText={password} />
      </Animated.View>
    </>
  )
}

export function CustomizeAfterSignup(props: { email: string; password: string; goBack: () => void }) {
  const { email, password } = props
  const theme = themes[0]
  const mobile = useIsMobile()
  const { launchInfo } = useContext(AppContext)

  const [acceptInvite] = useMutation<ModalInviteAcceptInviteMutation>(ModalInviteAcceptInvite)

  const [username, _setUsername] = useState<string | undefined>(undefined)
  const [changedUsername, setChangedUsername] = useState(false)

  const setUsername = useCallback((username: string) => {
    _setUsername(slugify(username))
    setChangedUsername(true)
  }, [])

  const [displayName, setDisplayName] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [avatarIndex, setAvatarIndex] = useState(Math.round(Math.random() * 4) + 1)
  const modalCtx = useContext(AuthModalContext)

  const hasSelectedASubscribeOnlyAvatar = avatarIndex > 5

  const errored = !!errorMessage
  const ctx = useContext(AppContext)

  const canSubmit =
    displayName?.length > 0 &&
    email?.length > 0 &&
    !email.includes(" ") &&
    password?.length > 0 &&
    typeof avatarIndex === "number" &&
    !(changedUsername && !username)

  const RowOrView = mobile ? View : Row

  const user = useGetUser()
  const [startNewSub] = useStripeSignupActions(user.currentUser?.accounts[0].id, {})

  const onSubmitCreateUser = async (_evt: object, thenSignup?: true) => {
    setErrorMessage("")
    setLoading(true)
    try {
      const res = await ctx.apiClient.signup({
        email,
        username: username || slugify(displayName),
        password,
        displayName,
        avatarIndex,
        partnerSlug: launchInfo.partnerSlug || undefined,
      })

      if (ctx.apiClient.authed()) {
        if (launchInfo?.inviteToken) {
          acceptInvite({
            variables: { id: launchInfo.inviteToken },
            onCompleted: (data) => {
              if (data.acceptInvite.failed) {
                setErrorMessage(
                  `An unexpected error occurred with your invite, please contact support and mention invite '${launchInfo.inviteToken}'.`
                )
              } else {
                if (thenSignup) {
                  startNewSub({}, res!.data.accountID)
                } else {
                  modalCtx.closeModal()
                }
              }
            },
          })
        } else {
          if (thenSignup) {
            startNewSub({}, res!.data.accountID)
          } else {
            modalCtx.closeModal()
          }
        }
      } else {
        console.error({ res })

        setErrorMessage("Unknown error occurred, please contact support or try again.")
        setLoading(false)
      }
    } catch (error: any) {
      console.log("error", error)
      setLoading(false)
      setErrorMessage(error.message)
    }
  }

  const onSubmitThenCreate = () => onSubmitCreateUser({}, true)

  const onEnterSubmit = (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    if (e.nativeEvent.key === "Enter") {
      onSubmitCreateUser({})
    }
  }

  return (
    <View>
      <Row>
        <ModalForm.Title style={{ display: "flex", alignItems: "center" }}>
          <Pressable
            style={{ marginRight: BASE_MARGIN }}
            accessibilityLabel="Go back to email/password"
            accessibilityHint="Go back"
            onPress={props.goBack}
          >
            <BackArrow width={18} fill="fg" />
          </Pressable>
          Customize
        </ModalForm.Title>
      </Row>
      <RowOrView>
        <View>
          <ModalForm.Subtitle>Display name</ModalForm.Subtitle>
          <ModalForm.TextInput
            key="displayName"
            value={displayName}
            onChangeText={setDisplayName}
            onKeyPress={onEnterSubmit}
            error={errored}
            textContentType="givenName"
            loading={loading}
            editable={!loading}
            autoFocus
          />

          <ModalForm.Subtitle>Username</ModalForm.Subtitle>
          <Row>
            <ModalForm.TextInput
              pointerEvents="none"
              error={errored}
              editable={false}
              value="@"
              style={{ width: 28, color: theme.player }}
              focusable={false}
              loading={loading}
            />
            <ModalForm.TextInput
              key="username"
              style={{
                width: "100%",
              }}
              value={changedUsername ? username : slugify(displayName)}
              onChangeText={(text) => setUsername(slugify(text))}
              onKeyPress={onEnterSubmit}
              error={errored}
              keyboardType="twitter"
              textContentType="username"
              loading={loading}
              editable={!loading}
            />
          </Row>
        </View>
        {!mobile && (
          <View style={{ flex: 1, justifyContent: "center", alignContent: "center", alignItems: "center" }}>
            <Avatar source={{ uri: ctx.environment.CDNPath(`avatars/${avatarIndex}.png`) }} size={140} />
          </View>
        )}
      </RowOrView>
      <ModalForm.Subtitle>Select Avatar</ModalForm.Subtitle>
      <ScrollView horizontal contentOffset={{ x: 0, y: 30 }}>
        <Row>
          {[...Array(15).keys()].map((i) => {
            const isSelected = avatarIndex === i + 1
            return (
              <Pressable
                key={i}
                onPress={() => setAvatarIndex(i + 1)}
                accessibilityRole="button"
                accessibilityHint={`Selects avatar ${i + 1} for your profile`}
              >
                <Avatar
                  source={{ uri: ctx.environment.CDNPath(`avatars/${1 + i}.png`) }}
                  size={44}
                  style={{
                    marginRight: BASE_MARGIN * 0.5,
                    marginBottom: BASE_MARGIN,
                    opacity: i > 4 ? 0.3 - (i - 5) * 0.03 : 1,
                    ...(isSelected ? {} : { borderColor: avatarIndex === i + 1 ? undefined : "transparent" }),
                  }}
                />
              </Pressable>
            )
          })}
        </Row>
      </ScrollView>

      {!!errorMessage && <BodyText style={{ color: theme.error, marginTop: 12 }}>{errorMessage}</BodyText>}

      <Row centerV centerH style={{ marginTop: BASE_MARGIN }}>
        <PuzmoButton
          block
          title="Sign up for free"
          bgKey="player"
          color="empty"
          onPress={onSubmitCreateUser}
          disabled={!canSubmit || loading || hasSelectedASubscribeOnlyAvatar}
          style={{ flexShrink: 1, flexGrow: 1 }}
        />

        <BodyText style={{ flexShrink: 0, paddingTop: 0, marginHorizontal: 12, color: "white" }}>or</BodyText>

        <PuzmoButton
          block
          iconComponent={<BtnSubscribe width={16} fill="alwaysDark" />}
          title="Subscribe"
          bgKey="a_puzmo"
          color="alwaysDark"
          onPress={onSubmitThenCreate}
          disabled={!canSubmit || loading}
          style={{ flexShrink: 1, flexGrow: 1 }}
          titleStyle={{ marginLeft: "auto", marginRight: "auto", paddingRight: 24 }}
        />
      </Row>
    </View>
  )
}
