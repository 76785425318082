import { BASE_MARGIN, BodyText, Box, Code } from "$components/DesignSystem"
import { ScrollView, View } from "react-native"
import { useTheme } from "styled-rn"
import { useContextSelector } from "use-context-selector"

import { useNakamaStateSelector } from "../../hooks/useNakama"
import { playGameContext } from "../../hooks/useSetupPlayGameContext"
import { useViewport } from "../../lib/useViewport"

export const SocialDebugInfo = () => {
  const _gameState = useContextSelector(playGameContext, (s) => s.gameUIState)
  const friendsData = useNakamaStateSelector((s) => s)

  const gameState = {
    ..._gameState,
    completedGames: [..._gameState.completedGames.values()],
  }

  const chat = [...(friendsData.friendChats || []).values()].map((f) => ({ ...f }))
  chat.forEach((f) => (f.sender = { ...f.sender, __fragmentOwner: "{ cut }" } as any))

  const nakama = {
    ...friendsData,
    onlineViaUsername: [...friendsData.onlineViaUsername.values()],
    onlineViaNakamaID: [...friendsData.onlineViaNakamaID.values()],
    invitesViaNakamaID: [...friendsData.invitesViaNakamaID.values()],
    chatHistory: [...friendsData.chatHistory.entries()],
    friendsChat: chat,
  }

  const theme = useTheme()
  const { vh } = useViewport()
  return (
    <View style={{ width: 380, maxHeight: vh(100) }}>
      <ScrollView>
        <Box>
          <BodyText bold style={{ marginBottom: BASE_MARGIN }}>
            Game State
          </BodyText>
          <Code style={{ color: theme.fg }}>{JSON.stringify(gameState, null, 2)}</Code>

          <BodyText bold style={{ marginBottom: BASE_MARGIN }}>
            Nakama Data
          </BodyText>
          <Code style={{ fontSize: 14, color: theme.fg }}>{JSON.stringify(nakama, null, 2)}</Code>
        </Box>
      </ScrollView>
    </View>
  )
}
