/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions, getPathFromState, getStateFromPath } from "@react-navigation/native"
import * as Linking from "expo-linking"
import { Platform } from "react-native"

import { RootStackParamList } from "../../types"
import { isTests } from "../lib/environment"

// NOTE: This technique is unlikely to work in native, but  we're not using native yet

const base = Platform.OS !== "web" ? undefined : getPartnerBase()

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [isTests ? "" : Linking.createURL("/"), "https://www.puzzmo.com", "https://staging.puzzmo.com"],
  config: {
    screens: {
      Today: {
        path: "/today/:day?",
      },
      TodayRecap: {
        path: "/today/:day/recap",
      },
      SignOut: {
        path: "signout",
      },
      Logout: {
        path: "logout",
      },
      Account: {
        path: "me",
      },
      VerifyEmail: {
        path: "me/verifyEmail",
      },
      ResetPassword: {
        path: "me/resetPassword",
      },
      UserSwitch: {
        path: "me/switch",
      },
      UserProfileSettings: {
        path: "me/settings",
      },

      Subscribe: {
        path: "/subscribe/:focus?",
      },
      SubscriptionSuccess: "/success",
      PlayGameRedirect: "/play/:gameSlug",
      // Allows urls like g/crossword/abcde/share
      PlayGame: "/play/:gameSlug/:playGameSlug/:subSection?",

      UserProfile: {
        path: "user/:usernameID/:username",
      },
      Group: "group/:groupSlugID/:groupSlug",
      GroupEdit: "group/:groupSlugID/:groupSlug/edit",

      ChatSelector: {
        path: "chat",
        screens: {
          Friends: "friends",
          Groups: "groups",
        },
      },
      Chat: "chat/:type/:chatSlug",

      Dev: "dev",
      AdminEditMarkdown: "admin/markdown/:markdownSlug",
      Modal: "modal",
      NotFound: "*",
    },
  },
  // We want to be able to let partners use their own base url
  // which is /+/:partner so we need to both strip that out to do normal routing
  // (and then re-add it after)

  // And under that we want to make sure any internal links that are generated
  // keep this base url.
  getStateFromPath: (path, options) => {
    if (path.startsWith("/+/") && base) {
      const skipFirstTwo = path.split("/").slice(3)
      const newPath = "/" + skipFirstTwo.join("/")
      const newState = getStateFromPath(newPath, options)
      const rootRoute = newState?.routes[0]
      if (rootRoute) {
        newState.routes[0] = {
          ...rootRoute,
          path: "/+/" + base + newPath,
        }
      }

      return newState
    } else {
      return getStateFromPath(path, options)
    }
  },
  getPathFromState(state, config) {
    const path = getPathFromState(state, config)
    if (base) {
      return `/+/${base}${path}`
    } else {
      return path
    }
  },
}

// Extracts the partner base from the document URL, maybe when
// we do native this is something we can get from Expo?
function getPartnerBase() {
  if (typeof document === "undefined") {
    return undefined
  }
  const paths = document.location.pathname.split("/")

  if (paths[1] !== "+") {
    return undefined
  }
  return paths[2]
}

export default linking
