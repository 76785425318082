import { BASE_MARGIN, Avatar, BodyText, Row } from "$components/DesignSystem"
import { UserSettingsAvatarSelector$key } from "$relay/UserSettingsAvatarSelector.graphql"
import React, { useCallback, useContext, useState } from "react"
import { Platform, View } from "react-native"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { useTheme } from "styled-rn"

import { Avatars, SelectedAvatar } from "./Avatars"
import { AppContext } from "../../../AppContext"
import { useSimpleUserEditMutation } from "../../mutations/useUserEditMutation"

const FREE_AVATARS = 5
const AVATAR_MAX = 61
export const allAvatarPaths = [...Array(AVATAR_MAX).keys()].slice(1).map((n) => `avatars/${n}.png?q=2`)

const Fragment = graphql`
  fragment UserSettingsAvatarSelector on User {
    id
    avatarURL
    type
  }
`

export const UserSettingsAvatarSelector = (props: { user: UserSettingsAvatarSelector$key }) => {
  const appTheme = useTheme()
  const { environment } = useContext(AppContext)
  const user = useFragment(Fragment, props.user)

  const canOnlyChooseFreeAvatars = user.type !== "Paid"

  const [freeIconURLs, paidIconsURLs] = [
    allAvatarPaths.slice(0, FREE_AVATARS).map(environment.CDNPath),
    allAvatarPaths.slice(FREE_AVATARS).map(environment.CDNPath),
  ]

  const avatarIndexStr = user.avatarURL.replace(environment.CDNPath("avatars/"), "").replace(".png", "").split("/").pop() || "0"
  const currentAvatarIndex = parseInt(avatarIndexStr || "", 10)

  const [avatarIndex, setAvatarIndex] = useState(currentAvatarIndex)

  const [updateUser, loading] = useSimpleUserEditMutation()
  const updateUserAvatar = useCallback(
    (index: number) => {
      setAvatarIndex(index)
      updateUser(user.id, { avatarIndex: index })
    },
    [user.id, updateUser]
  )

  return (
    <Row>
      <View style={{ flex: 1 }}>
        <BodyText>Available for everyone</BodyText>
        <Avatars
          avatarURLs={freeIconURLs}
          startingIndex={1}
          selectedIndex={avatarIndex}
          onSelect={updateUserAvatar}
          selectionColor={appTheme.player}
          disabled={false}
        />

        <BodyText style={{ marginTop: BASE_MARGIN }}>Subscribers Only</BodyText>
        <Avatars
          avatarURLs={paidIconsURLs}
          startingIndex={freeIconURLs.length + 1}
          selectedIndex={avatarIndex}
          onSelect={updateUserAvatar}
          selectionColor={appTheme.player}
          disabled={canOnlyChooseFreeAvatars}
        />
      </View>
    </Row>
  )
}
