import { PuzzmoCurrentUserFragment$data } from "$relay/PuzzmoCurrentUserFragment.graphql"
import { PuzzmoCurrentUserStateFragment$data } from "$relay/PuzzmoCurrentUserStateFragment.graphql"
import { createContext, useContext } from "react"

/** The core info about the current user or a guest user with a generated ID */
export type LoggedInUser = {
  type: "user"
  userState: PuzzmoCurrentUserStateFragment$data
  currentUser: NonNullable<PuzzmoCurrentUserFragment$data>
}
export type AnonUser = { type: "anon"; userState: PuzzmoCurrentUserStateFragment$data; currentUser: undefined }
export type CurrentUser = LoggedInUser | AnonUser

type CoreContextType = {
  getUser: () => CurrentUser

  partner: {
    id: string
    slug: string
    name: string
    logoLongBlack: string
    logoLongWhite: string
  } | null
}

export const CoreContext = createContext<CoreContextType>({} as any)
export const useGetUser = () => useContext(CoreContext).getUser()
export const useGetPartner = () => useContext(CoreContext).partner
