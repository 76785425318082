import { GamePlayUpdateMutationsGameCompleteMutation } from "$relay/GamePlayUpdateMutationsGameCompleteMutation.graphql"
import { GamePlayUpdateMutationsGameStateMutation } from "$relay/GamePlayUpdateMutationsGameStateMutation.graphql"
import { GamePlayUpdateMutationsGameStateSanUpdateMutation } from "$relay/GamePlayUpdateMutationsGameStateSanUpdateMutation.graphql"
import { GamePlayUpdateMutationsUpdateGameSettingsMutation } from "$relay/GamePlayUpdateMutationsUpdateGameSettingsMutation.graphql"
import { graphql, useMutation } from "react-relay"

export const UpdateGameState = graphql`
  mutation GamePlayUpdateMutationsGameStateMutation($id: ID!, $input: UpdateGamePlayedInput!) {
    updateGameState(id: $id, input: $input) {
      puzzle {
        ...CurrentUserPuzzleThumbnail
      }
      id
    }
  }
`

export const UpdateGameStateNoSideEffects = graphql`
  mutation GamePlayUpdateMutationsGameStateSanUpdateMutation($id: ID!, $input: UpdateGamePlayedInput!) {
    updateGameState(id: $id, input: $input) {
      id
      elapsedTimeSecs
    }
  }
`

export const CompleteGame = graphql`
  mutation GamePlayUpdateMutationsGameCompleteMutation($id: ID!, $input: CompleteGamePlayedInput!) {
    completeGameState(id: $id, input: $input) {
      puzzle {
        ...CurrentUserPuzzleThumbnail
      }
      id
    }
  }
`

export const UpdateGameSettings = graphql`
  mutation GamePlayUpdateMutationsUpdateGameSettingsMutation($input: UpdateUserGameSettingsInput!) {
    updateUserGameSettings(input: $input) {
      id
      ...PuzzmoCurrentUserStateFragment
      ...PlayGameSettingsSync
    }
  }
`

export type GameMutations = ReturnType<typeof useCreateGameplayMutations>
export const useCreateGameplayMutations = () => {
  const [updateGamePlay] = useMutation<GamePlayUpdateMutationsGameStateMutation>(UpdateGameState)
  const [timerUpdateGamePlay] = useMutation<GamePlayUpdateMutationsGameStateSanUpdateMutation>(UpdateGameStateNoSideEffects)

  const [updateUserGameSettings] = useMutation<GamePlayUpdateMutationsUpdateGameSettingsMutation>(UpdateGameSettings)
  const [completeGame] = useMutation<GamePlayUpdateMutationsGameCompleteMutation>(CompleteGame)

  return {
    updateGamePlay,
    updateUserGameSettings,
    completeGame,
    timerUpdateGamePlay,
  }
}
