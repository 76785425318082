import { NativeSyntheticEvent, TextInputKeyPressEventData, View } from "react-native"

import { BodyText, ModalForm, PuzmoButton } from "../DesignSystem"
import { CircledCheck } from "../icons/CircledCheck"

import { themes } from "$consts/themes"

// auth form used by login / signup
export function MainAuthForm(props: {
  loading: boolean
  errorMessage: string
  actionButtonText: string
  placeholders: {
    password: string
    email: string
  }
  onSubmitLogin: () => void
  email: string
  setEmail: (email: string) => void
  password: string
  setPassword: (password: string) => void
  // This is mainly used for accessbility in the form
  isCreate?: boolean
}) {
  const { errorMessage, loading, onSubmitLogin, email, password, setEmail, setPassword } = props
  const errored = !!props.errorMessage
  const theme = themes[0]

  const onEnterSubmit = (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    if (e.nativeEvent.key === "Enter") {
      onSubmitLogin()
    }
  }

  const passwordAutoComplete: any = props.isCreate ? "new-password" : "current-password"

  return (
    <View>
      <ModalForm.Subtitle>{email?.includes("#") ? "Username + ID" : "Email"}</ModalForm.Subtitle>
      <ModalForm.TextInput
        key="email"
        value={email}
        onChangeText={setEmail}
        keyboardType="email-address"
        onKeyPress={onEnterSubmit}
        error={errored}
        placeholder={props.placeholders.email}
        loading={loading}
        editable={!loading}
        autoComplete="email"
      />

      <ModalForm.Subtitle>Password</ModalForm.Subtitle>
      <ModalForm.TextInput
        key="password"
        last={true}
        value={password}
        secureTextEntry
        onChangeText={setPassword}
        error={errored}
        placeholder={props.placeholders.password}
        loading={loading}
        editable={!loading}
        autoComplete={passwordAutoComplete}
      />

      {errorMessage ? <BodyText style={{ color: theme.error, marginTop: 12 }}>{errorMessage}</BodyText> : null}

      <PuzmoButton
        block
        title={errored ? "Try again?" : props.actionButtonText}
        onPress={onSubmitLogin}
        iconComponent={<CircledCheck width={15} fill="alwaysDark" />}
        bgKey="a_puzmo"
        color="alwaysDark"
        style={{ marginTop: errored ? 12 : 24 }}
        disabled={!(email.includes("@") && email.length > 2 && password.length > 4) || loading}
      />
    </View>
  )
}
