import { InitialState } from "@react-navigation/native"
import React from "react"
import { useContextSelector } from "use-context-selector"

import { OverlayContext } from "./OverlayContext"
import { useIsMobile } from "../../hooks/useIsMobile"
import { ChatNav } from "../../navigation/SocialNav"
import { ShadowView } from "../DesignSystem"

export const SOCIAL_OVERLAY_WIDTH = 320
export function SocialOverlay() {
  const isMobile = useIsMobile()
  const isOpen = useContextSelector(OverlayContext, (c) => c.socialOverlay)

  // All the use state stuff needs to happen before this return
  if (isMobile || !isOpen) return null

  const initialState: InitialState = {
    index: 0,
    routes: [
      {
        name: "ChatSelector",
        params: {},
      },
    ],
  }

  return (
    <ShadowView
      style={{
        width: SOCIAL_OVERLAY_WIDTH,
        height: "100%",
      }}
    >
      <ChatNav initialState={initialState} />
    </ShadowView>
  )
}
