// based on https://stackoverflow.com/questions/37096367/how-to-convert-seconds-to-minutes-and-hours-in-javascript

export function secondsToHms(d: number) {
  const h = Math.floor(d / 3600)
  const m = Math.floor((d % 3600) / 60)
  const s = Math.floor((d % 3600) % 60)

  const hDisplay = h > 0 ? h + "h" : ""
  const mDisplay = m > 0 ? m + "m" : ""
  const sDisplay = s > 0 ? s + "s" : ""
  return hDisplay + mDisplay + sDisplay
}

export function secondsToColonSeparated(d: number) {
  const h = Math.floor(d / 3600)
  const m = Math.floor((d % 3600) / 60)
  const s = Math.floor((d % 3600) % 60)

  const hDisplay = h > 0 ? (h < 10 ? "0" + h + ":" : h + ":") : ""
  const mDisplay = m < 10 ? "0" + m + ":" : m + ":"
  const sDisplay = s < 10 ? "0" + s : s
  return hDisplay + mDisplay + sDisplay
}
