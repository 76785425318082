import { BASE_MARGIN } from "$components/DesignSystem"
import { OverlayContext } from "$components/socialOverlay/OverlayContext"
import { View } from "react-native"
import { useContextSelector } from "use-context-selector"

import { BASE_RADIUS, ShadowView } from "./DesignSystem"
import { ModalDisplay } from "./ModalDisplay"
import { DAY_INFO_BAR_HEIGHT } from "./today/DayInfoBar"
import { NAV_HEIGHT } from "../navigation"

export function CalendarPopover(props: { children: React.ReactNode }) {
  const showCalendar = useContextSelector(OverlayContext, (c) => c.calendarOverlay)
  const setShowCalendar = useContextSelector(OverlayContext, (c) => c.setCalendarOverlay)

  return (
    <ModalDisplay
      unframed
      modal={
        <View
          style={{
            marginTop: NAV_HEIGHT + DAY_INFO_BAR_HEIGHT,
            marginLeft: BASE_MARGIN * 2,
            width: 320,
            position: "relative",
          }}
        >
          <ShadowView
            style={{
              width: "100%",
              borderBottomLeftRadius: BASE_RADIUS,
              borderBottomRightRadius: BASE_RADIUS,
              overflow: "hidden",
            }}
          >
            {props.children}
          </ShadowView>
        </View>
      }
      close={() => setShowCalendar(false)}
      show={showCalendar}
    />
  )
}
