/**
 * @generated SignedSource<<cf1e0f9e2230da1c7679404b68b4add9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PuzzmoQuery$variables = {
  partnerSlug?: string | null;
};
export type PuzzmoQuery$data = {
  readonly publishingPartner: {
    readonly id: string;
    readonly logoLongBlack: string;
    readonly logoLongWhite: string;
    readonly name: string;
    readonly slug: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"PuzzmoFragment">;
};
export type PuzzmoQuery = {
  response: PuzzmoQuery$data;
  variables: PuzzmoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "partnerSlug"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "partnerSlug"
    }
  ],
  "concreteType": "PublishingPartner",
  "kind": "LinkedField",
  "name": "publishingPartner",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logoLongBlack",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logoLongWhite",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatarURL",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "usernameID",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PuzzmoQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "PuzzmoFragment"
      },
      (v3/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PuzzmoQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "accounts",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "users",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v4/*: any*/),
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roles",
            "storageKey": null
          },
          (v5/*: any*/),
          (v6/*: any*/),
          (v4/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nakamaID",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notificationsCursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "settings",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "profilePrivacy",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "friendNakamaIDs",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "HasMessages",
            "kind": "LinkedField",
            "name": "hasMessages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "friends",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "groups",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserState",
        "kind": "LinkedField",
        "name": "userState",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ownerID",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nakamaLogin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nakamaPassword",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gameSettings",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v3/*: any*/)
    ]
  },
  "params": {
    "cacheID": "6d5604cc8f74e938a1356d635e9c0eec",
    "id": null,
    "metadata": {},
    "name": "PuzzmoQuery",
    "operationKind": "query",
    "text": "query PuzzmoQuery(\n  $partnerSlug: ID\n) {\n  ...PuzzmoFragment\n  publishingPartner(id: $partnerSlug) {\n    id\n    slug\n    name\n    logoLongBlack\n    logoLongWhite\n  }\n}\n\nfragment PuzzmoCurrentUserFragment on User {\n  id\n  type\n  accounts {\n    id\n    email\n    users {\n      id\n      avatarURL\n      name\n      username\n      usernameID\n    }\n  }\n  roles\n  username\n  usernameID\n  avatarURL\n  name\n  nakamaID\n  notificationsCursor\n  settings\n  profilePrivacy\n  friendNakamaIDs\n  hasMessages {\n    friends\n    groups\n  }\n}\n\nfragment PuzzmoCurrentUserStateFragment on UserState {\n  id\n  ownerID\n  nakamaLogin\n  nakamaPassword\n  gameSettings\n}\n\nfragment PuzzmoFragment on Query {\n  currentUser {\n    ...PuzzmoCurrentUserFragment\n    id\n  }\n  userState {\n    ...PuzzmoCurrentUserStateFragment\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4173bfb7c405b7f047aba71e401e05d8";

export default node;
