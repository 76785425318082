import { useWindowDimensions } from "react-native"

export const useViewport = () => {
  const dimensions = useWindowDimensions()
  return {
    vw: (number: number) => dimensions.width * (number / 100),
    vh: (number: number) => dimensions.height * (number / 100),
    vmin: (number: number) => Math.min(dimensions.width * (number / 100), dimensions.height * (number / 100)),
    vmax: (number: number) => Math.max(dimensions.width * (number / 100), dimensions.height * (number / 100)),
  }
}
