/**
 * @generated SignedSource<<0a5d14029d29863438b3427e8fefe11c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Privacy = "FriendsOnly" | "Public" | "%future added value";
export type UserType = "Paid" | "Unverified" | "User" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PuzzmoCurrentUserFragment$data = {
  readonly accounts: ReadonlyArray<{
    readonly email: string;
    readonly id: string;
    readonly users: ReadonlyArray<{
      readonly avatarURL: string;
      readonly id: string;
      readonly name: string | null;
      readonly username: string;
      readonly usernameID: string;
    }>;
  }>;
  readonly avatarURL: string;
  readonly friendNakamaIDs: ReadonlyArray<string>;
  readonly hasMessages: {
    readonly friends: boolean;
    readonly groups: boolean;
  };
  readonly id: string;
  readonly nakamaID: string;
  readonly name: string | null;
  readonly notificationsCursor: string;
  readonly profilePrivacy: Privacy;
  readonly roles: string;
  readonly settings: any;
  readonly type: UserType;
  readonly username: string;
  readonly usernameID: string;
  readonly " $fragmentType": "PuzzmoCurrentUserFragment";
};
export type PuzzmoCurrentUserFragment$key = {
  readonly " $data"?: PuzzmoCurrentUserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PuzzmoCurrentUserFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatarURL",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "usernameID",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PuzzmoCurrentUserFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Account",
      "kind": "LinkedField",
      "name": "accounts",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "users",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roles",
      "storageKey": null
    },
    (v3/*: any*/),
    (v4/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nakamaID",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notificationsCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settings",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "profilePrivacy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "friendNakamaIDs",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HasMessages",
      "kind": "LinkedField",
      "name": "hasMessages",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "friends",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "groups",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "17c40357caf72a9dc8f44921123b59ba";

export default node;
