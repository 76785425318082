import { BASE_MARGIN } from "$components/DesignSystem"
import { PlayGameHeader$data, PlayGameHeader$key } from "$relay/PlayGameHeader.graphql"
import React from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { useTheme } from "styled-rn"

import { BodyText, Row, BorderCenter } from "../DesignSystem"

export const HEADER_CONTENT_AREA = 28
export const HEADER_PADDING = 12
export const TITLE_MARGIN = 6
// odd number so keyline falls on a pixel
export const TITLE_HEIGHT = 19
export const HEADER_TOTAL_HEIGHT = HEADER_CONTENT_AREA + HEADER_PADDING + TITLE_MARGIN + TITLE_HEIGHT

const PlayQuery = graphql`
  fragment PlayGameHeader on GamePlayed {
    puzzle {
      name
      puzzle
      seriesNumber

      game {
        displayName
      }
    }
  }
`

export const PlayGameHeader = React.memo((props: { playGame: PlayGameHeader$key }) => {
  const playGame = useFragment(PlayQuery, props.playGame)
  const puz = playGame.puzzle
  const theme = useTheme()
  return (
    <Row style={{ marginTop: TITLE_MARGIN, height: TITLE_HEIGHT, justifyContent: "space-between" }}>
      <BorderCenter style={{ marginRight: BASE_MARGIN }} />
      <BodyText
        numberOfLines={1}
        style={{
          textAlign: "center",
          fontWeight: "normal",
          lineHeight: TITLE_HEIGHT,
          color: theme.a_headerText,
        }}
      >
        {getTitle(puz)}
      </BodyText>
      <BorderCenter style={{ marginLeft: BASE_MARGIN }} />
    </Row>
  )
})

export function getTitleString(displayName: string, seriesNumber: number) {
  return seriesNumber > 0 ? `${displayName} #${seriesNumber}` : `(preview) ${displayName} #${seriesNumber * -1} to go`
}

function getTitle(puz: PlayGameHeader$data["puzzle"]) {
  const title = [getTitleString(puz.game.displayName, puz.seriesNumber)]
  if (puz.name) {
    title.push(puz.name)
  }
  return title.join(" — ")
}
