/** Takes a string which is the slug commonly used in URLs and gives it a displayname */
export const gameSlugToName = (slug: string) => {
  switch (slug) {
    case "crossword":
      return "Crossword"

    case "tic-tac-toe":
      return "Tic-Tac-Toe"

    case "really-bad-chess":
      return "Really Bad Chess"

    case "typeshift":
      return "Typeshift"

    case "flip-art":
      return "Flip Art"

    case "cubeclear":
      return "CubeClear"

    case "spelltower":
      return "SpellTower"

    case "wordbind":
      return "Wordbind"
  }

  debugger
  return slug
}
