/**
 * @generated SignedSource<<22086ccfb3fdf8694f9708938786dabf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StripeButtonsManageBillingMutation$variables = {
  accountSlug: string;
  returnURL: string;
};
export type StripeButtonsManageBillingMutation$data = {
  readonly getManageBillingLink: string | null;
};
export type StripeButtonsManageBillingMutation = {
  response: StripeButtonsManageBillingMutation$data;
  variables: StripeButtonsManageBillingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountSlug"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "returnURL"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "accountSlug",
        "variableName": "accountSlug"
      },
      {
        "kind": "Variable",
        "name": "returnURL",
        "variableName": "returnURL"
      }
    ],
    "kind": "ScalarField",
    "name": "getManageBillingLink",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "StripeButtonsManageBillingMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "StripeButtonsManageBillingMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "ae0681fd52b11f2795c3972cb92c34ee",
    "id": null,
    "metadata": {},
    "name": "StripeButtonsManageBillingMutation",
    "operationKind": "mutation",
    "text": "mutation StripeButtonsManageBillingMutation(\n  $returnURL: String!\n  $accountSlug: String!\n) {\n  getManageBillingLink(returnURL: $returnURL, accountSlug: $accountSlug)\n}\n"
  }
};
})();

(node as any).hash = "bfe150e6686d466297791537a3e5fbac";

export default node;
