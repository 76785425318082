import {
  BASE_MARGIN,
  BASE_PADDING,
  BASE_RADIUS,
  BodyText,
  InlineText,
  InternalLink,
  LeftArrowIcon,
  Select,
  ThinButton,
  UiH4,
} from "$components/DesignSystem"
import { OverlayContext } from "$components/socialOverlay/OverlayContext"
import { DayInfoContextType, DayInfoContext } from "$components/today/DayInfoContext"
import { CalendarQuery } from "$relay/CalendarQuery.graphql"
import { useNavigation } from "@react-navigation/native"
import React, { useRef, useState } from "react"
import { View } from "react-native"
import { DateData, Calendar as RnCalendar } from "react-native-calendars"
import { DayProps } from "react-native-calendars/src/calendar/day"
import { Direction } from "react-native-calendars/src/types"
import { fetchQuery, graphql, useRelayEnvironment } from "react-relay"
import { useTheme } from "styled-rn"
import { useContextSelector } from "use-context-selector"

import { PulsingLoader } from "./PulsingLoader"
import { SubscribeToPayOverlay } from "./SubscribeToPayOverlay"
import { CircledCheck } from "./icons/CircledCheck"
import { RootStackParamList } from "../../types"
import { useGetUser } from "../CoreContext"
import { RightArrowIcon } from "../components/DesignSystem"
import { getIsFuture } from "../lib/getIsFuture"
import { getPlayerReadableDateString } from "../lib/getPlayerReadableDateString"
import { numberFormatter } from "../util/numberFormatter"

const Query = graphql`
  query CalendarQuery($month: Int!, $year: Int!, $gameFilter: String) {
    dailiesForAMonth(month: $month, year: $year, gameFilter: $gameFilter) {
      day
      puzzles {
        puzzle {
          slug
          name
          game {
            slug
            displayName
          }

          currentAccountGamePlayed {
            slug
            completed
            pointsAwarded
          }
        }
      }
    }
  }
`

type Puzzle = NonNullable<CalendarQuery["response"]["dailiesForAMonth"][0]["puzzles"][0]>
type GamePickerData = { slugs: string[]; displayNames: string[] }

function isRightArrowEnabled(date: Date | undefined, todayDayDate: Date | undefined) {
  if (!date || !todayDayDate) return false

  const isPastYear = date.getFullYear() < todayDayDate.getFullYear()
  const isPastMonthInCurrentYear = date.getUTCMonth() < todayDayDate.getUTCMonth() && date.getFullYear() === todayDayDate.getFullYear()
  return isPastMonthInCurrentYear || isPastYear
}

function CalendarHeader({
  activeGame,
  setActiveGame,
  gamePickerData,
  screen,
}: {
  gamePickerData: GamePickerData
  activeGame: DayInfoContextType["game"]
  setActiveGame: (game: DayInfoContextType["game"]) => void
  screen: DayInfoContextType["screen"]
}) {
  const theme = useTheme()

  // only on today page
  if (screen === "Today") {
    return (
      <View style={{ paddingHorizontal: BASE_PADDING, paddingTop: BASE_PADDING / 2 }}>
        <BodyText size="small">Filter</BodyText>
        <Select
          onChange={(v) => {
            if (v === "") {
              return setActiveGame(undefined)
            }

            setActiveGame({
              slug: v,
              displayName: gamePickerData.displayNames[gamePickerData.slugs.indexOf(v)],
            })
          }}
          value={activeGame?.slug || ""}
          values={gamePickerData.slugs}
          displays={gamePickerData.displayNames}
        />
      </View>
    )
  }

  if (activeGame) {
    return (
      <View style={{ paddingHorizontal: BASE_PADDING, paddingTop: BASE_PADDING / 2 }}>
        <BodyText
          size="small"
          style={{
            borderRadius: BASE_RADIUS,
            backgroundColor: `${theme.a_bg}`,
            paddingHorizontal: BASE_PADDING / 2,
            paddingVertical: BASE_PADDING / 3,
            textAlign: "center",
            color: theme.fg,
          }}
        >
          {activeGame.displayName} archives
        </BodyText>
      </View>
    )
  }

  return null
}

export function Calendar() {
  const user = useGetUser()
  const hasAccessToArchives = user.type === "user" && user.currentUser.type === "Paid"

  const setShowCalendar = useContextSelector(OverlayContext, (c) => c.setCalendarOverlay)

  if (!hasAccessToArchives) {
    return (
      <SubscribeToPayOverlay title="Subscribe to access archives" focus="archives" onPress={() => setShowCalendar(false)}>
        <View pointerEvents="none">
          <CalendarContent hasAccess={false} />
        </View>
      </SubscribeToPayOverlay>
    )
  }

  return <CalendarContent hasAccess={true} />
}

function CalendarContent(props: { hasAccess: boolean }) {
  const relayEnv = useRelayEnvironment()
  const theme = useTheme()
  const screen = useContextSelector(DayInfoContext, (c) => c.screen)
  const dayState = useContextSelector(DayInfoContext, (c) => c.dayState)
  const activeGameFromContext = useContextSelector(DayInfoContext, (c) => c.game)
  const nav = useNavigation()

  const [gamePickerData, setGamePickerData] = useState<GamePickerData>({
    slugs: [""],
    displayNames: ["All puzzles"],
  })
  const [activeGame, setActiveGame] = useState<DayInfoContextType["game"]>(activeGameFromContext)

  // This is the date that corresponds to the page's day parameter. It may not be today!
  // Dates may be temporarily undefined in the case where player is navigating from Today page to
  // a game page.
  const nowString = dayState ? dayState.day.substring(0, 10) : undefined
  const nowDate = dayState ? new Date(dayState.day.substring(0, 10)) : undefined

  // This is the server's actual today date.
  const todayDayDate = dayState ? new Date(dayState.todayDay) : undefined

  const [disableRightArrow, setDisableRightArrow] = useState(!isRightArrowEnabled(nowDate, todayDayDate))
  const [puzzles, setPuzzles] = useState<Record<string, Puzzle[]>>({})
  const [loading, setShowLoading] = useState(false)

  const onMonthChange = async (date: Partial<DateData>) => {
    if (!date.month) throw new Error("Need a month to query")
    if (!date.year) throw new Error("Need a year to query")
    // prevent making this query if we don't have access to archives, it'll error and
    // the error will be caught by RelayProvider.
    if (!props.hasAccess) return

    setShowLoading(true)
    const data = await fetchQuery<CalendarQuery>(relayEnv, Query, {
      month: date.month - 1,
      year: date.year,
      gameFilter: undefined,
    }).toPromise()

    if (!data) return

    setDisableRightArrow(!isRightArrowEnabled(new Date(date.year, date.month, 0), todayDayDate))

    const newPuzzles: Record<string, Puzzle[]> = {}

    // only used on today page
    const gameDataSlugs = new Set<string>()
    const gameDataDisplayNames = new Set<string>()

    data.dailiesForAMonth.forEach((daily) => {
      if (!daily.puzzles.length) return

      const date = daily.day.substring(0, 10)
      newPuzzles[date] = (daily.puzzles || []) as Puzzle[]

      const puzzles = [] as Puzzle[]
      daily.puzzles.forEach((p) => {
        if (activeGame) {
          if (p.puzzle.game.slug === activeGame?.slug) {
            puzzles.push(p)
          }
        } else {
          puzzles.push(p)
        }

        gameDataSlugs.add(p.puzzle.game.slug)
        gameDataDisplayNames.add(p.puzzle.game.displayName)
      })
    })

    setPuzzles(newPuzzles)
    setShowLoading(false)

    if (screen === "Today") {
      setGamePickerData({
        slugs: ["", ...Array.from(gameDataSlugs)],
        displayNames: ["All puzzles", ...Array.from(gameDataDisplayNames)],
      })
    }
  }

  const firstRun = useRef(true)
  if (firstRun.current && nowDate) {
    firstRun.current = false
    onMonthChange({ month: nowDate.getUTCMonth() + 1, year: nowDate.getFullYear() })
  }

  return (
    <View
      style={{
        backgroundColor: theme.a_infoBG,
      }}
    >
      <CalendarHeader screen={screen} setActiveGame={setActiveGame} activeGame={activeGame} gamePickerData={gamePickerData} />
      <RnCalendar
        current={nowString}
        hideExtraDays={true}
        style={{ paddingHorizontal: BASE_PADDING, paddingBottom: BASE_PADDING, paddingTop: 0 }}
        disableAllTouchEventsForDisabledDays
        disableAllTouchEventsForInactiveDays
        onMonthChange={onMonthChange}
        renderArrow={(dir) => <Arrow dir={dir} disable={!props.hasAccess || (dir === "right" && disableRightArrow)} />}
        dayComponent={(data) => {
          const dateString = data.date?.dateString
          if (!dateString) return null
          const isToday = dateString === dayState?.todayDay
          const isFuture = getIsFuture({ today: dayState?.todayDay, date: dateString })
          return (
            <DayButton
              screen={screen as keyof RootStackParamList}
              activeGame={activeGame}
              puzzles={puzzles[dateString] || []}
              isSelected={dateString === nowString}
              isFuture={isFuture}
              isLinkingToTodayDay={isToday && screen === "Today"}
              dateString={dateString}
              data={data}
            />
          )
        }}
        futureScrollRange={0}
        disabledByDefault={!props.hasAccess}
        disableArrowRight={!props.hasAccess || disableRightArrow}
        disableArrowLeft={!props.hasAccess}
        maxDate={dayState?.todayDay}
        theme={{
          weekVerticalMargin: 0,
          backgroundColor: "transparent",
          calendarBackground: "transparent",
          monthTextColor: theme.fg,
          indicatorColor: theme.key,
          textMonthFontFamily: "LeagueSpartan",
          textDayHeaderFontFamily: "LeagueSpartan",
          textMonthFontWeight: "600",
          textDayHeaderFontWeight: "300",
          textMonthFontSize: 16,
          textDayHeaderFontSize: 14,
          textDisabledColor: `${theme.fg}66`,
          textSectionTitleColor: `${theme.fg}99`,
          "stylesheet.calendar.header": {
            week: {
              marginTop: BASE_MARGIN / 2,
              marginBottom: BASE_MARGIN / 2,
              marginHorizontal: -BASE_MARGIN,
              paddingHorizontal: BASE_MARGIN,
              flexDirection: "row",
              justifyContent: "space-around",
              borderBottomColor: `${theme.fg}33`,
              borderBottomWidth: 1,
            },
          },
        }}
      />
      {(loading || !dayState) && (
        <View
          pointerEvents="none"
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            backgroundColor: `${theme.a_infoBG}bb`,
            zIndex: 100,
          }}
        >
          <PulsingLoader loaderColor={theme.fg} />
        </View>
      )}

      {nowString && <DaySummary isToday={Boolean(dayState?.isToday)} selectedDate={nowString} puzzles={puzzles} />}
      {screen === "Today" && todayDayDate && !dayState?.isToday && (
        <ThinButton
          onPress={() => {
            nav.navigate(screen, {})
            // reset calendar
            onMonthChange({ month: todayDayDate.getUTCMonth() + 1, year: todayDayDate.getFullYear() })
          }}
          style={{ marginBottom: BASE_MARGIN, display: "flex", marginHorizontal: BASE_MARGIN }}
          bgKey="a_puzmo"
          colorKey="keyFG"
          title={`Go to today`}
        />
      )}
    </View>
  )
}

const Arrow = ({ disable, dir }: { dir: Direction; disable: boolean }) => {
  const Component = dir === "left" ? LeftArrowIcon : RightArrowIcon
  return (
    <View
      pointerEvents={disable ? "none" : "auto"}
      style={{
        [dir === "left" ? "marginLeft" : "marginRight"]: -BASE_MARGIN,
        opacity: disable ? 0.25 : 1,
      }}
    >
      <Component size={15} colorKey={"fg"} />
    </View>
  )
}

function getLinkParamsFromPuzzle(puzzle: Puzzle["puzzle"]): [keyof RootStackParamList, Record<string, string>] {
  return puzzle.currentAccountGamePlayed
    ? ["PlayGame", { playGameSlug: puzzle.currentAccountGamePlayed.slug, gameSlug: puzzle.game.slug }]
    : ["PlayGameRedirect", { puzzleSlug: puzzle.slug, gameSlug: puzzle.game.slug }]
}

const DaySummaryItem = React.memo(function DaySummaryItem(props: { item: Puzzle }) {
  const puzzle = props.item.puzzle
  const theme = useTheme()

  if (!props.item.puzzle) return null

  const linkParams = getLinkParamsFromPuzzle(puzzle)

  return (
    <View
      style={{
        paddingBottom: BASE_PADDING * 0.25,
        paddingHorizontal: BASE_PADDING,
        marginBottom: BASE_MARGIN * 0.25,
        width: "100%",
      }}
    >
      <InternalLink
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
        screen={linkParams[0]}
        routeOpts={linkParams[1]}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            flexShrink: 1,
          }}
        >
          <View
            style={{
              opacity: props.item.puzzle?.currentAccountGamePlayed?.completed ? 1 : 0.4,
              marginRight: BASE_MARGIN * 0.5,
            }}
          >
            {props.item.puzzle.currentAccountGamePlayed ? (
              <CircledCheck width={15} fill={"key"} />
            ) : (
              <View
                style={{
                  width: 15,
                  height: 15,
                  borderRadius: 15,
                  backgroundColor: `${theme.fg}44`,
                }}
              />
            )}
          </View>
          <BodyText size="small">{puzzle.game.displayName}</BodyText>
          <BodyText numberOfLines={1} size="small" style={{ marginLeft: BASE_MARGIN * 0.5, opacity: 0.5 }}>
            {puzzle.name}
          </BodyText>
        </View>
        {props.item.puzzle.currentAccountGamePlayed?.pointsAwarded !== undefined && (
          <BodyText size="small" style={{ flexShrink: 0, marginRight: BASE_MARGIN * 0.5 }}>
            {numberFormatter(props.item.puzzle.currentAccountGamePlayed?.pointsAwarded)}
          </BodyText>
        )}
      </InternalLink>
    </View>
  )
})

function DaySummary(props: {
  selectedDate: string
  isToday: boolean
  puzzles: Record<
    string,
    {
      readonly puzzle: {
        readonly currentAccountGamePlayed: { readonly completed: boolean; readonly slug: string; readonly pointsAwarded: number } | null
        readonly game: { readonly displayName: string; readonly slug: string }
        readonly name: string | null
        readonly slug: string
      }
    }[]
  >
}) {
  const { isToday, selectedDate, puzzles } = props
  const theme = useTheme()
  const matchingPuzzles = puzzles[selectedDate]
  if (!matchingPuzzles || matchingPuzzles.length === 0) return null

  return (
    <View
      style={{
        paddingBottom: BASE_PADDING / 2,
      }}
    >
      <View
        style={{
          backgroundColor: theme.a_infoBG,
          paddingHorizontal: BASE_PADDING,
          marginBottom: BASE_MARGIN / 2,
        }}
      >
        <UiH4>{isToday ? "Today" : getPlayerReadableDateString(selectedDate)}</UiH4>
      </View>
      {matchingPuzzles.map((item) => (
        <DaySummaryItem key={item.puzzle.slug} item={item} />
      ))}
    </View>
  )
}

function DayButton(props: {
  isSelected: boolean
  isFuture: boolean
  activeGame: DayInfoContextType["game"]
  puzzles: Puzzle[]
  screen: keyof RootStackParamList
  isLinkingToTodayDay: boolean
  dateString: string
  data: DayProps & {
    date?: DateData | undefined
  }
}) {
  const { isFuture, puzzles, screen, activeGame, isLinkingToTodayDay, dateString, data, isSelected } = props
  const theme = useTheme()

  const gamesPlayed = puzzles
    .map((puzzle) => {
      return puzzle.puzzle
    })
    .filter((p) => {
      return Boolean(p?.currentAccountGamePlayed)
    })

  const completed = gamesPlayed.filter((puzzle) => puzzle.currentAccountGamePlayed?.completed)
  const ratioStarted = gamesPlayed.length / puzzles.length
  const ratioCompleted = completed.length / puzzles.length

  const matchingGame = puzzles.find((puzzle) => puzzle.puzzle.game.slug === activeGame?.slug)?.puzzle
  const score = matchingGame?.currentAccountGamePlayed?.pointsAwarded || 0

  const disabled = puzzles.length === 0 || isFuture

  let summaryDisplay = (
    <View
      style={{
        // @ts-ignore
        opacity: ratioCompleted ? 1 : 0,
        width: "50%",
        overflow: "hidden",
        backgroundColor: `${theme.fg}44`,
        height: 3,
        borderRadius: 2,
      }}
    >
      <View
        style={{
          // @ts-ignore
          width: `${ratioStarted * 100}%`,
          height: "100%",
          left: 0,
          top: 0,
          bottom: 0,
          position: "absolute",
          backgroundColor: theme.key,
          opacity: 0.5,
        }}
      />
      <View
        style={{
          width: `${ratioCompleted * 100}%`,
          height: "100%",
          left: 0,
          top: 0,
          bottom: 0,
          position: "absolute",
          backgroundColor: theme.key,
        }}
      />
    </View>
  )

  if (activeGame) {
    summaryDisplay = (
      <InlineText
        numberOfLines={1}
        bold={true}
        style={{
          marginTop: -3,
          fontSize: 9,
          color: score ? theme.keyStrong : `${theme.fg}33`,
        }}
      >
        {score ? numberFormatter(score) : "–"}
      </InlineText>
    )
  }

  let routeOpts = { day: dateString } as any
  let screenForLink = screen
  if (isLinkingToTodayDay && screen !== "PlayGame") {
    routeOpts = {}
  }

  if (matchingGame) {
    ;[screenForLink, routeOpts] = getLinkParamsFromPuzzle(matchingGame)
  }

  return (
    <InternalLink
      screen={screenForLink}
      routeOpts={routeOpts}
      wrapperStyle={{
        width: "100%",
      }}
      disabled={disabled}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        opacity: disabled ? 0.5 : 1,
        borderRadius: 0,
        backgroundColor: isSelected ? `${theme.fg}22` : "transparent",
        borderBottomColor: isSelected ? theme.a_puzmo : "transparent",
        borderBottomWidth: 2,
        paddingTop: 6,
        paddingBottom: 6,
        width: "100%",
      }}
    >
      <BodyText size="small">{data.date?.day}</BodyText>
      <View
        style={{
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: 9,
        }}
      >
        {summaryDisplay}
      </View>
    </InternalLink>
  )
}
